import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RequestService } from '../service/request.service';

@Injectable({
    providedIn: 'root'
})
export class ReferalGuard implements CanActivate {
    constructor(private _requestService: RequestService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        let token;
        token = route.queryParams['token'];
        if (token) {
            return true
        }
        if (this._requestService.isLoggedIn()) {
            return true;
        }
        else {
            this._router.navigate([this._requestService.setUrl('login')]);
            return false;
        }
    }
}
