import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { RequestService } from '../service/request.service';
import { Router } from '@angular/router';
import { GetterService } from '../service/getter-service.service';
import {
  paymentInfo,
  ProductDetail,
  RecipeDetail,
} from 'src/Model/interface/previous-order';
import { ApiService } from '../service/api.service';
import { Subscription } from 'rxjs';
import { RecipeService } from '../service/recipe.service';
import {
  ExtraObject,
  Recipe,
  CategoryWithRecipes,
} from 'src/Model/interface/productInterfaces';
import { NgxSpinnerService } from 'ngx-spinner';

interface Order {
  OrderId: number;
  OrderPlaceTime: string;
  OrderStatus: boolean;
  OrderPaymentType: string;
  OrderPaymentDeliveryType: string;
  OrderPaymentStatus: boolean;
  OrderPaymentBill: number;
  address: string;
  ProductDetail?: ProductDetail[];
  RecipeDetail?: RecipeDetail[];
  paymentInfo: paymentInfo;
  schedule?: string;
  Customer: {
    AccountNumber: string;
    CustomerEmail: string;
    CustomerFirstName: string;
    CustomerId: number;
    CustomerImageUrl: string;
    CustomerLastName: string;
    CustomerMobile: string;
    RestaurantName: string;
  };
}
interface PreviousExtras {
  Title: string;
  Quantity: number;
  Price: number;
}

interface Extras {
  Key: string;
  extras: {
    Image: string;
    Key: string;
    Price: number;
    Quantity: number;
    Title: string;
  }[];
}
@Component({
  selector: 'app-previous-order',
  templateUrl: './previous-order.component.html',
  styleUrls: ['./previous-order.component.scss'],
})
export class PreviousOrderComponent implements OnInit, OnDestroy {
  itemsTotal = 0;
  openOrders: Order[] = [];
  closedOrders: Order[] = [];
  order: Order;

  branchAddress: string;
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  data: CategoryWithRecipes[];
  kgList: boolean;
  showMessage: boolean;
  simpleCheckout: boolean;

  /*Subscriptions */
  webCustomerSub: Subscription;
  recipeServiceSub: Subscription;
  savedOrder: {
    OrderId: number;
    OrderPlaceTime: string;
    OrderStatus: boolean;
    OrderPaymentType: string;
    OrderPaymentDeliveryType: string;
    OrderPaymentStatus: boolean;
    OrderPaymentBill: number;
    address: string;
    ProductDetail?: ProductDetail[];
    RecipeDetail?: RecipeDetail[];
    paymentInfo: paymentInfo;
    schedule?: string;
    Customer: {
      AccountNumber: string;
      CustomerEmail: string;
      CustomerFirstName: string;
      CustomerId: number;
      CustomerImageUrl: string;
      CustomerLastName: string;
      CustomerMobile: string;
      RestaurantName: string;
    };
  };
  distributorLogin: boolean;
  accountNumberEnabled: boolean;

  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private _router: Router,
    private apiService: ApiService,
    private recipeService: RecipeService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    if (this._local.get('access_token')) {
      this.getCustomerPreviousOrders();
    }

    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.accountNumberEnabled = res.TemplateData?.AccountNumberEnabled;
      this.simpleCheckout = res.TemplateData.CheckoutDetail?.simpleCheckout;
      this.distributorLogin = res.TemplateData.CheckoutDetail?.DistributorLogin;
      this.kgList = res.TemplateData.AdditionalDetail?.PopupOnKgItems;
      this.branchAddress = res.BrandData.BrandBranch.BrandBranchAddress;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
    });
    this.recipeServiceSub = this.recipeService.data$.subscribe((data) => {
      if (!data) return;
      this.data = data.CategoriesAndRecipes;
    });
  }

  ngOnDestroy(): void {
    this.webCustomerSub.unsubscribe();
    this.recipeServiceSub.unsubscribe();
  }

  getCustomerPreviousOrders() {
    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let success = (res) => {
      this.spinner.hide();
      if (res['Info']['Status'] == 200) {
        let orders = res['Data']['OpenOrders'];
        orders.forEach((data) => {
          let order: Order;
          let orderPayment = data.paymentInfo.OrderPayment;

          order = {
            OrderId: data.OrderId,
            OrderPlaceTime: data.OrderPlaceTime,
            OrderStatus: data.OrderStatus,
            OrderPaymentType: orderPayment.OrderPaymentType,
            OrderPaymentDeliveryType: orderPayment.OrderPaymentDeliveryType,
            OrderPaymentStatus: orderPayment.OrderPaymentStatus,
            OrderPaymentBill: orderPayment.OrderPaymentBill,
            address: data.paymentInfo.OrderDelivery.OrderDeliveryAddress,
            ProductDetail: data.ProductDetail,
            RecipeDetail: data.RecipeDetail,
            paymentInfo: data.paymentInfo,
            Customer: data.Customer,
          };

          this.openOrders.push(order);
        });
        let closedOrders = res['Data']['ClosedOrders'];
        closedOrders.forEach((data) => {
          let order;
          let orderPayment = data.paymentInfo.OrderPayment;

          order = {
            OrderId: data.OrderId,
            OrderPlaceTime: data.OrderPlaceTime,
            OrderStatus: data.OrderStatus,
            OrderPaymentType: orderPayment.OrderPaymentType,
            OrderPaymentDeliveryType: orderPayment.OrderPaymentDeliveryType,
            OrderPaymentStatus: orderPayment.OrderPaymentStatus,
            OrderPaymentBill: orderPayment.OrderPaymentBill,
            address: data.paymentInfo.OrderDelivery.OrderDeliveryAddress,
            ProductDetail: data.ProductDetail,
            RecipeDetail: data.RecipeDetail,
            paymentInfo: data.paymentInfo,
            Customer: data.Customer,
          };

          this.closedOrders.push(order);
        });
      } else {
        console.log('Not Logged In');
      }
    };
    let error = () => {
      alert('Network Error! Something went wrong could not fetch orders');
    };
    let complete = () => {
      this.spinner.hide();
    };
    this._requestService
      .getRequest(
        'api/Order/getCustomerOrders/' +
          this._local.get('CustomerId') +
          '/' +
          this._local.get('BrandId') +
          '/' +
          0 +
          '/' +
          10,
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
        complete: complete,
      });
  }

  openOrderModal(order: Order) {
    this.order = { ...order };
    this.savedOrder = { ...order };
    this.itemsTotal = 0;
    const schedule = this.order.paymentInfo.OrderDelivery.OrderDeliverySchedule
      ? this.order.paymentInfo.OrderDelivery.OrderDeliverySchedule
      : this.order.paymentInfo.OrderPickup.OrderPickupSchedule;
    this.order.schedule = schedule == 'asap' ? 'ASAP' : schedule;
    this.order.RecipeDetail.forEach((recipe, index) => {
      this.itemsTotal +=
        recipe.OrderRecipeTotalPrice * recipe.OrderRecipeQuantity;
      if (typeof recipe.OrderRecipeExtraDetails == 'string') {
        let extras = JSON.parse(recipe.OrderRecipeExtraDetails);
        this.order.RecipeDetail[index].OrderRecipeExtraDetails = extras;
      }
    });
    this.order.ProductDetail.forEach((product, index) => {
      this.itemsTotal +=
        product.OrderProductTotalPrice * product.OrderDetailQuantity;
      if (typeof product.OrderProductExtraDetails == 'string') {
        let extras = JSON.parse(product.OrderProductExtraDetails);
        this.order.ProductDetail[index].OrderProductExtraDetails = extras;
      }
    });
    let modifiedRecipeDetail = [];
    let array = [];
    if (this.order.ProductDetail.length) {
      let recipeDetail1: ProductDetail[] = this.order.ProductDetail.filter(
        (item) => item
      );
      let recipeDetail2: ProductDetail[] = this.order.ProductDetail.filter(
        (item) => item
      );
      recipeDetail1.forEach((product) => {
        recipeDetail2.forEach((item, index) => {
          if (
            product?.Product?.ProductCategory == item?.Product?.ProductCategory
          ) {
            array.push(item);
            recipeDetail2[index] = null;
          }
        });

        if (array.length) {
          modifiedRecipeDetail.push({
            category: product.Product.ProductCategory,
            products: array,
          });
        }

        array = [];
      });
      this.order.ProductDetail = modifiedRecipeDetail;
    }
    modifiedRecipeDetail = [];
    array = [];
    if (this.order.RecipeDetail.length) {
      let recipeDetail1: RecipeDetail[] = this.order.RecipeDetail.filter(
        (item) => item
      );
      let recipeDetail2: RecipeDetail[] = this.order.RecipeDetail.filter(
        (item) => item
      );

      recipeDetail1.forEach((recipe) => {
        recipeDetail2.forEach((item, index) => {
          if (recipe?.Recipe?.CategoryTitle == item?.Recipe?.CategoryTitle) {
            array.push(item);
            recipeDetail2[index] = null;
          }
        });

        if (array.length) {
          modifiedRecipeDetail.push({
            category: recipe.Recipe.CategoryTitle,
            recipes: array,
          });
        }

        array = [];
      });
      this.order.RecipeDetail = modifiedRecipeDetail;
    }
  }
  orderAgain(order: Order, event: MouseEvent) {
    event.stopPropagation();
    this._requestService.cartCounter = 0;
    if (order.ProductDetail.length) {
      this._local.remove('cartProductItems');
      order.ProductDetail.forEach((product) => {
        let extras: PreviousExtras[] = [];
        let temp = [];
        if (typeof product.OrderProductExtraDetails == 'string') {
          temp = JSON.parse(product.OrderProductExtraDetails);
        } else {
          temp = product.OrderProductExtraDetails;
        }
        temp.forEach((extra) => {
          extras.push({
            Title: extra.ExtraTitle,
            Quantity: extra.ExtraQuantity,
            Price: extra.ExtraPrice,
          });
        });
        this.addToCartProduct(product, extras);
      });
      let url = this.simpleCheckout ? 'order-checkout' : 'checkout';
      if (this.distributorLogin) {
        url = 'checkout';
      }

      this._router.navigateByUrl(this._requestService.setUrl(url));
      return;
    }

    this._local.remove('cartRecipeItems');
    this.data.forEach((data) => {
      data.Recipes.forEach((recipe) => {
        order.RecipeDetail.forEach((item) => {
          if (recipe.RecipeId == item.Recipe.RecipeId) {
            let extras = [];
            let items: any[] = [];
            if (typeof item.OrderRecipeExtraDetails == 'string') {
              items = JSON.parse(item.OrderRecipeExtraDetails);
            } else {
              items = item.OrderRecipeExtraDetails;
            }
            let OrderRecipeExtraDetails: any[] = items.filter((item) => item);
            if (OrderRecipeExtraDetails?.length) {
              OrderRecipeExtraDetails.forEach((item) => {
                extras.push({
                  Title: item.ExtraTitle,
                  Quantity: item.ExtraQuantity,
                  Price: item.ExtraPrice,
                });
              });
            }
            this.addToCartRecipes(recipe, item, extras);
          }
        });
      });
    });
    let recipes: any[] = this._local.get('cartRecipeItems')
      ? JSON.parse(this._local.get('cartRecipeItems'))
      : [];
    if (!recipes.length) {
      this.showMessage = true;
      return;
    }
    let url = this.simpleCheckout ? 'order-checkout' : 'checkout';
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }
  addToCartRecipes(
    selectedRecipe: Recipe,
    previousRecipe: RecipeDetail,
    extraArray: PreviousExtras[]
  ) {
    let stockFlag = selectedRecipe.RecipeStock;
    if (!stockFlag) return;
    let data: any;
    let extraTotal: number = 0;
    let selectedRecipeExtras: Extras[] = [];
    if (extraArray.length) {
      /**MAtching extras price with current available recipe*/
      let recipeExtras: ExtraObject = JSON.parse(
        selectedRecipe.RecipeExtraObject.toString()
      );
      Object.entries(recipeExtras).forEach(([key, value]) => {
        if (typeof value !== 'string') {
          Object.entries(value).forEach(([k, v]) => {
            v.Key = key;
            v.Quantity = 0;
          });
        }
        let param: Extras = {
          Key: key,
          extras: value,
        };
        selectedRecipeExtras.push(param);
      });
      let temp: PreviousExtras[] = [];
      extraArray.forEach((extra) => {
        selectedRecipeExtras.forEach((item) => {
          item.extras.forEach((ext) => {
            if (extra.Title == ext.Title) {
              temp.push({
                Title: ext.Title,
                Quantity: extra.Quantity,
                Price: ext.Price,
              });
              extraTotal += ext.Price;
            }
          });
        });
      });
      extraArray = temp.filter((item) => item);
    }
    data = {
      RecipeId: selectedRecipe.RecipeId,
      RecipeTitle: selectedRecipe.RecipeTitle,
      RecipePrice: selectedRecipe.RecipePrice + extraTotal,
      recipeTotal:
        (selectedRecipe.RecipePrice + extraTotal) *
        previousRecipe.OrderRecipeQuantity,
      RecipeImage: selectedRecipe.RecipeImage,
      RecipeQuantity: previousRecipe.OrderRecipeQuantity,
      IsDiscountedItem: selectedRecipe.isDiscountedItem,
      RecipeExtraObject: extraArray,
      socialDiscountItem: selectedRecipe?.socialDiscountItem,
      ForDelivery: selectedRecipe?.ForDelivery,
      ForCollection: selectedRecipe?.ForCollection,
    };
    let recipes: any[] = this._local.get('cartRecipeItems')
      ? JSON.parse(this._local.get('cartRecipeItems'))
      : [];
    recipes.push(data);
    this._requestService.saveRecipeCartItems(recipes);

    this._requestService.storageChange.next();
  }
  addToCartProduct(
    previousProduct: ProductDetail,
    extraArray: PreviousExtras[]
  ) {
    let data;
    let extraTotal: number = 0;
    extraArray.forEach((extra) => {
      extraTotal += extra.Price;
    });
    data = {
      ProductId: previousProduct.Product.ProductId,
      ProductTitle: previousProduct.Product.ProductTitle,
      ProductOldPrice: previousProduct.Product.ProductOldPrice + extraTotal,
      ProductNewPrice: previousProduct.Product.ProductNewPrice,
      ProductTotal:
        (previousProduct.Product.ProductOldPrice + extraTotal) *
        previousProduct.OrderDetailQuantity,
      ProductImageUrl: previousProduct.Product.ProductImageUrl,
      ProductUnits: previousProduct.Product.ProductUnits,
      IsDiscountedItem: false,
      ProductQuantity: previousProduct.OrderDetailQuantity,
      ProductExtraObject: extraArray,
      ForDelivery: true,
      ForCollection: true,
    };
    if (
      this.kgList &&
      previousProduct.Product.ProductUnits.toLowerCase() == 'kg'
    ) {
      data.ProductQuantity = data.ProductQuantity.toFixed(1);
    }
    let products: any[] = this._local.get('cartProductItems')
      ? JSON.parse(this._local.get('cartProductItems'))
      : [];
    products.push(data);
    this._requestService.saveProductCartItems(products);
  }
  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
