import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostcodeIframeComponent } from './postcode-iframe.component'
import { PostcodeIframeRoutingModule } from './postcode-iframe-routing.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandlingService } from '../service/error-handling.service';

@NgModule({
  declarations: [PostcodeIframeComponent],
  imports: [
    CommonModule,
    PostcodeIframeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlingService }
  ]
})
export class PostcodeIframeModule { }
 