import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { RequestService } from 'src/app/service/request.service';
import { GetterService } from '../service/getter-service.service';

@Injectable({
  providedIn: 'root',
})
export class PaypalBtnService {
  private scriptAddedSubject = new BehaviorSubject<boolean>(false);
  scriptAdded$: Observable<boolean> = this.scriptAddedSubject.asObservable();
  brandFlavour: string | string[];
  constructor(
    private apiService: ApiService,
    private _requestService: RequestService,
    private _local: GetterService
  ) {}
  inititateScript() {
    let scriptAdded = this.scriptAddedSubject.getValue();
    if (!scriptAdded) {
      this.getWebCustomer();
    }
  }
  private getWebCustomer() {
    this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      let brandId = res.BrandData.BrandId;
      if (res.TemplateData.CheckoutDetail.PayPalEnabled) {
        this.getSandboxClientId(brandId);
      }
    });
  }
  private getSandboxClientId(brandId) {
    this.brandFlavour = window.location.pathname.split('/')[1];
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });

    this._requestService
      .getRequest('/api/Paypal/GetClientId/' + brandId, httpHeaders)
      .subscribe({
        next: (res) => {
          if (res['Info']['OK']) {
            let clientId: string = res['ClientId'];
            let envSand: string = res['Environment'];
            this.addPaypalScript(clientId)
              .then(() => {
                this.scriptAddedSubject.next(true);
              })
              .catch((error) => {
                this.scriptAddedSubject.next(false);
              });
          } else {
            this.scriptAddedSubject.next(false);
          }
        },
        error: () => {
          this.scriptAddedSubject.next(false);
        },
      });
  }
  private addPaypalScript(clientId) {
    let scriptURL =
      'https://www.paypal.com/sdk/js?client-id=' +
      clientId +
      '&locale=en_GB&disable-funding=credit,venmo&currency=GBP&components=buttons';
    return new Promise((resolve, reject) => {
      let scriptTagElement = document.createElement('script');
      scriptTagElement.src = scriptURL;
      scriptTagElement.onload = resolve;
      document.body.appendChild(scriptTagElement);
    });
  }
}
