import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';
import { DynamicColorsService } from '../service/dynamic-colors.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { PaymentSenseService } from './payment-sense.service';
declare let Connect: any;
@Component({
  selector: 'app-payment-sense',
  templateUrl: './payment-sense.component.html',
  styleUrls: ['./payment-sense.component.scss'],
})
export class PaymentSenseComponent implements OnInit, OnDestroy {
  @Input() total: number = 0;
  @Output() response = new EventEmitter<any>();
  paying: boolean;
  submitted: boolean;
  component: string;
  buttonColor: string;
  buttonTextColor: string;
  webCusSub: Subscription;
  brandName: string;
  connectE: any;
  token: any;

  constructor(
    public dynamicCss: DynamicColorsService,
    private _local: GetterService,
    private _requestService: RequestService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private paymentsenseService: PaymentSenseService
  ) {}

  ngOnInit(): void {
    this.component = window.location.pathname.split('/')[2];
    this.spinner.show();
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.brandName = res.BrandData.BrandName;
      if (res.TemplateData.CheckoutDetail.PaymentGateway == 'Paymentsense') {
        this.getToken();
      }
    });
  }
  getToken(initForm: boolean = true) {
    const body = {
      BrandFlavor: window.location.pathname.split('/')[1],
      amount: this.total,
    };
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    if (!token) return;
    const headers = new HttpHeaders({
      Authorization: 'bearer ' + token,
    });
    this._requestService
      .postRequest('api/Paymentsense/CreateToken', body, headers)
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.token = res['id'];
            this.paymentsenseService.token = this.token;
            if (initForm) {
              this.initForm(this.token);
            }
            if (!initForm) {
              const paymentDetails = {
                paymentToken: this.token,
              };
              this.connectE.updateAccessToken(paymentDetails);
            }
          }
        },
        error: () => {
          alert('Something went wrong please refresh page and try again');
        },
      });
  }
  initForm(token: string) {
    const payConfig = {
      paymentDetails: {
        paymentToken: token,
      },
      containerId: 'demo-payment',
      fontCss: ['https://fonts.googleapis.com/css?family=Do+Hyeon'],
      styles: {
        base: {
          default: {
            color: 'black',
            textDecoration: 'none',
            fontFamily: "'Do Hyeon', sans-serif",
            boxSizing: 'border-box',
            padding: '.375rem .75rem',
            boxShadow: 'none',
            fontSize: '1rem',
            borderRadius: '.25rem',
            lineHeight: '1.5',
            backgroundColor: '#fff',
          },
          focus: {
            color: '#495057',
            borderColor: '#80bdff',
          },
          error: {
            color: '#B00',
            borderColor: '#B00',
          },
          valid: {
            color: 'green',
            borderColor: 'green',
          },
          label: {
            display: 'none',
          },
        },
        cv2: {
          container: {
            width: '25%',
            float: 'left',
            boxSizing: 'border-box',
          },
          default: {
            borderRadius: '0 .25rem .25rem 0',
          },
        },
        expiryDate: {
          container: {
            width: '25%',
            float: 'left',
            borderRadius: '0rem',
          },
          default: {
            borderRadius: '0',
            borderRight: 'none',
          },
        },
        cardNumber: {
          container: {
            width: '50%',
            float: 'left',
          },
          default: {
            borderRadius: '.25rem 0 0 .25rem',
            borderRight: 'none',
          },
        },
      },
    };

    this.connectE = new Connect.ConnectE(payConfig, displayErrors);
    this.spinner.hide();
    function displayErrors(errors) {
      const errorsDiv = document.getElementById('errors');
      errorsDiv.innerHTML = '';
      if (errors && errors.length) {
        const list = document.createElement('ul');
        for (const error of errors) {
          const item = document.createElement('li');
          item.innerText = error.message;
          list.appendChild(item);
        }
        errorsDiv.appendChild(list);
      }
    }
  }
  pay() {
    const location = window.location.pathname.split('/')[2];
    this.paying = true;
    let success = (res) => {
      this.paying = false;
      this.response.emit(res);
      if ((res['Info'].Status == 200)) return;
      this.getToken(false);
    };
    let error = (res) => {
      this.paying = false;
      this.response.emit(res);
      this.getToken(false);
    };

    this.connectE
      .executePayment()
      .then((data) => {
        this.paymentsenseService.statusCode = data.statusCode;
        if (
          location == 'checkout' ||
          location == 'order-checkout' ||
          location == 'guest'
        ) {
          this.paymentsenseService
            .checkout()
            .subscribe({ next: success, error: error });
          return;
        }
        if (location == 'reservation' || location == 'reserve-iframe') {
          this.paymentsenseService
            .bookTable()
            .subscribe({ next: success, error: error });
          return;
        }
        if (location == 'giftcard') {
          this.paymentsenseService
            .sendGiftCard()
            .subscribe({ next: success, error: error });
          return;
        }
        if (location == 'pay') {
          this.paymentsenseService
            .pay()
            .subscribe({ next: success, error: error });
          return;
        }
      })
      .catch((data) => {
        this.paying = false;
        if (typeof data === 'string') {
          document.getElementById('errors').innerText = data;
        }
        if (data && data.message) {
          document.getElementById('errors').innerText = data.message;
        }
      });
  }
  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
