<div class="overlay overlayNotify" *ngIf="selectDeliveryType">
    <div class="popup">
        <div class="popup-header d-block">
            <h2 class="text-center m-0 text-danger">Please Select Delivery Type</h2>
        </div>
        <div class="popup-body p-3 d-flex justify-content-around">
            <div class="popupDelivery" (click)="changeDeliveryType('Home');selectDeliveryType=false;">
                <img src="https://perkss.blob.core.windows.net/images/customer/portal/delivery.png"
                    alt="Delivery Image">
                <h2 class="m-0">Home Delivery</h2>
                <small class="d-block" *ngIf="!DeliveryFrom && MinDeliveryTime">Approx
                    {{MinDeliveryTime!=null ? MinDeliveryTime:"N/A"}}
                    minutes</small>
                <small class="d-block" *ngIf="DeliveryFrom"><strong>{{DeliveryFrom}}</strong></small>
            </div>
            <div class="popupDelivery" (click)="changeDeliveryType('Store');selectDeliveryType=false;">
                <img src="https://perkss.blob.core.windows.net/images/customer/portal/collection.png"
                    alt="Collection Image">
                <h2 class="m-0">Collection</h2>
                <small class="d-block" *ngIf="!CollectionFrom && MinCollectionTime">Approx
                    {{MinCollectionTime!=null ? MinCollectionTime:"N/A"}}
                    minutes</small>
                <small class="d-block" *ngIf="CollectionFrom"><strong>{{CollectionFrom}}</strong></small>
            </div>
        </div>
    </div>
</div>