import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { ApiService } from '../service/api.service';
import { Subscription } from 'rxjs';
import { Category, SubCategory } from 'src/Model/interface/productInterfaces';
export interface SideMenuData {
  cat: Category;
  subCat: SubCategory;
}
@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
})
export class SideDrawerComponent implements OnInit, OnDestroy {
  selectedSubCat: number;
  brandFlavour: string;
  brand_Categories;
  selectedCatId: number;

  /* Dynamic styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor: any = '#fff';

  @Output() closeSideCat = new EventEmitter<boolean>();
  template: string;
  webCusSub: Subscription;
  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService
  ) {}
  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.template = res.TemplateData.TemplateType;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      if (this.template == 'Stellar') {
        this.getCategories();
      }
    });
  }
  getCategories() {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    this._requestService
      .getRequest(
        'api/Category/v1/getCategories/' + this.brandFlavour + '/Product',
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.brand_Categories = res['Data'];
            this.openCat(this.brand_Categories[0]);
          }
        },
      });
  }
  openCat(cat, event?) {
    event?.stopPropagation();
    var data = {
      cat: cat,
      subCat: null,
    };
    this._requestService.sideCatChanged.next(data);
    if (!cat.Subcategories?.length) {
      this.closeSideCat.emit(false);
    }
    this.selectedCatId = cat.CategoryId;
    this.selectedSubCat = cat?.Subcategories[0]?.SubcategoryId;
  }
  openSubCat(cat, subCat, event?) {
    event?.stopPropagation();
    var data = {
      cat: cat,
      subCat: subCat,
    };
    this.selectedSubCat = subCat.SubcategoryId;
    this._requestService.sideCatChanged.next(data);
    this.closeSideCat.emit(false);
  }
  close() {}
}
