import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Observable, Subscription, Subject } from 'rxjs';
import { RequestService } from '../service/request.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { GetterService } from '../service/getter-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { popupService } from '../popup/popup.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { environment } from 'src/environments/environment';
import { Category } from 'src/Model/interface/productInterfaces';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit, OnDestroy {
  @Output() updateCart = new Subject<void>();
  selectedCategory: number = 0;
  seletedSubcategory: number = 0;
  brandFlavour: string;
  /* For hiding responsive or desktop views */
  windowWidth;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  /* Dynamic styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor: any = '#fff';
  SiteBackgroundColor: string;
  WebsiteBackground: string;
  /* Categories */
  brand_Categories: Category[];
  // get products//
  products = [];
  found: boolean;
  cartTotal = 0;
  MenuWebBanner: string;
  currentEnvironment: string;
  offset: number = 0;
  limit: number = 10;
  disablePagination: boolean = false;
  allProductsFetched: boolean;
  fetchingProducts: boolean;
  customer: boolean;
  /* brandFBPage: string;
	FBLikePageImg: string; */
  message: any;
  webCusSub: Subscription;
  loggedInSub: Subscription;
  sideCatSub: Subscription;
  popupSub: Subscription;

  constructor(
    public _requestService: RequestService,
    private _local: GetterService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private popupService: popupService,
    private recomendedRecipeService: recomendationsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.currentEnvironment = environment.production ? 'PROD' : 'Dev';
    this.getCartTotal();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.checkDeviceWidth();
    });
    this.checkDeviceWidth();
    this.found = false;

    this.sideCatSub = this._requestService.sideCatChanged.subscribe((data) => {
      if (data.subCat) {
        this.selectSubCategory(data.cat, data.subCat);
        return;
      }
      this.selectCategory(data.cat);
    });
    this.popupSub = this.popupService.addedToCartSuccessful.subscribe(
      (data) => {
        this.products.forEach((prod) => {
          if (prod.ProductId == data.ProductId) {
            prod.ProductQuantity = data.ProductQuantity;
          }
        });
        this.getCartTotal();
      }
    );
    this.getWebCustomer();
    this.afterLogin();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
    this.loggedInSub.unsubscribe();
    this.resizeSubscription$.unsubscribe();
    this.sideCatSub.unsubscribe();
    this.popupSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.WebsiteBackground = res.TemplateData.DynamicStyle?.WebsiteBackground;
      this.SiteBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors?.SiteBackgroundColor;
      this.MenuWebBanner = res.TemplateData.DynamicStyle.MenuWebBanner;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.getCategories();
    });
  }
  checkDeviceWidth() {
    this.windowWidth = document.body.clientWidth;
  }

  getCategories() {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this.spinner.show();
    this._requestService
      .getRequest(
        'api/Category/v1/getCategories/' + this.brandFlavour + '/Product',
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.brand_Categories = res['Data'];
            this.selectCategory(this.brand_Categories[0]);
          }
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  selectCategory(category) {
    if (category.CategoryId == this.selectedCategory) {
      return;
    }
    document.body.scrollTop = 0;
    this.limit = 10;
    this.offset = 0;
    this.disablePagination = false;
    this.products = [];
    this.allProductsFetched = false;
    this.seletedSubcategory = 0;
    this.selectedCategory = category.CategoryId;
    if (category.Subcategories.length) {
      this.getProducts(category, category.Subcategories[0]);
    } else {
      this.seletedSubcategory = 0;
      this.getProducts(category, null);
    }
  }
  selectSubCategory(category, subcat) {
    if (subcat?.SubcategoryId == this.seletedSubcategory) {
      return;
    }
    this.limit = 10;
    this.offset = 0;
    this.disablePagination = false;
    this.products = [];
    this.allProductsFetched = false;
    document.body.scrollTop = 0;
    this.getProducts(category, subcat);
  }

  getProducts(category, subCat) {
    this.fetchingProducts = true;
    // let params = new HttpParams();
    // params = params.append('BrandId', this._local.get('BrandId'));
    // params = params.append('CategoryId', category?.CategoryId);
    // if (subCat) {
    //   params = params.append('SubcategoryId', subCat.SubcategoryId);
    //   this.seletedSubcategory = subCat.SubcategoryId;
    // }
    // params = params.append('Offset', this.offset.toString());
    // params = params.append('Limit', this.limit.toString());
    // if (JSON.parse(this._local.get('CustomerId'))) {
    //   params = params.append('FK_CustomerId', this._local.get('CustomerId'));
    // }

    const params = {
      BrandId: this._local.get('BrandId'),
      CategoryId: category?.CategoryId,
      Offset: this.offset,
      Limit: this.limit,
    };
    if (subCat) {
      params['SubcategoryId'] = subCat.SubcategoryId;
      this.seletedSubcategory = subCat.SubcategoryId;
    }
    if (JSON.parse(this._local.get('CustomerId'))) {
      params['FK_CustomerId'] = this._local.get('CustomerId');
    }
    this._requestService
      .postRequest('api/Product/getProducts', params, [])
      .subscribe({
        next: (res) => {
          this.fetchingProducts = false;
          if (res['Info']['Status'] == 200) {
            let products = res['Data'];
            products.forEach((prod) => {
              this.products.push(prod);
            });
            this.products.forEach((product) => {
              product.ProductQuantity = 0;
            });
            let cart = JSON.parse(this._local.get('cartProductItems'));
            cart?.forEach((product) => {
              this.products.forEach((item) => {
                if (item.ProductId == product.ProductId) {
                  item.ProductQuantity = product.ProductQuantity;
                }
              });
            });
            this.found = true;
            this.disablePagination = false;
          } else if (res['Info']['Status'] == 404) {
            this.allProductsFetched = true;
          } else if (res['Info']['Status'] == 400) {
            this.found = false;
            this.products = [];
          }
        },
        error: () => {
          this.found = false;
          this.products = [];
          this.fetchingProducts = false;
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  openCheckout() {
    this.recomendedRecipeService.showRecomendations.next();
  }
  /*Cart*/
  goToCart() {
    document.getElementById('checkoutButton')?.scrollIntoView();
  }

  updateCartfromSideCart(product) {
    this.getCartTotal();
    if (product.flag == 'removed') {
      this.products?.forEach((item) => {
        if (item.ProductId == product.item.ProductId) {
          item.ProductQuantity = 0;
        }
      });
      return;
    }
    if (product.flag == '-' && product.item.ProductQuantity == 0) {
      this.products?.forEach((item) => {
        if (item.ProductId == product.item.ProductId) {
          item.ProductQuantity = 0;
        }
      });
      return;
    }

    this.products?.forEach((item) => {
      if (item.ProductId == product.item.ProductId) {
        item.ProductQuantity = product.item.ProductQuantity;
      }
    });
  }
  updateProductKgList(product) {
    this.products.forEach((item) => {
      if (item.ProductId == product.ProductId) {
        item.ProductQuantity = product.ProductQuantity;
      }
    });
  }
  getCartTotal() {
    let productTotal = 0;
    let cart = JSON.parse(this._local.get('cartProductItems'));
    cart?.forEach((product) => {
      productTotal += product.ProductTotal;
    });
    this.cartTotal = productTotal;
  }
  scrolled(event) {
    if (this.allProductsFetched) {
      return;
    }
    this.disablePagination = true;
    this.offset = this.limit;
    this.limit += 10;
    let category;
    let subCat;
    this.brand_Categories?.forEach((cat) => {
      if (cat.CategoryId == this.selectedCategory) {
        category = cat;
      }
    });
    if (category?.Subcategories?.length) {
      category.Subcategories.forEach((category) => {
        if (this.seletedSubcategory === category.SubcategoryId) {
          subCat = category;
        }
      });
    }
    this.getProducts(category, subCat);
  }
  scrollToTop() {
    document.body.scrollTop = 0;
  }

  afterLogin() {
    this.customer = this._requestService.isLoggedIn();
    this.loggedInSub = this.userService.isLoggedIn$.subscribe((action) => {
      this.customer = action;
      if (action) {
        this.customer = this._requestService.isLoggedIn();
      }
    });
  }
}
