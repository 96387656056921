import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { popupService } from '../popup/popup.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';
import { favouritesService } from './favourites.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
  /* providers: [
    favouritesService
  ] */
})
export class FavouritesComponent implements OnInit {
  favourites$: Observable<any[]>;
  cartTotal$: Observable<any>;
  webCustomerSub: Subscription;
  /* Dynamic styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor: any = '#fff';
  MenuWebBanner: string;
  spinnerSub: Subscription;
  popupSub: Subscription;
  constructor(
    private favouriteService: favouritesService,
    private apiService: ApiService,
    private recomendedRecipeService: recomendationsService,
    private popupService: popupService,
    private spinner: NgxSpinnerService,
    public _requestService:RequestService
  ) {
    this.popupSub = this.popupService.addedToCartSuccessful.subscribe(
      (product) => {
        this.favouriteService.addedToCart(product);
      }
    );
    this.spinnerSub = this.favouriteService.spinner$.subscribe((spin) => {
      spin ? this.spinner.show() : this.spinner.hide();
    });
    this.favourites$ = this.favouriteService.favourites$;
    this.cartTotal$ = this.favouriteService.cartTotal$;
    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.MenuWebBanner = res.TemplateData.DynamicStyle.MenuWebBanner;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
    });
  }

  ngOnInit(): void {
    this.loadFav();
  }
  loadFav() {
    this.favouriteService.loadFavs();
  }
  getCartTotal() {
    this.favouriteService.getCartTotal();
  }
  updateCartfromSideCart(product) {
    this.favouriteService.syncWithSideCart(product);
  }
  openCheckout() {
    this.recomendedRecipeService.showRecomendations.next();
  }
  goToCart() {
    document.getElementById('checkoutButton')?.scrollIntoView();
  }
  ngOnDestroy() {
    this.webCustomerSub.unsubscribe();
    this.spinnerSub.unsubscribe();
    this.popupSub.unsubscribe();
  }
}
