import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { GetterService } from './getter-service.service';
import { UserService } from './user.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  brandFlavour;
  constructor(private _local: GetterService, private userService: UserService) {
    this.brandFlavour = window.location.pathname.split('/')[1];
  }

  //function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //how to update the request Parameters
    const access = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');

    const token = 'bearer ' + access;

    const baseUrl =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href;

    const tenantUrl = baseUrl.split('/')[2];

    const updatedRequest = request.clone({
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: token,
        BrandFlavor: this.brandFlavour,
        TenantUrl: tenantUrl,
      }),
    });

    //logging the updated Parameters to browser's console
    return next.handle(updatedRequest).pipe(
      tap((event) => {
        //logging the http response to browser's console in case of a success
        if (event instanceof HttpResponse) {
          console.log('Done');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          if (this._local.get('access_token')) {
            this.userService.refreshToken();
          }
        }
        return throwError(() => {
          return error;
        });
      })
    );
  }
}
