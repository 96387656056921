<div
  [ngStyle]="{
    background: 'url(' + WebsiteBackground + ')',
    'background-color': SiteBackgroundColor
  }"
>
  <div
    class="btnGroup br-10px d-flex d-md-none justify-content-evenly align-item-center flex-row position-sticky"
    [ngStyle]="{ 'background-color': SelectedButtonColor }"
  >
    <p
      class="text-center m-0 pt-3 pb-3 fs-6"
      [ngStyle]="{ color: SelectedButtonTextColor }"
      (click)="scrollToCart()"
    >
      {{ _requestService.cartCounter }} Items
    </p>
    <span class="bg-light text-dark pt-3 pb-3">
      <p class="m-0 fs-6 ps-1 pe-1">
        Total: {{ cartTotal | currency: "GBP":"symbol":"1.2-2" }}
      </p>
    </span>
    <p
      class="text-center m-0 pt-3 pb-3 fs-6"
      (click)="openCheckout()"
      [ngStyle]="{ color: SelectedButtonTextColor }"
    >
      Checkout
    </p>
  </div>
  <div
    class="orderStatus CustomContainer"
    *ngIf="latestOrders.length && orderStatus"
  >
    <h2 class="text-center">{{ orderStatus?.Title }}</h2>
    <div *ngFor="let order of latestOrders" class="order row">
      <h3 class="m-0">Order# {{ order.OrderId }}</h3>
      <img
        src="{{ orderStatus.AcceptedImage }}"
        alt="Order Accepted Image"
        *ngIf="order.OrderStatus == 'Accepted'"
      />
      <img
        src="{{ orderStatus.RequestedImage }}"
        alt="Order Requested Image"
        *ngIf="order.OrderStatus == 'Requested'"
      />
      <img
        src="{{ orderStatus.CompletedImage }}"
        alt="Order Completed Image"
        *ngIf="order.OrderStatus == 'Completed'"
      />
    </div>
  </div>
  <section
    class="recipeResponsiveView"
    id="recipeResponsiveView"
    *ngIf="windowWidth < 768"
  >
    <div class="container-fluid">
      <div
        class="offers cursor-pointer d-flex align-items-center justify-content-between flex-row p-20 mb-2"
        *ngIf="offers"
        [ngStyle]="{
          color: offers?.TitleColor,
          'background-color': offers?.BackgroundColor
        }"
        (click)="brandOffers()"
      >
        <h2 class="mb-0 p-0" [ngStyle]="{ color: offers?.TitleColor }">
          {{ offers?.Title }}
        </h2>
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </div>
      <div *ngIf="showOffersMobile">
        <div *ngFor="let offer of offers.OfferTypes">
          <div class="RecipeCard p-3">
            <div>
              <h1>{{ offer.Title }}</h1>
              <p>{{ offer.Description }}</p>
            </div>
            <div class="recipeImage p-0" *ngIf="offer.Image">
              <img src="{{ offer.Image }}" alt="offer Image" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="catMessage"
        *ngIf="cartMessage"
        [ngStyle]="{
          'background-color': cartMessage?.BackgroundColor,
          border: '1px solid ' + cartMessage?.BorderColor
        }"
      >
        <h2
          class="mb-1 p-2 text-center"
          [ngStyle]="{
            color: cartMessage?.TextColor
          }"
        >
          {{ cartMessage?.Text }}
        </h2>
      </div>
    </div>
    <!--post code mobile-->
    <div
      id="sideCartContainerListView"
      class="sideCartContainerListView container-fluid"
    >
      <div Class="positionSticky">
        <div class="alert alert-success" *ngIf="CheckoutNotice">
          <strong>{{ CheckoutNotice }} {{ DeliveryFrom }}</strong>
        </div>
        <div
          class="alert alert-danger"
          *ngIf="ClosingNotice"
          [ngStyle]="{ 'background-color': ClosingNoticeBackground }"
        >
          <strong [ngStyle]="{ color: ClosingNoticeTextColor }">{{
            ClosingNotice
          }}</strong>
        </div>

        <div
          class="mb-3 minimumTimesContainer bg-light p-1"
          *ngIf="ShowCollection && ShowDelivery && MinDeliveryTime != 0"
        >
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Home')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Home'"
              name="homeRadio"
              id="homeRadio"
            />
            <label class="form-check-label cursor-pointer" for="homeRadio">
              <p class="m-0 tex-center">Home Delivery</p>
              <small
                class="text-center"
                *ngIf="!DeliveryFrom && MinDeliveryTime"
                ><strong>
                  Approx
                  {{ MinDeliveryTime != null ? MinDeliveryTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="DeliveryFrom"
                ><strong>{{ DeliveryFrom }}</strong></small
              >
            </label>
          </div>
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center border-start border-dark p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Store')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Store'"
              name="collectionRadio"
              id="collectionRadio"
            />
            <label
              class="form-check-label cursor-pointer"
              for="collectionRadio"
            >
              <p class="m-0 text-center">Collection</p>
              <small
                class="text-center"
                *ngIf="!CollectionFrom && MinCollectionTime"
                ><strong>
                  Approx
                  {{ MinCollectionTime != null ? MinCollectionTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="CollectionFrom"
                ><strong>{{ CollectionFrom }}</strong></small
              >
            </label>
          </div>
        </div>
        <!--post code validation -->
        <div
          *ngIf="
            ShowDelivery && ShowCollection
              ? deliveryType == 'Home' &&
                postcodeValidation?.ShowPostcodeValidityField
              : postcodeValidation?.ShowPostcodeValidityField
          "
          class="mb-3 postCodeValidation"
        >
          <h4 class="text-center">
            <b>{{ postcodeValidation?.PostcodeValidityField.Title }} </b>
          </h4>
          <div class="postCodeWarapper">
            <div class="p-relative w-100">
              <input
                name="manualCode"
                type="text"
                class="form-control post-code postCodeInputField"
                placeholder="Enter Post Code"
                (keyup.enter)="checkPostCodeValidity(manualPostCode)"
                maxlength="10"
                [(ngModel)]="manualPostCode"
                ngModel
              />
              <span
                class="spinner-border text-success"
                *ngIf="postSpinner"
              ></span>
            </div>
            <button
              class="btn checkBtn"
              [disabled]="!manualPostCode?.length"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              type="button"
              (click)="checkPostCodeValidity(manualPostCode)"
            >
              Check Delivery
            </button>
          </div>
          <p class="text-success" *ngIf="validPostCode">
            We are delivering to <b>{{ enteredPostCode }}</b>
          </p>
          <p class="text-danger" *ngIf="InvalidPostCode">
            <b>{{ postcodeValidation?.PostcodeValidityField.ErrorMessage }}</b>
          </p>
        </div>
      </div>
    </div>
    <div
      class="container-fluid"
      *ngIf="!hotCategoryList?.length"
      (click)="showCatsOnClick ? (showCats = true) : ''"
    >
      <h1
        class="text-center categoryMenu"
        [ngStyle]="{
          color: MobileMenuTextColor,
          boder: '1px solid ' + MobileMenuBorderColor,
          'background-color': MobileMenuBackgroundColor
        }"
      >
        Menu<span
          *ngIf="!currentEnvironment"
          [ngStyle]="{ color: MobileMenuTextColor }"
          >-Test Version</span
        >
      </h1>
    </div>
    <div class="row m-0 mb-3" *ngIf="hotCategoryList?.length">
      <div
        class="col-md-12 d-flex justify-content-center align-items-center"
        id="component-margin"
      >
        <div
          *ngFor="
            let category of hotCategoryList;
            let first = first;
            let last = last;
            let i = index
          "
          class="w-100 h-100"
        >
          <div
            class="hotCategory h-100 d-flex justify-content-center align-items-center"
            [ngStyle]="
              selectedHotCat == category?.Label
                ? {
                    color: category?.SelectedTextColor,
                    'background-color': category?.SelectedBackgroundColor
                  }
                : {
                    color: category?.UnSelectedTextColor,
                    'background-color': category?.UnSelectedBackgroundColor
                  }
            "
            (click)="showCategories(category)"
          >
            <p class="mb-0 hotCatLabel">
              <span *ngIf="!category?.Label.includes('&')">{{
                category?.Label
              }}</span>
              <span *ngIf="category?.Label.includes('&')">
                <span
                  *ngFor="
                    let char of category?.Label.split('&');
                    let last = last
                  "
                >
                  <p class="m-0">{{ char }}</p>
                  <span *ngIf="!last">&</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--post code mobile end-->
    <div *ngIf="showCatsOnClick ? showCats : true">
      <div
        *ngFor="let i = index; let category; of: brand_Categories"
        [id]="category.CategoryId"
      >
        <div class="container-fluid">
          <div class="custom-accord">
            <p
              [ngStyle]="{
                'background-color': category?.CategoryBackgroundColor
                  ? category?.CategoryBackgroundColor
                  : MobileMenuCategoryBackgroundColor,
                color: category?.CategoryTextColor
                  ? category?.CategoryTextColor
                  : MobileMenuCategoryTextColor
              }"
              (click)="toggelRecipiesOnMobile(category)"
            >
              {{ category?.CategoryTitle || ""
              }}<i class="fa fa-caret-down" aria-hidden="true"></i>
            </p>
            <div>
              <div *ngIf="this.selectedCategory == category?.CategoryId">
                <div class="RecipeCardHeader" *ngIf="categoryImage">
                  <img src="{{ categoryImage }}" alt="category image" />
                </div>
                <app-recipe-card-list-style
                  [recipes]="recipes"
                ></app-recipe-card-list-style>
                <div *ngIf="!recipes?.length && notfound">
                  <img
                    src="../../assets/img/not_found.png"
                    class="notFoundImage"
                  />
                  <h3 class="text-center text-danger">Didn’t find anything</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="AllergensMenuSection">
      <div
        *ngFor="let i = index; let category; of: AllergensMenuList"
        [id]="category.CategoryId"
      >
        <div class="container-fluid">
          <div class="custom-accord">
            <p
              [ngStyle]="{
                'background-color': category?.CategoryBackgroundColor
                  ? category?.CategoryBackgroundColor
                  : MobileMenuCategoryBackgroundColor,
                color: category?.CategoryTextColor
                  ? category?.CategoryTextColor
                  : MobileMenuCategoryTextColor
              }"
              (click)="getAllergenRecipies(category)"
            >
              {{ category?.Title || ""
              }}<i class="fa fa-caret-down" aria-hidden="true"></i>
            </p>
            <div *ngIf="this.selectedAllergen == category?.Title">
              <app-recipe-card-list-style
                [recipes]="recipes"
              ></app-recipe-card-list-style>
              <div *ngIf="!recipes?.length && notfound">
                <img
                  src="../../assets/img/not_found.png"
                  class="notFoundImage"
                />
                <h3 class="text-center text-danger">Didn’t find anything</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Desktop View-->

  <div class="CustomContainer">
    <div class="row p-0">
      <div class="recipeDesktopView" *ngIf="windowWidth >= 768">
        <div class="row">
          <div class="col-md-4">
            <div class="positionSticky">
              <div
                class="offers cursor-pointer"
                *ngIf="offers"
                [ngStyle]="{ 'background-color': offers?.BackgroundColor }"
                (click)="brandOffers()"
              >
                <h2 class="mb-1 p-2" [ngStyle]="{ color: offers?.TitleColor }">
                  {{ offers?.Title }}
                </h2>
              </div>
              <div class="recipeDesktopContent p-2 bg-light">
                <h1 class="mb-0 p-2">
                  Menu<span class="text-danger" *ngIf="!currentEnvironment"
                    >-Test Version</span
                  >
                </h1>
                <hr class="mt-0" />
                <div>
                  <ul class="p-0">
                    <li
                      class="categoryItem fs-5"
                      *ngFor="let i = index; let category; of: brand_Categories"
                      (click)="getRecipes(category)"
                      [ngStyle]="
                        selectedID == category.CategoryId
                          ? {
                              'background-color': SelectedButtonColor,
                              color: SelectedButtonTextColor
                            }
                          : {
                              'background-color':
                                category?.CategoryBackgroundColor,
                              color: category?.CategoryTextColor
                            }
                      "
                    >
                      {{ category?.CategoryTitle }}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="recipeDesktopContent p-2 mt-3"
                *ngIf="AllergensMenuSection"
                [ngStyle]="{ 'background-color': AllergenBackgroundColor }"
              >
                <ul class="p-0">
                  <li
                    class="categoryItem"
                    *ngFor="let i = index; let category; of: AllergensMenuList"
                    (click)="getAllergenRecipies(category)"
                    [ngStyle]="
                      selectedAllergen == category.Title
                        ? { 'background-color': SelectedAllergenColor }
                        : { 'background-color': UnSelectedAllergenColor }
                    "
                  >
                    <a
                      href="javascript:void(0);"
                      class="text-decoration-none"
                      [ngStyle]="
                        selectedAllergen == category.Title
                          ? { color: SelectedButtonTextColor }
                          : { color: category?.CategoryTextColor }
                      "
                    >
                      {{ category?.Title || "" }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-8 positionSticky" id="recipes">
            <div *ngIf="!recipes && !categoryImage && offers">
              <div *ngFor="let offer of offers.OfferTypes">
                <div class="RecipeCard p-3 bg-light">
                  <div>
                    <h1>{{ offer.Title }}</h1>
                    <p>{{ offer.Description }}</p>
                  </div>
                  <div class="recipeImage p-0" *ngIf="offer.Image">
                    <img src="{{ offer.Image }}" alt="offer Image" />
                  </div>
                </div>
              </div>
            </div>
            <div class="RecipeCardHeader" *ngIf="categoryImage && categoryName">
              <div>
                <img src="{{ categoryImage }}" alt="category image" />
              </div>
              <h2 class="text-center">{{ categoryName }}</h2>
            </div>
            <app-recipe-card-list-style
              [recipes]="recipes"
            ></app-recipe-card-list-style>
            <div *ngIf="recipes && !recipes?.length && notfound">
              <img src="../../assets/img/not_found.png" class="notFoundImage" />
              <h3 class="text-center text-danger">Didn’t find anything</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="sideCartContainerListView" class="sideCartContainerListView">
        <div Class="positionSticky">
          <div *ngIf="windowWidth >= 768">
            <div class="alert alert-success" *ngIf="CheckoutNotice">
              <strong>{{ CheckoutNotice }} {{ DeliveryFrom }}</strong>
            </div>
            <div
              class="alert alert-danger"
              *ngIf="ClosingNotice"
              [ngStyle]="{ 'background-color': ClosingNoticeBackground }"
            >
              <strong [ngStyle]="{ color: ClosingNoticeTextColor }">{{
                ClosingNotice
              }}</strong>
            </div>

            <div
              class="mb-3 minimumTimesContainer bg-light p-1"
              *ngIf="ShowCollection && ShowDelivery && MinDeliveryTime != 0"
            >
              <div
                class="form-check d-flex flex-column justify-content-center align-items-center p-0 w-100 cursor-pointer"
                (click)="changeDeliveryType('Home')"
              >
                <input
                  class="form-check-input m-0"
                  type="radio"
                  [checked]="deliveryType == 'Home'"
                  name="homeRadio"
                  id="homeRadio"
                />
                <label class="form-check-label cursor-pointer" for="homeRadio">
                  <p class="m-0 text-center">Home Delivery</p>
                  <small
                    class="text-center"
                    *ngIf="!DeliveryFrom && MinDeliveryTime"
                    ><strong>
                      Approx
                      {{ MinDeliveryTime != null ? MinDeliveryTime : "N/A" }}
                      minutes
                    </strong></small
                  >
                  <small class="d-block text-center" *ngIf="DeliveryFrom"
                    ><strong>{{ DeliveryFrom }}</strong></small
                  >
                </label>
              </div>
              <div
                class="form-check d-flex flex-column justify-content-center align-items-center border-start border-dark p-0 w-100 cursor-pointer"
                (click)="changeDeliveryType('Store')"
              >
                <input
                  class="form-check-input m-0"
                  type="radio"
                  [checked]="deliveryType == 'Store'"
                  name="collectionRadio"
                  id="collectionRadio"
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="collectionRadio"
                >
                  <p class="m-0 text-center">Collection</p>
                  <small
                    class="text-center"
                    *ngIf="!CollectionFrom && MinCollectionTime"
                    ><strong>
                      Approx
                      {{
                        MinCollectionTime != null ? MinCollectionTime : "N/A"
                      }}
                      minutes
                    </strong></small
                  >
                  <small class="d-block text-center" *ngIf="CollectionFrom"
                    ><strong>{{ CollectionFrom }}</strong></small
                  >
                </label>
              </div>
            </div>
            <!--post code validation -->
            <div
              *ngIf="
                ShowDelivery && ShowCollection
                  ? deliveryType == 'Home' &&
                    postcodeValidation?.ShowPostcodeValidityField
                  : postcodeValidation?.ShowPostcodeValidityField
              "
              class="mb-3 postCodeValidation"
            >
              <h4 class="text-center">
                <b>{{ postcodeValidation?.PostcodeValidityField.Title }} </b>
              </h4>
              <div class="postCodeWarapper">
                <div class="p-relative w-100">
                  <input
                    name="manualCode"
                    type="text"
                    class="form-control post-code postCodeInputField"
                    placeholder="Enter Post Code"
                    (keyup.enter)="checkPostCodeValidity(manualPostCode)"
                    maxlength="10"
                    [(ngModel)]="manualPostCode"
                    ngModel
                  />
                  <span
                    class="spinner-border text-success"
                    *ngIf="postSpinner"
                  ></span>
                </div>
                <button
                  class="btn checkBtn"
                  [disabled]="!manualPostCode?.length"
                  [ngStyle]="{
                    'background-color': SelectedButtonColor,
                    color: SelectedButtonTextColor
                  }"
                  type="button"
                  (click)="checkPostCodeValidity(manualPostCode)"
                >
                  Check Delivery
                </button>
              </div>
              <p class="text-success" *ngIf="validPostCode">
                We are delivering to <b>{{ enteredPostCode }}</b>
              </p>
              <p class="text-danger" *ngIf="InvalidPostCode">
                <b>{{
                  postcodeValidation?.PostcodeValidityField.ErrorMessage
                }}</b>
              </p>
            </div>
            <!--postcode validation end-->
          </div>
          <app-side-cart
            id="checkoutButton"
            (updateCart)="updateCartfromSideCart()"
            (checkout)="openCheckout()"
          >
          </app-side-cart>
          <div
            class="catMessage"
            *ngIf="cartMessage && windowWidth >= 768"
            [ngStyle]="{
              'background-color': cartMessage?.BackgroundColor,
              border: '1px solid ' + cartMessage?.BorderColor
            }"
          >
            <h2
              class="mb-1 p-2 text-center"
              [ngStyle]="{
                color: cartMessage?.TextColor
              }"
            >
              {{ cartMessage?.Text }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>

<div class="recentOrderBox" *ngIf="RecentOrderMessage">
  <app-recent-order
    [deliveryType]="deliveryType ? deliveryType : 'Home'"
  ></app-recent-order>
</div>
