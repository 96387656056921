import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicColorsService } from 'src/app/service/dynamic-colors.service';
import { InquiryPopupService } from './inquiry-popup.service';

@Component({
  selector: 'app-inquiry-popup',
  templateUrl: './inquiry-popup.component.html',
  styleUrls: ['./inquiry-popup.component.scss'],
})
export class InquiryPopupComponent implements OnInit {
  showPopup: Observable<boolean>;
  showMessage: boolean;
  country: string = 'Country';
  branches: string = 'Branches';
  currentCountryCode: string;
  countries = ['Belgium', 'Cyprus', 'Denmark', 'Finland', 'United Kingdom'];
  constructor(
    private inquiryPopupService: InquiryPopupService,
    public dynamicCss: DynamicColorsService,
    private HTTPClient: HttpClient
  ) {
    this.showPopup = this.inquiryPopupService.showMessage;
   // this.ipLookUp();
  }

  ngOnInit(): void {}
  // ipLookUp() {
  //   const headers: any = new HttpHeaders({
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json',
  //   });
  //   this.HTTPClient.get('http://ip-api.com/json', headers).subscribe((res) => {
  //     let exists = this.countries.indexOf(res['country']);
  //     if (exists >= 0) {
  //       this.country = res['country'];
  //       return;
  //     }
  //     this.country = 'United Kingdom';
  //   });
  // }
  hidePopup() {
    this.inquiryPopupService.hidePopup();
  }
  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
