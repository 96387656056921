import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '../app/service/request.service';
import { Router, NavigationEnd } from '@angular/router';
import { GetterService } from '../app/service/getter-service.service';
import { Subscription } from 'rxjs';
import { ApiService } from './service/api.service';
import { WebCustomer } from 'src/Model/interface/WebCustomer';
import { environment } from 'src/environments/environment';
declare let fbq: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ordering-system';
  isHeaderVisible: boolean = true;
  isFooterVisible: boolean = true;
  isAllergenseFooterVisible: boolean = false;
  isBottomMenuVisible: boolean = true;
  whatsappno: number;
  brand_Name: string;
  /*cart id*/
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  fbVerification: HTMLMetaElement = document.querySelector('#fbVerification');
  fbPixelScript: HTMLScriptElement;
  paymentSenseSDK: HTMLScriptElement;
  /* Bottom Menu */
  bottomMenu: boolean = false;

  webCusSub: Subscription;

  constructor(
    private _requestService: RequestService,
    private _router: Router,
    private _local: GetterService,
    private apiService: ApiService
  ) {
    const app = window.location.pathname.split('/')[2] === 'app-menu';

    if (!JSON.parse(this._local.getSessionItem('app'))) {
      this._local.setSessionItem('app', app);
    }

    console.log(
      '%c' + 'APP VERSION : ' + environment.appVersion,
      'background: red; color: #fff;padding:10px'
    );
    if (this._local.get('access_token') == 'guest') {
      this._local.remove('guestEmail');
      this._local.remove('guestLastName');
      this._local.remove('guestFirstName');
      this._local.remove('guestContact');
      this._local.remove('access_token');
    }
  }

  ngOnInit() {
    this._local.set('deliveryType', ''); //for RecipeListView postcode validation
    this.favIcon = document.querySelector('#appIcon');
    this.fbPixelScript = document.querySelector('#FbPixelCode');
    this.paymentSenseSDK = document.querySelector('#paymentSenseSDK');
    this.paymentSenseSDK.src = environment.PaymentSenseSDK;
    this.isHeaderVisible = true;
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) document.body.scrollTop = 0;
    });
    this.getwebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  setHeaderVisibility(visible: boolean) {
    this.isHeaderVisible = visible;
  }
  setBottomMenuVisibility(visible: boolean) {
    this.isBottomMenuVisible = visible;
  }
  setFooterVisibility(visible: boolean) {
    this.isFooterVisible = visible;
  }
  setAllergensFooter(visible: boolean) {
    this.isAllergenseFooterVisible = visible;
  }

  getwebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.bottomMenu = res.TemplateData.BottomMenu?.IsEnabled;
      this.favIcon.href = res.TemplateData.BusinessInfo.FavIcon;
      this.whatsappno = res.TemplateData.BusinessInfo.WhatsAppNo;
      this.brand_Name = res.BrandData.BrandName;

      this._local.set('BrandId', res.BrandData.BrandId);
      this.addFbCode(res);
    });
  }
  addFbCode(res: WebCustomer) {
    if (!res.TemplateData?.FacebookPixelCode?.script) return;

    this.fbVerification.setAttribute(
      'content',
      res.TemplateData.FacebookPixelCode.fbverification
    );
    let s = window.document.createElement('script');
    s.id = 'fbPixelScript';
    s.type = 'text/javascript';
    s.innerHTML = res.TemplateData.FacebookPixelCode.script;
    s.onload = () => {
      fbq('track', 'PageView');
    };
    window.document.body.appendChild(s);
  }

  openMobileWhatsApp() {
    window.open(
      'https://api.whatsapp.com/send?phone=' + this.whatsappno,
      '_blank'
    );
  }
  openWhatsApp() {
    window.open(
      'https://web.whatsapp.com/send?phone=' + this.whatsappno,
      '_blank'
    );
  }
  openUrl() {
    this._router.navigateByUrl(this._requestService.setUrl('newCheckout'));
  }
}
