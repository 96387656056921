import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GetterService } from 'src/app/service/getter-service.service';
import { PaypalBtnService } from '../paypalBtn.service';
import { CheckoutService } from '../../checkout/checkout.service';
import { environment } from 'src/environments/environment';
import { PayService } from 'src/app/pay/pay.service';
import { NewReservationService } from 'src/app/reservation/reservation.service';
import { SendGiftcardService } from 'src/app/send-giftcard/send-giftcard.service';
import { ErrorHandlingService } from 'src/app/service/error-handling.service';
declare let paypal: any;
@Component({
  selector: 'app-paypal-card',
  templateUrl: './paypal-card.component.html',
  styleUrls: ['./paypal-card.component.scss'],
})
export class PaypalCardComponent implements OnInit, OnDestroy {
  @Input() total: number = 0;
  @Input() email: string = '';
  @Output() response = new EventEmitter();
  guestCustomer: boolean;
  component: string;
  loading: boolean;
  /* Subscriptions */
  scriptSub: Subscription;
  brandFlavour: string;

  constructor(
    private _local: GetterService,
    private paypalBtnService: PaypalBtnService,
    private checkoutService: CheckoutService,
    private payService: PayService,
    private reservationService: NewReservationService,
    private giftcardService: SendGiftcardService,
    private errorHandler: ErrorHandlingService
  ) {
    this.brandFlavour = window.location.pathname.split('/')[1];
  }

  ngOnInit(): void {
    this.component = window.location.pathname.split('/')[2];
    this.guestCustomer = this.component == 'guest' ? true : false;
    this.loading = true;
    this.scriptSub = this.paypalBtnService.scriptAdded$.subscribe((res) => {
      if (res) {
        this.renderPaypalCard();
      }
    });
  }
  ngOnDestroy() {
    this.scriptSub.unsubscribe();
  }
  renderPaypalCard() {
    let email = this._local.get('username');
    let brandId = this._local.get('BrandId');
    let urlCreateOrder = environment.apiUrl + 'api/Paypal/CreateOrder/';
    let urlCheckout = environment.apiUrl + 'api/Checkout/v1/Web';

    if (this.component == 'pay') {
      urlCheckout = environment.apiUrl + 'api/Payment/OneTimePayment';
    }

    if (this.component == 'reservation' || this.component == 'reserve-iframe') {
      urlCheckout = environment.apiUrl + 'api/Reservation/book/v1/' + brandId;
    }

    if (this.component == 'giftcard') {
      urlCheckout = environment.apiUrl + 'api/GiftCard/send/v1';
      email = this._local.get('username')
        ? this._local.get('username')
        : this._local.getSessionItem('guestEmail');
    }

    if (this.guestCustomer) {
      urlCheckout = environment.apiUrl + 'api/Checkout/Guest/v1/Web';
      email = this._local.getSessionItem('guestEmail');
    }

    var paypalCredit = paypal.Buttons({
      fundingSource: paypal.FUNDING.CARD,
      style: {
        layout: 'vertical',
        color: 'black',
        shape: 'pill',
        label: 'paypal',
      },
      createOrder: (data, actions) => {
        const token = this._local.get('access_token')
          ? this._local.get('access_token')
          : this._local.getSessionItem('access_token');
        return fetch(
          urlCreateOrder + brandId + '/' + email + '/' + this.total + '/false',
          {
            method: 'post',
            headers: {
              Authorization: 'bearer ' + token,
              BrandFlavor: this.brandFlavour,
            },
          }
        )
          .then(function (res) {
            return res.json();
          })
          .then((orderData) => {
            this.checkoutService.paypalToken = orderData['Id'];
            this.payService.paypalToken = orderData['Id'];
            this.reservationService.paypalToken = orderData['Id'];
            this.giftcardService.paypalToken = orderData['Id'];
            return orderData['Id'];
          });
      },
      onApprove: (data, actions) => {
        let params = this.checkoutService.getParams('Paypal');
        if (this.component == 'pay') {
          params = this.payService.getParams('Paypal');
        }
        if (this.component == 'giftcard') {
          params = this.giftcardService.getParams('Paypal');
        }
        if (
          this.component == 'reservation' ||
          this.component == 'reserve-iframe'
        ) {
          params = this.reservationService.getParams('Paypal');
        }
        const token = this._local.get('access_token')
          ? this._local.get('access_token')
          : this._local.getSessionItem('access_token');
        return fetch(urlCheckout, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + token,
            BrandFlavor: this.brandFlavour,
          },
          body: JSON.stringify(params),
        })
          .then(function (res) {
            return res.json();
          })
          .then((res) => {
            // Show a success message
            this.response.emit(res);
          });
      },
      onError: (err) => {
        // For example, redirect to a specific error page
        this.errorHandler.handleError(err);
      },
    });

    if (paypalCredit.isEligible()) {
      // Render the standalone button for that funding source
      paypalCredit.render('#paypalCard');
      this.loading = false;
    }
  }
}
