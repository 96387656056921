import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { favouritesService } from 'src/app/favourites/favourites.service';
import { recomendationsService } from 'src/app/recomendations/recomendations.service';
import { ApiService } from 'src/app/service/api.service';
import { DynamicColorsService } from 'src/app/service/dynamic-colors.service';
import { GetterService } from 'src/app/service/getter-service.service';
import { RequestService } from 'src/app/service/request.service';
import { Product } from 'src/Model/interface/productInterfaces';
import { RedirectionConfig } from 'src/Model/interface/WebCustomer';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  product: Product;
  selectedImage: string;
  quantity: number = 1;
  price: number = 0;
  extraList: any[] = [];
  extrasChooseOnes: number = 0;
  selectedChooseOnes: number = 0;
  extraArray: any[] = [];

  @ViewChild('description', { static: false }) description: ElementRef;
  selectedRecipeTitle: any;
  addedTocart: boolean;
  message: string;
  redirectionConfig: RedirectionConfig;
  webCustomerSub: Subscription;
  training: boolean;

  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private router: Router,
    private _requestService: RequestService,
    private favService: favouritesService,
    private _local: GetterService,
    private renderer: Renderer2,
    public dynamicCss: DynamicColorsService,
    private spinner: NgxSpinnerService,
    private recomendationsService: recomendationsService,
    private apiService: ApiService
  ) {
    const id = this.route.snapshot.params['id'];
    this.training = this.route.snapshot.queryParams['training'] == 'true';
    this.getProductDetails(id);
    this.dynamicCss.SelectedButtonTextColor;
  }

  ngOnInit(): void {
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCustomerSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.redirectionConfig = res.TemplateData.RedirectionConfig;
    });
  }
  getProductDetails(id: number) {
    this.spinner.show();
    this.productsService.getProductDeatilsById(id).subscribe({
      next: (res) => {
        if (res['Info']['Status'] == 200) {
          this.product = res['Data'];
          this.price = this.product.ProductOldPrice;
          if (
            this.product?.ProductImageConfig &&
            typeof this.product?.ProductImageConfig == 'string'
          ) {
            let temp: string[] = [];
            let array = JSON.parse(this.product?.ProductImageConfig);
            array?.Images?.forEach((img) => {
              temp.push(img.Image);
            });
            this.product.ProductImageConfig = temp;
            this.selectedImage = this.product.ProductImageConfig[0];
          } else {
            this.product.ProductImageConfig = [];
          }
          setTimeout(() => {
            this.renderer.setProperty(
              this.description.nativeElement,
              'innerHTML',
              this.product?.ProductContent
            );
          }, 1);

          if (!this.product.ProductExtraObject) return;

          this.product.ProductExtraObject = JSON.parse(
            this.product.ProductExtraObject.toString()
          );
          if (this.product.ProductExtraObject['Weight']) return;

          Object.entries(this.product.ProductExtraObject).forEach(
            ([key, value]) => {
              if (typeof value !== 'string') {
                Object.entries(value).forEach(([k, v]) => {
                  v.Key = key;
                  v.Quantity = 0;
                });
              }
              let param = {
                Key: key,
                extras: value,
              };
              this.extraList.push(param);
            }
          );
          this.extraList.forEach((val) => {
            if (val.Key.toLowerCase().includes('choose')) {
              this.extrasChooseOnes += 1;
            }
          });
        }
      },
      error: () => {
        this.spinner.hide();
        alert('Network Error! Please refresh page and try again');
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }
  changeImage(image: string) {
    this.selectedImage = image;
    this.product.ProductImageUrl = image;
  }

  openUrl(url) {
    if (url == 'checkout' && this.training) {
      this.router.navigateByUrl(
        this._requestService.setUrl(url + '?training=true')
      );
      return;
    }
    if (url == 'checkout') {
      this.recomendationsService.showRecomendations.next();
      return;
    }
    this.router.navigateByUrl(this._requestService.setUrl(url));
  }
  contiueShopping() {
    if (this.training) {
      this.openUrl('training');
      return;
    }
    this.openUrl('products');
  }
  validateQuantity(input: NgModel) {
    const value = input.control.value;
    if (!value) {
      input.control.setValue(1);
    }

    let extrasTotal = 0;
    this.extraArray.forEach((val) => {
      extrasTotal += val.Price * val.Quantity;
    });

    this.price = (this.product.ProductOldPrice + extrasTotal) * this.quantity;
    return;
  }

  validateInput(event: KeyboardEvent) {
    return event.key == '.' ? false : true;
  }

  AddRemoveFavorites(status: boolean) {
    if (!this._local.get('CustomerId')) {
      this.openUrl('login');
      return;
    }
    this.productsService.addRemoveFavs(this.product, status).subscribe({
      next: (res) => {
        if (res['Info'].Status == 200 && status) {
          this.favService.addFavouriteToList(this.product);
          return;
        }
        if (res['Info'].Status == 200 && !status) {
          this.favService.removeFavouriteFromList(this.product);
          return;
        }
        if (res['Info'].Status !== 200) {
          this.product.IsFavourite = !this.product.IsFavourite;
        }
      },
      error: () => {
        this.product.IsFavourite = !this.product.IsFavourite;
      },
    });
  }
  getProductModal() {
    this.productsService.getProductModal(this.product, this.quantity);
  }
  chooseExtra(item) {
    if (this.extrasChooseOnes) {
      this.extrasChooseOnes -= 1;
    }
    let sum: number = 0;
    this.selectedChooseOnes = 0;
    let indexFlag = 0;

    if (this.extraArray.length && item.Key.toLowerCase().includes('choose')) {
      this.extraArray.forEach((val) => {
        if (val.Key == item.Key) {
          this.extraArray.splice(this.extraArray.indexOf(val), 1);
          indexFlag = 1;
        }
      });
      if (indexFlag == 1) {
        item.Quantity = 1;
        this.extraArray.push(item);
      } else {
        item.Quantity = 1;
        this.extraArray.push(item);
      }
    } else {
      item.Quantity += 1;
      this.extraArray.push(item);
    }

    this.extraArray.forEach((val) => {
      sum += val.Price * val.Quantity;
      if (val.Key.toLowerCase().includes('choose')) {
        this.selectedChooseOnes += 1;
      }
    });
    let extrasTotal = sum;
    this.price = (this.product.ProductOldPrice + extrasTotal) * this.quantity;
  }

  decreaseItemQuantity(item: any) {
    let extrasTotal = 0;
    if (item.Quantity == 1) {
      item.Quantity -= 1;
      this.extraArray.splice(this.extraArray.indexOf(item), 1);
    } else {
      if (item.Quantity > 0) {
        item.Quantity -= 1;
      }
    }
    this.extraArray.forEach((val) => {
      extrasTotal += val.Price * val.Quantity;
    });

    this.price = (this.product.ProductOldPrice + extrasTotal) * this.quantity;
  }

  increaseExtraQuantity(item) {
    let extrasTotal: number = 0;

    if (this.extraArray.length) {
      if (item.Quantity < 9) {
        if (this.extraArray.indexOf(item) >= 0) {
          item.Quantity += 1;
        } else {
          item.Quantity += 1;
          this.extraArray.push(item);
        }
      }
    } else {
      item.Quantity += 1;
      this.extraArray.push(item);
    }
    this.extraArray.forEach((val) => {
      extrasTotal += val.Price * val.Quantity;
    });
    this.price = (this.product.ProductOldPrice + extrasTotal) * this.quantity;
  }

  addToCart() {
    if (this.extrasChooseOnes) {
      this.message = 'Please select all required options';
      return;
    }
    let data: any;
    let existing: any[] = [];

    data = {
      ProductId: this.product.ProductId,
      ProductTitle: this.product.ProductTitle,
      ProductOldPrice: this.price / this.quantity,
      ProductNewPrice: this.product.ProductNewPrice,
      ProductTotal: this.price,
      ProductImageUrl: this.product.ProductImageUrl,
      ProductUnits: this.product?.ProductUnits,
      IsDiscountedItem: this.product.isDiscountedItem,
      ProductQuantity: this.quantity,
      ProductExtraObject: this.extraArray,
      ForDelivery: this.product?.ForDelivery,
      ForCollection: this.product?.ForCollection,
    };
    existing = JSON.parse(this._local.get('cartProductItems'));
    if (existing && existing?.length) {
      let match: boolean = false;
      existing.forEach((val) => {
        if (val.ProductId == this.product.ProductId) {
          if (val.ProductExtraObject.length == this.extraArray.length) {
            let j: number = 0;
            for (let i = 0; i < this.extraArray.length; i++) {
              if (
                val.ProductExtraObject[i].Title === this.extraArray[i].Title
              ) {
                j++;
              }
            }
            if (j >= this.extraArray.length) {
              val.ProductTotal = val.ProductTotal + this.price * this.quantity;
              val.ProductQuantity = val.ProductQuantity + this.quantity;
              match = true;
            }
          }
        }
      });

      if (!match) {
        existing.push(data);
      }
      this._requestService.saveProductCartItems(existing);
    } else {
      var tmp_array = [];
      tmp_array.push(data);
      this._local.set('cartProductItems', JSON.stringify(tmp_array));
      this._requestService.saveProductCartItems(tmp_array);
    }

    this.extraArray = [];
    this.addedTocart = true;
    setTimeout(() => {
      this.addedTocart = false;
      if (this.training) {
        this.openUrl('training');
        return;
      }
    }, 1300);
  }
}
