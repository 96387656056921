import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { ApiService } from '../service/api.service';
import { popupService } from '../popup/popup.service';
import { RecipeService } from '../service/recipe.service';
import {
  CategoryWithRecipes,
  ExtraObject,
  Recipe,
} from 'src/Model/interface/productInterfaces';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-dine-in-list-view',
  templateUrl: './dine-in-list-view.component.html',
  styleUrls: ['./dine-in-list-view.component.scss'],
})
export class DineInListViewComponent implements OnInit, OnDestroy {
  /* For hiding responsive or desktop views */
  windowWidth;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  /* Dynamic styles */
  SelectedButtonColor: string;
  MobileMenuCategoryBackgroundColor: string;
  MobileMenuCategoryTextColor: string;
  MobileMenuBackgroundColor: string;
  MobileMenuBorderColor: string;
  MobileMenuTextColor: string;
  SelectedButtonTextColor: string;

  brand_Categories: any;
  categoryName: any;
  categoryImage: any;
  currentEnvironment: string;

  /* Extras PopUp */
  recipesBackup: CategoryWithRecipes[] = [];
  selectedCategory: CategoryWithRecipes;
  recipes: Recipe[];
  selectedCategoryResponsive: CategoryWithRecipes;
  recipeDataSub: Subscription;
  webCusSub: Subscription;

  constructor(
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private popupService: popupService,
    private recipeService: RecipeService,
    private app: AppComponent
  ) {
    this.app.setAllergensFooter(true);
  }

  ngOnInit(): void {
    this.checkDeviceWidth();
    this.getEnvironment();
    this.getCategoriesAndRecipes();
    this.getWebcustomer();
  }
  checkDeviceWidth() {
    this.windowWidth = document.body.clientWidth;
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.windowWidth = document.body.clientWidth;
    });
  }
  getEnvironment() {
    this.currentEnvironment = environment.production ? 'PROD' : 'Dev';
  }
  getCategoriesAndRecipes() {
    this.spinner.show();
    this.recipeDataSub = this.recipeService.data$.subscribe((data) => {
      if (!data) return;
      this.brand_Categories = data.Categories.filter(
        (category) => category.ForDineIn
      );
      this.recipesBackup = data.CategoriesAndRecipes;
      this.toggleRecipesOnMobile(this.brand_Categories[0]);
    });
  }
  toggleRecipesOnMobile(category: CategoryWithRecipes) {
    if (this.selectedCategoryResponsive?.CategoryId == category.CategoryId) {
      this.selectedCategoryResponsive = null;
      return;
    }
    this.selectedCategoryResponsive = category;
    this.getRecipes(category);
  }
  getRecipes(category: CategoryWithRecipes) {
    this.selectedCategory = category;
    this.recipesBackup.forEach((recipe) => {
      if (recipe.CategoryId == category.CategoryId)
        this.recipes = recipe.Recipes.filter((recip) => recip.ForDineIn);
    });
    setTimeout(() => {
      if (document.getElementById(`${category.CategoryId}`)) {
        document.getElementById(`${category.CategoryId}`)?.scrollIntoView();
      } else {
        document.getElementById(`recipes`)?.scrollIntoView();
      }
    }, 300);
    this.spinner.hide();
  }
  getRecipeModal(recipe: Recipe) {
    let renderedExtraList = [];
    let extras: ExtraObject = recipe.RecipeExtraObject;
    if (!extras) return;
    if (typeof extras == 'string') {
      extras = JSON.parse(extras);
    }
    Object.entries(extras).forEach(([key, value]) => {
      value.forEach((v) => {
        v.Key = key;
        v.Quantity = 0;
      });
      let param = {
        Key: key,
        extras: value,
      };
      renderedExtraList.push(param);
    });
    let popupData = {
      MenuBuilder: false,
      dineIn: true,
      filteredProduct: null,
      filteredRecipe: recipe,
      renderedExtraList: renderedExtraList,
      renderExtralistChooseOnes: 0,
      openForNonExtras: true,
    };
    this.popupService.popUpData.next(popupData);
  }
  getWebcustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }

      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.MobileMenuCategoryBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuCategoryBackgroundColor;
      this.MobileMenuCategoryTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuCategoryTextColor;
      this.MobileMenuBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBackgroundColor;
      this.MobileMenuBorderColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBorderColor;
      this.MobileMenuTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuTextColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
    });
  }
  ngOnDestroy(): void {
    this.app.setAllergensFooter(false);
    this.recipeDataSub.unsubscribe();
    this.webCusSub.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }
}
