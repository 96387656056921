import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-allergens-footer',
  templateUrl: './allergens-footer.component.html',
  styleUrls: ['./allergens-footer.component.scss'],
})
export class AllergensFooterComponent implements OnInit, OnDestroy {
  allergens = [];
  FooterColor: string;
  FooterTextColor: string;
  SelectedButtonColor: String;
  AllergensBottomText: string;
  DineInBottomText: string;
  AllergensBottomTextColor: string;
  AllergensBottomTextBackgroundColor: string;
  showDineInBottomText: boolean;
  webCusSub: Subscription;
 

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.DineInBottomText = res.TemplateData?.DineInBottomText;
      this.allergens = res.Allergens;
      this.AllergensBottomText = res.TemplateData.AllergensBottomText;
      this.AllergensBottomTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors.AllergensBottomTextColor;
      this.AllergensBottomTextBackgroundColor =
        res.TemplateData.DynamicStyle.TertiaryColors.AllergensBottomTextBackgroundColor;
      this.FooterColor =
        res.TemplateData.DynamicStyle.TertiaryColors.FooterColor;
      this.FooterTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors.FooterTextColor;
      
    });
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
}
