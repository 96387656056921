<div class="container mt-3">
  <div class="row">
    <div
      class="col-lg-4 col-sm-6 col-12 mb-2 cursor-pointer"
      *ngFor="let product of products"
      (click)="openURL(product.ProductId)"
    >
      <div class="position-relative">
        <img [src]="product.ProductImageUrl" [alt]="product.ProductTitle" />
        <span
          *ngIf="!product.IsFavourite"
          class="heart bg-white border border-1"
          (click)="addToFav(product, $event)"
          ><i class="far fa-heart fs-4" title="Add To Favourite List"></i
        ></span>
        <span
          *ngIf="product.IsFavourite"
          class="heart bg-white border border-1 text-danger"
          (click)="removeFav(product, $event)"
          ><i class="fa fa-heart fs-4" title="Remove From Favourite List"></i
        ></span>
      </div>
      <h2 class="mt-4">{{ product.ProductTitle }}</h2>
      <p class="text-danger fw-bold">{{product.ToTime | date:'medium'}}</p>
      <p class="text-muted">{{product.ProductLabel}}</p>
      <p class="text-muted">
        Starts at
        {{ product.ProductOldPrice | currency: "GBP":"symbol":"1.2-2" }}
      </p>
    </div>
  </div>
</div>
