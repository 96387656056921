<div class="container" *ngIf="!cancel">
  <div class="main p-2 mx-auto">
    <form (ngSubmit)="formSubmit(form)" #form="ngForm" id="form">
      <section *ngIf="calendarSec || editing">
        <div class="calendar mb-2">
          <ejs-calendar
            [(value)]="selectedCelenderDate"
            [min]="minDate"
            [max]="maxDate"
            (change)="onValueChange($event)"
            class="mw-100"
          ></ejs-calendar>
          <p
            class="help-block text-danger text-center mt-2"
            *ngIf="!selectedCelenderDate"
          >
            Please select a date
          </p>
        </div>
        <div>
          <div class="mb-2">
            <label for="guests">Guests</label>
            <select
              name="guests"
              id="guests"
              class="form-select b-0 rounded-0"
              [(ngModel)]="noOfGuests"
              (change)="dataChanged()"
              required
              [ngStyle]="{
                'border-bottom': '1px solid ' + SelectedButtonColor
              }"
              [disabled]="editing ? isPaymentRequired : false"
            >
              <option value="{{ guest }}" *ngFor="let guest of guestList">
                {{ guest }}
              </option>
            </select>
            <p *ngIf="!noOfGuests" class="text-danger form-text">
              No seats available for selected date
            </p>
          </div>
          <div class="mb-2">
            <label for="slots">Slots</label>
            <select
              name="slots"
              id="slots"
              class="form-select b-0 rounded-0"
              [(ngModel)]="selectedSlot"
              (change)="dataChanged()"
              required
              [ngStyle]="{
                'border-bottom': '1px solid ' + SelectedButtonColor
              }"
              [disabled]="!timeSlots?.length"
            >
              <option value="{{ slot }}" *ngFor="let slot of timeSlots">
                {{ slot }}
              </option>
            </select>
            <p *ngIf="noSlotsAvailable" class="text-danger form-text">
              No time slot available for selected date
            </p>
          </div>
          <div *ngIf="showArea">
            <label for="area">Area</label>
            <select
              name="area"
              id="area"
              class="form-select b-0 rounded-0"
              [(ngModel)]="selectedArea"
              (change)="dataChanged()"
              [ngStyle]="{
                'border-bottom': '1px solid ' + SelectedButtonColor
              }"
              [disabled]="editing"
            >
              <option value="{{ area }}" *ngFor="let area of areaList">
                {{ area }}
              </option>
            </select>
          </div>
          <button
            class="btn w-100 br-40px mt-3"
            type="button"
            [ngStyle]="{
              'background-color': SelectedButtonColor,
              color: SelectedButtonTextColor
            }"
            (click)="openPersonalInfoSec()"
            [disabled]="
              !selectedCelenderDate ||
              !selectedSlot ||
              (showArea ? !selectedArea : false) ||
              !noOfGuests
            "
            *ngIf="!editing"
          >
            Next
          </button>
        </div>
      </section>
      <section *ngIf="personalInfosec || editing" class="mt-3">
        <span class="cursor-pointer" *ngIf="!editing">
          <a (click)="calendarSec = true; personalInfosec = false">
            <li class="fa fa-chevron-left cal-fa-arrow text-dark"></li>
            <span class="cal-fa-text"> Back</span>
          </a>
        </span>
        <div class="row mt-3 mb-3" *ngIf="!editing">
          <span class="col-md-4 mb-2">
            <li class="fa fa-calendar cal-fa"></li>
            <span class="ms-2 cal-fa-text">{{
              selectedCelenderDate | date: "mediumDate"
            }}</span>
          </span>
          <span class="col-md-4 mb-2">
            <li class="fa fa-user cal-fa"></li>
            <span class="ms-2 cal-fa-text">{{ noOfGuests }}</span>
          </span>
          <span class="col-md-4 mb-2">
            <li class="fa fa-clock cal-fa"></li>
            <span class="ms-2 cal-fa-text">{{ selectedSlot }}</span>
          </span>
        </div>
        <h1 class="text-center">Personal Information</h1>
        <div>
          <div class="inputContainer">
            <input
              type="text"
              class="form-control"
              name="firstname"
              id="firstname"
              required
              [(ngModel)]="firstName"
              maxlength="50"
              (input)="dataChanged()"
              autocomplete="cc-name"
            />
            <label for="firstname">First Name</label>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="!firstName && formSubmitted"
            >
              First Name is Required
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="text"
              class="form-control"
              rows="1"
              name="lastname"
              id="lastname"
              required
              (input)="dataChanged()"
              [(ngModel)]="lastName"
              maxlength="50"
            />
            <label for="lastname">Last Name</label>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="!lastName && formSubmitted"
            >
              Last Name is Required
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="tel"
              class="form-control"
              rows="1"
              name="contact"
              id="contact"
              required
              (input)="dataChanged()"
              pattern="((^(\+44)([1-9]\d)(\d{8,8})$)|(^(0)([1-9]\d)(\d{8,8})$))"
              [(ngModel)]="contact"
              (keyup)="focus(contactInput, 'contact')"
              maxlength="50"
              #contactInput="ngModel"
            />
            <label for="contact">Contact Number</label>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="contactInput.errors?.['required'] && formSubmitted"
            >
              Contact Number is Required
            </span>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="contactInput.errors?.['pattern'] && formSubmitted"
            >
              Contact Number is Incorrect
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="email"
              class="form-control"
              rows="1"
              name="email"
              id="email"
              emial
              (keyup)="focus(emailInput, 'email')"
              required
              [(ngModel)]="email"
              maxlength="50"
              email
              #emailInput="ngModel"
            />
            <label for="email">Email</label>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="emailInput.errors?.['required'] && formSubmitted"
            >
              Email is Required
            </span>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="emailInput.errors?.['email'] && formSubmitted"
            >
              Enter correct Email
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="email"
              class="form-control"
              rows="1"
              name="confirmEmail"
              id="confirmEmail"
              (keyup)="focus(confirmEmailInput, 'confirmEmail')"
              required
              [(ngModel)]="confirmEmail"
              maxlength="50"
              email
              #confirmEmailInput="ngModel"
            />
            <label for="confirmEmail">Confirm Email</label>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="confirmEmailInput.errors?.['required'] && formSubmitted"
            >
              Confirm Email
            </span>
            <span
              class="help-block text-danger position-absolute"
              *ngIf="
								confirmEmail != email && !confirmEmailInput.errors?.['required']
							"
            >
              Email Not Match
            </span>
          </div>
          <div
            class="mb-3"
            *ngIf="(showTermsAndConditions || showNotice) && !editing"
          >
            <div class="form-check" *ngIf="showTermsAndConditions">
              <input
                class="form-check-input"
                type="checkbox"
                id="TermsAndConditions"
                required
                name="TermsAndConditions"
                [value]="termsAndConditionsDetails.TermsAndConditionsText"
                ngModel
                #termsAndConditions="ngModel"
              />
              <label class="form-check-label" for="TermsAndConditions">
                {{ termsAndConditionsDetails.TermsAndConditionsText }}
              </label>
              <span
                *ngIf="termsAndConditions.errors?.['required'] && formSubmitted"
              >
                <p class="text-danger">
                  Please agree to terms and conditions
                </p></span
              >
            </div>
            <div class="form-check" *ngIf="showNotice">
              <input
                class="form-check-input"
                type="checkbox"
                id="NoticeText"
                required
                name="NoticeText"
                [value]="termsAndConditionsDetails.NoticeText"
                ngModel
                #noticeText="ngModel"
              />
              <label class="form-check-label" for="NoticeText">
                {{ termsAndConditionsDetails.NoticeText }}
              </label>
              <span *ngIf="noticeText.errors?.['required'] && formSubmitted">
                <p class="text-danger">Please agree check notice</p></span
              >
            </div>
          </div>

          <div class="inputContainer">
            <textarea
              id="note"
              class="form-control change"
              name="note"
              rows="4"
              cols="50"
              (keyup)="focus(textArea, 'note')"
              maxlength="99"
              [(ngModel)]="notes"
              #textArea="ngModel"
            ></textarea>
            <label for="note">Any Special Notes</label>
          </div>
        </div>
        <div *ngIf="!editing">
          <button
            type="submit"
            class="btn w-100 br-40px"
            [ngStyle]="{
              'background-color': SelectedButtonColor,
              color: SelectedButtonTextColor
            }"
            *ngIf="!isPaymentRequired"
          >
            Book
          </button>

          <div *ngIf="isPaymentRequired">

            <div *ngIf="guestLimitForBookingPaymentEnabled">

              <button
                type="submit"
                class="btn w-100 br-40px"
                [ngStyle]="{
                  'background-color': SelectedButtonColor,
                  color: SelectedButtonTextColor
                }"
                *ngIf="noOfGuests  < guestLimitForBookingPayment"
              >
                Book
              </button>

              <button
                type="button"
                class="btn w-100 br-40px"
                [ngStyle]="{
                  'background-color': SelectedButtonColor,
                  color: SelectedButtonTextColor
                }"
                (click)="openPaymentSec(form)"
                *ngIf="noOfGuests >= guestLimitForBookingPayment"
              >
                Pay Now
              </button>

            </div>

            <div *ngIf="!guestLimitForBookingPaymentEnabled">
              <button
                type="button"
                class="btn w-100 br-40px"
                [ngStyle]="{
                  'background-color': SelectedButtonColor,
                  color: SelectedButtonTextColor
                }"
                (click)="openPaymentSec(form)"
              >
                Pay Now
              </button>
            </div>

          </div>
        </div>
        <div *ngIf="editing" class="row m-0">
          <span class="col-md-6 mb-3">
            <button
              type="button"
              class="btn btn-warning w-100 br-40px"
              (click)="updateReservation()"
            >
              Update
            </button>
          </span>
          <a href="{{ brandWeblink }}" class="col-md-6 mb-3">
            <button type="btn" class="btn btn-success w-100 br-40px">
              Cancel
            </button>
          </a>
        </div>
      </section>
      <section *ngIf="paymentSec" class="mt-3">
        <span class="cursor-pointer">
          <a (click)="personalInfosec = true; paymentSec = false">
            <li class="fa fa-chevron-left cal-fa-arrow text-dark"></li>
            <span class="cal-fa-text"> Back</span>
          </a>
        </span>
        <h1 class="text-center mt-3">Payment Method</h1>
        <button
          class="paymentBtn br-40px mb-2 btn"
          type="button"
          [ngStyle]="{
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
          (click)="checkPaymentGateway()"
        >
          Debit/Credit Card
        </button>
        <div class="mb-1" *ngIf="checkoutConfig?.PayPalEnabled">
          <app-paypal-btn
            [total]="totalAmount"
            [email]="email"
            (response)="handleBooKTableResponse($event)"
          ></app-paypal-btn>
        </div>
        <div class="mb-2" *ngIf="checkoutConfig?.PayPalEnabled">
          <app-paypal-card
            [total]="totalAmount"
            [email]="email"
            (response)="handleBooKTableResponse($event)"
          ></app-paypal-card>
        </div>
      </section>
    </form>
  </div>
</div>
<div
  class="container h-100 p-2 d-flex justify-content-center align-items-center"
  *ngIf="cancel"
>
  <div class="cancelBox">
    <div class="row mb-2">
      <div class="col-md-4 mb-2">
        <i class="fa fa-calendar cal-fa"></i>
        <span class="ms-2 fontSize18px">{{ selectedCelenderDate }}</span>
      </div>
      <div class="col-md-4 mb-2">
        <i class="fa fa-user cal-fa"></i>
        <span class="ms-2 fontSize18px">{{ noOfGuests }} Guests</span>
      </div>
      <div class="col-md-4 mb-2">
        <i class="fa fa-clock cal-fa"></i>
        <span class="ms-2 fontSize18px">{{ selectedSlot }}</span>
      </div>
    </div>
    <h2>Are you sure you want to cancel this reservation?</h2>
    <div class="row">
      <div class="col-md-6 mb-3">
        <button
          class="btn btn-danger br-40px w-100"
          (click)="cancelReservation()"
        >
          Yes
        </button>
      </div>
      <div class="col-md-6">
        <a href="{{ brandWeblink }}"
          ><button class="btn btn-success br-40px w-100">No</button></a
        >
      </div>
    </div>
  </div>
</div>
<div class="overlay messagesOverlay p-2" *ngIf="showMessage">
  <div class="content">
    <p>{{ message }}</p>
    <button
      (click)="showMessage = false"
      class="closeBtn"
      *ngIf="!showCanceledMsg"
    >
      Close
    </button>
    <a href="{{ brandWeblink }}" *ngIf="showCanceledMsg" class="w-100">
      <button class="btn closeBtn">Okay</button>
    </a>
  </div>
</div>
<div
  class="overlay d-flex justify-content-center align-items-center"
  [ngClass]="{ 'd-none': !showStripe }"
  (click)="showStripe = false"
>
  <app-stripe (response)="response($event)" [total]="totalAmount"></app-stripe>
</div>
<div
  *ngIf="showPaymentSense"
  class="overlay d-flex justify-content-center align-items-center flex-column"
  (click)="showPaymentSense = false"
>
  <app-payment-sense
    [total]="totalAmount"
    (response)="response($event)"
  ></app-payment-sense>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.5)"
  size="large"
  color="{{ SelectedButtonColor }}"
  type="ball-scale-multiple"
>
</ngx-spinner>
