import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { GetterService } from '../service/getter-service.service';

@Injectable({
  providedIn: 'root',
})
export class NewReservationService {
  PaymentMethodId: any;
  noOfGuests: number;
  selectedArea: string;
  selectedCelenderDate: Date;
  selectedSlot: string;
  notes: string;
  defaultStatus: any;
  configId: number;
  email: string;
  firstName: string;
  lastName: string;
  contact: string;
  component: string;
  paypalToken: string;

  constructor(private datePipe: DatePipe, private _local: GetterService) {
    this.component = window.location.pathname.split('/')[2];
  }

  getParams(paymentType: string = 'Cash'): {} {
    let param = {
      Guests: this.noOfGuests,
      Area: this.selectedArea,
      Source: 'Web Customer',
      Time:
        this.datePipe.transform(this.selectedCelenderDate, 'mediumDate') +
        ' ' +
        this.selectedSlot,
      Notes: this.notes,
      Status: this.defaultStatus,
      ReservationConfigId: this.configId,
      PaymentType: paymentType,
      Customer: {
        Email: this.email,
        FirstName: this.firstName,
        LastName: this.lastName,
        Mobile: this.contact,
        Id:
          this.component == 'reserve-iframe'
            ? 0
            : this._local.get('CustomerId')
            ? this._local.get('CustomerId')
            : 0,
      },
      MarketingPreferences: {
        Email: true,
        SMS: false,
      },
      WebComponent: window.location.pathname.split('/')[2],
    };
    if (paymentType == 'Card') {
      const brandName = window.location.pathname.split('/')[1];
      param['CardDetail'] = {
        CustomerEmail: this.email,
        PaymentMethodId: this.PaymentMethodId,
        Description: '',
        StatementDescriptor: brandName,
      };
    }
    if (paymentType == 'Paypal') {
      param['PaypalPurchaseDetails'] = {
        PaypalOrderId: this.paypalToken,
      };
    }
    return param;
  }
}
