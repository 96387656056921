import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SubCategory } from 'src/Model/interface/productInterfaces';

@Injectable({
  providedIn: 'root',
})
export class SubcatDetailsService {
  private showDetailSub = new Subject<SubCategory>();
  showDetail$: Observable<SubCategory> = this.showDetailSub.asObservable();

  constructor() {}

  showDetails(category: SubCategory) {
    this.showDetailSub.next(category);
  }
}
