<div
  *ngIf="branches"
  class="container-fluid min-vh-100 w-100 bgImage"
  [ngStyle]="{ 'background-image': 'url(' + branches.BackgroundImg + ')' }"
>
  <div class="row justify-content-center">
    <div class="mt-5 col-lg-6 col-md-8 col-12">
      <div class="d-flex justify-content-center align-items-center p-2">
        <img [src]="branches.Logo" alt="image" />
      </div>
      <div class="searchbar-Container position-relative mb-2">
        <input
          type="text"
          autofocus
          autocomplete="postal-code"
          placeholder="Enter Post Code"
          class="p-3 w-100 br-40px"
          [(ngModel)]="postCode"
          (keyup.enter)="branchSearch()"
          [ngStyle]="{ border: '3px solid ' + branches.TextColor }"
        />
        <button
          type="button"
          class="searchBtn btn br-40px"
          (click)="branchSearch()"
          [ngStyle]="{
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
          [disabled]="spinner || !postCode"
        >
          <span *ngIf="!spinner">Search</span
          ><span
            class="spinner-border spinner-border-sm text-dark"
            *ngIf="spinner"
          ></span>
        </button>
      </div>
      <p
        *ngIf="postCodeSearchResponse.length"
        [ngStyle]="{ color: branches.TextColor }"
      >
        {{ postCodeSearchResponse }}
      </p>
    </div>
  </div>
  <div class="row mt-100px justify-content-center align-content-strectch">
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-3" *ngFor="let branch of allBranches">
      <div
        [ngStyle]="{ border: '1px solid ' + branches.TextColor }"
        class="br-10px p-2 h-100 position-relative cursor-pointer bg-dark"
        (click)="navigateToBranch(branch.Brand.BrandFlavor)"
      >
        <div class="d-flex justify-content-end align-items-center">
          <img [src]="branches.Logo" alt="logo" class="w-25" />
        </div>
        <p class="text-white fs-5 fw-bolder">{{ branch.Brand.BrandName }}</p>

        <div [ngStyle]="{ color: branches.TextColor }">
          <i class="fa fa-map-marker" aria-hidden="true"></i>
          <p class="address">{{ branch.Branch.BrandBranchAddress }}</p>
          <div class="row mt-2">
            <div class="col-6">
              <div class="d-flex align-items-center">
                <div class="dot br-40px bg-success"></div>
                <p class="address m-0 ms-2">Collection</p>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center">
                <div class="dot br-40px bg-danger"></div>
                <p class="address m-0 ms-2">Delivery</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-3">
          <img [src]="branches.ChooseStoreImage" alt="logo" class="w-75" />
        </div>
      </div>
    </div>
  </div>
</div>
