<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 col-sm-8">
      <div
        class="border border-dark border-2 p-1 mb-3 mt-2 br-10px fs-2 d-md-none"
        [ngStyle]="{
          border: '1px solid ' + MobileMenuBorderColor,
          'background-color': MobileMenuBackgroundColor
        }"
      >
        <h1
          class="text-center m-0"
          (click)="openUrl(RedirectionFromLogin)"
          [ngStyle]="{ color: MobileMenuTextColor }"
        >
          <span *ngIf="recipesBrand">Menu</span>
          <span *ngIf="!recipesBrand">Products</span>
        </h1>
      </div>

      <h1
        class="text-center mb-5 mt-4 fs-1"
        *ngIf="!simpleCheckout && !distributorLogin"
      >
        Login
      </h1>
      <h1
        class="text-center mb-5 mt-4 fs-1"
        *ngIf="!simpleCheckout && distributorLogin"
      >
        Distributor Login
      </h1>
      <ul
        class="d-flex justify-content-between align-items-center mb-5 mt-4 p-0"
        *ngIf="simpleCheckout"
      >
        <li
          (click)="changeTab('Login')"
          [ngClass]="tabName == 'Login' ? 'activeTab' : 'notActiveTab'"
          [ngStyle]="
            tabName == 'Login'
              ? { color: 'black', 'border-bottom': '2px solid black' }
              : { color: 'black' }
          "
          class="fs-5 pb-3 cursor-pointer"
        >
          Login
        </li>
        <li
          (click)="changeTab('signup')"
          [ngClass]="tabName == 'signup' ? 'activeTab' : 'notActiveTab'"
          [ngStyle]="
            tabName == 'signup'
              ? { color: 'black', 'border-bottom': '2px solid black' }
              : { color: 'black' }
          "
          class="fs-5 pb-3 cursor-pointer"
        >
          I'm new here
        </li>
        <li
          *ngIf="!(showGuestLogin | async)?.guest && guestCheckoutEnabled"
          (click)="changeTab('guest')"
          [ngClass]="tabName == 'guest' ? 'activeTab' : 'notActiveTab'"
          [ngStyle]="
            tabName == 'guest'
              ? { color: 'black', 'border-bottom': '2px solid black' }
              : { color: 'black' }
          "
          class="fs-5 pb-3 cursor-pointer"
        >
          I'm guest
        </li>
      </ul>

      <div *ngIf="tabName == 'Login'" class="mb-3">
        <form (ngSubmit)="login()" [formGroup]="loginForm">
          <div class="inputContainer">
            <input
              type="email"
              id="email"
              name="username"
              class="form-control"
              (keyup)="focusInputs($event)"
              (click)="focusInputs($event)"
              required
              email
              formControlName="email"
            />
            <label class="fs-14 cursor-pointer" for="email">Email</label>
            <span
              *ngIf="submitted && loginFormControls?.['email']?.errors"
              class="form-text text-danger"
            >
              <span *ngIf="loginFormControls?.['email']?.errors?.['required']"
                >Email is required</span
              >
              <span *ngIf="loginFormControls?.['email']?.errors?.['email']"
                >Email Is Invalid</span
              >
            </span>
          </div>
          <div class="inputContainer position-relative">
            <input
              type="password"
              id="password-field"
              name="passwordLogin"
              toggle
              class="form-control"
              (keyup)="focusInputs($event)"
              required
              formControlName="passwordLogin"
            />
            <label class="cursor-pointer fs-14">Password</label>
            <span
              *ngIf="submitted && loginFormControls?.['passwordLogin']?.errors"
              class="form-text text-danger"
            >
              <span
                *ngIf="loginFormControls?.['passwordLogin']?.errors?.['required']"
                >Password is required</span
              >
              <span
                *ngIf="loginFormControls?.['passwordLogin']?.errors?.['minlength']"
                >Password is too short</span
              >
            </span>
          </div>
          <button
            type="submit"
            [ngClass]="{
              'btn-secondary': loginForm?.invalid,
              'btn-success': !loginForm?.invalid
            }"
            class="w-100 btn br-40px"
          >
            Log in
          </button>
        </form>
        <div class="row mt-2">
          <div class="col-sm-6 text-danger">
            <p
              class="cursor-pointer"
              (click)="changeTab('signup')"
              *ngIf="simpleCheckout"
            >
              Create Account
            </p>
          </div>
          <div class="col-sm-6 text-sm-end text-danger">
            <p class="cursor-pointer" (click)="openUrl('forgot-password')">
              Forgot Password
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="tabName == 'signup'" class="mb-3">
        <form [formGroup]="registerForm" (ngSubmit)="registerUser()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group inputContainer">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  class="form-control"
                  required
                  formControlName="firstName"
                />
                <label for="first_name">First Name*</label>
                <span
                  *ngIf="submitted && regForm?.['firstName']?.errors"
                  class="form-text text-danger"
                >
                  <span *ngIf="regForm?.['firstName']?.errors?.['required']"
                    >First Name is required</span
                  >
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="inputContainer">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  class="form-control"
                  required
                  formControlName="lastName"
                />
                <label for="last_name">Last Name*</label>
                <span
                  *ngIf="submitted && regForm?.['lastName']?.errors"
                  class="form-text text-danger"
                >
                  <span *ngIf="regForm?.['lastName']?.errors?.['required']"
                    >Last Name is required</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="inputContainer">
            <input
              type="email"
              name="email"
              id="emailSignUp"
              class="form-control"
              (keyup)="focusInputs($event)"
              (click)="focusInputs($event)"
              required
              formControlName="email"
            />
            <label for="emailSignUp">Email*</label>
            <span
              *ngIf="submitted && regForm?.['email']?.errors"
              class="form-text text-danger"
            >
              <span *ngIf="regForm?.['email']?.errors?.['required']"
                >Email is required</span
              >
              <span *ngIf="regForm?.['email']?.errors?.['email']"
                >Email must be a valid email address</span
              >
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="phone"
              name="phone"
              id="phone"
              pattern="((^(\+44)([1-9]\d)(\d{8,8})$)|(^(0)([1-9]\d)(\d{8,8})$))"
              class="form-control"
              (keyup)="focusInputs($event)"
              (click)="focusInputs($event)"
              required
              formControlName="contactNo"
            />
            <label for="phone">Contact No*</label>
            <span
              *ngIf="submitted && regForm?.['contactNo']?.errors"
              class="form-text text-danger"
            >
              <span *ngIf="regForm?.['contactNo']?.errors?.['required']"
                >Phone No is required</span
              >
              <span *ngIf="regForm?.['contactNo']?.errors?.['pattern']"
                >Phone No is Invalid</span
              >
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="password"
              id="password-fieldSignUp"
              name="password"
              class="form-control"
              required
              toggle
              formControlName="password"
              (input)="matchPass()"
            />
            <label for="password-fieldSignUp"
              >Password* (8 character minimum)</label
            >
            <span
              *ngIf="submitted && regForm?.['password']?.errors"
              class="form-text text-danger"
            >
              <span *ngIf="regForm?.['password']?.errors?.['required']"
                >Password is required</span
              >
              <span *ngIf="regForm?.['password']?.errors?.['minlength']"
                >Password must be at least 8 characters long.</span
              >
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="password"
              name="confirmpassword"
              class="form-control"
              required
              toggle
              formControlName="confirmpassword"
              id="confirmpassword"
              (input)="matchPass()"
            />
            <label for="confirmpassword">Confirm Password</label>
            <span
              *ngIf="submitted && regForm?.['confirmpassword']?.errors?.['mustMatch']"
              class="form-text text-danger"
            >
              Passwords Do Not Match
            </span>
          </div>
          <div class="form-check cursor-pointer">
            <input
              type="checkbox"
              name="terms"
              id="terms"
              required
              formControlName="terms"
              class="form-check-input"
            />
            <label for="terms" class="form-check-label">
              I agree to the
              <a
                (click)="openUrl('terms-and-conditions')"
                href="javascript:void(0);"
                class="text-danger"
                >Terms and Conditions</a
              >
              and
              <a
                (click)="openUrl('privacy-policy')"
                href=" javascript:void(0);"
                class="text-danger"
              >
                Privacy Policy</a
              ></label
            >
            <p
              *ngIf="regForm?.['terms']?.errors?.['required'] && submitted"
              class="form-text text-danger"
            >
              Please agree to terms & conditions and privacy policy
            </p>
          </div>
          <div class="mt-4 mb-4">
            <p>I would like to receive new deals and promotions through</p>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                name="email"
                id="dealsEmail"
                formControlName="EmailMarketing"
                class="form-check-input"
              />
              <label for="dealsEmail" class="form-check-label cursor-pointer">
                Email
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                name="email"
                id="dealsSms"
                formControlName="SmsMarketing"
                class="form-check-input"
              />
              <label for="dealsSms" class="form-check-label cursor-pointer">
                SMS
              </label>
            </div>
          </div>
          <button
            type="submit"
            [ngClass]="{
              'btn-success': registerForm.valid,
              'btn-secondary': registerForm.invalid
            }"
            class="btn w-100 br-40px"
          >
            Create your account
          </button>
          <p class="text-center mt-2">
            Already have an account?
            <a
              href="javascript:void(0)"
              (click)="changeTab('Login')"
              class="text-danger"
              >Login</a
            >
          </p>
        </form>
      </div>

      <div *ngIf="tabName == 'guest'" class="mb-3">
        <form [formGroup]="GuestForm" (ngSubmit)="registerGuest()">
          <div class="row">
            <div class="col-md-6">
              <div class="inputContainer">
                <input
                  type="text"
                  name="first_name"
                  id="first_nameGuest"
                  class="form-control"
                  required
                  formControlName="firstNameGuest"
                />
                <label for="first_nameGuest">First Name*</label>
                <span
                  *ngIf="
										submittedGuestFrom && guestControls?.['firstNameGuest']?.errors
									"
                  class="form-text text-danger"
                >
                  <span
                    *ngIf="guestControls?.['firstNameGuest']?.errors?.['required']"
                    >First Name is required</span
                  >
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="inputContainer">
                <input
                  type="text"
                  name="last_name"
                  id="last_nameGuest"
                  class="form-control"
                  required
                  formControlName="lastNameGuest"
                />
                <label for="last_nameGuest">Last Name*</label>
                <span
                  *ngIf="
										submittedGuestFrom && guestControls?.['lastNameGuest']?.errors
									"
                  class="text-danger form-text"
                >
                  <span
                    *ngIf="guestControls?.['lastNameGuest']?.errors?.['required']"
                    >Last Name is required</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="inputContainer">
            <input
              type="email"
              name="email"
              id="emailGuest"
              class="form-control form-control-submit"
              (keyup)="focusInputs($event)"
              (click)="focusInputs($event)"
              required
              formControlName="emailGuest"
            />
            <label for="emailGuest">Email*</label>
            <span
              *ngIf="submittedGuestFrom && guestControls?.['emailGuest']?.errors"
              class="text-danger form-text"
            >
              <span *ngIf="guestControls?.['emailGuest']?.errors?.['required']"
                >Email is required</span
              >
              <span *ngIf="guestControls?.['emailGuest']?.errors?.['email']"
                >Email must be a valid email address</span
              >
            </span>
          </div>
          <div class="inputContainer">
            <input
              type="phone"
              name="phone"
              id="phoneGuest"
              class="form-control"
              pattern="((^(\+44)([1-9]\d)(\d{8,8})$)|(^(0)([1-9]\d)(\d{8,8})$))"
              required
              formControlName="contactNoGuest"
              (click)="focusInputs($event)"
              (keyup)="focusInputs($event)"
            />
            <label for="phoneGuest">Contact No*</label>
            <span
              *ngIf="
                submittedGuestFrom && guestControls?.['contactNoGuest']?.errors
              "
              class="form-text text-danger"
            >
              <span
                *ngIf="guestControls?.['contactNoGuest']?.errors?.['required']"
                >Phone Number Is Required</span
              >
              <span
                *ngIf="guestControls?.['contactNoGuest']?.errors?.['pattern']"
                >Phone Number Is Invalid</span
              >
            </span>
          </div>
          <button
            type="submit"
            [ngClass]="{
              'btn-secondary': GuestForm.invalid,
              'btn-success': GuestForm.valid
            }"
            class="btn w-100 br-40px"
          >
            Continue as Guest
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showMessage"
>
  <div
    class="content overflow-hidden bg-light br-40px d-flex justify-content-between flex-column"
  >
    <p class="text-center fs-4">{{ message }}</p>
    <button class="btn btn-danger" (click)="showMessage = false">Okay</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
