import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from 'src/Model/interface/productInterfaces';
import { AppComponent } from '../app.component';
import { RequestService } from '../service/request.service';

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.scss'],
})
export class CategoryProductsComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  categoryName: string;
  constructor(
    private route: ActivatedRoute,
    private _requestService: RequestService,
    private app: AppComponent,
    private spinner: NgxSpinnerService
  ) {
    this.app.setHeaderVisibility(false);
    this.app.setFooterVisibility(false);
    this.app.setAllergensFooter(false);
    this.app.setBottomMenuVisibility(false);
    const catID = this.route.snapshot.params['catID'];
    this.getProducts(catID);
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.app.setHeaderVisibility(true);
    this.app.setFooterVisibility(true);
    this.app.setAllergensFooter(true);
    this.app.setBottomMenuVisibility(true);
  }
  getProducts(catID: number) {
    this.spinner.show();
    const brandFlvaor = window.location.pathname.split('/')[1];
    this._requestService
      .getRequest(
        `api/Product/getProductsByCategoryId/${brandFlvaor}/${catID}`,
        {}
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.products = res['CategoriesAndProducts'].Products;
            this.categoryName = res['CategoriesAndProducts'].CategoryTitle;
          }
        },
        error: () => {
          alert('Network Error! Please refresh page and try again');
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
}
