import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private brandFlavour: string;
  private keySubject = new BehaviorSubject<string>('');
  key$ = this.keySubject.asObservable()

  constructor(private _requestService: RequestService, private _local: GetterService) {
    this.brandFlavour = window.location.pathname.split('/')[1];
  }

  getStripeKey() {
    const token = this._local.get('access_token') ? this._local.get('access_token') : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "BrandFlavor": this.brandFlavour
    });
    return this._requestService
      .getRequest(`api/Stripe/getStripeKeyByFlavor/${this.brandFlavour}`, httpHeaders)
  }
}
