<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showPopup | async"
  (click)="hidePopup()"
>
  <div
    class="content br-40px m-1 overflow-hidden"
    (click)="stopPropagation($event)"
    *ngIf="!showMessage"
  >
    <div
      class="head d-flex justify-content-between align-items-center bg-light"
    >
      <h2 class="text-center m-0 w-100">Order Inquiry</h2>
      <button class="btn fs-1" (click)="hidePopup()">&times;</button>
    </div>
    <div class="body p-3 bg-white">
      <p class="text-center">Select country & branch to send inquiry</p>
      <p
        class="text-center text-muted"
        *ngIf="country !== 'Country' && branches !== 'Branches'"
      >
        {{ country }} {{ branches }}
      </p>
      <div class="row justify-content-center m-0 mb-3">
        <div class="col-sm-6">
          <select
            name="country"
            id="country"
            class="form-select"
            [(ngModel)]="country"
          >
            <option [value]="country" *ngFor="let country of countries">
              {{ country }}
            </option>
          </select>
        </div>
      </div>
      <div class="row m-0 mb-2">
        <div class="col branchesCard border border-1 br-10px bg-light">
          <h2>Custom Import Arts</h2>
          <p>Phone: 0791234567</p>
          <p>Email: test@test.com</p>
          <p>Website: <a href="test.com">test.com</a></p>
        </div>
      </div>
      <div class="row m-0 mb-2">
        <div class="col branchesCard border border-1 br-10px bg-light">
          <h2>Cougar Power Motorsports</h2>
          <p>Phone: 0791234567</p>
          <p>Email: test@test.com</p>
          <p>Website: <a href="test.com">test.com</a></p>
        </div>
      </div>
      <div class="row m-0 mb-2">
        <div class="col branchesCard border border-1 br-10px bg-light">
          <h2>Alternative Engineering A/S</h2>
          <p>Phone: 0791234567</p>
          <p>Email: test@test.com</p>
          <p>Website: <a href="test.com">test.com</a></p>
        </div>
      </div>
      <div class="row m-0 mb-2">
        <div class="col branchesCard border border-1 br-10px bg-light">
          <h2>AMW DYNO Service Ay</h2>
          <p>Phone: 0791234567</p>
          <p>Email: test@test.com</p>
          <p>Website: <a href="test.com">test.com</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
