import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category, CategoryWithRecipes } from 'src/Model/interface/productInterfaces';
import { RequestService } from './request.service';
interface CategoriesAndRecipes {
  Categories: Category[],
  CategoriesAndRecipes: CategoryWithRecipes[],
  Info: {
    Status: number
  }
}
@Injectable({
  providedIn: 'root'
})
export class RecipeService {
  private brandFlavour: string;
  private dataSubject = new BehaviorSubject<CategoriesAndRecipes>(null);
  data$: Observable<CategoriesAndRecipes> = this.dataSubject.asObservable();

  constructor(private _requestService: RequestService) {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.initateRecipes();
  }
  private initateRecipes() {
    const data = this.dataSubject.getValue();
    if (!data) {
      this.getCategories();
    }
  }
  private getCategories() {
    this._requestService
      .getRequest("api/Recipe/GetOnlineRecipesData/" + this.brandFlavour, [])
      .subscribe((res: CategoriesAndRecipes) => {
        if (res.Info.Status == 200) {
          this.dataSubject.next(res);
        }
      });
  }
}
