import { Component, OnInit, OnDestroy } from '@angular/core';
import { RequestService } from '../service/request.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { NgForm, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  brandId: any;
  recoverEmail: string = '';
  codeFromApi: number;
  codeFromUser: number;
  infoToken: string;
  isEmailSection: boolean = true;
  isCodeSection: boolean = false;
  isResetSection: boolean = false;
  MobileMenuTextColor: string;
  MobileMenuBorderColor: string;
  MobileMenuBackgroundColor: string;
  RedirectionFromLogin: any;
  simpleCheckout: boolean;
  submitted: boolean;
  message: any;
  showMessage: boolean;
  webCusSub: Subscription;

  constructor(
    private _requestService: RequestService,
    private app: AppComponent,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService
  ) {
    this.app.setFooterVisibility(false);
  }

  ngOnInit(): void {
    this.getWebCustomer();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.simpleCheckout = !res.TemplateData.CheckoutDetail?.simpleCheckout;
      this.MobileMenuTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuTextColor;
      this.MobileMenuBorderColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBorderColor;
      this.MobileMenuBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBackgroundColor;
      this.brandId = res.BrandData.BrandId;
      this.RedirectionFromLogin =
        res.TemplateData.RedirectionConfig.RedirectionFromLogin;
    });
  }

  getCode(form: NgForm) {
    this.submitted = true;
    if (form.invalid) return;

    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this.recoverEmail = form.controls['username'].value;
    let param = {
      Email: this.recoverEmail,
      BrandId: this.brandId,
    };

    this._requestService
      .postRequest(
        'api/Account/ForgotPassword',
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          this.message = res['Info']['Message'];
          this.showMessage = true;
          if (res.Info.Status == 200) {
            this.infoToken = res['Info']['Token'];
            this.isEmailSection = false;
            this.isCodeSection = true;
            this.codeFromApi = res['Info']['Code'];
          }
        },
        error: () => {
          this.message = 'Network Error! Something went wrong please try again';
          this.showMessage = true;
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  checkCode() {
    if (this.codeFromUser == this.codeFromApi) {
      this.isCodeSection = false;
      this.isResetSection = true;
    }
  }

  matchPass(password: NgModel, confirmPassword: NgModel) {
    if (password.value != confirmPassword.value) {
      return confirmPassword.control.setErrors({ mustMatch: true });
    } else {
      if (confirmPassword.control.hasError('mustMatch')) {
        confirmPassword.control.setErrors(null);
      }
    }
  }

  resetPasword(form: NgForm) {
    if (form.invalid) return;
    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let param = {
      Email: this.recoverEmail,
      BrandId: this.brandId,
      Password: form.controls['password'].value,
      ConfirmPassword: form.controls['confirmpassword'].value,
      Code: this.infoToken,
    };
    this._requestService
      .postRequest(
        'api/Account/ResetPassword',
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res.Info.Status == 200) {
            this.openUrl('login');
          }
          this.message = res.Info.Message;
          this.showMessage = true;
        },
        error: () => {
          this.message =
            'Some error occured! Check your connection and try again.';
          this.showMessage = true;
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  openUrl(url) {
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }

  focusInputs(event: Event) {
    let input = event.target as HTMLInputElement;
    if (input.value) {
      input.classList.add('in-focus');
    } else {
      input.classList.remove('in-focus');
    }
  }

  ngOnDestroy() {
    this.app.setHeaderVisibility(true);
    this.app.setFooterVisibility(true);
    this.webCusSub.unsubscribe();
  }
}
