import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  ErrorHandler,
} from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { RequestInterceptor } from './service/request-interceptor';

import { PreviousOrderComponent } from './previous-order/previous-order.component';
import { DatePipe } from '@angular/common';
import { RewardComponent } from './reward/reward.component';
import { SearchComponent } from './search/search.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MyRewardsComponent } from './my-rewards/my-rewards.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SendGiftcardComponent } from './send-giftcard/send-giftcard.component';

import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PostcodeIframeModule } from './postcode-iframe/postcode-iframe.module';
import { RecipeModule } from './recipe/recipe.module';
import { OrderModule } from './online/online.module';
import { LoginModule } from './login/login.module';
import { BranchesComponent } from './branches/branches.component';
import { SharedModule } from './shared/shared.module';
import { AllergensFooterComponent } from './allergens-footer/allergens-footer.component';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { SideDrawerComponent } from './side-drawer/side-drawer.component';
import { ShopComponent } from './shop/shop.component';
import { UpdateAccountComponent } from './update-account/update-account.component';
import { DineInListViewComponent } from './dine-in-list-view/dine-in-list-view.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { ReferalComponent } from './referal/referal.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PayComponent } from './pay/pay.component';
import { RecomendationsComponent } from './recomendations/recomendations.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReservationModule } from './reservation/reservation.module';
import { LikeComponent } from './like/like.component';
import { ErrorHandlingService } from './service/error-handling.service';
import { ProductsModule } from './products/products.module';
import { CategoryProductsComponent } from './category-products/category-products.component';
import { StoreModule } from './store/store.module';
import { TrainingModule } from './training/training.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PreviousOrderComponent,
    RewardComponent,
    SearchComponent,
    MyRewardsComponent,
    SendGiftcardComponent,
    FooterComponent,
    ForgotPasswordComponent,
    BranchesComponent,
    AllergensFooterComponent,
    BottomMenuComponent,
    SideDrawerComponent,
    ShopComponent,
    DineInListViewComponent,
    UpdateAccountComponent,
    FavouritesComponent,
    ReferalComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    PayComponent,
    RecomendationsComponent,
    LikeComponent,
    CategoryProductsComponent,
  ],
  imports: [
    SharedModule,
    PostcodeIframeModule,
    RecipeModule,
    OrderModule,
    LoginModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    CalendarModule,
    MatSnackBarModule,
    InfiniteScrollModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      showSubtitle: false,
      showUnits: true,
      maxPercent: 100,
      outerStrokeWidth: 1,
      // innerStrokeWidth: 8,
      showInnerStroke: false,
      outerStrokeColor: '#ff2536',
      subtitleColor: 'red',
      // innerStrokeColor: '#ffffff',
      animationDuration: 300,
    }),
    BrowserAnimationsModule,
    ReservationModule,
    OrderModule,
    ProductsModule,
    StoreModule,
    TrainingModule,
  ],
  providers: [
    AppComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: ErrorHandler, useClass: ErrorHandlingService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
