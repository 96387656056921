import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicColorsService {
  ItemBackgroundColor: string;
  ItemTitleColor: string;
  SelectedButtonTextColor: string;
  SelectedButtonColor: string;
  MobileHeaderBackgroundColor: string;
  BurgerMenuColor: string;
  MobileSearchColor: string;
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  HeaderHoverBackgroundColor: string;
  HeaderTextHoverColor: string;
  SiteBackgroundColor: string;
  HeaderCartColor: string;
  HeaderCartCounterColor: string;


  constructor(private apiService: ApiService) {
    this.apiService.getWebcustomer$.subscribe(res => {
      if (!res) return;
      this.ItemBackgroundColor = res.TemplateData.DynamicStyle.SecondaryColors?.ItemBackgroundColor
      this.ItemTitleColor = res.TemplateData.DynamicStyle.SecondaryColors?.ItemTitleColor
      this.SelectedButtonTextColor = res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.SelectedButtonColor = res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.MobileHeaderBackgroundColor = res.TemplateData.DynamicStyle.PrimaryColors.MobileHeaderBackgroundColor;
      this.BurgerMenuColor = res.TemplateData.DynamicStyle.PrimaryColors.BurgerMenuColor
      this.MobileSearchColor = res.TemplateData.DynamicStyle.PrimaryColors.MobileSearchColor;
      this.HeaderBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderBackgroundColor;
      this.HeaderTextColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextColor;
      this.HeaderHoverBackgroundColor = res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderHoverBackgroundColor;
      this.HeaderTextHoverColor = res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextHoverColor;
      this.SiteBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.SiteBackgroundColor;
      this.HeaderCartColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartColor;
      this.HeaderCartCounterColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartCounterColor;
    })
  }
}
