<div class="container mt-4">
  <h2 class="text-center fs-3">{{ subCategoryTitle }}</h2>
  <p class="fs-5 text-muted">
    <span class="text-decoration-underline cursor-pointer" (click)="openProducts()">Colors</span> /
    <span class="text-decoration-underline cursor-pointer" (click)="goBack()">{{ categoryTitle }}</span> /
    <span>{{ subCategoryTitle }}</span>
  </p>
  <div class="row justify-content-center">
    <div
      class="col-lg-3 col-md-4 col-sm-6 col-12 mb-2"
      *ngFor="let product of products"
    >
      <app-product [product]="product"></app-product>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
