import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  BrandName;
  BrandContact;
  webCustomer: Subscription
  constructor(
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.webCustomer = this.apiService.getWebcustomer$.subscribe(res => {
      if (!res) {
        return;
      }
      this.BrandName = res.BrandData.BrandName;
      this.BrandContact = res.BrandData.BrandBranch.BrandBranchContact
    })
  }
  ngOnDestroy() {
    this.webCustomer.unsubscribe()
  }

}
