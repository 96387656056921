<div class="containerBox" *ngIf="!token">
  <div class="contentBox">
    <img
      src="https://perkss.blob.core.windows.net/images/customer/refer/w_bg.png"
      alt="Referal Image"
    />
    <div class="referForm mt-4">
      <p class="text-center">Feeling generous?</p>
      <p>Enter email to invite your friend by sending refer code</p>
      <div class="p-relative mb-3">
        <input
          type="email"
          name="email"
          id="email"
          required
          email
          ngModel
          #email="ngModel"
          placeholder="Enter email"
          class="form-control br-40px"
          (keyup.enter)="sendCode(email)"
          (input)="restMsg()"
        />
        <p
          *ngIf="email?.errors?.['email'] && submitted"
          class="text-danger invalidText"
        >
          Invalid Email
        </p>
        <p
          *ngIf="email?.errors?.['required'] && submitted"
          class="text-danger invalidText"
        >
          Email Required
        </p>
      </div>
      <button
        class="br-40px btn w-100 mt-3"
        (click)="sendCode(email)"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
        [disabled]="disabled"
      >
        Send Refer Code
        <span
          class="spinner-border text-dark spinner-border-sm"
          *ngIf="disabled"
        ></span>
      </button>
      <p *ngIf="referalSendSuccess" class="text-success text-center">
        {{ message }}
      </p>
      <p *ngIf="!referalSendSuccess" class="text-danger text-center">
        {{ message }}
      </p>
    </div>
  </div>
</div>
<div class="containerBox" *ngIf="token">
  <div class="contentBox">
    <div *ngIf="!activateStatus" class="content">
      <p class="text-danger activateMsg">{{ message }}</p>
      <img
        src="https://perkss.blob.core.windows.net/images/customer/refer/invalid.png"
        alt="Referal Image"
      />
      <button class="btn btn-danger w-100 br-40px mt-3" (click)="openUrl()">
        Okay
      </button>
    </div>
    <div *ngIf="activateStatus" class="content">
      <p class="text-success activateMsg">{{ message }}</p>
      <img
        src="https://perkss.blob.core.windows.net/images/customer/refer/valid.png"
        alt="Referal Image"
      />
      <button class="btn btn-success w-100 br-40px mt-3" (click)="openUrl()">
        Order Now
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
