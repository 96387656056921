import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class GetterService {
  brand_Name: string = null;

  constructor(private deviceDetector: DeviceDetectorService) {}

  get(key) {
    if (this.brand_Name == null) {
      this.getID();
      return localStorage.getItem(this.brand_Name + ' ' + key);
    } else {
      return localStorage.getItem(this.brand_Name + ' ' + key);
    }
  }

  set(key, val) {
    if (this.brand_Name == null) {
      this.getID();
      localStorage.setItem(this.brand_Name + ' ' + key, val);
    } else {
      localStorage.setItem(this.brand_Name + ' ' + key, val);
    }
  }

  remove(key) {
    if (this.brand_Name == null) {
      this.getID();
      localStorage.removeItem(this.brand_Name + ' ' + key);
    } else {
      localStorage.removeItem(this.brand_Name + ' ' + key);
    }
  }

  setSessionItem(key: string, value) {
    if (this.brand_Name == null) {
      this.getID();
      sessionStorage.setItem(this.brand_Name + ' ' + key, value);
    } else {
      sessionStorage.setItem(this.brand_Name + ' ' + key, value);
    }
  }
  getSessionItem(key: string) {
    if (this.brand_Name == null) {
      this.getID();
      return sessionStorage.getItem(this.brand_Name + ' ' + key);
    } else {
      return sessionStorage.getItem(this.brand_Name + ' ' + key);
    }
  }
  removeSessionItem(key) {
    if (this.brand_Name == null) {
      this.getID();
      sessionStorage.removeItem(this.brand_Name + ' ' + key);
    } else {
      sessionStorage.removeItem(this.brand_Name + ' ' + key);
    }
  }
  clear() {
    //localStorage.clear();
  }

  getID() {
    this.brand_Name = window.location.pathname.split('/')[1];
  }
  
  getDeviceInfo() {
    return this.deviceDetector;
  }
}
