import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-referal',
  templateUrl: './referal.component.html',
  styleUrls: ['./referal.component.scss'],
})
export class ReferalComponent implements OnInit, OnDestroy {
  token: string;
  submitted: boolean;
  disabled: boolean;
  brandFlavour: string;
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor = '#FFFFFF';
  message: string;
  referalSendSuccess: boolean;
  activateStatus: boolean;
  redirectTo: string;
  webCusSub: Subscription;
  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.token = this.route.snapshot.params['token'];
    if (this.token) {
      this.activateCode();
    }
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.redirectTo =
        res.TemplateData.RedirectionConfig.RedirectionFromCheckout;
      this.spinner.hide();
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
    });
  }
  sendCode(email: NgModel) {
    this.spinner.hide();
    this.message = '';
    this.submitted = true;
    if (email.invalid && email.errors) {
      return;
    }
    this.disabled = true;
    let customerId = this._local.get('CustomerId');
    let body = {
      Source: 'web',
      SenderId: customerId,
      RecipientEmail: email.value,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
    });
    this._requestService
      .postRequest(
        `api/Referral/addReferral/${this.brandFlavour}`,
        body,
        httpHeaders
      )
      .subscribe((res) => {
        this.disabled = false;
        this.spinner.hide();
        if (res['Info'].Status == 200) {
          this.referalSendSuccess = true;
        } else {
          this.referalSendSuccess = false;
        }
        this.message = res['Info'].Message;
      });
  }
  activateCode() {
    let body = {
      BrandFlavor: this.brandFlavour,
      ReferralCode: this.token,
    };
    this._requestService
      .postRequest('api/Referral/activateReferral', body)
      .subscribe((res) => {
        this.spinner.hide();
        this.activateStatus = res['Info'].Status == 200;
        this.message = res['Info'].Message;
      });
  }
  restMsg() {
    this.message = '';
  }
  openUrl() {
    this.router.navigateByUrl(this._requestService.setUrl(this.redirectTo));
  }
}
