<section
	class="recipeResponsiveView"
	id="recipeResponsiveView"
	*ngIf="windowWidth <= 768"
>
	<div class="container-fluid">
		<h1
			class="text-center menuHeading"
			[ngStyle]="{
				color: MobileMenuTextColor,
				boder: '1px solid ' + MobileMenuBorderColor,
				'background-color': MobileMenuBackgroundColor
			}"
		>
			Dine-In Menu<span
				*ngIf="currentEnvironment == 'Dev'"
				[ngStyle]="{ color: MobileMenuTextColor }"
				>-Test Version</span
			>
		</h1>
	</div>
	<!--post code mobile end-->
	<div *ngFor="let category of brand_Categories" [id]="category.CategoryId">
		<div class="container-fluid">
			<div class="custom-accord">
				<p
					[ngStyle]="{
						'background-color': category?.CategoryBackgroundColor
							? category?.CategoryBackgroundColor
							: MobileMenuCategoryBackgroundColor,
						color: category?.CategoryTextColor
							? category?.CategoryTextColor
							: MobileMenuCategoryTextColor
					}"
					(click)="toggleRecipesOnMobile(category)"
				>
					{{ category?.CategoryTitle || ""
					}}<i class="fa fa-caret-down" aria-hidden="true"></i>
				</p>
				<div
					*ngIf="
						this.selectedCategoryResponsive?.CategoryId == category?.CategoryId
					"
				>
					<div
						class="RecipeCardHeader"
						*ngIf="selectedCategoryResponsive?.CategoryImage"
					>
						<img
							src="{{ selectedCategoryResponsive?.CategoryImage }}"
							alt="category image"
						/>
					</div>
					<app-recipe-card-list-style
						[recipes]="recipes"
						[openForNonExtras]="true"
						[addTocart]="false"
						[dineIn]="true"
						[doNotOpenDeliveryPopup]="true"
					></app-recipe-card-list-style>
				</div>
			</div>
		</div>
	</div>
</section>

<!--Desktop View-->
<section class="container mt-3" *ngIf="windowWidth >= 768">
	<div class="row">
		<div class="col-4">
			<div class="bg-light br-10px p-2 position-sticky top-0">
				<h1>
					Dine-In Menu<span
						class="text-danger"
						*ngIf="currentEnvironment == 'Dev'"
						>-Test Version</span
					>
				</h1>
				<hr />
				<ul class="p-0">
					<li
						class="p-1 br-40px fs-5 cursor-pointer mb-1"
						*ngFor="let i = index; let category; of: brand_Categories"
						(click)="getRecipes(category)"
						[ngStyle]="
							selectedCategory.CategoryId == category.CategoryId
								? {
										'background-color': SelectedButtonColor,
										color: SelectedButtonTextColor
								  }
								: {
										'background-color': category?.CategoryBackgroundColor,
										color: category?.CategoryTextColor
								  }
						"
					>
						{{ category?.CategoryTitle || "" }}
					</li>
				</ul>
			</div>
		</div>
		<div class="col-8" id="recipes">
			<div class="RecipeCardHeader" *ngIf="categoryImage && categoryName">
				<div>
					<img src="{{ categoryImage }}" alt="category image" />
				</div>
				<h2 class="text-center">{{ categoryName }}</h2>
			</div>
			<app-recipe-card-list-style
				[recipes]="recipes"
				[openForNonExtras]="true"
				[addTocart]="false"
				[dineIn]="true"
				[doNotOpenDeliveryPopup]="true"
			></app-recipe-card-list-style>
		</div>
	</div>
</section>

<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
