import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsComponent } from './products.component';
import { SubCategoriesComponent } from './sub-categories/sub-categories.component';
import { SubcategoryProductsComponent } from './subcategory-products/subcategory-products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent
  },
  {
    path: 'product-details/:id',
    component: ProductDetailsComponent
  },
  {
    path: ':catTitle/:catID/sub-categories',
    component: SubCategoriesComponent
  },
  {
    path: ':catTitle/:catID/sub-category/:subCatTitle/:subCatID',
    component: SubcategoryProductsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
