import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewReservationRoutingModule } from './reservation-routing.module';
import { ReservationComponent } from './reservation.component';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ErrorHandlingService } from '../service/error-handling.service';

@NgModule({
  declarations: [ReservationComponent],
  imports: [
    CommonModule,
    NewReservationRoutingModule,
    CalendarModule,
    NgxSpinnerModule,
    FormsModule,
    SharedModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class ReservationModule {}
