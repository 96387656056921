import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root',
})

export class GuestGuard implements CanActivate {
  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private _router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !this._requestService.isLoggedIn() &&
      this._local.getSessionItem('access_token')
    ) {
      return true;
    } else {
      this._router.navigate([this._requestService.setUrl('login')]);
      return false;
    }
  }
}
