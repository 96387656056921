<div class="container-fluid mt-4 mb-5">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="row" *ngFor="let category of catAndProducts">
        <div
          class="col mb-4"
          *ngIf="preventDuplication(category)"
          [id]="category.CategoryId"
        >
          <h2 class="text-center fs-3">{{ category.CategoryTitle }}</h2>
          <div class="row justify-content-center">
            <div
              class="col-lg-4 col-sm-6 col-12 mb-2"
              *ngFor="let product of category.Products"
            >
              <app-product [product]="product"></app-product>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let section of homePageTemplate">
        <div class="row justify-content-center">
          <h2 class="text-center fs-3">{{ section.Section }}</h2>
          <div
            class="col-lg-4 col-sm-6 col-12 mb-2"
            *ngFor="let category of section.Categories"
            [id]="category.ID"
          >
            <div
              class="border br-10px min-h-150px d-flex justify-content-center align-items-center cursor-pointer animation"
              [ngStyle]="{
                background: category.CategoryBackgroundColor,
                color: category.CategoryTextColor
              }"
              (click)="openSubCategory(category)"
            >
              <p class="m-0 text-center fs-5 fw-500">
                {{ category.Title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-lg-4 col-sm-6 col-12 mb-2"
          *ngFor="let product of sectionProducts"
        >
          <app-product [product]="product"></app-product>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="position-sticky top-0">
        <app-side-cart></app-side-cart>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
