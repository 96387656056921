<main class="container">
    <h2 class="text-center">{{quote}}</h2>

    <div class="row p-2">
        <section class="col-md-6 p-2">
            <div class="fb-like" data-width="" data-layout="box_count" data-action="like" data-size="large"
                data-share="true" id="fbBtn" #fbBtn></div>
        </section>
        <section class="col-md-6">
            <app-recipe-card-list-style [recipes]='recipes' [addTocart]='addTocart'></app-recipe-card-list-style>
        </section>
    </div>
</main>
<div class="pageLikedBox p-2" [ngStyle]="{'background-color':this.SelectedButtonColor}" *ngIf="message">
    <h2 class="text-center m-0" [ngStyle]="{'color':this.SelectedButtonTextColor}">{{message}}</h2>
</div>