import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product, Recipe } from 'src/Model/interface/productInterfaces';
import { popupService } from '../popup/popup.service';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
declare var FB;

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss']
})
export class LikeComponent implements OnInit, AfterViewInit, OnDestroy {
  fbImage: string
  message: string
  quote: string
  FailedMessage: string
  SuccessMessage: string
  SelectedButtonColor: string
  SelectedButtonTextColor: string
  brandFBPage: string;
  brandFlavour: string;
  recipes: Recipe[];
  products: Product[];
  addTocart: boolean = false
  redirectComponent: string;
  @ViewChild('fbBtn') fbBtn: ElementRef;
  firstCall: boolean = true;
  popupServiceSub: Subscription;
  webCusSub: Subscription;
  constructor(
    private apiService: ApiService,
    private _local: GetterService,
    private _requestService: RequestService,
    private popupService: popupService,
    private router: Router,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._local.remove('cartRecipeItems');
    this._local.remove('cartProductItems');
    this._requestService.cartCounter = 0;
    this.cd.detectChanges();
    this.brandFlavour = window.location.pathname.split('/')[1]
    this.getItems();
    this.popupServiceSub = this.popupService.addedToCartSuccessful.subscribe(data => {
      this.router.navigateByUrl(this._requestService.setUrl(this.redirectComponent))
    })
  }
  ngOnDestroy(): void {
    this.popupServiceSub.unsubscribe();
    this.webCusSub.unsubscribe();
  }
  ngAfterViewInit() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe(res => {
      if (!res) {
        return;
      }
      this.SelectedButtonColor = res.TemplateData?.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor = res.TemplateData?.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.brandFBPage = res.TemplateData.BusinessInfo?.FacebookLink
      this.fbImage = res.TemplateData?.FBLike?.image;
      this.quote = res.TemplateData?.FBLike?.Message;
      this.FailedMessage = res.TemplateData?.FBLike?.FailedMessage;
      this.SuccessMessage = res.TemplateData?.FBLike?.SuccessMessage;
      this.redirectComponent = res.TemplateData.RedirectionConfig.RedirectionFromCheckout
      this.renderer.setAttribute(this.fbBtn.nativeElement, 'data-href', this.brandFBPage)
    })
    if (!window.document.getElementById("fbSdk")) {
      let s = window.document.createElement("script");
      s.id = "fbSdk";
      s.type = "text/javascript";
      s.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0";
      s.onload = () => {
        FB.init({
          status: true,
          xfbml: true,
          version: 'v2.3' // or v2.6, v2.5, v2.4, v2.3
        });
        FB.Event.subscribe('edge.create', this.openFbPage());
      }
      window.document.body.appendChild(s);
    }
  }
  getItems() {
    let body = {
      BrandFlavor: this.brandFlavour,
      ProductOrderDetail: this._requestService.getProductCartItems(),
      RecipeOrderDetail: this._requestService.getCartRecipeItems()
    }
    this._requestService.postRequest('api/CheckoutDiscount/GetSocialDiscountedItemsList', body, {}).subscribe(res => {
      if (res['Info'].Status == 200) {
        this.recipes = res['RecipeData'];
        this.products = res['ProductData'];
        this.recipes.forEach(recipe => {
          recipe.socialDiscountItem = true;
        })
        this.products.forEach(product => {
          product.socialDiscountItem = true;
        })
      }
    })
  }
  openFbPage = () => {
    window.addEventListener('focus', this.likeBrandFbPage, { once: true })
  }

  likeBrandFbPage = (() => {
    this.addTocart = true;
  }).bind(this)
}
