import { Injectable } from '@angular/core';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  brandFlavour: string;
  description: string;
  amount: number;
  PaymentMethodId: string;
  paypalToken: any;
  orderId: number;

  constructor(
    private _local: GetterService,
    private _requestService: RequestService
  ) {
    this.brandFlavour = window.location.pathname.split('/')[1];
  }

  getParams(type) {
    let params = {
      BrandFlavor: this.brandFlavour,
      CustomerId: +this._local.get('CustomerId'),
      PaymentDetails: this.description
        ? this.description
        : 'Dummy Text Dummy Text',
      Amount: this.amount,
      PaymentType: type,
      CardPaymentAmount: 0,
      CashPaymentAmount: 0,
      OrderId: this.orderId,
    };

    if (this.orderId) {
      params['OrderId'] = this.orderId;
    }

    if (!this._requestService.isLoggedIn()) {
      params['CustomerId'] = 0;
      params['CustomerEmail'] = this._local.getSessionItem('guestEmail');
      params['CustomerFirstName'] =
        this._local.getSessionItem('guestFirstName');
      params['CustomerLastName'] = this._local.getSessionItem('guestLastName');
      params['CustomerMobile'] = this._local.getSessionItem('guestContact');
    }
    if (type == 'Card') {
      params['CardDetail'] = {
        CustomerEmail: this._local.get('username'),
        Description: this.description,
        StatementDescriptor: this.brandFlavour,
        PaymentMethodId: this.PaymentMethodId,
      };
    }
    if (type == 'Card' && this._local.getSessionItem('access_token')) {
      params['CardDetail']['CustomerEmail'] =
        this._local.getSessionItem('guestEmail');
    }
    if (type == 'Paypal') {
      params['PaypalPurchaseDetails'] = {
        PaypalOrderId: this.paypalToken,
      };
    }

    return params;
  }
}
