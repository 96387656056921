import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CheckoutDetail } from 'src/Model/interface/WebCustomer';
import { AppComponent } from '../app.component';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { NewReservationService } from './reservation.service';
interface Days {
  _Day: string;
  _MaxBookPerSlot: number;
  _Slots: string[];
  _SpanHours: number;
  _SpanMinutes: number;
  Guests: {
    Min: number;
    Max: number;
  };
}

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit, OnDestroy {
  selectedCelenderDate;
  minDate;
  maxDate;
  timeSlots: any = [];
  calendarSec: boolean = true;
  guestMin: number = 0;
  guestMax: number = 0;
  days: Days[] = [];

  /* Form Data */
  noOfGuests: number;
  selectedSlot: string;
  firstName;
  lastName;
  contact;
  email;
  confirmEmail;
  notes: string = '';
  checkoutConfig: CheckoutDetail;
  /* Styles */
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor = '#FFFFFF';
  UnselectedButtonTextColor = '#000000';
  UnselectedButtonColor = '#FFFFFF';

  guestList: any = [];
  areaList: any;
  showArea: any;
  personalInfosec: boolean;
  paymentSec: boolean;
  isPaymentRequired: any;
  selectedArea: string = '';
  defaultStatus: any;
  configId: number;
  showMessage: boolean;
  message: string;
  depositAmount: number = 0;
  totalAmount: number;
  cardId: any;
  showCanceledMsg: boolean = false;
  showStripe: boolean;
  noSlotsAvailable: boolean;
  showPaymentSense: boolean;
  /* Settings */
  cancel: boolean = false;
  editing: boolean = false;
  component: string = '';
  reservationId: string;
  brandWeblink: string;
  editingSlot: string;
  formSubmitted: boolean;
  

  showTermsAndConditions: boolean;
  showNotice: boolean;
  @ViewChild('form') form: NgForm;
  paymentGateway: string;
  webCusSub: Subscription;
  termsAndConditionsDetails: TermsAndConditionsDetails;

  guestLimitForBookingPayment: number;
  guestLimitForBookingPaymentEnabled: boolean;
  
  constructor(
    private datePipe: DatePipe,
    private _requestService: RequestService,
    private spinner: NgxSpinnerService,
    private _local: GetterService,
    private apiService: ApiService,
    private app: AppComponent,
    private route: ActivatedRoute,
    private reservationService: NewReservationService
  ) {
    this.cancel =
      this.route.snapshot.queryParams['cancel'] == 'true' ? true : false;
    this.editing =
      this.route.snapshot.queryParams['edit'] == 'true' ? true : false;
    this.reservationId = this.route.snapshot.queryParams['reservationId'];

    this.getRevervationConfig();

    this.component = window.location.pathname.split('/')[2];
    if (this.component == 'reserve-iframe') {
      this.app.setHeaderVisibility(false);
      this.app.setFooterVisibility(false);
    }
  }

  ngOnInit(): void {
    this.getWebCustomer();
  }

  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }

  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.paymentGateway = res.TemplateData.CheckoutDetail.PaymentGateway;
      this.brandWeblink = res.TemplateData.BusinessInfo.WebLink;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.checkoutConfig = res.TemplateData.CheckoutDetail;
    });
  }

  onValueChange(data) {
    this.setGuestList();
    this.getSlotByDate(this.selectedCelenderDate);
    this.reservationService.selectedCelenderDate = this.selectedCelenderDate;
  }

  getSlotByDate(date: Date) {
    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let transformedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.timeSlots = [];
    this.selectedSlot = null;
    this.reservationService.selectedSlot = this.selectedSlot;
    this.noSlotsAvailable = false;
    const brandId = this._local.get('BrandId');
    let param = {
      BrandId: brandId,
      SelectedDate: transformedDate,
    };
    let success = (res) => {
      this.spinner.hide();
      if (res.Info.Status == 200 && res.Data.length) {
        this.timeSlots = res.Data;
        this.selectedSlot = this.timeSlots[0];
        this.reservationService.selectedSlot = this.selectedSlot;
        if (this.editing) {
          this.timeSlots.forEach((slot) => {
            if (slot == this.editingSlot) {
              this.selectedSlot = this.editingSlot;
              this.reservationService.selectedSlot = this.selectedSlot;
            }
          });
        }
      } else {
        this.noSlotsAvailable = true;
      }
    };
    let error = (error) => {
      this.spinner.hide();
      this.message =
        'Network error please refresh page and check network connection';
      this.showMessage = true;
    };
    this._requestService
      .postRequest(
        'api/Reservation/getSlotByDate',
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
      });
  }

  getRevervationConfig() {
    this.spinner.show();
    const brandFalvour = window.location.pathname.split('/')[1];
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let success = (res) => {
      if (res.Info.Status == 200) {
        this.depositAmount = res.Data.Object.ReservationDeposit;
        this.guestLimitForBookingPayment = res.Data.Object?.GuestLimitForBookingPayment;
        this.guestLimitForBookingPaymentEnabled = res.Data.Object?.GuestLimitForBookingPaymentEnabled 
        this.configId = res.Data.Id;
        this.reservationService.configId = this.configId;
        this.defaultStatus = res.Data.Object.DefaultStatus;
        this.reservationService.defaultStatus = this.defaultStatus;
        const startAt: string = res.Data.Object.CalendarViewDetail.StartAt;
        const endAt: string = res.Data.Object.CalendarViewDetail.EndAt;
        this.minDate = new Date(startAt);
        this.maxDate = new Date(endAt);
        this.selectedCelenderDate = this.minDate;
        this.reservationService.selectedCelenderDate =
          this.selectedCelenderDate;
        this.isPaymentRequired = res.Data.Object.PaymentRequired;
        /* new Date(this.date.setDate(this.date.getDate() + 1)) */
        this.guestMin = res.Data.Object.Guests.Min;
        this.guestMax = res.Data.Object.Guests.Max;
        this.setGuestList();
        this.areaList = res.Data.Object.Areas;
        this.selectedArea = this.areaList[0];
        this.reservationService.selectedArea = this.selectedArea;
        this.showArea = res.Data.Object.ShowArea;
        this.days = res.Data.Object.TimeSlots;
        this.termsAndConditionsDetails =
          res.Data.Object?.TermsAndConditionsDetails;
        this.showNotice = this.termsAndConditionsDetails.ShowNotice;
        this.showTermsAndConditions =
          this.termsAndConditionsDetails.ShowTermsAndConditions;
        if (this.reservationId) {
          this.getReservationbyId();
        } else {
          this.spinner.hide();
        }
      } else {
        this.message = 'Some thing went wrong please refresh page';
        this.showMessage = true;
        this.spinner.hide();
      }
    };
    let error = (error) => {
      this.spinner.hide();
      this.message = 'Some thing went wrong please refresh page';
      this.showMessage = true;
    };

    this._requestService
      .getRequest('api/Reservation/getConfig_v1/' + brandFalvour, httpHeaders)
      .subscribe({
        next: success,
        error: error,
      });
  }

  setGuestList() {
    this.guestList = [];
    let min = 0;
    let max = 0;
    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    let day = this.days.find(
      (day) => day._Day == weekday[this.selectedCelenderDate.getDay()]
    );
    if (day?.Guests) {
      min = day.Guests.Min;
      max = day.Guests.Max;
    } else {
      min = this.guestMin;
      max = this.guestMax;
    }

    for (let i: number = min, index: number = 0; i <= max; i++, index++) {
      this.guestList.push(i);
    }
    this.noOfGuests = this.guestList[0];
    this.reservationService.noOfGuests = this.noOfGuests;
  }

  openPersonalInfoSec() {
    this.calendarSec = false;
    this.personalInfosec = true;
  }

  openPaymentSec(form: NgForm) {
    this.formSubmitted = true;
    if (this.termsAndConditionsDetails.ShowNotice && !form.value.NoticeText) {
      return;
    }

    if (
      this.termsAndConditionsDetails.ShowTermsAndConditions &&
      !form.value.TermsAndConditions
    ) {
      return;
    }

    if (this.showArea && !this.selectedArea) {
      return;
    }
    if (
      !this.noOfGuests ||
      !this.selectedSlot ||
      this.email != this.confirmEmail ||
      this.form.invalid
    ) {
      return;
    }
    this.totalAmount = this.noOfGuests * this.depositAmount;
    if (!this.checkoutConfig.PayPalEnabled) {
      this.checkPaymentGateway();
      return;
    }
    this.personalInfosec = false;
    this.paymentSec = true;
  }

  focus(input: NgModel, id: string) {
    var element = document.getElementById(id);
    if (input.value) {
      element.classList.add('in-focus');
    } else {
      element.classList.remove('in-focus');
    }
    if (id == 'note') {
      this.reservationService.notes = this.notes;
    }
    if (id == 'email') {
      this.reservationService.email = this.email;
    }
  }

  response(res) {
    this.showStripe = false;
    this.showPaymentSense = false;
    if (res?.error) {
      this.message = res.error.message;
      this.showMessage = true;
      this.spinner.hide();
    } else {
      this.handleBooKTableResponse(res);
    }
  }

  formSubmit(form: NgForm) {
    this.formSubmitted = true;
    if (this.showArea && !this.selectedArea) {
      return;
    }
    if (
      !this.noOfGuests ||
      !this.selectedSlot ||
      this.email != this.confirmEmail ||
      form.invalid
    ) {
      return;
    }
    this.bookTable();
  }

  bookTable() {
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this.spinner.show();
    let error = (error) => {
      this.message = 'Some went wrong please try again';
      this.showMessage = true;
      this.spinner.hide();
    };

    const brandId = this._local.get('BrandId');
    let param = this.reservationService.getParams();
    this._requestService
      .postRequest(
        'api/Reservation/book/v1/' + brandId,
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: this.handleBooKTableResponse,
        error: error,
      });
  }

  handleBooKTableResponse = (res) => {
    this.spinner.hide();
    if (res.Info.Status == 201 || res.Info.Status == 200) {
      this.calendarSec = true;
      this.personalInfosec = false;
      this.paymentSec = false;
      this.resetForm();
    }
    this.message = res.Info.Message;
    this.showMessage = true;
  };

  getReservationbyId() {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let success = (res) => {
      this.spinner.hide();
      if (res.Info.Status == 200) {
        let time = res.Data.BookingTime;
        this.selectedCelenderDate = this.datePipe.transform(time, 'mediumDate');
        this.reservationService.selectedCelenderDate =
          this.selectedCelenderDate;
        this.selectedSlot = this.datePipe.transform(time, 'h:mm a');
        this.reservationService.selectedSlot = this.selectedSlot;
        this.editingSlot = this.datePipe.transform(time, 'hh:mm a');
        this.selectedArea = res.Data.Area;
        this.reservationService.selectedArea = this.selectedArea;
        this.noOfGuests = res.Data.Guests;
        this.reservationService.noOfGuests = this.noOfGuests;
        this.firstName = res.Data.Customer.CustomerFirstName;
        this.lastName = res.Data.Customer.CustomerLastName;
        this.contact = res.Data.Customer.CustomerMobile;
        this.email = res.Data.Customer.CustomerEmail;
        this.reservationService.firstName = this.firstName;
        this.reservationService.lastName = this.lastName;
        this.reservationService.contact = this.contact;
        this.reservationService.email = this.email;
        this.confirmEmail = res.Data.Customer.CustomerEmail;
        this.notes = res.Data.Notes;
        this.reservationService.notes = this.notes;
      } else {
        this.message =
          'Could not fetch your reservation data please refresh page and try again';
        this.showMessage = true;
      }
    };

    let error = (error) => {
      this.message =
        'Could not fetch your reservation data please refresh page and try again';
      this.showMessage = true;
    };
    let completed = () => {
      this.spinner.hide();
    };
    this.spinner.show();
    this._requestService
      .getRequest(
        'api/Reservation/getReservationById/' + this.reservationId,
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
        complete: completed,
      });
  }

  cancelReservation() {
    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let success = (res) => {
      this.spinner.hide();
      if (res['Info']['Status'] == 201) {
        this.message = res['Info']['Message'];
        this.showCanceledMsg = true;
        this.showMessage = true;
      } else {
        this.message = res['Info']['Message'];
        this.showCanceledMsg = true;
        this.showMessage = true;
      }
    };
    let error = (error) => {
      this.spinner.hide();
      this.message = 'Something went wrong please refresh page and try again';
      this.showMessage = true;
    };
    this._requestService
      .putRequest(
        'api/Reservation/update/' + this.reservationId + '/Cancelled',
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
      });
  }

  updateReservation() {
    this.formSubmitted = true;
    if (
      !this.noOfGuests ||
      !this.selectedSlot ||
      !this.selectedSlot ||
      !this.selectedCelenderDate ||
      this.email != this.confirmEmail ||
      this.form.invalid
    ) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    this.spinner.show();
    let param = {
      Guests: this.noOfGuests,
      Source: 'Web Customer',
      Time:
        this.datePipe.transform(this.selectedCelenderDate, 'mediumDate') +
        ' ' +
        this.selectedSlot,
      Customer: {
        Email: this.email,
        FirstName: this.firstName,
        LastName: this.lastName,
        Mobile: this.contact,
      },
    };
    let success = (res) => {
      if (res['Info']['Status'] == 200) {
        this.message = res['Info'].Message;
        this.showCanceledMsg = true;
        this.showMessage = true;
      } else {
        this.message = res['Info'].Message;
        this.showCanceledMsg = true;
        this.showMessage = true;
      }
    };
    let error = (error) => {
      this.message =
        'Could not update reservation please refresh page and try again';
      this.showMessage = true;
    };
    let completed = () => {
      this.spinner.hide();
    };
    this._requestService
      .putRequest(
        'api/Reservation/updateReservation/' + this.reservationId,
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
        complete: completed,
      });
  }

  stripeError(message: string) {
    this.message = message;
    this.showMessage = true;
  }

  resetForm() {
    if ((this.selectedCelenderDate = this.minDate)) {
      this.getSlotByDate(this.selectedCelenderDate);
    } else {
      this.selectedCelenderDate = this.minDate;
      this.reservationService.selectedCelenderDate = this.selectedCelenderDate;
    }
    this.noOfGuests = this.guestList[0];
    this.reservationService.noOfGuests = this.noOfGuests;
    if (this.showArea) {
      this.selectedArea = this.areaList[0];
      this.reservationService.selectedArea = this.selectedArea;
    }
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.contact = '';
    this.confirmEmail = '';
    this.formSubmitted = false;
    this.reservationService.email = this.email;
    this.setGuestList();
  }

  checkPaymentGateway() {
    if (this.paymentGateway == 'Paymentsense') {
      this.showPaymentSense = true;
      return;
    }
    this.showStripe = true;
  }

  dataChanged() {
    this.reservationService.noOfGuests = this.noOfGuests;
    this.reservationService.selectedArea = this.selectedArea;
    this.reservationService.selectedSlot = this.selectedSlot;
    this.reservationService.firstName = this.firstName;
    this.reservationService.lastName = this.lastName;
    this.reservationService.contact = this.contact;
  }
  
}

interface TermsAndConditionsDetails {
  NoticeText: string;
  ShowNotice: boolean;
  ShowTermsAndConditions: boolean;
  TermsAndConditionsText: string;
}
