<div class="container mt-4" *ngIf="category">
  <h2 class="text-center fs-3">{{ category?.CategoryTitle }}</h2>
  <p class="fs-5 text-muted">
    <span
      class="text-decoration-underline cursor-pointer"
      (click)="openProducts()"
      >Colors</span
    >
    /
    <span>{{ category?.CategoryTitle }}</span>
  </p>
  <div class="row">
    <div
      class="col-md-3 col-sm-6 mb-3"
      *ngFor="let category of category?.SubCategories"
    >
      <div
        class="border br-10px min-h-150px d-flex justify-content-center align-items-center cursor-pointer animation"
        [ngStyle]="{
					background: category?.['SubCategoryBackgroundColor'],
					color: category?.['SubCategoryTextColor']
				}"
        (click)="openSubCategories(category)"
      >
        <p class="m-0 text-center fs-5 fw-500">
          {{ category.SubcategoryTitle }}
        </p>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <div
      class="col-lg-3 col-md-4 col-sm-6 col-12 mb-2"
      *ngFor="let product of category.Products"
    >
      <app-product [product]="product"></app-product>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
