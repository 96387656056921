import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { CategoryWithProducts, Product } from 'src/Model/interface/productInterfaces';
import { popupData, popupService } from '../popup/popup.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  brandFlavour: string;
  private catAndProductsSub$ = new BehaviorSubject<CategoryWithProducts[]>([]);
  catAndProducts: Observable<CategoryWithProducts[]> = this.catAndProductsSub$.asObservable();

  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private popupService: popupService,
    private spinner: NgxSpinnerService,
  ) {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getProducts();
  }

  getProducts() {
    this._requestService
      .getRequest(`api/Product/GetOnlineProductsData/${this.brandFlavour}`, {}).subscribe({
        next: res => {
          if (res['Info']['Status'] == 200) {
            this.catAndProductsSub$.next(res['CategoriesAndProducts']);
          }
        }
      })
  }

  getProductDeatilsById(productID: number): Observable<any> {
    let customerId = this._local.get('CustomerId') ? this._local.get('CustomerId') : 0;
    return this._requestService.getRequest(`api/Product/getProductByProductId/${this.brandFlavour}/${productID}/${customerId}`, {})
  }

  addRemoveFavs(product: Product, status: boolean): Observable<any> {
    product.IsFavourite = !product.IsFavourite
    let body = {
      FK_CustomerId: this._local.get("CustomerId"),
      FK_RecipeId: null,
      FK_ProductId: product.ProductId,
      Status: status
    }
    const httpToken: any = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });
    return this._requestService.postRequest("api/CustomerFavourites/updateItem", JSON.stringify(body), httpToken)
  }
  
  getProductModal(product: Product, quantity: number) {
    let renderedExtraList: Array<any> = [];
    let renderExtralistChooseOnes = 0;
    this.spinner.show();
    this._requestService
      .getRequest("api/ProductExtra/getProductExtras/" + product.ProductId, [])
      .subscribe({
        next: res => {
          this.spinner.hide();
          if (res["Info"]["Status"] == 200) {
            let data: any = res["Data"];
            Object.entries(data).forEach(([key, value]) => {
              if (typeof value !== "string") {
                Object.entries(value).forEach(([k, v]) => {
                  v.Key = key;
                  v.Quantity = 0;
                });
              }
              let param = {
                Key: key,
                extras: value
              };
              renderedExtraList.push(param);
            });

            if (renderedExtraList.length > 0) {
              renderedExtraList.forEach(extra => {
                if (extra.Key.toLowerCase().includes('choose')) {
                  renderExtralistChooseOnes += 1;
                }
              })
            }
          }
          let popupdata: popupData = {
            MenuBuilder: res['Data']?.MenuBuilder,
            dineIn: false,
            filteredProduct: product,
            filteredRecipe: null,
            renderedExtraList: renderedExtraList,
            openForNonExtras: false,
            renderExtralistChooseOnes: renderExtralistChooseOnes,
            quantity: quantity
          }
          this.popupService.popUpData.next(popupdata);
        },
        error: () => {
          this.spinner.hide();
          alert('Network Error! Please try again')
        },
        complete: () => {
          this.spinner.hide();
        }
      });
  }
}
