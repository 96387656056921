import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { SendGiftcardService } from './send-giftcard.service';
import { CheckoutDetail } from 'src/Model/interface/WebCustomer';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'send-giftcard',
  templateUrl: './send-giftcard.component.html',
  styleUrls: ['./send-giftcard.component.scss'],
})
export class SendGiftcardComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  submitted = false;
  SelectedButtonColor: string = '#B8860B';
  SelectedButtonTextColor = '#FFFFFF';
  UnselectedButtonTextColor = '#000000';
  showTab: string = 'new';
  showHistory: any[] = [];
  channelsAmount: any[] = [];
  Channels: any[] = [];
  Dominations: any[] = [];
  channelName: any;
  radioAccess: any;
  messageAccess: string = '';
  amountAcceess: number = 0;
  Recipientemail: string;
  recipientSms: any;
  Title: any;
  stripeKey: string;
  handler: any;
  stripeToken: any;
  email: any;
  cardID: any;
  brandBranchName: any;
  giftDesign: any;
  checkoutConfig: CheckoutDetail;
  customerBalance: number = 0;
  redeemSuccess: string = '';
  promotionCode: string = 'GC-';
  showStripe: boolean;
  showPaymentSense: boolean;
  message: string;
  showMessage: boolean;
  loggedIn: boolean;
  redeemingCard: boolean;
  paymentGateway: string;
  webCusSub: Subscription;
  brandId: number;
  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService,
    private giftcardService: SendGiftcardService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.loggedIn = this._local.get('CustomerId') ? true : false;
    this.dynamicCss();
    this.giftCardhistory();
    this.giftConfig();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  response(res) {
    if (res?.error) {
      this.message = res?.error.message;
      this.showMessage = true;
      return;
    }
    this.showStripe = false;
    this.showPaymentSense = false;
    if (res.Info.Status == 201) {
      this.message = 'Successfully sent card';
      this.showMessage = true;
      this.radioAccess = '';
      this.messageAccess = '';
      this.amountAcceess = 0;
      this.giftcardService.messageAccess = '';
      this.giftcardService.amountAcceess = 0;
      this.giftcardService.radioAccess = '';
      let inputAmount = document.getElementById('amount') as HTMLInputElement;
      inputAmount.value = null;
      this.giftCardhistory();
    } else {
      this.message = res.Info.Message;
      this.showMessage = true;
    }
  }
  dynamicCss() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.brandId = res.BrandData.BrandId;
      this.paymentGateway = res.TemplateData.CheckoutDetail.PaymentGateway;
      this.checkoutConfig = res.TemplateData.CheckoutDetail;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.UnselectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.UnselectedButtonTextColor;
    });
  }
  giftCardhistory() {
    let customerId = this._local.get('CustomerId');
    if (!customerId) return;
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    this._requestService
      .getRequest(
        'api/GiftCard/getSentCards/' + customerId + '/0/20',
        httpHeaders
      )
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.showHistory = res['Data'];
        }
      });
  }

  giftConfig() {
    this.spinner.show();
    let BrandId = this._local.get('BrandId');
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    this._requestService
      .getRequest('api/GiftCard/getConfig/' + BrandId, httpHeaders)
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res['Info']['Status'] == 200) {
            this.channelsAmount = res['Data'];
            this.Channels = res['Data'].Channels;
            this.Dominations = res['Data'].Denominations;
            this.Title = res['Data'].Title;
            this.giftDesign = res['Data'].Designs;
            this.giftcardService.giftDesign = this.giftDesign;
          }
        },
        error: () => {
          this.spinner.hide();
          alert('Network Error! Something went wrong please refresh page');
        },
      });
  }

  get f() {
    return this.registerForm.controls;
  }

  toggle(tab) {
    this.showTab = tab;
    this.redeemSuccess = '';
  }

  checkName(item) {
    this.channelName = item;

    if (item == 'SMS') {
      this.radioAccess = '';
      this.Recipientemail = '';
      this.recipientSms = item;
    } else {
      this.radioAccess = '';
      this.Recipientemail = item;
      this.recipientSms = '';
    }
    this.giftcardService.radioAccess = this.radioAccess;
  }
  radioAccessChange(event) {
    this.giftcardService.radioAccess = event.target.value;
  }

  focusTextArea(event: Event) {
    const textArea = event.target as HTMLTextAreaElement;
    if (textArea.value) {
      textArea.classList.add('in-focus');
    } else {
      textArea.classList.remove('in-focus');
    }
    this.giftcardService.messageAccess = this.messageAccess;
  }

  isNumberKey(val) {
    if (val.valid && +val.value <= 300) {
      this.amountAcceess = +val.value;
    } else {
      this.amountAcceess = 0;
    }
    this.giftcardService.amountAcceess = this.amountAcceess;
  }
  amountSelected(item) {
    this.amountAcceess = item;
    let inputAmount = document.getElementById('amount') as HTMLInputElement;
    inputAmount.value = null;
    this.giftcardService.amountAcceess = this.amountAcceess;
  }
  getBalance() {
    let customerId = this._local.get('CustomerId');
    if (!customerId) return;
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    this._requestService
      .getRequest(
        'api/Wallet/GetBalance/' + this.brandId + '/' + customerId,
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.customerBalance = res['Amount'];
          } else {
            this.customerBalance = 0;
          }
        },
        error: () => {
          this.customerBalance = 0;
        },
      });
  }
  redeemCard() {
    this.redeemSuccess = '';
    const brandId = this._local.get('BrandId');
    const customerId = this._local.get('CustomerId');
    if (!customerId) return;
    const param = {
      QrIdentity: null,
      Code: this.promotionCode,
    };
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this.redeemingCard = true;
    this._requestService
      .postRequest(
        'api/Wallet/RedeemGiftCard/' + brandId + '/' + customerId,
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          this.redeemingCard = false;
          if (res['Info']['Status'] == 200) {
            this.getBalance();
            this.redeemSuccess = 'Gift voucher successfully redeemed';
          } else {
            this.redeemSuccess = res['Info']['Message'];
          }
        },
        error: () => {
          this.redeemingCard = false;
        },
      });
  }
  checkPaymentGateway() {
    if (this.paymentGateway == 'Paymentsense') {
      this.showPaymentSense = true;
      return;
    }
    this.showStripe = true;
  }
}
