<div class="container mt-4 mb-4">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7 col-sm-8">
      <h1 class="text-center fs-3">Update Account</h1>
      <form [formGroup]="updateForm" (ngSubmit)="updateUser()">
        <div class="row">
          <div class="col-md-6">
            <div class="inputContainer">
              <input
                type="text"
                name="first_name"
                id="first_name"
                class="form-control"
                required
                formControlName="firstName"
              />
              <label for="first_name">First Name*</label>
              <span
                *ngIf="submitted && updateUserForm?.['firstName']?.errors"
                class="form-text text-danger"
              >
                <span
                  *ngIf="updateUserForm?.['firstName']?.errors?.['required']"
                  >First Name is required</span
                >
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="inputContainer">
              <input
                type="text"
                name="last_name"
                id="last_name"
                class="form-control"
                required
                formControlName="lastName"
              />
              <label for="last_name">Last Name*</label>
              <span
                *ngIf="submitted && updateUserForm?.['lastName']?.errors"
                class="form-text text-danger"
              >
                <span *ngIf="updateUserForm?.['lastName']?.errors?.['required']"
                  >Last Name is required</span
                >
              </span>
            </div>
          </div>
        </div>
        <div class="inputContainer">
          <input
            type="phone"
            name="phone"
            id="phone"
            pattern="((^(\+44)([1-9]\d)(\d{8,8})$)|(^(0)([1-9]\d)(\d{8,8})$))"
            class="form-control"
            (keyup)="focusphoneSignUp($event)"
            required
            formControlName="contactNo"
          />
          <label for="phone">Contact No*</label>
          <span
            *ngIf="submitted && updateUserForm?.['contactNo']?.errors"
            class="form-text text-danger"
          >
            <span *ngIf="updateUserForm?.['contactNo']?.errors?.['required']"
              >Phone No is required</span
            >
            <span *ngIf="updateUserForm?.['contactNo']?.errors?.['pattern']"
              >Phone No is Invalid</span
            >
          </span>
        </div>
        <button
          type="submit"
          [ngClass]="{
            'btn-secondary': updateForm.invalid,
            'btn-success': updateForm.valid
          }"
          class="btn w-100 br-40px"
        >
          Update account
        </button>
      </form>
    </div>
  </div>
</div>

<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showMessage"
>
  <div
    class="content overflow-hidden bg-light br-40px d-flex justify-content-between flex-column"
  >
    <p class="text-center fs-4">{{ message }}</p>
    <button class="btn btn-danger" (click)="showMessage = false">Okay</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
