<div class="container p-3">
    <h1 class="text-center">Privacy Policy</h1>
    <h2>1- Introduction</h2>
    <p>
        Our mobile and web application is committed to protecting the privacy and security of your personal information.
        The
        information you shared with our mobile application allows us to provide you with the best experience with our
        products and services.<br>
        No surprises we’ll only ever collect, use, and share your information in ways that are described in this
        policy.<br>
        We’ll keep your information safe we’re committed to the confidentiality and security of the personal data you
        give
        us.<br>
        You’re always in control Update your profile and communication preferences at any time.
    </p>
    <h2> 2- About this policy</h2>
    <p>
        Along with our Terms and Conditions, this policy explains how we collect and handle your information across all
        of our websites, mobile apps, and other services. We’ll review this policy from time to time to make sure it’s
        up-to-date. If we make changes, we’ll post the latest version here. When we make significant changes, we’ll let
        you know when you next access our services, or by other communications
    </p>
    <h2>3- Our principles regarding user privacy and data protection</h2>
    <p>
        Our mobile application complies with the principles of GDPR. Our principles regarding user privacy and data
        protection are;<br>
        <b>Lawfulness, fairness, and transparency:</b> We obey the law. We believe that user privacy and data
        protection are
        human rights. We only process personal data in a way that our users would reasonably expect.<br>
        <b>Purpose limitation:</b> We only collect and process data when it is critically important, and when we do so,
        we make
        it clear why we are doing so and how it will be used.<br>
        <b>Data minimization:</b> We only process that amount of data which is crucially required because we realize we
        have a duty of care to the people whose data we hold.<br>
        <b>Accuracy:</b> We make sure that all the data that we hold is adequate and accurate.<br>
        <b>Storage limitation:</b> We do not store the data longer than we need. We will not send you regular email
        newsletters that you have not subscribed to. We will always give you the freedom to unsubscribe anytime to
        want.
        <img src="../../assets/img/policies-1.jpg" alt="policy image" class="policyImage">
        <br>
        <b>Integrity and confidentiality:</b> We will not share your data with anyone else without your permission.
    </p>
    <h2>4- What personal data do we collect?</h2>
    <p>
        Everyone who uses our services will have bits and pieces of information about them collected, stored, and
        processed. However, we don’t collect more information than we need.
    </p>
    <h2>5- For what purposes we use this data?</h2>
    <p>
        Everyone who uses our services will have bits and pieces of information about them collected, stored, and
        processed. However, we don’t collect more information than we need.<br>
        Verifying your identity.<br>
        Improving the design and style of the website.<br>
        Informing you about products, services, or promotional offers that you might find interesting if you have chosen
        to receive these types of communications.<br>
        Please be aware that, in certain circumstances, where you do not provide personal information which is required
        by us, we will not be able to provide the products and services under our contract with you.<br>
        You will be made aware of this situation and what the consequences of not providing personal information will
        be.
    </p>
    <h2>6- Collection of personal data and general information</h2>
    <p>
        We will collect personal data when you set up an account with us, purchase services from us, complete forms we
        provide to you, make a report or notification about our products or services, contact us by phone, email, or
        communicate with us directly in some other way.<br>
        We collect the following types of personal data from you:<br>
        <b>Contact details:</b> Information that allows us to contact you such as your name, email address, telephone
        number, and addresses associated with your account, order, or query.
        <img src="../../assets/img/policies-2.jpg" alt="policy image" class="policyImage">
        <br>
    </p>
    <h2>Personal data in reports and notifications you submit to us:</h2>
    <p>
        If you submit information to us about our products and services through our website we will collect any personal
        data you include.
    </p>
    <h2>Records of your discussions with us:</h2>
    <p>When you contact us, whether, by email or phone, we will keep a record of this. We also record calls with our
        customer support team so that we can keep up with training and ensure a good quality of service for you.</p>
    <h2>7- How long do we store your personal data?</h2>
    <p>
        We only keep your data for as long as we need it, or are required to for legal reasons. We’ll then either delete
        it or anonymize it so it doesn’t identify you.
    </p>
    <h2>8- Is your information shared with or collected by third parties?</h2>
    <p>
        We do not use any third party.
    </p>
    <h2>9- Disclosing information for legal and other reasons</h2>
    <p>
        We may also disclose your information if necessary to prevent, detect or prosecute illegal or suspected illegal
        activities, including fraud, or to prevent other damage or where necessary in response to legally binding
        requests, legal action against us, or to enforce our rights and claims.
    </p>
    <h2>10- How do we keep your personal data secure?</h2>
    <p>
        Keeping your personal data secure is our highest priority. We limit access to only those {{BrandName}} group
        employees who have to come into contact with your information to do their jobs and deliver our services. For
        more detail, you can contact us by using this number {{BrandContact}}.
    </p>
    <h2> 11- Do we use cookies or similar technologies?</h2>
    <p>
        We use cookies and similar technologies to help deliver, optimize, personalize, and analyze our services and for
        advertising purposes.
        We use a combination of cookies and other technologies such as pixel beacons and tracking codes to collect
        information for use in line with the purposes set out in this policy.
    </p>
</div>