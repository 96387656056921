<div class="container-fluid pt-3 pb-3 bg-Light">
  <div class="row">
    <div class="col-lg-8 p-1">
      <div class="row m-0">
        <div class="col-4 p-1 d-none d-md-block">
          <div class="position-sticky top-0 bg-white br-10px overflow-hidden">
            <div
              [ngStyle]="{
                border: '2px solid ' + dynamicCss.SelectedButtonColor
              }"
              class="br-10px"
            >
              <p
                class="text-center p-2 fs-6 m-0"
                [ngStyle]="{
                  'background-color': dynamicCss.SelectedButtonColor,
                  color: dynamicCss.SelectedButtonTextColor
                }"
              >
                Top Categories
              </p>
              <ul class="p-0 p-2">
                <li
                  *ngFor="let category of brand_Categories; let last = last"
                  class="cursor-pointer animation"
                  (click)="selectCategory(category)"
                  [ngClass]="{ 'border-bottom': !last }"
                >
                  <span
                    class="d-flex justify-content-between p-2 br-10px overflow-hidden"
                    [ngStyle]="
                      selectedCategory == category.CategoryId
                        ? {
                            'background-color':
                              category.CategoryBackgroundColor,
                            color: category.CategoryTextColor
                          }
                        : {}
                    "
                  >
                    {{ category.CategoryTitle }}
                    <span>
                      <span
                        *ngIf="
                          category.Subcategories?.length &&
                          selectedCategory !== category.CategoryId
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </span>
                      <span
                        *ngIf="
                          selectedCategory == category.CategoryId &&
                          category.Subcategories?.length
                        "
                      >
                        <i class="fas fa-minus"></i>
                      </span>
                    </span>
                  </span>
                  <ul
                    *ngIf="selectedCategory == category.CategoryId"
                    class="mt-1"
                  >
                    <li
                      *ngFor="
                        let subCat of category.Subcategories;
                        let last = last
                      "
                      [ngClass]="{ 'border-bottom': !last }"
                      [ngStyle]="
                        seletedSubcategory == subCat.SubcategoryId
                          ? {
                              'background-color':
                                subCat.SubCategoryBackgroundColor,
                              color: subCat.SubCategoryTextColor
                            }
                          : {}
                      "
                      class="p-2 cursor-pointer animation br-10px overflow-hidden"
                      (click)="
                        getProducts(category, subCat); stopPropagation($event)
                      "
                    >
                      {{ subCat.SubcategoryTitle }}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8" *ngIf="!showProducts">
          <app-sub-categories
            [subCategories]="subCategories"
            [category]="category"
            (showProductsEvent)="showProductsEvent($event)"
          ></app-sub-categories>
        </div>
        <div class="col-md-8" *ngIf="showProducts">
          <div class="row">
            <div class="col-md-3 col-6 p-1">
              <select name="make" id="make" class="form-select">
                <option value="Make">Make</option>
                <option value="NISSAN">NISSAN</option>
                <option value="TOYOTA">TOYOTA</option>
                <option value="LEXUS">LEXUS</option>
                <option value="UNIVERSAL">UNIVERSAL</option>
              </select>
            </div>
            <div class="col-md-3 col-6 p-1">
              <select name="model" id="model" class="form-select">
                <option value="Model">Model</option>
                <option value="GT-R">GT-R</option>
                <option value="370Z">370Z</option>
                <option value="FIT">FIT</option>
                <option value="CIVIC">CIVIC</option>
                <option value="HR-V">HR-V</option>
              </select>
            </div>
            <div class="col-md-3 col-6 p-1">
              <select name="year" id="year" class="form-select">
                <option value="Year">Year</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
              </select>
            </div>
            <div class="col-md-3 col-6 p-1">
              <select name="type" id="type" class="form-select">
                <option value="part type">Part Type</option>
                <option value="Brake Pad Set">Brake Pad Set</option>
                <option value="Brake Hose">Brake Hose</option>
                <option value="Crank Angle Sensor">Crank Angle Sensor</option>
                <option value="Water Pump">Water Pump</option>
                <option value="Air Filter">Air Filter</option>
                <option value="Brake Cable">Brake Cable</option>
              </select>
            </div>
          </div>
          <app-store-product [products]="products"></app-store-product>
        </div>
      </div>
    </div>
    <div class="col-lg-4 p-1">
      <div class="position-sticky top-0">
        <app-side-cart></app-side-cart>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
