<div
  *ngIf="
    hotCatogries?.length && windowWidth < 768
      ? cartProductItems.length || cartRecipeItems.length
      : true
  "
>
  <div class="head">
    <p class="m-0">My Cart</p>
    <small
      ><b>{{ _requestService.cartCounter }}</b> : Items</small
    >
  </div>
  <div
    *ngIf="cartProductItems.length == 0 && cartRecipeItems.length == 0"
    class="bg-white br-10px"
  >
    <h2 class="NoItem">There are no items in the cart</h2>
  </div>
  <div *ngIf="cartProductItems.length != 0 || cartRecipeItems.length != 0">
    <table class="bg-white">
      <thead>
        <tr>
          <th>item</th>
          <th>quantity</th>
          <th *ngIf="total">price</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of cartRecipeItems"
          [ngStyle]="
            (!item.ForCollection && _requestService.deliveryType == 'Store') ||
            (!item.ForDelivery && _requestService.deliveryType == 'Home')
              ? { 'background-color': '#dc354526' }
              : {}
          "
        >
          <td>
            <div class="produtInfo">
              <p class="maxWidth">{{ item?.RecipeTitle }}</p>
              <div>
                <small
                  (click)="removeRecipeItem(item)"
                  style="color: red; cursor: pointer"
                  title="Delete Item"
                  ><i class="fa fa-trash hoverTrash" aria-hidden="true"></i
                ></small>
              </div>
            </div>
            <div class="deliveryOrCollection">
              <p
                class="m-0 text-danger"
                *ngIf="
                  !item.ForDelivery && _requestService.deliveryType == 'Home'
                "
              >
                Not Available For Delivery
              </p>
              <p
                class="m-0 text-danger"
                *ngIf="
                  !item.ForCollection && _requestService.deliveryType == 'Store'
                "
              >
                Not Available For Collection
              </p>
            </div>
            <div *ngFor="let extra of item?.RecipeExtraObject">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <p class="p-1 m-0 fs11px" *ngIf="extra.Quantity > 1">
                    {{ extra?.Quantity }}
                  </p>
                  <p
                    class="p-1 text-center m-0 fs11px"
                    *ngIf="extra.Quantity > 1"
                  >
                    X
                  </p>
                  <p class="p-1 m-0 fs11px">{{ extra?.Title }}</p>
                </div>
                <p class="p-1 text-end m-0 fs11px" *ngIf="extra.Price > 0">
                  {{ extra?.Price | currency: "GBP":"symbol":"1.2-2" }}
                </p>
              </div>
            </div>
          </td>
          <td>
            <div class="QuantityContainer">
              <span
                class="prev"
                (click)="
                  !item?.socialDiscountItem ? cartRecipeUpdation('-', item) : ''
                "
              >
                <i class="fas fa-minus"></i
              ></span>
              <span class="quntitySpan" style="font-weight: bold">{{
                item?.RecipeQuantity
              }}</span>
              <span
                class="next"
                (click)="
                  !item?.socialDiscountItem ? cartRecipeUpdation('+', item) : ''
                "
                ><i class="fas fa-plus"></i
              ></span>
            </div>
          </td>
          <td class="alignTextCenter">
            {{ item?.recipeTotal | currency: "GBP":"symbol":"1.2-2" }}
          </td>
        </tr>

        <tr *ngFor="let item of cartProductItems">
          <td class="w-55">
            <div class="produtInfo">
              <p class="maxWidth">{{ item?.ProductTitle }}</p>
              <div>
                <small
                  (click)="removeProductItem(item)"
                  style="color: red; cursor: pointer"
                  title="Delete Item"
                  ><i class="fa fa-trash hoverTrash" aria-hidden="true"></i
                ></small>
              </div>
            </div>
            <div class="deliveryOrCollection">
              <p
                class="m-0 text-danger"
                *ngIf="
                  !item.ForDelivery && _requestService.deliveryType == 'Home'
                "
              >
                Not Available For Delivery
              </p>
              <p
                class="m-0 text-danger"
                *ngIf="
                  !item.ForCollection && _requestService.deliveryType == 'Store'
                "
              >
                Not Available For Collection
              </p>
            </div>
            <div *ngFor="let extra of item?.ProductExtraObject">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <p class="p-1 m-0 fs11px" *ngIf="extra.Quantity > 1">
                    {{ extra?.Quantity }}
                  </p>
                  <p
                    class="p-1 text-center m-0 fs11px"
                    *ngIf="extra.Quantity > 1"
                  >
                    X
                  </p>
                  <p class="p-1 m-0 fs11px">{{ extra?.Title }}</p>
                </div>
                <p class="p-1 text-end m-0 fs11px" *ngIf="extra.Price > 0">
                  {{ extra?.Price | currency: "GBP":"symbol":"1.2-2" }}
                </p>
              </div>
            </div>
          </td>
          <td class="w-30">
            <div
              class="QuantityContainer"
              *ngIf="
                PopupOnKgItems
                  ? !(item.ProductUnits.toLowerCase() == 'kg')
                  : true
              "
            >
              <span
                class="prev"
                (click)="
                  !item?.socialDiscountItem
                    ? cartProductUpdation('-', item)
                    : ''
                "
              >
                <i class="fas fa-minus"></i
              ></span>
              <span class="quntitySpan" style="font-weight: bold">{{
                item?.ProductQuantity
              }}</span>
              <span
                class="next"
                (click)="
                  !item?.socialDiscountItem
                    ? cartProductUpdation('+', item)
                    : ''
                "
                ><i class="fas fa-plus"></i
              ></span>
            </div>
            <div
              *ngIf="
                PopupOnKgItems ? item.ProductUnits.toLowerCase() == 'kg' : false
              "
            >
              <select
                name="kgList"
                id="kgList"
                class="form-select kgList"
                (change)="kgListChanged(item)"
                [disabled]="item?.socialDiscountItem"
                [(ngModel)]="item.ProductQuantity"
              >
                <option value="0.5">0.5kg</option>
                <option value="1.0">1.0kg</option>
                <option value="1.5">1.5kg</option>
                <option value="2.0">2.0kg</option>
                <option value="2.5">2.5kg</option>
                <option value="3.0">3.0kg</option>
              </select>
            </div>
          </td>
          <td class="alignTextCenter w-20" *ngIf="item?.ProductTotal">
            {{ item?.ProductTotal | currency: "GBP":"symbol":"1.2-2" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="total" *ngIf="total">
      <p class="m-0">Total</p>
      <p class="m-0 text-danger">
        {{ total | currency: "GBP":"symbol":"1.2-2" }}
      </p>
    </div>
    <button
      [disabled]="cartRecipeItems.length == 0 && cartProductItems.length == 0"
      (click)="callCheckout()"
      class="checkout-btn"
      [ngStyle]="{
        'background-color': SelectedButtonColor,
        color: SelectedButtonTextColor
      }"
    >
      {{ sideCartButtonText ? sideCartButtonText : "Checkout" }}
    </button>
  </div>
</div>
