<div class="row m-0">
  <div *ngFor="let product of products" class="col-lg-4 col-md-6 col-sm-6 p-1">
    <div
      class="productBox"
      [ngStyle]="{ 'background-color': ItemBackgroundColor }"
    >
      <div class="d-flex justify-content-end cursor-pointer">
        <div *ngIf="deleteCart" (click)="RemoveFavourite(product)">
          <i class="fa fa-trash text-danger fs-4" aria-hidden="true"></i>
        </div>
      </div>
      <div class="productImg mb-2" *ngIf="product?.ProductImageUrl">
        <img
          src="{{ product?.ProductImageUrl }}"
          class="w-100 br-8px"
          alt="product-img"
        />
      </div>
      <div class="product-caption">
        <p>
          <strong class="product-title" [ngStyle]="{ color: ItemTitleColor }">{{
            product?.ProductTitle
          }}</strong>
        </p>
        <p>
          <strong>Category : </strong>
          {{
            product?.CategoryTitle
              ? product?.CategoryTitle
              : product?.ProductCategory
          }}
        </p>

        <p class="text-danger" *ngIf="!product?.ProductStocks">
          <strong>Out of Stock</strong>
        </p>

        <div class="product-details">
          <div
            *ngIf="product?.ProductOldPrice"
            class="price-time"
          >
            <p>
              <strong>Price: </strong>
              <span class="text-light-white price">
                {{
                  product?.ProductOldPrice | currency: "GBP":"symbol":"1.2-2"
                }}/{{ product?.ProductUnits }}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <button
              class="btn btn-success w-100 br-40px"
              *ngIf="!product.ProductQuantity"
              [disabled]="!product?.ProductStocks"
              (click)="getProductModal(product)"
              [ngStyle]="{
                'border-color': SelectedButtonColor,
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
            >
              <i class="fa fa-shopping-basket" aria-hidden="true"></i>
              Add To Cart
            </button>

            <div
              *ngIf="
                product.ProductQuantity &&
                (PopupOnKgItems
                  ? !(product.ProductUnits.toLowerCase() == 'kg')
                  : true)
              "
              class="w-100"
            >
              <div
                class="input-group br-40px border w-auto flex-nowrap w-100 justify-content-between"
              >
                <button
                  class="btn z-index-0"
                  (click)="quantityControl(product, '-')"
                  type="button"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <span class="input-group-text m-0">{{
                  product?.ProductQuantity
                }}</span>
                <button
                  class="btn z-index-0"
                  type="button"
                  (click)="quantityControl(product, '+')"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div
              *ngIf="
                product.ProductQuantity &&
                (PopupOnKgItems
                  ? product.ProductUnits.toLowerCase() == 'kg'
                  : false)
              "
              class="w-100"
            >
              <select
                name="kgList"
                id="kgList"
                class="form-select br-40px"
                (change)="kgListChanged(product)"
                [(ngModel)]="product.ProductQuantity"
              >
                <option value="0.5">0.5kg</option>
                <option value="1.0">1.0kg</option>
                <option value="1.5">1.5kg</option>
                <option value="2.0">2.0kg</option>
                <option value="2.5">2.5kg</option>
                <option value="3.0">3.0kg</option>
              </select>
            </div>
            <div *ngIf="!deleteCart" class="ms-2">
              <div
                (click)="addRemoveFavourite(product)"
                *ngIf="!product.IsFavourite"
                class="cursor-pointer"
              >
                <i class="far fa-heart fs-4" title="Add To Favourite List"></i>
              </div>
              <div
                (click)="addRemoveFavourite(product)"
                *ngIf="product.IsFavourite"
                class="cursor-pointer"
              >
                <i
                  class="fa fa-heart text-danger fs-4"
                  title="Add To Favourite List"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
