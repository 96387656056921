import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { RedirectionConfig } from 'src/Model/interface/WebCustomer';
import { ApiService } from '../service/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  redirectionConfig: RedirectionConfig;
  training: boolean;

  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private _router: Router,
    private apiService: ApiService
  ) {
    this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.redirectionConfig = res.TemplateData.RedirectionConfig;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean {
    this.training = route.queryParams['training'] == 'true';

    if (
      this._local.getSessionItem('orderId') == null ||
      this._local.getSessionItem('orderId') == undefined ||
      this._local.getSessionItem('orderId') == 'null' ||
      this._local.getSessionItem('orderId') == 'undefined'
    ) {
      const orderId = route.queryParams['orderId']
        ? route.queryParams['orderId']
        : undefined;
      this._local.setSessionItem('orderId', orderId);
    }

   
    let result = false;

    if (this._requestService.isLoggedIn()) {
      result = true;
    } else if (
      !this._requestService.isLoggedIn() &&
      this._local.getSessionItem('access_token')
    ) {
      if (this.training) {
        this._router.navigate([this._requestService.setUrl('guest')], {
          queryParams: { training: this.training },
        });
      } else {
        this._router.navigate([this._requestService.setUrl('guest')]);
      }
    } else {
      if (this.training) {
        this._router.navigate([this._requestService.setUrl('login')], {
          queryParams: { training: this.training },
        });
      } else {
        this._router.navigate([this._requestService.setUrl('login')]);
      }
    }

    return result;
  }
}
