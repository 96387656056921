import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetterService } from './getter-service.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private isLoggedInSub = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedInSub.asObservable();
  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private _router: Router
  ) {
    if (this._local.get('access_token')) this.loggedIn();
  }
  loggedIn() {
    this.isLoggedInSub.next(true);
  }

  logout() {
    this._local.remove('cartProductItems');
    this._local.remove('cartRecipeItems');
    this._requestService.cartCounter = 0;
    this.isLoggedInSub.next(false);
    this.removeSession();
  }

  createSession(userName: string, accessToken: any, refreshToken: any) {
    this._local.set('username', userName);
    this._local.set('access_token', accessToken);
    this._local.set('refresh_token', refreshToken);
  }

  private removeSession() {
    this._local.remove('username');
    this._local.remove('CustomerId');
    this._local.remove('CustomerFirstName');
    this._local.remove('CustomerLastName');
    this._local.remove('CustomerImageUrl');
    this._local.remove('access_token');
    this._local.remove('refresh_token');
    this._local.removeSessionItem('access_token');
    this._router.navigate([this._requestService.setUrl('login')]);
  }

  refreshToken() {
    let params = new HttpParams();
    params = params.append('grant_type', 'refresh_token');
    params = params.append('refresh_token', this._local.get('refresh_token'));
    this._requestService.postRequest('Token', params).subscribe({
      next: (response) => {
        this.createSession(
          this._local.get('username'),
          response['access_token'],
          response['refresh_token']
        );
        this.isLoggedInSub.next(true);
      },
      error: (error) => {
        this.logout();
      },
    });
  }
}
