<div class="container-fluid mt-3">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="position-relative min-height-430px">
        <div class="bg-warning br-10px yellowBox pt-3">
          <h1 class="text-center mt-3">
            <b>Your Points: {{ rewardPoints }}</b>
          </h1>
          <div class="row justify-content-center m-0">
            <div class="col-3 d-none d-md-block">
              <img
                src="../../assets/img/giftPack.png"
                alt="gift pack image"
                class="giftImage w-100"
              />
            </div>
            <div class="col-md-5 col-sm-8 col-7 p-0">
              <div class="p-2" *ngIf="!isCurrentRewardUnlocked">
                <h1 class="text-danger m-0">
                  {{ currentRewardRemaningPoint }} points more to go
                </h1>

                <span class="fs-5"
                  >until you unlock your
                  <span class="text-danger">{{
                    currentRewardTitle
                  }}</span></span
                >
              </div>
            </div>
            <div class="col-sm-4 col-5">
              <div class="d-flex justify-content-center">
                <circle-progress
                  [percent]="currentRewardPointsInPercent"
                  [maxPercent]="100"
                  [radius]="50"
                  [outerStrokeWidth]="4"
                  [animation]="true"
                  titleFontSize="30"
                  subtitleFontSize="30"
                  unitsFontSize="30"
                  [animationDuration]="300"
                >
                </circle-progress>
              </div>
            </div>
          </div>
        </div>
        <div class="br-10px blueBox w-75 mx-auto position-absolute p-3">
          <h1 class="text-center text-light m-0">Your Gift Account Balance</h1>
          <h1 class="text-light text-center fs-1 fw-bold">
            {{ customerBalance | currency: "GBP":"symbol" }}
          </h1>
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-secondary br-40px fw-bolder ps-4 pe-4 text-dark"
              data-bs-toggle="modal"
              data-bs-target="#scancardpopup"
            >
              SCAN CARD
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-lg-4 col-md-6 col-sm-8 col-10">
      <label for="gcnumber" class="form-label fs-6"
        >Enter Your Promotion Code</label
      >
      <div class="position-relative">
        <input
          type="text"
          maxlength="20"
          id="gcnumber"
          class="form-control br-40px"
          value="GC-"
          [(ngModel)]="promotionCode"
        />
        <button
          class="btn br-40px ps-5 pe-5 position-absolute top-0 end-0"
          [ngStyle]="{
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
          (click)="redeemGiftCard()"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
  <section class="mt-5" *ngIf="regularRewards.length">
    <h1 class="text-center"><b>Upcoming Rewards</b></h1>
    <div class="container d-flex justify-content-center mb-4 position-relative">
      <div
        *ngIf="regularRewards.length > 1"
        class="leftArrow border border-1 border-dark"
        (click)="sliderLeft('UpcomingSlider')"
      >
        <i class="fas fa-angle-left fs-4"></i>
      </div>

      <div class="sliderWrapper" id="UpcomingSlider">
        <div
          class="rewardItem d-flex flex-column cursor-pointer"
          *ngFor="
            let reward of regularRewards;
            let first = first;
            let last = last
          "
          [ngClass]="last ? '' : 'me-1'"
          (click)="onRegularRewardClicked(reward)"
        >
          <div
            class="border-1 border-dark position-relative w-100 d-flex justify-content-center align-items-center borderRadius"
            [ngStyle]="
              first && regularRewards.length > 1
                ? {
                    'border-top-left-radius': '0px',
                    'border-bottom-left-radius': '0px'
                  }
                : last && regularRewards.length > 1
                ? {
                    'border-top-right-radius': '0px',
                    'border-bottom-right-radius': '0px'
                  }
                : {}
            "
            [ngClass]="
              first && regularRewards.length > 1
                ? 'border-end border-top border-bottom'
                : last && regularRewards.length > 1
                ? 'border-start border-top border-bottom'
                : 'border'
            "
          >
            <img
              src="{{ reward?.Image }}"
              class="rewardImage br-10px"
              alt="rewards"
            />
            <span
              class="br-40px position-absolute bg-danger ps-2 pe-2 reward-title"
              >{{ reward?.Title }}</span
            >
          </div>

          <button
            *ngIf="reward.DeductionPoints <= rewardPoints"
            class="reward-activate-btn mt-3"
            (click)="openActivateDialog(reward)"
          >
            Activate
          </button>
        </div>
      </div>
      <div
        *ngIf="regularRewards.length > 1"
        class="rightArrow border border-1 border-dark"
        (click)="sliderRight('UpcomingSlider')"
      >
        <i class="fas fa-angle-right fs-4"></i>
      </div>
    </div>
  </section>
  <section class="mt-3" *ngIf="redeemedRewards.length">
    <h1 class="text-center"><b>Redeem Rewards</b></h1>
    <div class="container d-flex justify-content-center mb-4 position-relative">
      <div
        *ngIf="redeemedRewards.length > 1"
        class="leftArrow border border-1 border-dark"
        (click)="sliderLeft('redeemSlider')"
      >
        <i class="fas fa-angle-left fs-4"></i>
      </div>

      <div class="sliderWrapper" id="redeemSlider">
        <div
          class="rewardItem d-flex flex-column cursor-pointer"
          *ngFor="
            let redeemReward of redeemedRewards;
            let first = first;
            let last = last
          "
          [ngClass]="last ? '' : 'me-1'"
        >
          <div
            class="border-1 border-dark position-relative w-100 d-flex justify-content-center align-items-center borderRadius"
            [ngStyle]="
              first && redeemedRewards.length > 1
                ? {
                    'border-top-left-radius': '0px',
                    'border-bottom-left-radius': '0px'
                  }
                : last && redeemedRewards.length > 1
                ? {
                    'border-top-right-radius': '0px',
                    'border-bottom-right-radius': '0px'
                  }
                : {}
            "
            [ngClass]="
              first && redeemedRewards.length > 1
                ? 'border-end border-top border-bottom'
                : last && redeemedRewards.length > 1
                ? 'border-start border-top border-bottom'
                : 'border'
            "
          >
            <img
              src="{{ redeemReward?.Reward?.Image }}"
              class="rewardImage br-10px"
              alt="rewards"
            />
            <span
              class="br-40px position-absolute bg-danger ps-2 pe-2 reward-title"
              >{{ redeemReward?.Reward?.Title }}</span
            >
          </div>
        </div>
      </div>

      <div
        *ngIf="redeemedRewards.length > 1"
        class="rightArrow border border-1 border-dark"
        (click)="sliderRight('redeemSlider')"
      >
        <i class="fas fa-angle-right fs-4"></i>
      </div>
    </div>
  </section>
  <section>
    <div class="row justify-content-center">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <h1 class="text-center m-sm-0">Recent Activity</h1>
        </div>
        <div class="col-md-4">
          <div class="switchButtons mx-auto mb-3">
            <button
              class="switchButton"
              type="button"
              (click)="toggle(true)"
              [ngStyle]="
                isReward
                  ? {
                      'background-color': SelectedButtonColor,
                      color: SelectedButtonTextColor
                    }
                  : { 'background-color': 'transparent' }
              "
            >
              Loyalty
            </button>
            <button
              class="switchButton"
              type="button"
              (click)="toggle(false)"
              [ngStyle]="
                !isReward
                  ? {
                      'background-color': SelectedButtonColor,
                      color: SelectedButtonTextColor
                    }
                  : { 'background-color': 'transparent' }
              "
            >
              Account
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-sm-8">
        <div *ngIf="isReward">
          <h1 class="text-center" *ngIf="!recentHistoryList?.length">
            No Reward History Available
          </h1>

          <div *ngFor="let history of recentHistoryList" class="mb-2">
            <div
              class="br-10px p-2"
              [ngStyle]="
                history?.CurrentLoadedPoints - history?.LastLoadedPoints > 0
                  ? { 'background-color': '#C5DCD7' }
                  : { 'background-color': '#FCDEDA' }
              "
            >
              <div class="reward-history-item">
                <h1 class="m-0">
                  {{ history?.CurrentLoadedPoints - history?.LastLoadedPoints }}
                  Points
                </h1>

                <h1 class="text-end m-0">
                  {{ history?.CheckInTime | date }}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isReward">
          <h1 class="text-center">Coming Soon</h1>
        </div>
      </div>
    </div>
  </section>
</div>

<div
  class="modal fade"
  id="rewardModal"
  tabindex="-1"
  aria-labelledby="rewardModalTitle"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title m-0" id="rewardModalTitle">Activate Reward</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h1 class="col-md-12 text-center">
          Are you sure you want to activate it?
        </h1>

        <div class="row">
          <div class="col-sm-6">
            <button
              [disabled]="activatingReward"
              class="btn btn-danger w-100 br-40px mb-3"
              data-dismiss="modal"
              (click)="activateReward()"
            >
              Yes
              <span
                class="spinner-border spinner-border-sm ms-2"
                *ngIf="activatingReward"
              ></span>
            </button>
          </div>
          <div class="col-sm-6">
            <button
              class="btn btn-success w-100 br-40px mb-3"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- product popup Scan Card -->
<div
  class="modal fade"
  id="scancardpopup"
  tabindex="-1"
  aria-labelledby="scanCardTitle"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="scanCardTitle">Scan Card</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div id="card-img">
          <img src="{{ rewardQrImage }}" alt="QR image" />
        </div>
        <div id="card-text">
          <h2
            id="centered-text"
            class="text-light-black fw-600 no-margin position"
          >
            Points: {{ customerRewardPoints }}
          </h2>
          <h2
            id="centered-text"
            class="text-light-black fw-600 no-margin position"
          >
            Balance: £{{ customerBalance }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
