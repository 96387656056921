import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Tags } from 'src/Model/interface/WebCustomer';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Component({
  selector: 'app-new-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  currentDateStr: string = new Date().getFullYear().toString();
  title = 'angulartoastr';
  showModal: boolean;
  registerForm: FormGroup;
  submitted = false;
  BrandName = '';
  allergens = [];
  FooterColor = '#DDDDDD';
  FooterTextColor = '#000000';
  SelectedButtonColor = '#B8860B';
  AllergensBottomText: any;
  AllergensBottomTextColor: any;
  AllergensBottomTextBackgroundColor: any;
  PerkssFooter: boolean = false;
  unsubscribeModel: boolean;
  unsubResult;
  UnsubField;
  brandFlavour;
  webCusSub: Subscription;
  tags = new Subject<Tags[]>();
  defaultFooter: boolean;
  TermsAndConditionsFooterLink: boolean;
  PrivacyPolicyFooterLink: boolean;
  UnsubscribeFooterLink: boolean;
  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private apiService: ApiService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.TermsAndConditionsFooterLink = res.TemplateData?.['Terms&ConditionsFooterLink']
      this.PrivacyPolicyFooterLink = res.TemplateData?.PrivacyPolicyFooterLink;
      this.UnsubscribeFooterLink = res.TemplateData?.UnsubscribeFooterLink;
      this.brandFlavour = res.BrandData.BrandFlavor;
      this.BrandName = res.BrandData.BrandName;
      this.allergens = res.Allergens;
      this.AllergensBottomText = res.TemplateData.AllergensBottomText;
      this.PerkssFooter = res.TemplateData.PerkssFooter;
      this.AllergensBottomTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors.AllergensBottomTextColor;
      this.AllergensBottomTextBackgroundColor =
        res.TemplateData.DynamicStyle.TertiaryColors.AllergensBottomTextBackgroundColor;
      this.FooterColor =
        res.TemplateData.DynamicStyle.TertiaryColors.FooterColor;
      this.FooterTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors.FooterTextColor;
      this.tags.next(
        res.TemplateData.RegistrationTemplate.AgreementDetail.Tags
      );
      this.defaultFooter = res.TemplateData.DefaultPerkssFooter;
    });
  }

  showForm() {
    this.showModal = true; // Show-Hide Modal Check
  }
  openUrl(url) {
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  get f() {
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (this.submitted) {
      this.showModal = false;
    }
  }
  unsubscribe() {
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let body = {
      BrandFlavor: this.brandFlavour,
      Token: this.UnsubField,
    };
    this._requestService
      .postRequest(
        'api/Customer/UnsubscribeCustomerMarketingPreferences',
        body,
        httpHeaders
      )
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.unsubResult = 'Unsubscribed Successfully';
        } else {
          this.unsubResult = res['Info']['Message'];
        }
      });
  }
}
