<div class="container-fluid p-0">
  <div class="row">
    <div
      class="col-md-4 col-sm-6 col-12 p-1"
      *ngFor="let subCategory of subCategories"
    >
      <div class="br-10px overflow-hidden">
        <div class="bg-dark p-2">
          <p class="text-white m-0">{{ subCategory.SubcategoryTitle }}</p>
        </div>
        <img
          [src]="subCategory.SubcategoryImage"
          [alt]="subCategory.SubcategoryTitle + 'Image'"
          class="w-100 rounded-0"
          *ngIf="subCategory.SubcategoryImage"
        />
        <div class="d-flex justify-content-between align-items-center p-2">
          <button
            class="btn br-40px btn-danger text-white"
            (click)="showProducts(subCategory)"
          >
            Products
          </button>
          <p
            class="m-0 text-decoration-underline text-danger cursor-pointer"
            (click)="showDetails(subCategory)"
            *ngIf="subCategory?.SubcategoryDescription"
          >
            Details
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
