import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RequestService } from '../service/request.service';
import { NavigationStart, Router } from '@angular/router';
import { GetterService } from '../service/getter-service.service';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { Campaigns, HelpDeskConfig } from 'src/Model/interface/WebCustomer';
import { UserService } from '../service/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  sideMenu: boolean;
  tiles: Campaigns[];
  showMobileSearch: boolean = false;
  brand_Name: string;
  branch_Logo: string;
  titlesFlag: any;
  webLink: string;
  ShowReferSection: boolean;
  brandFlavour: string;
  currentComponent: string;
  cartCount: number = 0;
  hover: string;
  distributorLogin: boolean;

  /* template Type */
  tempalte: string;

  /*Customer*/
  customer: boolean;
  cusFirstName: string;
  cusLastName: string;

  /* Dynamic CSS */
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  HeaderHoverBackgroundColor: string;
  HeaderTextHoverColor: string;
  SiteBackgroundColor: string;
  HeaderCartColor: string;
  HeaderCartCounterColor: string;
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  MenuWebBanner: string;
  MobileMenuWebBanner: string;
  HeaderRewardIcon: boolean;
  MobileHeaderBackgroundColor: string;
  BurgerMenuColor: string;
  MobileSearchColor: string;
  /* Side Categories */
  sideCat: boolean;
  /**Subscriptions */
  webcustomerSub: Subscription;
  storageChangeSub: Subscription;
  loggedInSub: Subscription;
  routerSub: Subscription;
  helpDeskConfig: HelpDeskConfig;

  constructor(
    public _requestService: RequestService,
    private _router: Router,
    private _local: GetterService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private apiService: ApiService,
    private recomendedService: recomendationsService
  ) {
    this.routerSub = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentComponent = event.url.split('/')[2];
        const brandName = event.url.split('/')[1];
        this._local.set('BrandFlavor', brandName);
        if (
          this.currentComponent != 'login' &&
          this.currentComponent != 'forgot-password' &&
          this.currentComponent != 'registeruser' &&
          this.currentComponent != 'login?guest=false' &&
          this.currentComponent != 'login?training=true' &&
          this.currentComponent != 'guest?training=true' &&
          this.currentComponent != 'guest'
        ) {
          if (
            this.currentComponent == 'products' ||
            this.currentComponent == 'category-products'
          ) {
            this._requestService.redirectToComponent = event.url
              .split('/')
              .slice(2)
              .join('/');
            return;
          }

          this._requestService.redirectToComponent = event.url.split('/')[2];
        }
      }
    });
  }

  ngOnInit() {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.currentComponent = window.location.pathname.split('/')[2];
    this.customer = this._requestService.isLoggedIn();
    this.getWebCustomer();
    this.afterLogin();

    this.recipeCounter();
    this._requestService.cartCounter = this.cartCount;
    this.storageChangeSub = this._requestService.storageChange.subscribe(() => {
      this.recipeCounter();
      this._requestService.cartCounter = this.cartCount;
      this.cd.detectChanges();
    });
  }

  recipeCounter() {
    this.cartCount = this._requestService.getProductCartItems().length;
    this.cartCount =
      this.cartCount + this._requestService.getCartRecipeItems().length;
  }

  userDetailsCheck() {
    this.customer = this._requestService.isLoggedIn();
    this.cusFirstName = this._local.get('CustomerFirstName');
    this.cusLastName = this._local.get('CustomerLastName');
  }

  getWebCustomer() {
    this.webcustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        this.userDetailsCheck();
        return;
      }
      this.helpDeskConfig = res.TemplateData?.HelpDeskConfig;
      this.MobileHeaderBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileHeaderBackgroundColor;
      this.BurgerMenuColor =
        res.TemplateData.DynamicStyle.PrimaryColors.BurgerMenuColor;
      this.MobileSearchColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileSearchColor;
      this.tempalte = res.TemplateData.TemplateType;
      this._local.set('TemplateType', this.tempalte);
      this.webLink = res.TemplateData.BusinessInfo.WebLink;
      this.branch_Logo = res.BrandData.BrandBranch.BrandBranchLogo;
      this.ShowReferSection = res.TemplateData.CheckoutDetail.ShowReferSection;
      this.HeaderRewardIcon = res.TemplateData.BusinessInfo.HeaderRewardIcon;
      this.MenuWebBanner = res.TemplateData?.DynamicStyle?.MenuWebBanner;

      this.MobileMenuWebBanner =
        res.TemplateData?.DynamicStyle?.MobileMenuWebBanner;
      this.brand_Name = res.BrandData.BrandName;

      this.HeaderBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderBackgroundColor;

      this.HeaderTextColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextColor;

      this.HeaderHoverBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderHoverBackgroundColor;

      this.HeaderTextHoverColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextHoverColor;

      this.SiteBackgroundColor =
        res.TemplateData?.DynamicStyle?.PrimaryColors?.SiteBackgroundColor;

      this.HeaderCartColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartColor;

      this.HeaderCartCounterColor =
        res.TemplateData?.DynamicStyle?.TertiaryColors?.HeaderCartCounterColor;
      this.tiles = res.TemplateData.HomeTemplate.Campaigns;
      this.distributorLogin = res.TemplateData.CheckoutDetail?.DistributorLogin;
      if (
        (!this._requestService.redirectToComponent &&
          this.currentComponent == 'login') ||
        this.currentComponent == 'forgot-password' ||
        this.currentComponent == 'registeruser' ||
        this.currentComponent == 'login?guest=false' ||
        this.currentComponent == 'login?training=true'
      ) {
        this._requestService.redirectToComponent =
          res.TemplateData.RedirectionConfig.RedirectionFromCheckout;
      }
    });
  }

  callCheckout() {
    this.recomendedService.showRecomendations.next();
  }

  updateAccount() {
    this.openUrl('updateaccount');
  }

  signOut() {
    this.userService.logout();
  }

  redirect(link) {
    console.log(link);
    if(link.Redirection){
      this.openUrl(link.Redirection)
      return
    }
    location.href = link.WebLink;
  }

  openUrl(url: string) {
    console.log(url);
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }

  afterLogin() {
    this.loggedInSub = this.userService.isLoggedIn$.subscribe((status) => {
      this.customer = status;
      if (status) this.userDetailsCheck();
    });
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.webcustomerSub.unsubscribe();
    this.storageChangeSub.unsubscribe();
    this.loggedInSub.unsubscribe();
    this.routerSub.unsubscribe();
  }
}
