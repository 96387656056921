import { Injectable } from '@angular/core';
import { GetterService } from '../service/getter-service.service';

@Injectable({
  providedIn: 'root',
})
export class SendGiftcardService {
  amountAcceess: number;
  messageAccess: string;
  giftDesign: string;
  radioAccess: string;
  email: string;
  PaymentMethodId: string;
  brandFlavour: string;
  paypalToken: any;

  constructor(private _local: GetterService) {
    this.brandFlavour = window.location.pathname.split('/')[1];
  }
  getParams(type: string) {
    let params = {
      Balance: this.amountAcceess,
      BrandId: +this._local.get('BrandId'),
      Channel: 'Email',
      SenderId: this._local.get('CustomerId')
        ? this._local.get('CustomerId')
        : 0,
      Source: 'WEB',
      GiftCardDetail: {
        Message: this.messageAccess,
        DesignUrl: this.giftDesign != null ? this.giftDesign[0] : null,
      },
      Recipient: {
        Email: this.radioAccess,
        // Mobile: this.channelName == 'SMS' ? this.radioAccess : null,
        // CountryCode: this.channelName == 'SMS' ? "GBP" : '',
      },
      PaymentDetail: {
        PaymentType: type,
        DeliveryType: 'Digital',
        Bill: this.amountAcceess,
        CardPaymentAmount: 0,
        CashPaymentAmount: 0,
      },
    };
    if (type == 'Card') {
      params['PaymentDetail']['CardDetail'] = {
        PaymentMethodId: this.PaymentMethodId,
        CustomerEmail: this._local.get('username')
          ? this._local.get('username')
          : this._local.getSessionItem('guestEmail'),
        Description: '',
        StatementDescriptor: this.brandFlavour,
      };
    }
    if (type == 'Paypal') {
      params['PaymentDetail']['PaypalPurchaseDetails'] = {
        PaypalOrderId: this.paypalToken,
      };
    }
    return params;
  }
}
