import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipeRoutingModule } from './recipe-routing.module';
import { Recipe } from './recipe.component';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ErrorHandlingService } from '../service/error-handling.service';

@NgModule({
  declarations: [Recipe],
  imports: [
    SharedModule,
    CommonModule,
    RecipeRoutingModule,
    CalendarModule,
    MatSnackBarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      subtitle: 'Points',
      showUnits: false,
      maxPercent: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#ffffff',
      animationDuration: 300
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlingService }
  ]
})
export class RecipeModule { }
