import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsRoutingModule } from './products-routing.module';
import { ProductComponent } from './product/product.component';
import { ProductsComponent } from './products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FormsModule } from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SubcategoryProductsComponent } from './subcategory-products/subcategory-products.component';
import { SubCategoriesComponent } from './sub-categories/sub-categories.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { ErrorHandlingService } from '../service/error-handling.service';

@NgModule({
  declarations: [
    ProductComponent,
    ProductsComponent,
    ProductDetailsComponent,
    SubcategoryProductsComponent,
    SubCategoriesComponent,
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    FormsModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxSpinnerModule,
    SharedModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class ProductsModule {}
