import { Component, OnInit, OnDestroy } from '@angular/core';
import { RequestService } from '../service/request.service';
import { HttpHeaders } from '@angular/common/http';
import { GetterService } from '../service/getter-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { popupService } from '../popup/popup.service';
import { RecipeService } from '../service/recipe.service';
import {
  Category,
  CategoryWithRecipes,
} from 'src/Model/interface/productInterfaces';
import { Recipe as IRecipe } from 'src/Model/interface/productInterfaces';
import { AppComponent } from '../app.component';
interface PostcodeValidation {
  ShowPostcodeValidityField: boolean;
  PostcodeValidityField: {
    Title: string;
    ErrorMessage: string;
  };
}
@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
})
export class Recipe implements OnInit, OnDestroy {
  DeliveryFrom = null;
  CollectionFrom = null;
  ShowDelivery: boolean = false;
  ShowCollection: boolean = false;
  CheckoutNotice: string = null;
  ClosingNotice: string = null;
  selectedID: number = null;
  selectedCategory = null;
  hotCategoryList = [];
  selectedHotCat: string;
  brandCategpriesBackup = [];

  allergens = [];
  AllergensBottomText: string = null;
  /* Dynamic styles */
  AllergenBackgroundColor = '#97E897';
  SelectedAllergenColor = '#D8B56E';
  UnSelectedAllergenColor = '#179FDA';
  SelectedButtonColor = '#B8860B';
  MobileMenuCategoryBackgroundColor = '#D7281E';
  MobileMenuCategoryTextColor = '#FFFFFF';
  MobileMenuBackgroundColor = '#F58A1D';
  MobileMenuBorderColor = '#000000';
  MobileMenuTextColor = '#FFFFFF';
  SiteBackgroundColor;
  MenuWebBanner: string;
  SelectedButtonTextColor: any = '#fff';
  ClosingNoticeBackground = '#000';
  ClosingNoticeTextColor = '#DD9D3A';
  WebsiteBackground;

  brand_Categories: Category[] = [];
  recipes: IRecipe[] = [];
  completeRecipeList: CategoryWithRecipes[];
  notfound: any;
  categoryName: string = null;
  categoryImage: string = null;

  //postcode validation
  validPostCode: boolean = false;
  InvalidPostCode: boolean = false;
  enteredPostCode: string;
  manualPostCode: string = '';
  InvalidMessage: string;
  postSpinner: boolean = false;
  /*Message*/
  cartMessage: any;
  /* Minimum Times */
  MinDeliveryTime = null;
  MinCollectionTime = null;
  /* For hiding responsive or desktop views */
  windowWidth;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  scrollEvent: Observable<Event>;
  scrollEventSubscription: Subscription;
  /* Startup Spinner  */
  offers = null;
  showOffersMobile: boolean = false;

  brandFlavour: string;
  deliveryType: string;
  cartTotal = 0;
  /* Environment */
  currentEnvironment;
  selectedAllergen: string = '';
  AllergensMenuList = [];
  AllergensMenuSection = false;
  /* Order Status */
  orderStatus;
  latestOrders = [];
  brandId: number;
  RecentOrderMessage: boolean;
  postcodeValidation: PostcodeValidation;
  showCats: boolean;
  showCatsOnClick: boolean;
  popServiceSub: Subscription;
  deliveryTypeSub: Subscription;
  brandConfigSub: Subscription;
  webCusSub: Subscription;
  recipeServiceSub: Subscription;
  constructor(
    public _requestService: RequestService,
    private _local: GetterService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private recomendedRecipeService: recomendationsService,
    private extrasPopupService: popupService,
    private recipeService: RecipeService,
    private app: AppComponent
  ) {
    this.app.setAllergensFooter(true);
  }
  ngOnInit() {
    this.spinner.show();
    this.popServiceSub =
      this.extrasPopupService.addedToCartSuccessful.subscribe(() => {
        this.getCartTotal();
      });
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.deliveryTypeSub = this._requestService.deliveryType$.subscribe(
      (deliveryType) => {
        this._requestService.deliveryType = deliveryType;
        this.deliveryType = deliveryType;
        this.selectedID = 0;
        this.selectedCategory = null;
        this._requestService.deliveryType = deliveryType;
        this.getCategories();
      }
    );
    this.currentEnvironment = environment.production;
    this.deliveryType = this._local.get('deliveryType');
    this.checkDeviceWidth();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.checkDeviceWidth();
    });
    this.notfound = 0;

    this.getWebCustomer();
    this.getConfig();
    this.getCartTotal();
  }

  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.showCatsOnClick = res.TemplateData?.ShowCategoriesOnMenuClick;
      this.postcodeValidation = res.TemplateData?.PostcodeValidation;
      this.RecentOrderMessage = res.TemplateData.RecentOrderMessage;
      this.SiteBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors?.SiteBackgroundColor;
      this.hotCategoryList = res.TemplateData?.HotCategories;
      this.brandId = res.BrandData.BrandId;
      this.orderStatus = res.TemplateData?.OrderStatus;
      this.WebsiteBackground = res.TemplateData.DynamicStyle?.WebsiteBackground;
      this.AllergenBackgroundColor =
        res.TemplateData.DynamicStyle?.AllergensMenuList?.BackgroundColor;
      this.SelectedAllergenColor =
        res.TemplateData.DynamicStyle?.AllergensMenuList?.SelectedColor;
      this.UnSelectedAllergenColor =
        res.TemplateData.DynamicStyle?.AllergensMenuList?.UnSelectedColor;
      this.AllergensMenuSection = res.TemplateData?.AllergensMenuSection;
      this.AllergensMenuList = res.TemplateData?.AllergensMenuList;
      this.offers = res.TemplateData.Offer;
      this.MenuWebBanner = res.TemplateData.DynamicStyle.MenuWebBanner;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.MobileMenuCategoryBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuCategoryBackgroundColor;
      this.MobileMenuCategoryTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuCategoryTextColor;
      this.MobileMenuBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBackgroundColor;
      this.MobileMenuBorderColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBorderColor;
      this.MobileMenuTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuTextColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.ClosingNoticeBackground =
        res.TemplateData.DynamicStyle.TertiaryColors.ClosingNoticeBackground;
      this.ClosingNoticeTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors.ClosingNoticeTextColor;
      this.cartMessage = res.TemplateData.CartMessage;
      this.getCategories();

      if (this.orderStatus) {
        this.getLatestOrders();
      }
    });
  }
  getConfig() {
    this.brandConfigSub = this.apiService.getbrandConfig$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.MinDeliveryTime = res.MinDeliveryTime;
      this.MinCollectionTime = res.MinCollectionTime;
      this.DeliveryFrom = res.DeliveryFrom;
      this.CollectionFrom = res.CollectionFrom;
      this.CheckoutNotice = res.CheckoutNotice;
      this.ClosingNotice = res.ClosingNotice;
      this.ShowDelivery = res.ShowDelivery;
      this.ShowCollection = res.ShowCollection;
    });
  }
  checkDeviceWidth() {
    this.windowWidth = document.body.clientWidth;
  }

  getCategories() {
    this.selectedID = this.selectedCategory = null;
    this.recipeServiceSub = this.recipeService.data$.subscribe((res) => {
      if (!res) return;
      this.brand_Categories = res.Categories.filter(
        (category) => category.ForCollection || category.ForDelivery
      );
      this.brandCategpriesBackup = res.Categories;
      this.completeRecipeList = res.CategoriesAndRecipes;
      if (this.windowWidth < 768 && this.hotCategoryList?.length) {
        this.brand_Categories = [];
        this.spinner.hide();
        return;
      }
      if (this.deliveryType == 'Home') {
        this.brand_Categories = this.brand_Categories.filter(
          (category) => category.ForDelivery
        );
      }
      if (this.deliveryType == 'Store') {
        this.brand_Categories = this.brand_Categories.filter(
          (category) => category.ForCollection
        );
      }
      if (this.windowWidth < 768 && this.showCatsOnClick) {
        this.spinner.hide();
        return;
      }
      this.toggleRecipies(this.brand_Categories[0]);
    });
  }
  toggleRecipies(category) {
    if (this.selectedCategory == category.CategoryId) {
      this.selectedCategory = null;
      return;
    }
    this.getRecipes(category);
  }
  toggelRecipiesOnMobile(category) {
    if (this.selectedCategory == category.CategoryId) {
      this.selectedCategory = null;
      return;
    }
    this.selectedCategory = category.CategoryId;
    this.getRecipes(category);
  }
  getRecipes(category: Category) {
    this.selectedAllergen = null;
    if (this.selectedID == category.CategoryId) {
      return;
    }
    this.selectedID = this.selectedCategory = category.CategoryId;
    this.categoryName = category.CategoryTitle;
    this.categoryImage = category.CategoryImage;
    this.recipes = [];
    this.completeRecipeList.forEach((category) => {
      if (category.CategoryId == this.selectedID) {
        this.recipes = category.Recipes.filter(
          (recipe) => recipe.ForCollection || recipe.ForDelivery
        );
      }
    });
    if (this.deliveryType == 'Home') {
      this.recipes = this.recipes.filter((recipe) => recipe.ForDelivery);
    }
    if (this.deliveryType == 'Store') {
      this.recipes = this.recipes.filter((recipe) => recipe.ForCollection);
    }
    if (!this.recipes.length) {
      this.notfound = 1;
    }
    setTimeout(() => {
      if (document.getElementById(`${category.CategoryId}`)) {
        document.getElementById(`${category.CategoryId}`)?.scrollIntoView();
      } else {
        document.getElementById(`recipes`)?.scrollIntoView();
      }
    }, 300);
    this.spinner.hide();
  }

  getCartTotal() {
    let recipeTotal = 0;
    let cart = JSON.parse(this._local.get('cartRecipeItems'));
    cart?.forEach((item) => {
      recipeTotal += item.recipeTotal;
    });
    this.cartTotal = recipeTotal;
  }
  scrollToCart() {
    document.getElementById('checkoutButton')?.scrollIntoView();
  }
  updateCartfromSideCart() {
    this.getCartTotal();
  }
  changeDeliveryType(type: string) {
    this._local.set('deliveryType', type);
    this._requestService.deliveryType$.next(type);
    this.selectedHotCat = null;
  }
  brandOffers() {
    this.showOffersMobile = !this.showOffersMobile;
    this.recipes = null;
    this.categoryImage = null;
    this.selectedCategory = null;
    this.selectedID = null;
  }

  //post code validation//

  checkPostCodeValidity(val: string) {
    if (val == null) {
      this.InvalidPostCode = false;
      this.validPostCode = false;
      this.manualPostCode = '';
    } else {
      this.postSpinner = true;
      const cartProductItems = this._requestService.getProductCartItems();
      const cartRecipeItems = this._requestService.getCartRecipeItems();

      val = val.toUpperCase();
      const params = {
        BrandId: this._local.get('BrandId'), // Parent Brand Id.
        Postcode: val, // Customer's entered postcode.
        ProductOrderDetail: cartProductItems,
        RecipeOrderDetail: cartRecipeItems,
      };
      this._requestService
        //.getRequest("api/CustomerAddress/getAddress/" + brandId + "/" + val, [])
        .postRequest('api/CustomerAddress/checkPostcodeValidity', params, [])
        .subscribe((res) => {
          if (res['Info']['Status'] == 200) {
            this._requestService.postCode = this.manualPostCode;
            this.validPostCode = true;
            this.InvalidPostCode = false;
            this.enteredPostCode = val;
            this.manualPostCode = null;
          } else {
            this.InvalidMessage = res['Info']['Message'];
            this.InvalidPostCode = true;
            this.validPostCode = false;
            this.manualPostCode = null;
            this._requestService.postCode = '';
          }
          this.postSpinner = false;
        });
    }
  }
  openCheckout() {
    this.recomendedRecipeService.showRecomendations.next();
  }
  getAllergenRecipies(allergen) {
    this.spinner.show();
    this.selectedID = null;
    this.selectedAllergen = allergen.Title;
    this.selectedCategory = null;
    this.categoryImage = null;
    this.categoryName = null;
    let url = allergen.Key;
    if (this.deliveryType) {
      url += this.deliveryType == 'Home' ? '/Delivery' : '/Pickup';
    }
    this._requestService
      .getRequest(
        `api/Recipe/searchRecipeByAllergen/${this.brandFlavour}/${url}`,
        []
      )
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res['Info']['Status'] == 200) {
            this.recipes = res['Data'];
            if (this.windowWidth < 768 && this.recipes.length) {
              document.getElementById(allergen.CategoryId)?.scrollIntoView();
            }
          } else {
            this.recipes = [];
          }
        },
        error: () => {
          this.spinner.hide();
          this.recipes = [];
        },
      });
  }
  getLatestOrders() {
    if (!this._local.get('CustomerId')) {
      return;
    }
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + this._local.get('access_token'),
      BrandFlavor: this.brandFlavour,
    });
    const customerId = this._local.get('CustomerId');
    this._requestService
      .getRequest(
        `api/Order/getCustomerOrderStatus/${this.brandFlavour}/${customerId}`,
        headers
      )
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.latestOrders = res['Data'];
          this.latestOrders.length;
          if (document.getElementById(`recipes`)) {
            setTimeout(() => {
              document.getElementById(`recipes`)?.scrollIntoView();
            }, 1000);
          }
        }
      });
  }
  showCategories(category) {
    if (this.selectedHotCat == category.Label) {
      this.brand_Categories = [];
      this.selectedHotCat = null;
      return;
    }
    this.brand_Categories = [];
    this.selectedHotCat = category.Label;
    this.brandCategpriesBackup.forEach((cat) => {
      category.CategoryList.forEach((item) => {
        if (cat.CategoryId == item) {
          this.brand_Categories.push(cat);
        }
      });
    });
  }
  ngOnDestroy() {
    this.app.setAllergensFooter(false);
    this.resizeSubscription$.unsubscribe();
    this.popServiceSub.unsubscribe();
    this.deliveryTypeSub.unsubscribe();
    this.brandConfigSub.unsubscribe();
    this.webCusSub.unsubscribe();
    this.recipeServiceSub.unsubscribe();
  }
}
