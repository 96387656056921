<div class="container">
  <div class="row border mt-2 br-10px overflow-hidden m-0">
    <h2 class="text-center bg-light p-2">Open Orders</h2>
    <div class="col" *ngIf="openOrders.length">
      <div
        *ngFor="let openorder of openOrders"
        class="border border-dark mb-2 br-10px"
      >
        <div
          class="row align-items-center cursor-pointer animation p-1 br-10px m-0"
          (click)="openOrderModal(openorder)"
        >
          <div class="col-md-3">
            <p>Order # {{ openorder?.OrderId }}</p>
            <p *ngIf="openorder.paymentInfo.OrderPayment.OrderPaymentBill">
              {{ openorder?.OrderPaymentType }}
            </p>
          </div>
          <div class="col-md-3">
            <p>
              {{
                openorder?.OrderPaymentDeliveryType === "Home"
                  ? "Home Delivery"
                  : "Collection"
              }}
            </p>
            <p>
              {{ openorder?.OrderPlaceTime | date: "medium" }}
            </p>
          </div>
          <div class="col-md-3">
            <p class="text-danger text-decoration-underline">
              {{ openorder?.OrderStatus }}
            </p>
            <p
              *ngIf="openorder?.Customer?.AccountNumber && accountNumberEnabled"
            >
              Account# {{ openorder?.Customer?.AccountNumber }}
            </p>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-green w-100 br-40px mb-3"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              (click)="openOrderModal(openorder); stopPropagation($event)"
            >
              Details
            </button>
            <button
              class="btn btn-green w-100 br-40px"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              (click)="orderAgain(openorder, $event)"
            >
              Order Again
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col" *ngIf="!openOrders.length">
      <h1 class="text-center">No Orders Found</h1>
    </div>
  </div>
  <div class="row mt-3 mb-3 border br-10px overflow-hidden m-0">
    <h2 class="text-center bg-light p-2">Closed Orders</h2>
    <div class="col" *ngIf="closedOrders.length">
      <div
        *ngFor="let closedorder of closedOrders"
        class="border border-dark mb-2 br-10px"
      >
        <div
          class="row align-items-center cursor-pointer animation p-1 br-10px m-0"
          (click)="openOrderModal(closedorder)"
        >
          <div class="col-md-3">
            <p>Order # {{ closedorder?.OrderId }}</p>
            <p *ngIf="closedorder.paymentInfo.OrderPayment.OrderPaymentBill">
              {{ closedorder?.OrderPaymentType }}
            </p>
          </div>
          <div class="col-md-3 col-6">
            <p>
              {{
                closedorder?.OrderPaymentDeliveryType === "Home"
                  ? "Home Delivery"
                  : "Collection"
              }}
            </p>
            <p>
              {{ closedorder?.OrderPlaceTime | date: "medium" }}
            </p>
          </div>
          <div class="col-md-3">
            <p class="text-danger text-decoration-underline">
              {{ closedorder?.OrderStatus }}
            </p>
            <p
              *ngIf="closedorder.Customer.AccountNumber && accountNumberEnabled"
            >
              Account# {{ closedorder.Customer.AccountNumber }}
            </p>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-green w-100 br-40px mb-3"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              (click)="openOrderModal(closedorder); stopPropagation($event)"
            >
              Details
            </button>
            <button
              class="btn btn-green w-100 br-40px"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              (click)="orderAgain(closedorder, $event)"
            >
              Order Again
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col" *ngIf="!closedOrders.length">
      <h1 class="text-center">No Orders Found</h1>
    </div>
  </div>
</div>

<div
  class="overlay d-flex justify-content-center align-items-center p-1"
  *ngIf="order"
  (click)="order = null"
>
  <div class="content bg-light p-2 br-10px" (click)="stopPropagation($event)">
    <div
      class="border-bottom d-flex justify-content-between align-items-center"
    >
      <h2 class="m-0">Order Details</h2>
      <div>
        <button
          class="btn br-40px"
          [ngStyle]="{
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
          (click)="orderAgain(savedOrder, $event)"
        >
          Order Again
        </button>
        <button class="btn fs-1 text-danger" (click)="order = null">
          &times;
        </button>
      </div>
    </div>
    <p>
      <strong>Delivery Type : </strong>
      {{
        order?.OrderPaymentDeliveryType === "Home"
          ? "Home Delivery"
          : "Collection"
      }}
    </p>
    <p *ngIf="order?.Customer?.AccountNumber && accountNumberEnabled">
      <strong>Account Number :</strong>
      {{ order?.Customer?.AccountNumber }}
    </p>
    <p>
      <strong>Address : </strong>
      <span *ngIf="order.address">{{ order.address }}</span>
      <span *ngIf="!order.address">{{ branchAddress }}</span>
    </p>
    <p *ngIf="order.schedule">
      <strong>Schedule : </strong>
      <span>{{ order.schedule }}</span>
    </p>
    <p>
      <strong>Order Place Time :</strong>
      {{ order?.OrderPlaceTime | date: "medium" }}
    </p>
    <p *ngIf="order?.paymentInfo.OrderPayment.Notes">
      <strong>Notes/Special Requests :</strong>
      {{ order?.paymentInfo.OrderPayment.Notes }}
    </p>

    <hr />
    <div *ngFor="let item of order.RecipeDetail" class="mt-2 border-bottom">
      <span
        class="ps-3 pe-3 br-40px d-inline-block btn-green"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
        >{{ item?.['category'] }}</span
      >
      <div
        *ngFor="let recipe of item?.['recipes']; let last = last"
        class="m-1"
        [ngClass]="{ 'border-bottom': !last }"
      >
        <div class="d-flex align-items-center justify-content-between">
          <p class="w-75">
            <span class="text-danger">{{ recipe.OrderRecipeQuantity }} X </span>
            {{ recipe.Recipe.RecipeTitle }}
          </p>
          <p
            class="ps-1 pe-1 br-40px bg-dark text-light"
            *ngIf="recipe?.OrderRecipeTotalPrice"
          >
            {{
              recipe.OrderRecipeTotalPrice * recipe.OrderRecipeQuantity
                | currency: "GBP":"symbol":"1.2-2"
            }}
          </p>
        </div>
        <div *ngFor="let extra of recipe.OrderRecipeExtraDetails" class="row">
          <div class="col-sm-6 col-9">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <p class="p-1 m-0 fs11px" *ngIf="extra.ExtraQuantity > 1">
                  {{ extra?.ExtraQuantity }}
                </p>
                <p
                  class="p-1 text-center m-0 fs11px"
                  *ngIf="extra.ExtraQuantity > 1"
                >
                  X
                </p>
                <p class="p-1 m-0 fs11px">{{ extra?.ExtraTitle }}</p>
              </div>
              <p class="p-1 text-end m-0 fs11px" *ngIf="extra.ExtraPrice > 0">
                {{ extra?.ExtraPrice | currency: "GBP":"symbol":"1.2-2" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let item of order.ProductDetail" class="mt-2 border-bottom">
      <span
        class="ps-3 pe-3 br-40px d-inline-block"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
        >{{ item?.['category'] }}</span
      >
      <div
        *ngFor="let product of item?.['products']; let last = last"
        class="m-1"
        [ngClass]="{ 'border-bottom': !last }"
      >
        <div class="d-flex align-items-center justify-content-between">
          <p class="w-75">
            <span class="text-danger"
              >{{ product.OrderDetailQuantity }} X
            </span>
            {{ product.Product.ProductTitle }}
          </p>
          <p
            class="ps-1 pe-1 br-40px bg-dark text-light"
            *ngIf="product.OrderProductTotalPrice"
          >
            {{
              product.OrderProductTotalPrice * product.OrderDetailQuantity
                | currency: "GBP":"symbol":"1.2-2"
            }}
          </p>
        </div>
        <div *ngFor="let extra of product.OrderProductExtraDetails" class="row">
          <div class="col-sm-6 col-9">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <p class="p-1 m-0 fs11px" *ngIf="extra.ExtraQuantity > 1">
                  {{ extra?.ExtraQuantity }}
                </p>
                <p
                  class="p-1 text-center m-0 fs11px"
                  *ngIf="extra.ExtraQuantity > 1"
                >
                  X
                </p>
                <p class="p-1 m-0 fs11px">{{ extra?.ExtraTitle }}</p>
              </div>
              <p class="p-1 text-end m-0 fs11px" *ngIf="extra.ExtraPrice > 0">
                {{ extra?.ExtraPrice | currency: "GBP":"symbol":"1.2-2" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="itemsTotal">
      <div class="col-sm-4 col-6">
        <strong class="text-danger">Items :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          itemsTotal | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.PostageAndPackages"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-danger">Postage and Packaging :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          order.paymentInfo.OrderPayment.PostageAndPackages
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.CarrierBagCharges"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-danger">Carrier Bag Charges :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          order.paymentInfo.OrderPayment.CarrierBagCharges
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.ServiceCharges"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-danger">Service Charges :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          order.paymentInfo.OrderPayment.ServiceCharges
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.VatAmount"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-danger">VAT Amount :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          order.paymentInfo.OrderPayment.VatAmount
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.TipAmount"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-danger">Tip Amount :</strong>
      </div>
      <div class="col-3">
        <strong class="text-danger">{{
          order.paymentInfo.OrderPayment.TipAmount
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.PromoDiscountAmount"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-success">Promo Discount Amount :</strong>
      </div>
      <div class="col-3">
        <strong class="text-success">{{
          order.paymentInfo.OrderPayment.PromoDiscountAmount
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
        <span> - {{ order.paymentInfo.OrderPayment.PromoCodeUsed }}</span>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.DiscountAmount"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-success">Discount Amount :</strong>
      </div>
      <div class="col-3">
        <strong class="text-success">{{
          order.paymentInfo.OrderPayment.DiscountAmount
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.GiftAmountBill"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-success">Gift Amount :</strong>
      </div>
      <div class="col-3">
        <strong class="text-success">{{
          order.paymentInfo.OrderPayment.GiftAmountBill
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.CustomerWalletBalance"
    >
      <div class="col-sm-4 col-6">
        <strong class="text-success">Balance :</strong>
      </div>
      <div class="col-3">
        <strong class="text-success">{{
          order.paymentInfo.OrderPayment.CustomerWalletBalance
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="order.paymentInfo.OrderPayment.OrderPaymentBill"
    >
      <div class="col-sm-4 col-6">
        <strong>Total :</strong>
      </div>
      <div class="col-3">
        <strong>{{
          order.paymentInfo.OrderPayment.OrderPaymentBill
            | currency: "GBP":"symbol":"1.2-2"
        }}</strong>
      </div>
    </div>
  </div>
</div>
<div
  class="overlay d-flex justify-content-center align-items-center p-1"
  *ngIf="showMessage"
>
  <div class="content bg-light br-40px overflow-hidden">
    <h2 class="m-2">
      Sorry for inconvienence the items your are trying to reorder are no longer
      available
    </h2>
    <button (click)="showMessage = false" class="closeBtn">Close</button>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
