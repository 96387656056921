import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '../service/request.service';
import { GetterService } from '../service/getter-service.service';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../service/api.service';
import { popupService } from '../popup/popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  notfound: any = 0;
  products: any;
  isLoading: boolean;
  recipeData: any = [];
  productData: any = [];
  brandName: string;
  focus: boolean;
  searchResult: string;
  searchResSub: Subscription;
  webCusSub: Subscription;
  popupServiceSub: Subscription;

  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService,
    private popupService: popupService
  ) {}

  ngOnInit() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.brandName = res.BrandData.BrandName;
    });
    this.popupServiceSub = this.popupService.addedToCartSuccessful.subscribe(
      (data) => {
        this.productData.forEach((prod) => {
          if (prod.ProductId == data.ProductId) {
            prod.ProductQuantity = data.ProductQuantity;
          }
        });
      }
    );
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
    this.popupServiceSub.unsubscribe();
  }
  searchProducts(inputEvent: Event) {
    if (this.searchResSub) {
      this.searchResSub.unsubscribe();
    }
    const value = (inputEvent.target as HTMLInputElement).value;
    if (value.length < 4) {
      this.recipeData = [];
      this.productData = [];
      this.isLoading = false;
      this.searchResult = null;
      return;
    }

    this.isLoading = true;
    let type = this._local.get('deliveryType');

    if (type.length) {
      if (type == 'Home') {
        type = 'Delivery';
      } else {
        type = 'Pickup';
      }
    }
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let body = {
      BrandId: this._local.get('BrandId'),
      SearchKey: value,
      Offset: 0,
      Limit: 40,
      OrderOption: type,
    };
    this.searchResSub = this._requestService
      .postRequest('api/Product/search', JSON.stringify(body), httpHeaders)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res['Info']['Status'] == 200) {
            this.searchResult = null;
            this.notfound = 0;
            this.recipeData = res['Data'].RecipeData;
            this.productData = res['Data'].ProductData;

            let cart = JSON.parse(this._local.get('cartProductItems'));
            cart?.forEach((product) => {
              this.productData.forEach((item) => {
                if (item.ProductId == product.ProductId) {
                  item.ProductQuantity = product.ProductQuantity;
                }
              });
            });
          } else {
            this.notfound = 1;
            this.recipeData = [];
            this.productData = [];
            this.searchResult = 'No item found';
          }
        },
        error: () => {
          this.notfound = 1;
          this.isLoading = false;
          this.recipeData = [];
          this.productData = [];
          this.searchResult = 'Something went wrong try another item';
        },
      });
  }
  onBlur() {
    this.focus = false;
  }
  onFocus() {
    this.focus = true;
  }
}
