<div class="container-fluid d-flex justify-content-center align-items-center">
  <div class="content d-flex flex-column">
    <h2 class="text-center">Pay Online</h2>
    <img
      src="https://perkss.blob.core.windows.net/images/customer/one_time.png"
      alt="Image"
      class="img"
    />
    <div class="input-group mb-4">
      <span
        class="input-group-text"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
        id="Enter amount"
        ><i class="fas fa-pound-sign"></i
      ></span>
      <input
        type="text"
        class="form-control"
        placeholder="Enter amount*"
        aria-label="Enter amount"
        aria-describedby="Enter amount"
        [(ngModel)]="amountInputReset"
        placeholder="Enter amount*"
        #amountInput="ngModel"
        required
        [disabled]="disableInput"
        pattern="[0-9]+(\.[0-9]{1,2})?"
        (input)="isNumberKey(amountInput)"
      />
      <p
        *ngIf="amountInput.errors?.['pattern']"
        class="m-0 text-center text-danger amountInvalidText"
      >
        Amount is invalid
      </p>
      <p
        *ngIf="amountInput.errors?.['required']"
        class="m-0 text-center text-danger amountInvalidText"
      >
        Amount is required
      </p>
    </div>
    <div class="input-group mb-4">
      <textarea
        name="description"
        id="des"
        cols="3"
        rows="3"
        [required]="desRequired"
        placeholder="Write purpose of payment*"
        class="form-control br-10px"
        [(ngModel)]="description"
        (input)="discriptionUpdate()"
        #des="ngModel"
      ></textarea>
      <p
        *ngIf="des.errors?.['required']"
        class="m-0 text-center text-danger amountInvalidText"
      >
        Discription is required
      </p>
    </div>
    <div class="paymentBox p-relative">
      <div
        class="overlay overlayPayment d-flex justify-content-center align-items-center"
        *ngIf="desRequired ? !(description && amount) : !amount"
      ></div>
      <button
        class="paymentBtn br-40px dynamicButtonHeight mb-2 btn"
        type="button"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
        (click)="checkPaymentGateway()"
      >
        Debit/Credit Card
      </button>
      <div class="mb-1" *ngIf="checkoutConfig?.PayPalEnabled">
        <app-paypal-btn
          [total]="amount"
          (response)="response($event)"
        ></app-paypal-btn>
      </div>
      <div class="mb-2" *ngIf="checkoutConfig?.PayPalEnabled">
        <app-paypal-card
          [total]="amount"
          (response)="response($event)"
        ></app-paypal-card>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
<div
  [ngClass]="{ 'd-none': !showStripe }"
  class="overlay d-flex justify-content-center align-items-center flex-column"
  (click)="showStripe = false"
>
  <app-stripe (response)="response($event)" [total]="amount"></app-stripe>
</div>
<div
  *ngIf="showPaymentSense"
  class="overlay d-flex justify-content-center align-items-center flex-column"
  (click)="showPaymentSense = false"
>
  <app-payment-sense
    [total]="amount"
    (response)="response($event)"
  ></app-payment-sense>
</div>
<div
  class="overlay ovarlayMessage d-flex justify-content-center align-items-center"
  *ngIf="showMessage"
>
  <div class="messageBox">
    <p>{{ message }}</p>
    <button (click)="showMessage = false">Close</button>
  </div>
</div>
