<div class="menuContainer">
  <div
    *ngFor="let item of bottomMenu"
    class="item"
    (click)="openUrl(item.Title, item.WebLink, item.Redirection)"
    [ngStyle]="
      component == item.Redirection
        ? {
            'background-color': BottomMenuSelectionColor,
            color: BottomMenuTextSelectionColor
          }
        : {}
    "
  >
    <div class="image">
      <img src="{{ item.Image }}" alt="item.Title" />
    </div>
    <P class="m-0 title">{{ item.Title }}</P>
  </div>
</div>
