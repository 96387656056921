<div class="container-fluid mt-3 mb-4" *ngIf="product">
  <div class="row justify-content-center">
    <div [ngClass]="training ? 'col-lg-8' : 'col-12'">
      <div class="row justify-content-center">
        <div [ngClass]="training ? 'col-md-6' : 'col-md-5'">
          <img
            *ngIf="product.ProductImageUrl"
            src="{{ product.ProductImageUrl }}"
            alt="{{ product.ProductTitle }}"
            class="w-100"
          />
          <div class="d-flex mt-2 justify-content-center flex-wrap">
            <span
              *ngFor="let image of product.ProductImageConfig"
              class="p-2 border-dark cursor-pointer rowImages"
              [ngClass]="{ border: image == selectedImage }"
              (click)="changeImage(image)"
            >
              <img
                src="{{ image }}"
                alt="Product catalog"
                class="w-100 h-100"
              />
            </span>
          </div>
          <div #description class="mt-4"></div>
        </div>
        <div [ngClass]="training ? 'col-md-6' : 'col-md-4'">
          <h2 class="mb-3">{{ product.ProductTitle }}</h2>
          <p
            class="text-danger text-decoration-underline cursor-pointer mb-3"
            (click)="contiueShopping()"
          >
            Continue shopping
          </p>
          <p class="mb-3">
            <strong>Product ID : </strong>{{ product.ProductId }}
          </p>
          <p class="mb-3 fs-6">{{ product.ProductTagline }}</p>
          <button
            class="btn btn-primary line-height-1 mb-3"
            disabled
            *ngIf="product.ProductLabel"
          >
            {{ product.ProductLabel }}
          </button>

          <div class="popup-body" *ngIf="extraList?.length">
            <div *ngFor="let extra of extraList; let i = index">
              <div *ngIf="extra?.Key.toLowerCase().includes('choose')">
                <h2>{{ extra?.Key }} <sup class="text-danger">*</sup></h2>
                <div class="row">
                  <div class="col">
                    <form>
                      <div *ngFor="let item of extra?.extras">
                        <div class="form-check mb-2">
                          <div class="row">
                            <div class="col-6">
                              <input
                                class="form-check-input"
                                type="radio"
                                (click)="chooseExtra(item)"
                                name="{{ item?.Key }}{{ i }}"
                                id="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              />
                              <label
                                (click)="chooseExtra(item)"
                                class="form-check-label cursor-pointer"
                                for="extra{{ item?.Title }}{{ item?.Key }}{{
                                  i
                                }}"
                              >
                                {{ item?.Title }}
                              </label>
                            </div>
                            <div class="col-3">
                              <p *ngIf="item?.Price" class="text-end m-0">
                                {{
                                  item?.Price | currency: "GBP":"symbol":"1.2-2"
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div *ngIf="!extra?.Key.toLowerCase().includes('choose')">
                <h2>{{ extra?.Key }}</h2>
                <div *ngFor="let item of extra?.extras; let i = index">
                  <div class="row mb-2 ms-0 me-0">
                    <div class="col-4 ps-1 pe-0">
                      <p class="m-0">{{ item?.Title }}</p>
                    </div>
                    <div class="col-4 p-0">
                      <p class="m-0" *ngIf="item.Price">
                        {{
                          (item.Quantity * item.Price
                            ? item.Quantity * item.Price
                            : item.Price
                          ) | currency: "GBP":"symbol":"1.2-2"
                        }}
                      </p>
                    </div>
                    <div class="col-4 ps-0 pe-1">
                      <div
                        class="input-group br-40px border w-auto flex-nowrap justify-content-between"
                      >
                        <button
                          class="btn ps-md-3 ps-2 pe-md-3 pe-2"
                          (click)="decreaseItemQuantity(item)"
                          type="button"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                        <span class="input-group-text m-0 p-2">{{
                          item.Quantity
                        }}</span>
                        <button
                          class="btn ps-md-3 ps-2 pe-md-3 pe-2"
                          type="button"
                          (click)="increaseExtraQuantity(item)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="fs-5 mb-3" *ngIf="product.ProductStocks">In stock</p>
          <p class="fs-5 text-danger mb-3" *ngIf="!product.ProductStocks">
            Out of Stock
          </p>
          <p class="fw-bold" *ngIf="product?.ToTime">
            Time :
            <span class="text-danger fw-bold">{{
              product.ToTime | date: "medium"
            }}</span>
          </p>
          <p class="mb-3" *ngIf="price">
            <strong>Price : </strong>
            <span class="text-danger fs-5">{{
              price | currency: "GBP":"symbol":"1.2-2"
            }}</span>
          </p>
          <span class="d-flex align-items-center mb-3"
            ><strong>Quantity : </strong>
            <span>
              <input
                type="number"
                class="form-control w-100px ms-2"
                value="1"
                min="1"
                [(ngModel)]="quantity"
                #input="ngModel"
                (focusout)="validateQuantity(input)"
                (keydown)="validateInput($event)"
              /> </span
          ></span>
          <p class="text-danger" *ngIf="message && extrasChooseOnes">
            {{ message }}
          </p>
          <button
            class="btn mb-3 w-100 br-40px"
            [ngStyle]="{
              'background-color': dynamicCss.SelectedButtonColor,
              color: dynamicCss.SelectedButtonTextColor,
              outline: dynamicCss.SelectedButtonColor
            }"
            (click)="addToCart()"
          >
            <i class="fa fa-shopping-basket" aria-hidden="true"></i>
            Add to basket
          </button>
          <button
            class="btn mb-3 w-100 br-40px"
            [ngStyle]="{
              'background-color': dynamicCss.SelectedButtonColor,
              color: dynamicCss.SelectedButtonTextColor,
              outline: dynamicCss.SelectedButtonColor
            }"
            (click)="openUrl('checkout')"
          >
            Go to checkout
          </button>
          <p class="fs-6"><strong>Save this product for later</strong></p>
          <div class="d-flex justify-content-between mb-3">
            <p
              *ngIf="!product.IsFavourite"
              class="border ps-3 pe-3 pt-2 pb-2 d-flex align-items-center justify-content-center w-100 br-10px cursor-pointer text-center me-2 fs-6"
              (click)="AddRemoveFavorites(true)"
            >
              <i class="far fa-heart fs-4" title="Add To Favourite List"></i>
              <strong class="ms-3">Favourite</strong>
            </p>
            <p
              *ngIf="product.IsFavourite"
              class="border ps-3 pe-3 pt-2 pb-2 d-flex align-items-center justify-content-center br-10px cursor-pointer w-100 text-center me-2 fs-6"
              (click)="AddRemoveFavorites(false)"
            >
              <i
                class="fa fa-heart fs-4 text-danger"
                title="Add To Favourite List"
              ></i>
              <strong class="ms-3">Favourited</strong>
            </p>
            <p
              class="border ps-3 pe-3 pt-2 pb-2 d-flex align-items-center br-10px cursor-pointer w-100 text-center fs-6"
              (click)="openUrl('favourites')"
            >
              <strong class="w-100 text-center">View Favourites</strong>
            </p>
          </div>
          <p class="mb-3 fs-6">
            <Strong>Share this product with your friends</Strong>
          </p>
          <share-buttons
            theme="circles-dark"
            [include]="['copy', 'facebook', 'pinterest', 'twitter', 'whatsapp']"
            [showIcon]="true"
            [showText]="false"
            [description]="product.ProductTitle"
            class="mb-3"
          >
          </share-buttons>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-sm-6" *ngIf="training">
      <app-side-cart></app-side-cart>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>

<div
  class="AddedToCartNotification"
  *ngIf="addedTocart"
  [ngStyle]="{ 'background-color': dynamicCss.SelectedButtonColor }"
>
  <h3
    class="m-0 w-50"
    [ngStyle]="{ color: dynamicCss.SelectedButtonTextColor }"
  >
    {{ this.quantity + " x " + product.ProductTitle }}
  </h3>
  <h3
    class="m-0 w-50 text-center font-weight-bold"
    [ngStyle]="{ color: dynamicCss.SelectedButtonTextColor }"
  >
    {{ "Successfully Added" }}
  </h3>
</div>
