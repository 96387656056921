import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Branches } from 'src/Model/interface/WebCustomer';
import { AppComponent } from '../app.component';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';
@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss'],
})
export class BranchesComponent implements OnInit, OnDestroy {
  postCode = null;
  brand_Name = null;
  brandID = null;
  branches: Branches;
  brandData: any;
  allBranches = [];
  hostName = null;
  postCodeSearchResponse: string = '';
  SelectedButtonColor: any;
  SelectedButtonTextColor: any;
  BranchWebBanner: any;
  spinner: boolean = false;
  BranchTextColor: any;
  webCusSub: Subscription;
  constructor(
    private appComponent: AppComponent,
    private apiService: ApiService,
    private _requestService: RequestService
  ) {
    this.appComponent.setFooterVisibility(false);
    this.appComponent.setHeaderVisibility(false);
  }

  ngOnInit(): void {
    this.hostName = window.location.pathname.split('/')[0];
    this.getWebCustomer();
    this.getBrandBranches();
  }
  ngOnDestroy() {
    this.appComponent.setFooterVisibility(true);
    this.appComponent.setHeaderVisibility(true);
    this.webCusSub.unsubscribe();
  }
  getBrandBranches() {
    this.brand_Name = window.location.pathname.split('/')[1];
    this._requestService
      .getRequest(
        'api/BrandBranch/getAllBusinessBranchesInfo/' + this.brand_Name,
        []
      )
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.allBranches = res['Data'].Data;
        } else {
          this.allBranches = [];
        }
      });
  }
  showBranches() {
    document.getElementById('allBranches')?.scrollIntoView();
  }
  navigateToBranch(falvour: string) {
    this.hostName = window.location.protocol + '//' + window.location.host;
    window.location.href = this.hostName + '/' + falvour + '/' + 'online';
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
	  this.branches = res.TemplateData?.Branches;
      this.branches.Logo
      this.brand_Name = res.BrandData.BrandName;
      this.BranchWebBanner = res.TemplateData.DynamicStyle?.BranchWebBanner;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.BranchTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors?.BranchTextColor;
    });
  }
  branchSearch() {
    this.spinner = true;
    this.brand_Name = window.location.pathname.split('/')[1];
    let falvour;
    this.postCodeSearchResponse = '';
    const params = {
      BrandFlavor: this.brand_Name, // Parent Brand Id.
      CustomerPostcode: this.postCode, // Customer's entered postcode.
    };
    this._requestService
      .postRequest('api/BrandAddress/getNearbyBusinessInfo', params, [])
      .subscribe((res) => {
        this.spinner = false;
        falvour = res['Info'].Flavor;
        if (res['Info']['Status'] == 200) {
          falvour = res['Info'].Flavor;
          this.hostName =
            window.location.protocol + '//' + window.location.host;
          window.location.href = this.hostName + '/' + falvour + '/' + 'online';
        } else {
          this.postCodeSearchResponse = res['Info'].Message;
        }
      });
  }
}
