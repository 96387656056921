import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckoutService } from '../checkout/checkout.service';
import { ApiService } from '../service/api.service';
import { StripeService } from './stripe.service';
import { environment } from '../../environments/environment';
import { SendGiftcardService } from '../send-giftcard/send-giftcard.service';
import { PayService } from '../pay/pay.service';
import { GetterService } from '../service/getter-service.service';
import { NewReservationService } from '../reservation/reservation.service';
@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit, OnDestroy {
  stripeKey: string;
  stripe: any;
  OrderId: number;
  brandFlavour: string;
  @Input() total: number = 0;
  @Output() response = new EventEmitter<any>();
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  spinner: boolean;
  webCustomerSub: Subscription;
  component: string;
  GiftCardId: number;
  message: string;
  showMessage: boolean;
  ReservationId: any;
  brandName: string;
  constructor(
    private stripeService: StripeService,
    private checkoutService: CheckoutService,
    private apiService: ApiService,
    private giftcardService: SendGiftcardService,
    private payService: PayService,
    private _local: GetterService,
    private reservationService: NewReservationService
  ) {}

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.component = window.location.pathname.split('/')[2];

    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.brandName = res.BrandData.BrandName;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      if (res.TemplateData.CheckoutDetail.PaymentGateway == 'Stripe') {
        this.getStripeKey();
      }
    });
  }

  ngOnDestroy() {
    this.webCustomerSub.unsubscribe();
  }

  preventPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  getStripeKey() {
    this.stripeService.getStripeKey().subscribe((res) => {
      if (res['Info']['Status'] == 200) {
        this.stripeKey = res['Data'].StripeKey;
        if (res['Data']?.IsStripeConnectAccount) {
          const options = {
            stripeAccount: res['Data']['StripeConnectAccountId'],
          };

          this.stripe = Stripe(this.stripeKey, options);
        } else {
          this.stripe = Stripe(this.stripeKey);
        }
        var elements = this.stripe.elements();
        var style = {
          base: {
            color: 'black',
            lineHeight: '36px',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '19px',

            '::placeholder': {
              color: '#8898AA',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        };

        var cardNumberElement = elements.create('cardNumber', { style: style });
        var cardExpiryElement = elements.create('cardExpiry', { style: style });
        var cardCvcElement = elements.create('cardCvc', { style: style });
        cardNumberElement.mount('#cardNumber');
        cardExpiryElement.mount('#cardExpiry');
        cardCvcElement.mount('#cardCvc');
        this.initializeStripe(cardNumberElement);
      }
    });
  }

  initializeStripe(card: any) {
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (e) => {
      e.preventDefault();
      // Disable double submission of the form
      this.spinner = true;
      this.stripe
        .createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            // Include any additional collected billing details.
            name: 'Jenny Rosen',
          },
        })
        .then((response) => {
          this.stripePaymentMethodHandler(response);
        });
    });
  }

  stripePaymentMethodHandler = (result) => {
    if (result.error) {
      this.spinner = false;
      this.message = result.error.message;
      this.showMessage = true;
      return;
      // Show error in payment form
    }
    // Otherwise send paymentMethod.id to your server (see Step 4)
    let params = {};
    let URL = environment.apiUrl;
    if (this.component == 'checkout' || this.component == 'order-checkout') {
      this.checkoutService.PaymentMethodId = result.paymentMethod.id;
      params = this.checkoutService.getParams('Card');
      URL = URL + 'api/Checkout/v1/Web';
    } else if (this.component == 'guest') {
      this.checkoutService.PaymentMethodId = result.paymentMethod.id;
      params = this.checkoutService.getParams('Card');
      URL = URL + 'api/Checkout/Guest/v1/Web';
    } else if (this.component == 'giftcard') {
      this.giftcardService.PaymentMethodId = result.paymentMethod.id;
      params = this.giftcardService.getParams('Card');
      URL = URL + 'api/GiftCard/send/v1';
    } else if (this.component == 'pay') {
      this.payService.PaymentMethodId = result.paymentMethod.id;
      params = this.payService.getParams('Card');
      URL = URL + 'api/Payment/OneTimePayment/v1';
    } else if (
      this.component == 'reservation' ||
      this.component == 'reserve-iframe'
    ) {
      this.reservationService.PaymentMethodId = result.paymentMethod.id;
      params = this.reservationService.getParams('Card');
      URL = URL + 'api/Reservation/book/v1/' + this._local.get('BrandId');
    }
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
        BrandFlavor: this.brandFlavour,
      },
      body: JSON.stringify(params),
    })
      .then((result) => {
        if (result.status == 500) {
          this.message = result.statusText;
          this.showMessage = true;
          this.spinner = false;
          return;
        }
        // Handle server response (see Step 4)
        result.json().then((response) => {
          if (response.error) {
            this.spinner = false;
            this.response.emit(result);
            // Show error from server on payment form
          } else if (response.require_payment_auth) {
            // Use Stripe.js to handle required card action
            this.OrderId = response.OrderId;
            if (this.component == 'giftcard') {
              this.GiftCardId = response.GiftCardId;
            }
            if (
              this.component == 'reservation' ||
              this.component == 'reserve-iframe'
            ) {
              this.ReservationId = response.ReservationId;
            }
            this.stripe
              .handleCardAction(response.payment_intent_client_secret)
              .then((response) => {
                this.handleStripeJsResult(response);
              });
          } else {
            this.spinner = false;
            this.response.emit(response);
            // Show success message
          }
        });
      })
      .catch((response) => {
        this.spinner = false;
        this.message = response.statusText;
        this.showMessage = true;
      });
  };

  handleStripeJsResult = (result) => {
    if (result.error) {
      this.spinner = false;
      this.response.emit(result);
      return;
      // Show error in payment form
    }
    let params = {};
    let updatedParams = {};
    let URL = environment.apiUrl;

    if (this.component == 'checkout' || this.component == 'guest') {
      params = this.checkoutService.getParams('Card');
      updatedParams['OrderCheckoutModel'] = { ...params };
      updatedParams['OrderId'] = this.OrderId;
      updatedParams['BrandFlavor'] = this.brandFlavour;
      URL = URL + 'api/Stripe/v1/Required_Action_Pay_Order';
    } else if (this.component == 'giftcard') {
      params = this.giftcardService.getParams('Card');
      updatedParams['GiftCardAccountModel'] = { ...params };
      updatedParams['GiftCardId'] = this.GiftCardId;
      updatedParams['OrderId'] = this.OrderId;
      updatedParams['BrandFlavor'] = this.brandFlavour;
      URL = URL + 'api/Stripe/v1/Required_Action_Pay_Gift';
    } else if (this.component == 'pay') {
      params = this.payService.getParams('Card');
      updatedParams['OneTimePaymentParams'] = { ...params };
      updatedParams['OrderId'] = this.OrderId;
      updatedParams['BrandFlavor'] = this.brandFlavour;
      URL = URL + 'api/Stripe/v1/Required_Action_Pay_OTP';
    } else if (
      this.component == 'reservation' ||
      this.component == 'reserve-iframe'
    ) {
      params = this.reservationService.getParams('Card');
      updatedParams['ReservationModel'] = { ...params };
      updatedParams['OrderId'] = this.OrderId;
      updatedParams['ReservationId'] = this.ReservationId;
      updatedParams['BrandFlavor'] = this.brandFlavour;
      URL = URL + 'api/Stripe/v1/Required_Action_Pay_Reservation';
    }

    // The card action has been handled
    // The PaymentIntent can be confirmed again on the server
    updatedParams['PaymentIntentId'] = result.paymentIntent.id;
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token,
      },
      body: JSON.stringify(updatedParams),
    })
      .then(function (confirmResult) {
        return confirmResult.json();
      })
      .then((response) => {
        if (response.require_payment_auth) {
          this.stripe
            .handleCardAction(response.payment_intent_client_secret)
            .then((response) => {
              this.handleStripeJsResult(response);
            });
          return;
        }
        this.spinner = false;
        this.response.emit(response);
      });
  };
}
