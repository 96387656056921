import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { GetterService } from "src/app/service/getter-service.service";
import { environment } from "src/environments/environment";
import { WebCustomer } from "../../Model/interface/WebCustomer";
import { BrandConfig } from '../../Model/interface/brandConfigInterface';
import { NavigationStart, Router } from "@angular/router";

const Env = environment;
@Injectable({
	providedIn: "root",
})
export class ApiService {
	private webCustomerStore = new BehaviorSubject<WebCustomer>(null);
	private brandConfigStore = new BehaviorSubject<BrandConfig>(null);
	getbrandConfig$: Observable<BrandConfig> = this.brandConfigStore.asObservable();
	getWebcustomer$: Observable<WebCustomer> = this.webCustomerStore.asObservable();
	brandConfigSub: Subscription
	brandFlavour: string;
	constructor(
		private http: HttpClient,
		private _local: GetterService,
		private router: Router,
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.brandFlavour = event.url.split("/")[1]
				const webCustomer = this.webCustomerStore.getValue();
				const brandConfig = this.brandConfigStore.getValue();
				if (!webCustomer) {
					this.getWebCustomer();
				}
				if (!brandConfig) {
					this.getBrandConfig();
				}
			}
		})
	}

	private getBrandConfig(postcode = null, deliveryType = null) {
		if (this.brandConfigSub) {
			this.brandConfigSub.unsubscribe();
		}
		const httpToken: any = new HttpHeaders({
			Authorization: "bearer " + this._local.get("access_token"),
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"BrandFlavor": this.brandFlavour
		});
		const body = {}
		if (postcode) {
			body['Postcode'] = postcode
		}
		if (deliveryType) {
			body['OrderOption'] = deliveryType == "Home" ? "Delivery" : "Pickup"
		}

		this.brandConfigSub = this.http.post(
			Env.apiUrl + "api/Order/v2/getConfig/" + this.brandFlavour,
			body,
			httpToken
		).pipe(
			shareReplay()
		).subscribe(
			{
				next: (res) => {
					if (res['Info'].Status == 200) {
						this.brandConfigStore.next(res["Data"])
					}
				},
				error: () => {
					alert('Network Error! Please refresh page')
				}
			});
	}

	private getWebCustomer() {

		const httpHeaders = new HttpHeaders({
			"BrandFlavor": this.brandFlavour
		});
		this.http
			.get(
				Env.apiUrl +
				"api/Brand/getBrandInfo/" +
				this.brandFlavour +
				"/WebCustomer", {
				headers: httpHeaders
			}
			)
			.pipe(
				shareReplay()
			).subscribe(
				{
					next: (res) => {
						if (res['Info'].Status == 200) {
							this.webCustomerStore.next(res["Data"]);
						}
					},
					error: () => {
						alert('Network Error! Please refresh page')
					}

				});
	}
	/* only to update slots data in case of delivery in checkout */
	callBrandConfig(postcode: string, deliveryType: string) {
		if (!deliveryType) return;
		this.getBrandConfig(postcode, deliveryType);
	}
}
