import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[toggle]'
})
export class ToggleDirective {

  private _shown = false;

  constructor(private el: ElementRef) {
    const parent:HTMLDivElement = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.innerHTML = '<i Class="fa fa-fw fa-eye field-icon" ></i>';
    span.addEventListener('click', () => {
      this.toggle(span);
    });
    span.classList.add('showPassword');
    parent.appendChild(span);
  }

  toggle(div: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      //this.el.nativeElement.innerHTML= '<i Class="fa fa-fw  fa-eye-slash"></i>';
      //this.el.nativeElement.eye
      div.innerHTML = '<i Class="fa fa-fw fa-eye-slash field-icon"></i>';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      //this.el.nativeElement.innerHTML= '<i Class="fa fa-fw  fa-eye"</i>';
      div.innerHTML = '<i Class="fa fa-fw fa-eye field-icon"</i>';
    }
  }
}