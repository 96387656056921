<div
  class="btnGroup br-10px d-flex d-md-none justify-content-around align-item-center flex-row position-sticky"
  [ngStyle]="{ 'background-color': SelectedButtonColor }"
>
  <p
    class="text-center m-0 pt-3 pb-3 fs-6"
    [ngStyle]="{ color: SelectedButtonTextColor }"
    (click)="goToCart()"
  >
    {{ _requestService.cartCounter }} Items
  </p>
  <span class="bg-light text-dark pt-3 pb-3">
    <p class="m-0 fs-6 ps-1 pe-1">
      Total: {{ cartTotal | currency: "GBP":"symbol":"1.2-2" }}
    </p>
  </span>
  <p
    class="text-center m-0 pt-3 pb-3 fs-6"
    (click)="openCheckout()"
    [ngStyle]="{ color: SelectedButtonTextColor }"
  >
    Checkout
  </p>
</div>
<section
  *ngIf="windowWidth <= 768"
  [ngStyle]="{
    background: 'url(' + WebsiteBackground + ')',
    'background-color': SiteBackgroundColor
  }"
>
  <div class="p-1">
    <app-side-cart
      id="checkoutButton"
      (updateCart)="updateCartfromSideCart($event)"
      (updateKgList)="updateProductKgList($event)"
    >
    </app-side-cart>
  </div>
  <div *ngIf="found">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      (scrolled)="scrolled($event)"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="true"
      [infiniteScrollContainer]="'body'"
      [fromRoot]="true"
      [infiniteScrollDisabled]="disablePagination"
      class="h-100"
    >
      <app-responsive-grid-style
        [products]="products"
        (quantityChanged)="getCartTotal()"
      ></app-responsive-grid-style>
    </div>
    <div class="d-flex justify-content-center p-2" *ngIf="fetchingProducts">
      <span class="spinner-border text-success"></span>
    </div>
  </div>
  <!--Not Found-->
  <div *ngIf="!found" class="row">
    <div class="col-lg-12 col-md-12">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
          <img src="../../assets/img/not_found.png" />
          <h3>Didn’t find anything</h3>
          <p>Try searching for another Item</p>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
  <!--Not Found-->
</section>
<section
  class="container-fluid"
  *ngIf="windowWidth > 768"
  [ngStyle]="{
    background: 'url(' + WebsiteBackground + ')',
    'background-color': SiteBackgroundColor
  }"
>
  <div class="row">
    <div class="col-lg-8 p-1">
      <div class="row m-0">
        <div class="col-3 p-1">
          <div class="menuBox positionSticky">
            <h1 class="mb-0 p-2">
              Menu<span class="text-danger" *ngIf="currentEnvironment == 'dev'"
                >-Test Version</span
              >
            </h1>
            <hr class="mt-0" />
            <!-- *ngIf="subCategoryFlag==0" -->
            <div>
              <ul
                *ngFor="let category of brand_Categories; let i = index"
                class="categoryWrapper p-0"
              >
                <li
                  *ngIf="category?.Status"
                  class="categoryItem"
                  (click)="selectCategory(category)"
                  [ngStyle]="
                    selectedCategory == category.CategoryId
                      ? {
                          'background-color': SelectedButtonColor,
                          color: SelectedButtonTextColor
                        }
                      : {}
                  "
                >
                  {{ category?.CategoryTitle || "" }}
                  <i
                    class="fas fa-angle-right itemCaret"
                    aria-hidden="true"
                    *ngIf="category?.['Subcategories']?.length"
                  ></i>
                </li>
                <ul
                  *ngIf="selectedCategory == category.CategoryId && category?.['Subcategories']?.length"
                  class="p-0"
                >
                  <span
                    *ngFor="let subcat of category?.['Subcategories']; let i = index"
                  >
                    <li
                      class="subcategoryItem"
                      *ngIf="subcat?.Status"
                      (click)="selectSubCategory(category, subcat)"
                      [ngStyle]="
                        seletedSubcategory == subcat.SubcategoryId
                          ? {
                              'background-color': SelectedButtonColor,
                              color: SelectedButtonTextColor
                            }
                          : {}
                      "
                    >
                      {{ subcat?.SubcategoryTitle }}
                    </li>
                  </span>
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <!--Product View-->
        <div class="col-9 p-0 h-100">
          <div
            infiniteScroll
            [infiniteScrollDistance]="2"
            (scrolled)="scrolled($event)"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="true"
            [infiniteScrollContainer]="'body'"
            [fromRoot]="true"
            [infiniteScrollDisabled]="disablePagination"
            class="h-100"
          >
            <div class="h-100" *ngIf="found">
              <app-product-grid-style
                [products]="products"
                (quantityChanged)="getCartTotal()"
              ></app-product-grid-style>
            </div>
          </div>
          <div
            class="d-flex justify-content-center p-2"
            *ngIf="fetchingProducts"
          >
            <span class="spinner-border text-success"></span>
          </div>
          <!--Not Found-->
          <div *ngIf="!found" class="row">
            <div
              class="col-12 d-flex flex-column justify-content-center align-items-center"
            >
              <img src="../../assets/img/not_found.png" />
              <h3>Didn’t find anything</h3>
              <p>Try searching for another Item</p>
            </div>
          </div>
          <!--Not Found-->
        </div>
        <!--product View-->
      </div>
    </div>
    <!--Cart View-->
    <div class="col-lg-4 p-1">
      <div class="positionSticky mt-2">
        <app-side-cart
          id="checkoutButton"
          (updateCart)="updateCartfromSideCart($event)"
          (updateKgList)="updateProductKgList($event)"
        >
        </app-side-cart>
      </div>
    </div>
    <!--Cart View-->
  </div>
</section>
<div
  class="backToTop cursor-pointer"
  [ngStyle]="{ 'background-color': SelectedButtonColor }"
  (click)="scrollToTop()"
>
  <i
    class="fa fa-arrow-up topbtn"
    aria-hidden="true"
    [ngStyle]="{ color: SelectedButtonTextColor }"
  ></i>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
