import { RequestService } from './../service/request.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GetterService } from '../service/getter-service.service';
import { HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'join-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss'],
})
export class RewardComponent implements OnInit {
  readableRewards: any[] = [];
  loyaltyConfig: any;
  customerId: number;
  brandId: number;

  constructor(
    private _router: Router,
    private _requestService: RequestService,
    private _local: GetterService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.customerId = JSON.parse(this._local.get('CustomerId'));
    this.brandId = JSON.parse(this._local.get('BrandId'));
    this.getAccountInfo();
    this.getLoyaltyConfig();
  }
  getAccountInfo() {
    if (!this.customerId) return;
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + this._local.get('access_token'),
    });

    let success = (res) => {
      if (res.Info.Status == 200) {
        this.openUrl('my-rewards');
      }
    };
    let error = () => {
      alert(
        'Network Error! Something went wrong please refresh page and try again'
      );
    };
    let complete = () => {
      this.spinner.hide();
    };
    this.spinner.show();
    this._requestService
      .getRequest(
        `api/LoyaltyAccountHolder/getLoyaltyCardInfoByCustomerId/${this.brandId}/${this.customerId}`,
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
        complete: complete,
      });
  }

  getRewards() {
    let brand_id = this._local.get('BrandId');
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this._requestService
      .getRequest('/api/Reward/getReadableRewards/' + brand_id, httpHeaders)
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.readableRewards = res['Data'];
        } else {
          console.log('Something went Wrong!');
        }
      });
  }

  getLoyaltyConfig() {
    let brand_id = this._local.get('BrandId');
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this._requestService
      .getRequest(
        '/api/LoyaltyAccount/getLoyaltyOffer/' + brand_id,
        httpHeaders
      )
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          this.loyaltyConfig = res['Data'];
          this.getRewards();
        } else {
          console.log('Something went Wrong!');
        }
      });
  }

  joinCard() {
    if (!this._requestService.isLoggedIn()) {
      this.openUrl('login?guest=false');
      return;
    }
    this.spinner.show();
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    let success = (res) => {
      if (res['Info']['Status'] == 200) {
        this.openUrl('my-rewards');
      } else {
        alert(res['Info']['Message']);
      }
    };
    let error = () => {
      alert(
        'Network Error! Something went wrong please refresh page and try again'
      );
    };
    let complete = () => {
      this.spinner.hide();
    };
    this._requestService
      .postRequest(
        'api/LoyaltyAccountHolder/joinDigitalCard/' +
          this.customerId +
          '/' +
          this.loyaltyConfig.Id,
        [],
        httpHeaders
      )
      .subscribe({
        next: success,
        error: error,
        complete: complete,
      });
  }

  openUrl(link: string) {
    this._router.navigateByUrl(this._requestService.setUrl(link));
  }

  sliderRight() {
    let customSliderWrapper = document.getElementById('joinSlider');
    let sliderWidth = customSliderWrapper.clientWidth;
    if (customSliderWrapper.scrollLeft) {
      customSliderWrapper.scrollLeft =
        customSliderWrapper.scrollLeft + sliderWidth;
    } else {
      customSliderWrapper.scrollLeft = sliderWidth;
    }
  }

  sliderLeft() {
    let customSliderWrapper = document.getElementById('joinSlider');
    let sliderWidth = customSliderWrapper.clientWidth;
    customSliderWrapper.scrollLeft =
      customSliderWrapper.scrollLeft - sliderWidth;
  }
}
