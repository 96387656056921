import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
})
export class BottomMenuComponent implements OnInit, OnDestroy {
  brandFlavour: string;
  bottomMenu = [];
  HeaderBackgroundColor: string;
  HeaderTextColor: string;
  component: string;
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  BottomMenuSelectionColor: string;
  BottomMenuTextSelectionColor: string;
  webCusSub: Subscription;
  routerSub: Subscription;

  constructor(
    private _requestService: RequestService,
    private apiService: ApiService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.component = window.location.pathname.split('/')[2];
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getWebCustomer();
    this.routerSub = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.component = event.url.split('/')[2];
      }
    });
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
    this.routerSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.bottomMenu = res.TemplateData.BottomMenu.Items;
      this.BottomMenuSelectionColor =
        res.TemplateData.DynamicStyle.TertiaryColors?.BottomMenuSelectionColor;
      this.BottomMenuTextSelectionColor =
        res.TemplateData.DynamicStyle.SecondaryColors?.BottomMenuTextSelectionColor;
    });
  }
  openUrl(title, homeLink, url: string) {
    if (title.toLowerCase().includes('home')) {
      window.location.replace(homeLink);
      return;
    }
    this._router.navigate([this._requestService.setUrl(url)]);
  }
}
