import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import * as Interfaces from '../../Model/interface/productInterfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { Recipe } from '../../Model/interface/productInterfaces';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { AppComponent } from '../app.component';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { popupService } from '../popup/popup.service';
import { environment } from 'src/environments/environment';
import { RecipeService } from '../service/recipe.service';
import { Offer } from 'src/Model/interface/WebCustomer';
interface PostcodeValidation {
  ShowPostcodeValidityField: boolean;
  PostcodeValidityField: {
    Title: string;
    ErrorMessage: string;
  };
}
@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
})
export class OnlineComponent implements OnInit, OnDestroy {
  categories: Interfaces.Category[];
  recipies: Interfaces.CategoryWithRecipes[];
  recipe: Recipe;
  filteredRecipe: Interfaces.filteredRecipe;
  selectedCategory: number;
  brandId: number;
  brandFlavour;
  timeOut;

  // resize for device width //
  windowWidth;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  /* Dynamic css */
  SelectedButtonColor: string;
  SelectedButtonTextColor: string;
  RecipePriceTextColor: string;
  RecipePriceBorderColor: string;
  RecipePriceBackgroundColor: string;
  MenuCartColor: string;
  WebsiteBackground: string;
  SiteBackgroundColor: string;

  renderedExtraList: any[] = [];
  renderExtralistChooseOnes: number = 0;
  showPopup: boolean = false;
  MenuBuilder: boolean = false;
  stockFlag: boolean = false;
  recipePrice: number = 0;
  addedTocart: boolean = false;
  addToCartQuantity: number = 0;
  selectedRecipeTitle: string;
  addedToCartResult: boolean = false;
  MenuWebBanner = null;
  cartTotal: number = 0;
  /* For dynamic sticky position of cart and categories */
  scrollFunc;
  /* Recomendations */
  recomendedRecipies = [];
  recommendations: boolean = false;
  hotCategoryStyle: boolean;
  currentEnvironment: boolean;
  checkoutBtnSub: Subscription;
  popupServiceSub: Subscription;
  webCusSub: Subscription;
  recipeServiceSub: Subscription;
  CheckoutNotice: string;
  MinDeliveryTime: number;
  MinCollectionTime: number;
  DeliveryFrom: string;
  CollectionFrom: string;
  ClosingNotice: string;
  ShowDelivery: boolean;
  ShowCollection: boolean;
  ClosingNoticeBackground: string;
  ClosingNoticeTextColor: string;
  deliveryType: string;
  postcodeValidation: PostcodeValidation;
  InvalidPostCode: boolean;
  validPostCode: boolean;
  manualPostCode: string;
  postSpinner: boolean;
  enteredPostCode: string;
  InvalidMessage: any;
  deliveryTypeSub: Subscription;
  brandCategpriesBackup: Interfaces.Category[];
  completeRecipeList: Interfaces.CategoryWithRecipes[];
  offers: Offer;
  showOffers: boolean;
  brandConfigSub: Subscription;
  constructor(
    private app: AppComponent,
    public requestService: RequestService,
    private local: GetterService,
    private spinner: NgxSpinnerService,
    private popupService: popupService,
    private apiService: ApiService,
    private recomendeditems: recomendationsService,
    private recipeService: RecipeService
  ) {
    this.app.setAllergensFooter(true);
  }

  scrollToElement($element): void {
    $element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.currentEnvironment = !environment.production;
    this.checkoutBtnSub = this.requestService.headerCheckoutBtn.subscribe(
      () => {
        this.openCheckout();
      }
    );
    this.popupServiceSub = this.popupService.addedToCartSuccessful.subscribe(
      (data) => {
        this.getCartTotal();
      }
    );
    this.getCartTotal();
    this.spinner.show();
    this.getWebCustomer();
    this.getConfig();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.checkDeviceWidth();
    });
    this.checkDeviceWidth();
    this.getCategories();
    this.deliveryTypeSub = this.requestService.deliveryType$.subscribe(
      (deliveryType) => {
        this.requestService.deliveryType = deliveryType;
        this.deliveryType = deliveryType;
        this.selectedCategory = null;
        this.requestService.deliveryType = deliveryType;
        this.getCategories();
      }
    );
    this.scrollFunc = () => {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        for (let i = this.recipies.length - 1; i >= 0; i--) {
          if (
            document.getElementById(this.recipies[i].CategoryId.toString())
              ?.offsetTop -
              document.body.scrollTop <=
            10
          ) {
            this.selectedCategory = this.recipies[i].CategoryId;
            break;
          }
        }
      }, 1200);
    };
    document.body.addEventListener('scroll', this.scrollFunc);
  }

  checkDeviceWidth() {
    this.windowWidth = document.body.clientWidth;
  }
  getConfig() {
    this.brandConfigSub = this.apiService.getbrandConfig$.subscribe({
      next: (res) => {
        if (!res) return;
        this.MinDeliveryTime = res.MinDeliveryTime;
        this.MinCollectionTime = res.MinCollectionTime;
        this.DeliveryFrom = res.DeliveryFrom;
        this.CollectionFrom = res.CollectionFrom;
        this.CheckoutNotice = res.CheckoutNotice;
        this.ClosingNotice = res.ClosingNotice;
        this.ShowDelivery = res.ShowDelivery;
        this.ShowCollection = res.ShowCollection;
      },
    });
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.postcodeValidation = res.TemplateData?.PostcodeValidation;
      this.ClosingNoticeTextColor =
        res.TemplateData.DynamicStyle.TertiaryColors?.ClosingNoticeTextColor;
      this.ClosingNoticeBackground =
        res.TemplateData.DynamicStyle.TertiaryColors?.ClosingNoticeBackground;
      this.WebsiteBackground = res.TemplateData.DynamicStyle?.WebsiteBackground;
      this.SiteBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors?.SiteBackgroundColor;
      this.brandId = res.BrandData.BrandId;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.MenuWebBanner = res.TemplateData.DynamicStyle.MenuWebBanner;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.RecipePriceTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceTextColor;
      this.RecipePriceBackgroundColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceBackgroundColor;
      this.MenuCartColor =
        res.TemplateData.DynamicStyle.SecondaryColors.MenuCartColor;
      this.RecipePriceBorderColor =
        res.TemplateData.DynamicStyle.SecondaryColors.RecipePriceBorderColor;
      this.hotCategoryStyle = res.TemplateData.DynamicStyle.HotCategoryStyle;
      this.offers = res.TemplateData?.Offer;
    });
  }

  getCategories() {
    this.recipeServiceSub = this.recipeService.data$.subscribe((res) => {
      if (!res) return;

      this.brandCategpriesBackup = res.Categories;
      this.completeRecipeList = res.CategoriesAndRecipes;
      if (this.deliveryType == 'Home') {
        this.categories = res.Categories.filter(
          (category) => category.ForDelivery
        );
        this.recipies = [];
        this.completeRecipeList.forEach((category) => {
          let tempRecipesArray: Recipe[] = [];
          let tempCategory: Interfaces.CategoryWithRecipes;
          if (category.ForDelivery) {
            tempRecipesArray = category.Recipes.filter(
              (recipe) => recipe.ForDelivery
            );
            tempCategory = { ...category, Recipes: tempRecipesArray };
            this.recipies.push(tempCategory);
          }
        });
      }
      if (this.deliveryType == 'Store') {
        this.categories = res.Categories.filter(
          (category) => category.ForCollection
        );
        this.recipies = [];
        this.completeRecipeList.forEach((category) => {
          let tempRecipesArray: Recipe[] = [];
          let tempCategory: Interfaces.CategoryWithRecipes;
          if (category.ForCollection) {
            tempRecipesArray = category.Recipes.filter(
              (recipe) => recipe.ForCollection
            );
            tempCategory = { ...category, Recipes: tempRecipesArray };
            this.recipies.push(tempCategory);
          }
        });
      }

      if (!this.deliveryType) {
        this.categories = this.brandCategpriesBackup;
        this.recipies = this.completeRecipeList;
      }

      this.selectedCategory = this.categories[0].CategoryId;
      if (this.windowWidth >= 768) {
        setTimeout(() => {
          this.getRecipies(this.categories[0]);
        }, 1);
      }
      this.spinner.hide();
    });
  }

  getRecipies(category: any) {
    if (this.showOffers) {
      this.showOffers = false;
    }
    setTimeout(() => {
      document.body.removeEventListener('scroll', this.scrollFunc);
      this.selectedCategory = category.CategoryId;
      let divId = category.CategoryId.toString();
      document.getElementById(divId)?.scrollIntoView();
      document.body.addEventListener('scroll', this.scrollFunc);
    }, 1);
  }

  showHideCart() {
    document.getElementById('sideCart')?.scrollIntoView();
  }
  openCheckout() {
    this.recomendeditems.showRecomendations.next();
  }

  getCartTotal() {
    let recipeTotal = 0;
    let cartRecipiItems = JSON.parse(this.local.get('cartRecipeItems'));
    if (cartRecipiItems?.length) {
      cartRecipiItems.forEach((recipe) => {
        recipeTotal += recipe.recipeTotal;
      });
    }
    this.cartTotal = recipeTotal;
  }
  scrollToTop() {
    document.body.scrollTop = 0;
  }

  changeDeliveryType(type: string) {
    this.local.set('deliveryType', type);
    this.requestService.deliveryType$.next(type);
  }

  checkPostCodeValidity(val: string) {
    if (val == null) {
      this.InvalidPostCode = false;
      this.validPostCode = false;
      this.manualPostCode = '';
    } else {
      this.postSpinner = true;
      const cartProductItems = this.requestService.getProductCartItems();
      const cartRecipeItems = this.requestService.getCartRecipeItems();

      val = val.toUpperCase();
      const params = {
        BrandId: this.local.get('BrandId'), // Parent Brand Id.
        Postcode: val, // Customer's entered postcode.
        ProductOrderDetail: cartProductItems,
        RecipeOrderDetail: cartRecipeItems,
      };
      this.requestService
        .postRequest('api/CustomerAddress/checkPostcodeValidity', params, [])
        .subscribe((res) => {
          if (res['Info']['Status'] == 200) {
            this.requestService.postCode = this.manualPostCode;
            this.validPostCode = true;
            this.InvalidPostCode = false;
            this.enteredPostCode = val;
            this.manualPostCode = null;
          } else {
            this.InvalidMessage = res['Info']['Message'];
            this.InvalidPostCode = true;
            this.validPostCode = false;
            this.manualPostCode = null;
            this.requestService.postCode = '';
          }
          this.postSpinner = false;
        });
    }
  }
  brandOffers() {
    document.body.removeEventListener('scroll', this.scrollFunc);
    setTimeout(() => {
      this.showOffers = !this.showOffers;
      if (this.showOffers) {
        this.selectedCategory = null;
      }
      if (!this.showOffers) {
        this.selectedCategory = this.categories[0].CategoryId;
      }
      this.scrollToTop();
      document.body.addEventListener('scroll', this.scrollFunc);
    }, 1);
  }

  ngOnDestroy(): void {
    this.app.setAllergensFooter(false);
    this.resizeSubscription$.unsubscribe();
    this.popupServiceSub.unsubscribe();
    this.checkoutBtnSub.unsubscribe();
    this.recipeServiceSub.unsubscribe();
    this.webCusSub.unsubscribe();
    this.brandConfigSub.unsubscribe();
  }
}
