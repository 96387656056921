<div *ngFor="let product of products">
  <div
    class="productBox"
    [ngStyle]="{ 'background-color': ItemBackgroundColor }"
  >
    <div class="d-flex justify-content-end">
      <div *ngIf="!deleteCart">
        <div
          (click)="addRemoveFavourite(product, true)"
          *ngIf="!product.IsFavourite"
          class="cursor-pointer"
        >
          <i
            class="far fa-heart width1-5rem height1-5rem"
            title="Add To Favourite List"
          ></i>
        </div>
        <div
          *ngIf="product.IsFavourite"
          (click)="addRemoveFavourite(product, false)"
          class="cursor-pointer"
        >
          <i
            class="fa fa-heart text-danger width1-5rem height1-5rem"
            title="Remove From Favourite List"
          ></i>
        </div>
      </div>
      <div
        *ngIf="deleteCart"
        (click)="RemoveFavourite(product)"
        class="cursor-pointer"
      >
        <i
          class="fa fa-trash text-danger width1-5rem height1-5rem"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <p class="productTitle" [ngStyle]="{ color: ItemTitleColor }">
      <strong>{{ product?.ProductTitle }}</strong>
    </p>
    <div class="d-flex justify-content-between align-items-end">
      <div class="w-35 br-12px">
        <img
          *ngIf="product?.ProductImageUrl"
          src="{{ product?.ProductImageUrl }}"
          alt="product-img"
          class="br-12px"
        />
      </div>
      <div
        class="w-35 priceBtn d-flex flex-column justify-content-between align-items-center"
      >
        <p class="text-center" *ngIf="product?.ProductOldPrice">
          Price:
          <strong>
            {{ product?.ProductOldPrice | currency: "GBP":"symbol":"1.2-2" }}/{{
              product?.ProductUnits
            }}
          </strong>
        </p>
        <p class="text-danger text-center" *ngIf="!product?.ProductStocks">
          <strong>Out of Stock</strong>
        </p>
        <button
          class="btn btn-success w-100 br-40px"
          *ngIf="!product.ProductQuantity"
          [disabled]="!product?.ProductStocks"
          (click)="getProductModal(product)"
          [ngStyle]="{
            'border-color': SelectedButtonColor,
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }"
        >
          Add To Cart
        </button>
        <div
          class="productQuantity"
          *ngIf="
            product.ProductQuantity &&
            (PopupOnKgItems
              ? !(product.ProductUnits.toLowerCase() == 'kg')
              : true)
          "
        >
          <div class="product-quantity">
            <div class="input-group br-40px border w-auto flex-nowrap">
              <button
                class="btn z-index-0"
                (click)="quantityControl(product, '-')"
                type="button"
              >
                <i class="fas fa-minus"></i>
              </button>
              <span class="input-group-text m-0">{{
                product?.ProductQuantity
              }}</span>
              <button
                class="btn z-index-0"
                type="button"
                (click)="quantityControl(product, '+')"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            product.ProductQuantity &&
            (PopupOnKgItems
              ? product.ProductUnits.toLowerCase() == 'kg'
              : false)
          "
        >
          <select
            name="kgList"
            id="kgList"
            class="form-select br-40px"
            (change)="kgListChanged(product)"
            [(ngModel)]="product.ProductQuantity"
          >
            <option value="0.5">0.5kg</option>
            <option value="1.0">1.0kg</option>
            <option value="1.5">1.5kg</option>
            <option value="2.0">2.0kg</option>
            <option value="2.5">2.5kg</option>
            <option value="3.0">3.0kg</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
