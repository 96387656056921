<div
  class="border h-100 d-flex flex-column justify-content-between cursor-pointer"
  (click)="openDetails(product)"
>
  <div>
    <img
      src="{{ product?.ProductImageUrl }}"
      alt="{{ product?.ProductTitle }}"
      class="w-100"
      [ngClass]="{
        'd-none':
          product?.showSecondImage && product?.ProductImageConfig?.length
      }"
      *ngIf="product?.ProductImageUrl"
      (mouseenter)="productHover(product)"
    />
    <img
      *ngIf="
        product?.ProductImageConfig?.length && product?.ProductImageConfig[1]
      "
      src="{{ product?.ProductImageConfig[1] }}"
      alt="{{ product?.ProductTitle }}"
      class="w-100"
      [ngClass]="{ 'd-none': !product.showSecondImage }"
      (mouseleave)="productHover(product)"
    />
  </div>
  <div class="d-flex flex-column justify-content-between p-0 m-2 h-100">
    <div class="d-flex justify-content-between">
      <h2 class="m-0">{{ product?.ProductTitle }}</h2>
      <p class="ms-1 text-danger fs-5" *ngIf="product.ProductOldPrice">
        {{ product.ProductOldPrice | currency: "GBP":"symbol":"1.2-2" }}
      </p>
      <p class="ms-1 text-danger fs-5" *ngIf="!product.ProductOldPrice">
        {{ product.ProductDisplayAmountText }}
      </p>
    </div>
    <h3>{{ product?.ProductTagline }}</h3>
    <button
      class="btn w-100 btn-light br-40px"
      type="button"
      (click)="openDetails(product)"
      [ngStyle]="{
        'background-color': dynamicCss.SelectedButtonColor,
        color: dynamicCss.SelectedButtonTextColor
      }"
    >
      <i class="fa fa-shopping-basket" aria-hidden="true"></i>
      Buy
    </button>
  </div>
</div>
