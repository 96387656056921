import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Subscription } from 'rxjs';
import { Product } from 'src/Model/interface/productInterfaces';
import { Recipe } from 'src/Model/interface/productInterfaces';
import { popupService } from '../popup/popup.service';
import { ApiService } from '../service/api.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { UserService } from '../service/user.service';
import { InquiryPopupService } from '../store/inquiry-popup/inquiry-popup.service';
import { recomendationsService } from './recomendations.service';

@Component({
  selector: 'app-recomendations',
  templateUrl: './recomendations.component.html',
  styleUrls: ['./recomendations.component.scss'],
})
export class RecomendationsComponent implements OnInit, OnDestroy {
  brandFlavour: string;
  checkOutOff: boolean;
  ShowCheckOutOffMsg: string = '';
  recommendations;
  recomendedRecipies: Array<Recipe> = [];
  recomendedRecipiesBackup: Array<Recipe> = [];
  recomendedProducts: Array<Product> = [];
  recomendedProductsBackup: Array<Product> = [];
  SelectedButtonColor;
  SelectedButtonTextColor;
  deliveryTypeSub: Subscription;
  webCustomerSub: Subscription;
  addedToCartSub: Subscription;
  simpleCheckout: boolean;
  recomendationsSub: Subscription;
  configSub: Subscription;
  distributorLogin: boolean;
  recomendationsButtonText: string;
  constructor(
    private _requestService: RequestService,
    private recomendationsService: recomendationsService,
    private router: Router,
    private apiService: ApiService,
    private popupService: popupService,
    private _local: GetterService,
    private inquiryService: InquiryPopupService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.deliveryTypeSub = this._requestService.deliveryType$.subscribe(
      (type) => {
        this.getRecommendedRecipes();
      }
    );
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getRecommendedRecipes();
    this.recomendationsSub =
      this.recomendationsService.showRecomendations.subscribe(() => {
        if (!this.checkOutOff) {
          this.ShowCheckOutOffMsg = 'We are not currently taking any orders';
          return;
        }
        const component = window.location.pathname.split('/')[2];
        if (component == 'store' && !this._local.get('access_token')) {
          this.inquiryService.showPopup();
          return;
        }
        let cartRecipeItems = JSON.parse(this._local.get('cartRecipeItems'));
        let cartProductItems = JSON.parse(this._local.get('cartProductItems'));
        let recomendedRecipies = this.recomendedRecipies;
        let recomendedProducts = this.recomendedProducts;
        cartRecipeItems?.forEach((element) => {
          recomendedRecipies?.forEach((recipe, index) => {
            if (recipe != null) {
              if (
                recipe.RecipeId == element.RecipeId ||
                recipe.RecipeTitle == element.RecipeTitle
              ) {
                recomendedRecipies[index] = null;
              }
            }
          });
        });
        cartProductItems?.forEach((element) => {
          recomendedProducts?.forEach((product, index) => {
            if (product != null) {
              if (
                product.ProductId == element.ProductId ||
                product.ProductTitle == element.ProductTitle
              ) {
                recomendedProducts[index] = null;
              }
            }
          });
        });
        this.recomendedRecipies = [];
        this.recomendedProducts = [];
        recomendedRecipies?.forEach((recipe, index) => {
          if (recipe != null) {
            this.recomendedRecipies.push(recipe);
          }
        });
        recomendedProducts?.forEach((product, index) => {
          if (product != null) {
            this.recomendedProducts.push(product);
          }
        });
        if (this.recomendedRecipies.length || this.recomendedProducts.length) {
          this.recommendations = true;
          return;
        }
        if (
          !this.recomendedRecipies.length ||
          !this.recomendedProducts.length
        ) {
          this.openUrl('checkout');
        }
      });
    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.recomendationsButtonText =
        res.TemplateData.CheckoutButtonsText?.RecomendationsButtonText;
      this.simpleCheckout = res.TemplateData?.CheckoutDetail?.simpleCheckout;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
    });
    this.configSub = this.apiService.getbrandConfig$.subscribe((res) => {
      if (!res) return;
      this.checkOutOff = res.Active;
    });
    this.addedToCartSub = this.popupService.addedToCartSuccessful.subscribe(
      (data) => {
        if (this.recommendations) {
          if (
            this.recomendedRecipies.length == 1 ||
            this.recomendedProducts.length == 1
          ) {
            this.openUrl('checkout');
            return;
          }
          if (this.recomendedRecipies.length) {
            this.recomendedRecipies.forEach((recipe, index) => {
              if (recipe.RecipeId == data.RecipeId) {
                this.recomendedRecipies.splice(index, 1);
              }
            });
          } else {
            this.recomendedProducts.forEach((product, index) => {
              if (product.ProductId == data.ProductId) {
                this.recomendedProducts.splice(index, 1);
              }
            });
          }
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.deliveryTypeSub) {
      this.deliveryTypeSub.unsubscribe();
    }
    this.webCustomerSub.unsubscribe();
    this.addedToCartSub.unsubscribe();
    this.recomendationsSub.unsubscribe();
    this.configSub.unsubscribe();
  }
  getRecommendedRecipes() {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let url = 'api/Recipe/RecommendedItems/' + this.brandFlavour;
    if (this._local.get('deliveryType')) {
      let type = this._local.get('deliveryType');
      url = url + '/' + (type == 'Home' ? 'Delivery' : 'Pickup');
    }
    this._requestService.getRequest(url, httpHeaders).subscribe((res) => {
      if (res['Info'].Status == 200) {
        this.recomendedRecipies = res['RecipesData'];
        this.recomendedProducts = res['ProductsData'];
        this.recomendedProductsBackup = [];
        this.recomendedRecipiesBackup = [];
        this.recomendedRecipiesBackup = [...this.recomendedRecipies];
        this.recomendedProductsBackup = [...this.recomendedProducts];
      }
    });
  }
  openUrl(url: string) {
    const component = window.location.pathname.split('/')[2];
    if (this.simpleCheckout && component !== 'store') {
      url = 'order-checkout';
    }
    if (component == 'training') {
      url = 'checkout?training=true';
    }
    this.router.navigateByUrl(this._requestService.setUrl(url));
    this.recommendations = false;
    this.recomendedRecipies = [];
    this.recomendedProducts = [];
    this.recomendedRecipies = [...this.recomendedRecipiesBackup];
    this.recomendedProducts = [...this.recomendedProductsBackup];
  }
}
