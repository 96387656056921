import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/Model/interface/productInterfaces';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';
import { ProductCardService } from '../cards/product/product-card.service';
import { GetterService } from '../service/getter-service.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
})
export class TrainingComponent implements OnInit {
  products: Product[] = [];

  constructor(
    private _requestService: RequestService,
    private apiService: ApiService,
    private router: Router,
    private productCardService: ProductCardService,
    private _local: GetterService
  ) {}

  ngOnInit(): void {
    this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.getSingelCategoryProducts(res.TemplateData.EventCategoryList[0]);
    });
  }
  getSingelCategoryProducts(catID: number) {
    const brandFlvaor = window.location.pathname.split('/')[1];
    this._requestService
      .getRequest(
        `api/Product/getProductsByCategoryId/${brandFlvaor}/${catID}`,
        {}
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.products.push(...res['CategoriesAndProducts'].Products);
          }
        },
      });
  }
  addToFav(product: Product, event: MouseEvent) {
    event.stopPropagation();
    if (!this._local.get('CustomerId')) {
      this.router.navigate([this._requestService.setUrl(`login`)]);
      return;
    }
    this.productCardService.addRemoveFavs(product, true);
  }
  removeFav(product: Product, event: MouseEvent) {
    event.stopPropagation();
    this.productCardService.addRemoveFavs(product, false);
  }
  openURL(productID: number) {
    this.router.navigate(
      [this._requestService.setUrl(`products/product-details/${productID}`)],
      { queryParams: { training: true } }
    );
  }
}
