import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { SubcatDetailsService } from './subcat-details.service';

@Component({
  selector: 'app-subcat-details',
  templateUrl: './subcat-details.component.html',
  styleUrls: ['./subcat-details.component.scss'],
})
export class SubcatDetailsComponent implements OnInit {
  showPopup: boolean;
  @ViewChild('body', { static: false }) body: ElementRef;
  title: string;
  constructor(
    private subcat: SubcatDetailsService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subcat.showDetail$.subscribe((res) => {
      this.showPopup = true;
      setTimeout(() => {
        this.title = res.SubcategoryTitle
        this.renderer.setProperty(
          this.body.nativeElement,
          'innerHTML',
          res.SubcategoryDescription
        );
      }, 1);
    });
  }

  closePopup() {
    this.showPopup = false;
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }
}
