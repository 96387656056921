import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import {
  CategoryWithProducts,
  Product,
} from 'src/Model/interface/productInterfaces';
import { HomePageTemplate } from 'src/Model/interface/WebCustomer';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';
import { ProductsService } from './products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  fetchingProducts: boolean;
  catAndProducts: CategoryWithProducts[] = [];
  homePageTemplate: HomePageTemplate[] = [];
  sectionProducts: Product[] = [];
  webCustomerSub: Subscription;
  catProductsSub: Subscription;
  sideCatSub: Subscription;
  EventCategoryList: number[];
  constructor(
    private productsService: ProductsService,
    private _requestService: RequestService,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.getWebCustomer();
    this.sideCatSub = this._requestService.sideCatChanged.subscribe((data) => {
      const element = document.getElementById(`${data.cat.CategoryId}`);
      if (element) {
        element.scrollIntoView();
      }
    });
  }
  ngOnDestroy(): void {
    this.webCustomerSub.unsubscribe();
    this.catProductsSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCustomerSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) return;
      this.EventCategoryList = res.TemplateData?.EventCategoryList;
      this.homePageTemplate = res.TemplateData.HomePageTemplate;
      this.homePageTemplate.forEach((data) => {
        data.Categories.forEach((cat) => {
          this.getSingelCategoryProducts(+cat.ID);
        });
      });
      this.getProducts();
    });
  }
  getProducts() {
    this.fetchingProducts = true;
    this.catProductsSub = this.productsService.catAndProducts.subscribe({
      next: (catAndProducts) => {
        if (!catAndProducts.length) return;
        this.spinner.hide();
        this.catAndProducts = catAndProducts;
        this.catAndProducts.forEach((category) => {
          category.Products.forEach((product) => {
            if (product?.ProductImageConfig) {
              if (typeof product?.ProductImageConfig == 'string') {
                let array = JSON.parse(product?.ProductImageConfig);
                let temp: string[] = [];
                array?.Images?.forEach((img) => {
                  temp.push(img.Image);
                });
                product.ProductImageConfig = temp;
                product.showSecondImage = false;
              }
            } else {
              product.ProductImageConfig = [];
            }
          });
        });
      },
    });
  }
  getSingelCategoryProducts(catID: number) {
    const brandFlvaor = window.location.pathname.split('/')[1];
    this._requestService
      .getRequest(
        `api/Product/getProductsByCategoryId/${brandFlvaor}/${catID}`,
        {}
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.sectionProducts.push(...res['CategoriesAndProducts'].Products);
          }
        },
      });
  }
  openSubCategory(category) {
    this.router.navigateByUrl(
      this._requestService.setUrl(
        `products/${category.Title}/${category.ID}/sub-categories`
      )
    );
  }

  preventDuplication(category: CategoryWithProducts) {
    let result = true;
    this.homePageTemplate?.forEach((section) => {
      section.Categories.forEach((cat) => {
        if (+cat.ID == category.CategoryId) {
          result = false;
        }
      });
    });
    this.EventCategoryList?.forEach((cat) => {
      if (+cat == category.CategoryId) {
        result = false;
      }
    });

    return result;
  }
}
