import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Category, SubCategory } from 'src/Model/interface/productInterfaces';
import { SubcatDetailsService } from '../subcat-details/subcat-details.service';
interface ShowProductsEvent {
  category: Category;
  subCategory: SubCategory;
}
@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss'],
})
export class SubCategoriesComponent implements OnInit {
  @Input() subCategories: SubCategory[] = [];
  @Input() category: Category;
  @Output() showProductsEvent = new EventEmitter<ShowProductsEvent>();
  constructor(private details: SubcatDetailsService) {}

  ngOnInit(): void {}

  showProducts(subCategory: SubCategory) {
    const data: ShowProductsEvent = {
      category: this.category,
      subCategory: subCategory,
    };
    this.showProductsEvent.emit(data);
  }

  showDetails(subcat: SubCategory) {

    this.details.showDetails(subcat);
  }
}
