import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import {
  Category,
  Product,
  SubCategory,
} from 'src/Model/interface/productInterfaces';
import { ApiService } from '../service/api.service';
import { DynamicColorsService } from '../service/dynamic-colors.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
interface ShowProductsEvent {
  category: Category;
  subCategory: SubCategory;
}
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit, OnDestroy {
  brandFlavour: string;
  brand_Categories: Category[];
  selectedCategory: number;
  seletedSubcategory: number;
  products: Product[] = [];
  found: boolean;
  showProducts: boolean;
  subCategories: SubCategory[];
  category: Category;
  sideCatSub: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private _requestService: RequestService,
    private _local: GetterService,
    private apiService: ApiService,
    public dynamicCss: DynamicColorsService
  ) {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getCategories();
    this.sideCatSub = this._requestService.sideCatChanged.subscribe((data) => {
      if (data.subCat) {
        this.getProducts(data.cat, data.subCat);
        return;
      }
      this.selectCategory(data.cat);
    });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.sideCatSub.unsubscribe();
  }

  getCategories() {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this.spinner.show();
    this._requestService
      .getRequest(
        'api/Category/v1/getCategories/' + this.brandFlavour + '/Product',
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.brand_Categories = res['Data'];
            this.selectCategory(this.brand_Categories[0]);
          }
        },
        error: () => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  selectCategory(category: Category) {
    if (
      category.CategoryId == this.selectedCategory &&
      !category?.Subcategories?.length
    ) {
      return;
    }
    document.body.scrollTop = 0;
    this.selectedCategory = category.CategoryId;
    this.category = category;
    if (category.Subcategories.length) {
      this.subCategories = category.Subcategories;
      this.showProducts = false;
      this.seletedSubcategory = 0;
    } else {
      this.seletedSubcategory = 0;
      this.showProducts = true;
      this.getProducts(category, null);
    }
  }
  selectSubCategory(category: Category, subCat: SubCategory) {
    this.seletedSubcategory = subCat.SubcategoryId;
    this.getProducts(category, subCat);
  }
  getProducts(category: Category, subCat: SubCategory) {
    this.spinner.show();
    this.showProducts = true;
    let params = new HttpParams();
    params = params.append('BrandId', this._local.get('BrandId'));
    params = params.append('CategoryId', category.CategoryId);
    if (subCat) {
      params = params.append('SubcategoryId', subCat.SubcategoryId);
      this.seletedSubcategory = subCat.SubcategoryId;
    }
    
    if (JSON.parse(this._local.get('CustomerId'))) {
      params = params.append('FK_CustomerId', this._local.get('CustomerId'));
    }
    this._requestService
      .postRequest('api/Product/getProducts', params, [])
      .subscribe({
        next: (res) => {
          document.body.scrollTop = 0;
          if (res['Info']['Status'] == 200) {
            this.products = res['Data'];
            this.found = true;
          } else if (res['Info']['Status'] == 400) {
            this.found = false;
            this.products = [];
          }
        },
        error: () => {
          this.found = false;
          this.products = [];
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  showProductsEvent(data: ShowProductsEvent) {
    this.selectSubCategory(data.category, data.subCategory);
  }
  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
