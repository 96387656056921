<div class="container p-3">
    <h1 class="text-center">Terms & Conditions</h1>
    <p>By using the {{BrandName}} Mobile and Web application you as a user acknowledge and agree to the following
        terms:<br>
        The content of this web site in its entirety is subject to change without notice. Its purpose is for your
        general information only.<br>
        No parties guarantee the accuracy, timeliness, performance, completeness, or suitability of the content and
        information found on this site. You acknowledge that errors or inaccuracies may exist, and that the owners of
        the site are in no way liable for any such errors.<br>
        The owners of the site are not responsible and hold no liability for third-party content that may be posted on
        the site by end users, or for content that is linked to from this web site including other web sites.<br>
        The use of and viewing of information on this site is at your own risk. Any consequences of use that may occur
        are not the liability of the web site owners.<br>
        Unauthorized use of this web site or its contents may give rise to a claim for damages and/or be a criminal
        offense enforceable by local and international law.<br>
        We reserve the right to restrict access to certain areas of the web site at our own discretion. If a username
        and password, or any other access credentials, are provided to you as the end user, it is your responsibility to
        keep such information confidential.<br>
        The owners of the web site are not responsible for user-generated content, and no liable for any violations that
        such content may constitute<br>
    </p>
    <h2>1. Fair and Responsible use</h2>
    <p>
        <b>1.1</b> The Smartphone and Web application is provided by {{BrandName}} for personal use by you, and may not
        be used
        for commercial purposes.<br>
        <b>1.2</b> Users are required to keep their contact details up to date.<br>
        <b>1.3</b> Users must keep their login and password details confidential.<br>
        <b>1.4</b> Users agree not to interfere or tamper with any {{BrandName}} application or its related internet
        sites.
    </p>
    <h2>2. Refund & Returns Policy</h2>
    <p>
        Generally, there is no refund on food. Occasionally there may be an issue which a customer may wish to request a
        full refund. This refund is at the discretion of the brand owner and the customer must first contact brand owner
        and we will review each case on an individual basis and any refund agreed, will be processed within 5 days.
    </p>
    <h2> 3. Cancellations</h2>
    <p>
        You may cancel your order up to one hour before your selected delivery/collection time. If the
        delivery/collection time is less than one hour, the order cannot be cancelled. To cancel your order, please call
        us.
    </p>
    <h2> 4. Right of refusal</h2>
    <p>
        <b>4.1</b> Conditions of the offer are provided by the named business on the phone application. The named
        business is
        able to vary its offer and ultimately has the final right of refusal on the provision of the specified
        reward.<br>
        <b>4.2</b> {{BrandName}} reserves the right to modify or alter the terms and conditions at any time
    </p>
    <h2>5. Information about you</h2>
    <p>

        <b>5.1</b> You consent to us using the application to collect information about you. In its most basic form, we
        require
        your name and a current email address to activate an account with {{BrandName}}. Any additional personal
        information you provide to us will be used for the purposes of providing you with relevant discounts and offers
        for goods and services or relevant information regarding the {{BrandName}} Loyalty service.<br>
        <b>5.2</b> We will not sell or pass on your personal information to a third party other than merchants,
        businesses, and
        {{BrandName}} partners.


    </p>
    <h2> 6. Copyright</h2>
    <p>
        The content in its entirety, including text content, graphics, layouts, and all source code, belong to the
        owners of the web site. This term is protected by intellectual property rights and copyright law. Copying,
        redistribution, use or publication either for free or for monetary gain is strictly prohibited. Some of the
        content on the site is the copyrighted and licensed work of third parties.
    </p>
</div>