import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
  Input,
  EventEmitter,
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { ApiService } from '../service/api.service';
import { recomendationsService } from '../recomendations/recomendations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartRecipies } from '../../Model/interface/chckout-Interfaces';
declare let fbq: any;
@Component({
  selector: 'app-side-cart',
  templateUrl: './side-cart.component.html',
  styleUrls: ['./side-cart.component.scss'],
})
export class SideCartComponent implements OnInit, OnDestroy {
  cartProductItems: any[];
  cartRecipeItems: CartRecipies[];
  cartRecipeItemsextra = [];
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor;
  training: boolean;
  sideCartButtonText: string;

  @Output() updateCart = new Subject<any>();
  @Output() updateKgList = new Subject<any>();
  @Output() checkout = new EventEmitter();
  storagesubscription: Subscription;
  brandFlavour: string;
  PopupOnKgItems: boolean;

  hotCatogries: any[] = [];
  windowWidth;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  total: number;
  webCusSub: Subscription;

  constructor(
    private _local: GetterService,
    public _requestService: RequestService,
    private _ref: ChangeDetectorRef,
    private apiService: ApiService,
    private recomendationsService: recomendationsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.training = this.route.snapshot.queryParams['training'] == 'true';
  }

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.windowWidth = document.body.clientWidth;
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.windowWidth = document.body.clientWidth;
    });
    this.cartProductItems = [];
    this.cartRecipeItems = [];
    this.fetchCartItems();
    this.storagesubscription = this._requestService.storageChange.subscribe(
      () => {
        this.fetchCartItems();
        this._ref.detectChanges();
      }
    );
    this.getWebCustomer();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.hotCatogries = res.TemplateData?.HotCategories;
      this.PopupOnKgItems = res.TemplateData?.AdditionalDetail?.PopupOnKgItems;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.sideCartButtonText =
        res.TemplateData.CheckoutButtonsText?.SideCartButtonText;
    });
  }
  fetchCartItems() {
    this.cartRecipeItems = this._requestService.getRecipiesItemsForSideCart();
    this.cartProductItems = this._requestService.getProductItemsForSideCart();
    this.cartProductItems.forEach((value) => {
      if (value.ProductQuantity == 0) {
        this.removeProductItem(value);
      }
    });

    this.getCartTotal();
  }

  cartRecipeUpdation(flag, item) {
    if (flag == '-') {
      if (item.RecipeQuantity == 1) {
        this.cartRecipeItems.splice(this.cartRecipeItems.indexOf(item), 1);
      } else {
        let tmp_total = item.recipeTotal / item.RecipeQuantity;
        item.recipeTotal = item.recipeTotal - tmp_total;
        item.RecipeQuantity = item.RecipeQuantity - 1;
      }
    } else {
      let tmp_total = item.recipeTotal / item.RecipeQuantity;
      item.recipeTotal = item.recipeTotal + tmp_total;
      item.RecipeQuantity = item.RecipeQuantity + 1;
    }

    this._local.set('cartRecipeItems', JSON.stringify(this.cartRecipeItems));
    this.getCartTotal();
    this._requestService.cartCounter = this.cartRecipeItems.length;
    var data = {
      item: item,
      flag: flag,
    };
    this.updateCart.next(data);
  }
  cartProductUpdation(flag, item) {
    if (flag == '-') {
      if (item.ProductQuantity == 1) {
        item.ProductQuantity = 0;
        this.cartProductItems.splice(this.cartProductItems.indexOf(item), 1);
      } else {
        let tmp_total = item.ProductTotal / item.ProductQuantity;
        item.ProductTotal = item.ProductTotal - tmp_total;
        item.ProductQuantity = item.ProductQuantity - 1;
      }
    } else {
      let tmp_total = item.ProductTotal / item.ProductQuantity;
      item.ProductTotal = item.ProductTotal + tmp_total;
      item.ProductQuantity = item.ProductQuantity + 1;
    }

    this._local.set('cartProductItems', JSON.stringify(this.cartProductItems));
    this.getCartTotal();
    this._requestService.cartCounter = this.cartProductItems.length;
    var data = {
      item: item,
      flag: flag,
    };
    this.updateCart.next(data);
  }
  kgListChanged(product) {
    let cart = JSON.parse(this._local.get('cartProductItems'));
    cart?.forEach((element) => {
      if (element.ProductId == product.ProductId) {
        element.ProductQuantity = product.ProductQuantity;
        element.ProductTotal =
          element.ProductQuantity * element.ProductOldPrice;
      }
    });
    this._local.set('cartProductItems', JSON.stringify(cart));
    this._requestService.storageChange.next();
    this.updateKgList.next(product);
    this.getCartTotal();
  }
  getCartTotal() {
    let productTotal = 0;
    let recipeTotal = 0;

    if (this.cartProductItems.length != 0) {
      this.cartProductItems.forEach((val) => {
        productTotal = productTotal + val.ProductTotal;
      });
    }

    if (this.cartRecipeItems.length != 0) {
      this.cartRecipeItems.forEach((val) => {
        recipeTotal = recipeTotal + val.recipeTotal;
      });
    }

    this.total = productTotal + recipeTotal;
  }
  callCheckout() {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'Checkout', {
        currency: 'GBP',
        value: this.getCartTotal(),
        items: this.cartRecipeItems,
      });
    }
    if (this.training) {
      this.router.navigate([this._requestService.setUrl('checkout')], {
        queryParams: { training: true },
      });
      return;
    }
    this.recomendationsService.showRecomendations.next(null);
  }
  removeRecipeItem(item) {
    this.cartRecipeItems.splice(this.cartRecipeItems.indexOf(item), 1);
    this._local.set('cartRecipeItems', JSON.stringify(this.cartRecipeItems));
    this._requestService.cartCounter = this.cartRecipeItems.length;
    var data = {
      item: item,
      flag: 'removed',
    };
    this.getCartTotal();
    this.updateCart.next(data);
  }
  removeProductItem(item) {
    this.cartProductItems.splice(this.cartProductItems.indexOf(item), 1);
    this._local.set('cartProductItems', JSON.stringify(this.cartProductItems));
    this._requestService.cartCounter = this.cartProductItems.length;
    var data = {
      item: item,
      flag: 'removed',
    };
    this.updateCart.next(data);
    this.getCartTotal();
  }

  ngOnDestroy() {
    this.storagesubscription.unsubscribe();
    this.webCusSub.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }
}
