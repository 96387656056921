 <!--post code validation -->
 <div class="overlay" *ngIf="showpopup">
  <div class="popup">
  <div class="mb-3 postCodeValidation">
    <div class="popup-header">
      <h2 class="modalHeading">Enter Delivery Postcode</h2>
      <button type="button" class="close" (click)="closeModel()">&times;</button>
    </div> 
    <div class="postCodeWarapper">
      <div class="p-relative">
        <input name="manualCode" type="text" class="form-control post-code postCodeInputField" placeholder="Enter Post Code"
        (keyup.enter)="checkPostCodeValidity(manualPostCode)" maxlength="10" [(ngModel)]="manualPostCode"
        ngModel />
        <span class="spinner-border text-success" *ngIf="postSpinner"></span>
      </div>
    </div>
    <div style=" margin-top: 3%;
    text-align: center;
    align-items: center;">
      <div *ngIf="!validPostCode">
        <button class="btn checkBtn" [disabled]="!manualPostCode?.length"
        [ngStyle]="{'background-color':SelectedButtonColor,'color':SelectedButtonTextColor}" type="button"
        (click)='checkPostCodeValidity(manualPostCode)'>Check Delivery</button>
      </div>
      <div *ngIf="validPostCode">
        <button class="btn checkBtn" 
        [ngStyle]="{'background-color':SelectedButtonColor,'color':SelectedButtonTextColor}" type="button"
        (click)='bookdelivery()'>Book Delivery</button>
        <button class="btn checkBtn" id="signup-btn" (click)="redirecturl()" type="button">Sign Up</button>
      </div>
    </div>
    <div style="margin-top: 10px;margin-left:6px; text-align: center;">
      <p class="text-success" *ngIf="validPostCode">We are delivering to <b>{{ enteredPostCode
          }}</b></p>
      <p class="text-danger" *ngIf="InvalidPostCode"><b>{{ InvalidMessage }}</b></p>
    </div>
   
    <div class="btnContainer">
      
      
      <!-- <button class="btn btn-dark clearBtn" type="button" (click)='checkPostCodeValidity(null)'
        *ngIf="manualPostCode?.length">Clear</button> -->
    </div>
  </div>
</div>
  </div>

  <!--postcode validation end-->