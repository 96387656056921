<div class="stripe bg-light p-3 br-10px" (click)="preventPropagation($event)">
  <h2 class="text-center">{{ brandName }}</h2>
  <form id="payment-form">
    <label class="form-label" for="card-element"> Card details </label>
    <!-- placeholder for Elements -->
    <div id="cardNumber" class="form-control"></div>
    <div id="cardExpiry" class="form-control mt-2"></div>
    <div id="cardCvc" class="form-control mt-2"></div>
    <button
      type="submit"
      class="btn w-100 br-40px mt-3"
      [ngStyle]="{
        color: SelectedButtonTextColor,
        'background-color': SelectedButtonColor
      }"
      [disabled]="spinner"
    >
      Pay
      <span *ngIf="total">{{ total | currency: "GBP":"symbol":"1.2-2" }}</span>
      <span
        class="spinner-border spinner-border-sm ms-2"
        *ngIf="spinner"
      ></span>
    </button>
  </form>
</div>
<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showMessage"
>
  <div
    class="content bg-light d-flex justify-content-between align-items-center flex-column overflow-hidden"
  >
    <p class="fs-5 text-center p-1">{{ message }}</p>
    <button
      (click)="showMessage = false; preventPropagation($event)"
      class="closeBtn w-100 text-dark text-center fs-6 p-2"
    >
      Close
    </button>
  </div>
</div>
