import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {
  BrandName
  webCustomer: Subscription
  constructor(
    private apiService: ApiService
  ) { }
  ngOnInit(): void {
    this.webCustomer = this.apiService.getWebcustomer$.subscribe(res => {
      if (!res) {
        return;
      }
      this.BrandName = res.BrandData.BrandName;
    })
  }
  ngOnDestroy() {
    this.webCustomer.unsubscribe()
  }
}
