<section class="ex-collection mt-4" style="padding-bottom: 10px" id="lol">
	<div class="row">
		<div class="col-md-6 reward-main-card">
			<div class="ex-collection-box mb-xl-20">
				<img
					src="{{ loyaltyConfig?.CardImage }}"
					class="img-fluid reward-main-card-img"
					alt="image"
				/>
			</div>
		</div>
	</div>
</section>
<div class="mb-3 mt-3 d-flex justify-content-center">
	<button type="button" class="reward-join-btn" (click)="joinCard()">
		Join
	</button>
</div>
<div class="container d-flex justify-content-center align-items-center mb-4">
	<div class="leftArrow me-1" (click)="sliderLeft()">
		<i class="fas fa-arrow-left"></i>
	</div>
	<div class="customSliderWrapper" id="joinSlider">
		<div class="swiperContainer" *ngFor="let reward of readableRewards">
			<img src="{{ reward?.Image }}" class="swiperImage" alt="rewards" />
			<span class="text-light-black cat-name"
				><b>{{ reward?.Title }}</b></span
			>
		</div>
	</div>
	<div class="rightArrow ms-1" (click)="sliderRight()">
		<i class="fas fa-arrow-right"></i>
	</div>
</div>

<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
