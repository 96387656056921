import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetterService } from 'src/app/service/getter-service.service';
import { RequestService } from 'src/app/service/request.service';
import { Product } from 'src/Model/interface/productInterfaces';

@Component({
  selector: 'app-subcategory-products',
  templateUrl: './subcategory-products.component.html',
  styleUrls: ['./subcategory-products.component.scss'],
})
export class SubcategoryProductsComponent implements OnInit {
  products: Product[] = [];
  selectedImage: string;
  categoryTitle: string;
  subCategoryTitle: string;
  catID: number;
  constructor(
    private route: ActivatedRoute,
    private _local: GetterService,
    private _requestService: RequestService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.catID = this.route.snapshot.params['catID'];
    const subCatId = this.route.snapshot.params['subCatID'];
    this.subCategoryTitle = this.route.snapshot.params['subCatTitle'];
    this.categoryTitle = this.route.snapshot.params['catTitle'];
    this.getProducts(this.catID, subCatId);
  }

  ngOnInit(): void {}
  getProducts(categoryId, subcategoryId) {
    this.spinner.show();
    let params = new HttpParams();
    params = params.append('BrandId', this._local.get('BrandId'));
    params = params.append('CategoryId', categoryId);
    params = params.append('SubcategoryId', subcategoryId);
    params = params.append('Offset', 0);
    params = params.append('Limit', 100);
    if (JSON.parse(this._local.get('CustomerId'))) {
      params = params.append('FK_CustomerId', this._local.get('CustomerId'));
    }
    this._requestService
      .postRequest('api/Product/getProducts', params, [])
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this.products = res['Data'];
            this.products.forEach((product) => {
              if (
                product?.ProductImageConfig &&
                typeof product?.ProductImageConfig == 'string'
              ) {
                let temp: string[] = [];
                let array = JSON.parse(product?.ProductImageConfig);
                array.Images.forEach((img) => {
                  temp.push(img.Image);
                });
                product.ProductImageConfig = temp;
                product.showSecondImage = false;
              } else {
                product.ProductImageConfig = [];
              }
            });
          }
        },
        error: () => {
          this.spinner.hide();
          alert('Network Error! Please refresh page and try again');
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  goBack() {
    this.router.navigateByUrl(
      this._requestService.setUrl(
        'products/' + this.categoryTitle + '/' + this.catID + '/sub-categories'
      )
    );
  }
  openProducts() {
    this.router.navigateByUrl(this._requestService.setUrl('products'));
  }
}
