<div
	class="btnGroup br-10px d-flex d-md-none justify-content-around align-item-center flex-row position-sticky"
	[ngStyle]="{ 'background-color': SelectedButtonColor }"
>
	<p
		class="text-center m-0 pt-3 pb-3 fs-6"
		[ngStyle]="{ color: SelectedButtonTextColor }"
		(click)="goToCart()"
	>
		{{ _requestService.cartCounter }} Items
	</p>
	<span class="bg-light text-dark pt-3 pb-3">
		<p class="m-0 fs-6 ps-1 pe-1">
			Total: {{ cartTotal$ | async | currency: "GBP":"symbol":"1.2-2" }}
		</p>
	</span>
	<p
		class="text-center m-0 pt-3 pb-3 fs-6"
		(click)="openCheckout()"
		[ngStyle]="{ color: SelectedButtonTextColor }"
	>
		Checkout
	</p>
</div>
<div class="container-fluid mt-2">
	<div class="row">
		<div class="col-md-8">
			<app-product-grid-style
				[products]="favourites$ | async"
				[deleteCart]="true"
				(quantityChanged)="getCartTotal()"
				[showCategory]="true"
			>
			</app-product-grid-style>
			<ng-container *ngIf="favourites$ | async as favs">
				<ng-container *ngIf="!favs.length">
					<div class="notFound">
						<p class="text-center">No Favourites Found</p>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<div class="col-md-4 p-1">
			<div class="positionSticky">
				<app-side-cart
					id="checkoutButton"
					(updateCart)="updateCartfromSideCart($event)"
				>
				</app-side-cart>
			</div>
		</div>
	</div>
</div>

<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
