import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import * as CInterfaces from '../../Model/interface/chckout-Interfaces';
import { DatePipe } from '@angular/common';
import { Options } from 'src/Model/interface/brandConfigInterface';
export interface AddressData {
  addresses: string[];
  postCode: string;
  message: string;
  status: number;
}
export interface SlotsData {
  slots: string[];
  message: string;
  status: number;
}
@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private customerAddressesSubjet = new BehaviorSubject<
    CInterfaces.addressRadios[]
  >([]);
  customerDeliveryAddressesList$: Observable<CInterfaces.addressRadios[]> =
    this.customerAddressesSubjet.asObservable();
  getAddressesSub: Subscription;
  deliverySlotSub: Subscription;
  deliveryType: string;
  brandId: number;
  brandFlavour: string;
  PromotionUsageId: number = 0;
  PromotionId: number = 0;
  notes: string;
  EmailMarketingOrder: boolean;
  SmsMarketingOrder: boolean;
  tipAmount: number;
  vatCharges: number;
  cartTotal: number;
  deliveryCharges: number = 0;
  customerBalance: number = 0;
  address: string;
  postCode: string;
  asapSlot: boolean;
  DeliveryFrom: string;
  chosenDeliverySlot: string;
  date: Date;
  deliveryDayOptions: Options[] = [];
  collectionDayOptions: Options[] = [];
  changeTime: boolean;
  CollectionFrom: string;
  chosenCollectionSlot: string;
  email: string;
  cardID: string;
  guestCustomer: boolean;
  PaymentMethodId: string;
  paypalToken: any;
  orderId: number;
  constructor(
    private _local: GetterService,
    private _requestService: RequestService,
    private datePipe: DatePipe
  ) {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.brandId = +this._local.get('BrandId');
    this.getCustomerAddress();
  }

  getCustomerAddress() {
    if (!this._local.get('CustomerId')) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
    });
    this._requestService
      .getRequest(
        'api/CustomerAddress/get/' + this._local.get('CustomerId'),
        httpHeaders
      )
      .subscribe({
        next: (res: any) => {
          if (res.Info.Status === 200) {
            let addressRadios = [];
            const addresses = res.Data;
            addresses.forEach((address, i) => {
              if (i <= 4) {
                addressRadios.push({
                  Address: address.Address,
                  Postcode: address.Postcode,
                });
              }
            });
            this.customerAddressesSubjet.next(addresses);
          } else {
            this.customerAddressesSubjet.next([]);
          }
        },
        error: () => {
          this.customerAddressesSubjet.next([]);
        },
      });
  }

  fetchNewAddresses(val: string): Observable<AddressData> {
    let addresses$: Subject<AddressData> = new Subject<AddressData>();
    if (this.getAddressesSub) {
      this.getAddressesSub.unsubscribe();
    }
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
    });
    const cartProductItems = this._requestService.getProductCartItems();
    const cartRecipeItems = this._requestService.getCartRecipeItems();
    let CustomerId = 0;
    let email;
    let mobile;
    if (this._local.get('access_token')) {
      CustomerId = +this._local.get('CustomerId');
    }
    if (this._local.getSessionItem('access_token')) {
      email = this._local.getSessionItem('guestEmail');
      mobile = this._local.getSessionItem('guestContact');
    }
    val = val.toUpperCase();
    let brandId = this._local.get('BrandId');
    const params = {
      CustomerId: CustomerId,
      CustomerEmail: email,
      CustomerMobile: mobile,
      BrandId: brandId, // Parent Brand Id.
      Postcode: val, // Customer's entered postcode.
      ProductOrderDetail: cartProductItems,
      RecipeOrderDetail: cartRecipeItems,
    };
    this.getAddressesSub = this._requestService
      .postRequest('api/CustomerAddress/getAddress', params, httpHeaders)
      .subscribe((res) => {
        if (res['Info']['Status'] == 200) {
          let addressList = res['addresses'];
          let formattedAddressList = [];
          addressList?.forEach((val) => {
            const list = val.formatted_address;
            let formattedAddress = '';
            list.forEach((element) => {
              if (element.length != 0) {
                formattedAddress = formattedAddress + element + ' ';
              }
            });
            formattedAddressList.push(formattedAddress.trim());
          });
          let data: AddressData = {
            addresses: formattedAddressList,
            postCode: res['postcode'],
            message: 'Success',
            status: res['Info']['Status'],
          };
          addresses$.next(data);
        } else {
          let data: AddressData = {
            addresses: [],
            postCode: '',
            message: res['Info']['Message'],
            status: res['Info']['Status'],
          };
          addresses$.next(data);
        }
      });
    return addresses$;
  }

  // addAddressToList(data) {
  //   let addresses = this.customerAddressesSubjet.getValue();
  //   addresses.push(data)
  //   //this.customerAddressesSubjet.next(addresses);
  // }

  removeAddress(address) {
    if (!address.Id) {
      let addresses = this.customerAddressesSubjet.getValue();
      let index = addresses.findIndex(
        (addresses) => addresses.Address == address.Address
      );
      if (index >= 0) {
        addresses.splice(index, 1);
      }
      this.customerAddressesSubjet.next(addresses);
      return;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
    });
    this._requestService
      .deleteRequest('api/CustomerAddress/delete/' + address.Id, httpHeaders)
      .subscribe((res) => {
        if (res['Info']['Status'] === 200) {
          let addresses = this.customerAddressesSubjet.getValue();
          let index = addresses.findIndex(
            (addresses) => addresses.Address == address.Address
          );
          if (index >= 0) {
            addresses.splice(index, 1);
          }
          this.customerAddressesSubjet.next(addresses);
        }
      });
  }

  getSlotByDate(date: Date, postCode: string): Observable<SlotsData> {
    let slotsData: Subject<SlotsData> = new Subject<SlotsData>();
    if (this.deliveryType == 'Home' && !postCode) {
      return of(null);
    }
    if (this.deliverySlotSub) this.deliverySlotSub.unsubscribe();
    let dateTranformed = this.datePipe.transform(date, 'yyyy-MM-dd');
    const brandId = this._local.get('BrandId');
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let deliveryType = this.deliveryType == 'Home' ? 'Delivery' : 'Pickup';
    let param = {
      BrandId: brandId,
      SelectedDate: dateTranformed,
      OrderOption: deliveryType,
    };
    if (this.deliveryType == 'Home') {
      param['Postcode'] = postCode;
    }
    this.deliverySlotSub = this._requestService
      .postRequest(
        '/api/Order/getSlotsByDate',
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          let data: SlotsData = {
            slots: res['Info']['Status'] == 200 ? res['Data'] : [],
            message: res['Info']['Message'],
            status: res['Info']['Status'],
          };
          slotsData.next(data);
        },
        error: (error) => {
          let data: SlotsData = {
            slots: [],
            message: error,
            status: 200,
          };
          slotsData.next(data);
          alert(
            'Network Error! Please change date/Day and reselect it to try again'
          );
        },
      });
    return slotsData;
  }

  getDeliveryCharges(date: Date, postCode: string): Observable<number> {
    let charges: Subject<number> = new Subject<number>();
    if (!date && !postCode && this.deliveryType !== 'Home') {
      return null;
    }
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
    });
    let recipes = JSON.parse(this._local.get('cartRecipeItems'));
    let products = JSON.parse(this._local.get('cartProductItems'));
    let recipeSubtotal = 0;
    let productSubtotal = 0;
    recipes?.forEach((recipe) => {
      recipeSubtotal += recipe?.recipeTotal;
    });
    products?.forEach((product) => {
      productSubtotal += product.ProductTotal;
    });
    const CartTotal = recipeSubtotal + productSubtotal;
    if (this.deliveryType == 'Home' && postCode && CartTotal && date) {
      const dateTransformed = this.datePipe.transform(date, 'yyyy-MM-dd');
      const body = {
        Postcode: postCode,
        CartTotal: CartTotal?.toString(),
        SelectedDate: dateTransformed,
        ProductOrderDetail: this._requestService.getProductCartItems(),
        RecipeOrderDetail: this._requestService.getCartRecipeItems(),
      };
      this._requestService
        .postRequest(
          'api/Checkout/getDeliveryCharges/' + this._local.get('BrandId'),
          body,
          httpHeaders
        )
        .subscribe({
          next: (res) => {
            let amount = +res['DeliveryCharges'];
            charges.next(amount);
          },
          error: () => {
            charges.next(0);
          },
        });
    }
    return charges;
  }

  applyPromoCode(code, date: Date, postcode) {
    let params: any;
    const customerId = this._local.get('CustomerId');
    const CustomerEmail = this._local.getSessionItem('guestEmail');
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    let dateTransformed = this.datePipe.transform(date, 'yyyy-MM-dd');
    params = {
      PromoCode: code,
      FK_BrandId: this.brandId,
      FK_CustomerId: customerId,
      ProductOrderDetail: this._requestService.getProductCartItems(),
      RecipeOrderDetail: this._requestService.getCartRecipeItems(),
      SelectedDate: dateTransformed,
      OrderOption: this.deliveryType == 'Home' ? 'Delivery' : 'Pickup',
    };
    if (this.deliveryType == 'Home') {
      params['Postcode'] = postcode;
    }
    if (this.guestCustomer) {
      params['CustomerEmail'] = CustomerEmail;
      params['FK_CustomerId'] = 0;
    }
    return this._requestService.postRequest(
      'api/Promotion/ApplyPromoCode',
      JSON.stringify(params),
      httpHeaders
    );
  }

  getBalance() {
    if (!this._local.get('CustomerId')) {
      return of(null);
    }

    const brandId = this._local.get('BrandId');
    const customerId = this._local.get('CustomerId');
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    const products = this._requestService.getProductCartItems();
    const recipes = this._requestService.getCartRecipeItems();
    const body = {
      ProductOrderDetail: products,
      RecipeOrderDetail: recipes,
    };

    return this._requestService.postRequest(
      'api/Wallet/GetBalance/' + brandId + '/' + customerId,
      JSON.stringify(body),
      httpHeaders
    );
  }

  getDiscount(date: Date): Observable<any> {
    const products = this._requestService.getProductCartItems();
    const recipes = this._requestService.getCartRecipeItems();
    let params: any;
    let DiscountDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    const customerId = this._local.get('CustomerId');
    const CustomerEmail = this._local.getSessionItem('guestEmail');
    const brandId = this._local.get('BrandId');
    if (!brandId) return null;

    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    params = {
      BrandId: brandId,
      CustomerId: customerId,
      OrderOption: this.deliveryType == 'Home' ? 'Delivery' : 'Pickup',
      IsPriceless: false,
      SelectedDate: DiscountDate, //date//
      Notes: '', //this.notes,
      ProductOrderDetail: products,
      RecipeOrderDetail: recipes,
    };
    if (this.guestCustomer) {
      params['CustomerId'] = 0;
      params['CustomerEmail'] = CustomerEmail;
    }
    return this._requestService.postRequest(
      'api/Checkout/getCheckoutDiscountAmount/' + brandId,
      JSON.stringify(params),
      httpHeaders
    );
  }

  checkMarketingPreferences() {
    if (!this._local.get('CustomerId')) return null;
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    let customerId = this._local.get('CustomerId');
    return this._requestService.getRequest(
      `api/Customer/checkCustomerMarketingPreferences/${customerId}`,
      httpHeaders
    );
  }
  getMinimumSpendForPostCode(postCode: string): Observable<any> {
    if (!this.brandId || !postCode) {
      return of(null);
    }
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    return this._requestService.getRequest(
      'api/CustomerAddress/getPostcodeMinOrderAmount/' +
        this.brandId +
        '/' +
        postCode,
      httpHeaders
    );
  }

  getDiscountedItmes(): Observable<any> {
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');

    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });

    const deliveryOption = this.deliveryType == 'Home' ? 'Delivery' : 'Pickup';
    const params = {
      BrandFlavor: this.brandFlavour,
      FK_CustomerId: this._local.get('CustomerId'),
      PromoUsageId: 0,
      CustomerEmail: this._local.get('username'),
      OrderOption: deliveryOption,
      Postcode: null,
      SelectedDate: null,
      ProductOrderDetail: this._requestService.getProductCartItems(),
      RecipeOrderDetail: this._requestService.getCartRecipeItems(),
    };
    if (this.guestCustomer) {
      params['CustomerEmail'] = this._local.getSessionItem('guestEmail');
    }
    return this._requestService.postRequest(
      'api/CheckoutDiscount/GetDiscountedItemsList',
      params,
      httpHeaders
    );
  }

  checkPreOrder(): Observable<any> {
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    let type = 'Delivery';
    if (this.deliveryType == 'Store') {
      type = 'Pickup';
    }
    return this._requestService.getRequest(
      'api/Order/explicitPreOrderCheck/' + this.brandId + '/' + type,
      httpHeaders
    );
  }
  checkout(paymentType): Observable<any> {
    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: this.brandFlavour,
    });
    let body = this.getParams(paymentType);
    if (this.guestCustomer) {
      return this._requestService.postRequest(
        'api/Checkout/Guest/v1',
        JSON.stringify(body),
        httpHeaders
      );
    } else {
      return this._requestService.postRequest(
        'api/Checkout/v1/Web',
        JSON.stringify(body),
        httpHeaders
      );
    }
  }

  getParams(paymentType: string) {
    let body;
    const customerId: number = this._local.get('CustomerId')
      ? +this._local.get('CustomerId')
      : 0;
    /* Device Information */
    const deviceDetector = this._local.getDeviceInfo();
    const os = deviceDetector.os;
    const windows = os === 'Windows';
    const iOS = os === 'iOS';
    const app = JSON.parse(this._local.getSessionItem('app'));
    console.log(app);
    console.log(deviceDetector);

    let webSource = '';
    
    if (windows || iOS) {
      webSource = 'Website';
    }

    if (app) {
      webSource = os + ' ' + 'App';
    }


    body = {
      WebSource: webSource,
      OrderDetail: {
        PromotionUsageId: this.PromotionUsageId,
        PromotionId: this.PromotionId,
        BrandId: this.brandId,
        CustomerId: customerId,
        Notes: this.notes,
        IsPriceless: false,
        EmailMarketingOrder: this.EmailMarketingOrder,
        SmsMarketingOrder: this.SmsMarketingOrder,
        ProductOrderDetail: this._requestService.getProductCartItems(),
        RecipeOrderDetail: this._requestService.getCartRecipeItems(),
      },
      PaymentDetail: {
        DeliveryType: this.deliveryType,
        PaymentType: paymentType,
        TipAmount: this.tipAmount,
        VatAmount: this.vatCharges,
        Bill: this.cartTotal,
        PostageAndPackages: this.deliveryCharges,
        GiftAmountBill: this.customerBalance,
        CardPaymentAmount: 0,
        CashPaymentAmount: 0,
        PaymentRequested:
          this._local.getSessionItem('orderId') != 'undefined' ? true : false,
      },
    };

    if (this._local.getSessionItem('orderId') != 'undefined') {
      body['PaymentDetail']['AmendOrderId'] =
        this._local.getSessionItem('orderId');
    }

    if (this.guestCustomer) {
      body['OrderDetail']['CustomerId'] = 0;
      body['OrderDetail']['CustomerEmail'] =
        this._local.getSessionItem('guestEmail');
      body['OrderDetail']['CustomerFirstName'] =
        this._local.getSessionItem('guestFirstName');
      body['OrderDetail']['CustomerLastName'] =
        this._local.getSessionItem('guestLastName');
      body['OrderDetail']['CustomerMobile'] =
        this._local.getSessionItem('guestContact');
    }
    if (this.deliveryType == 'Home') {
      const DeliveryDetail = {
        Address: this.address,
        Postcode: this.postCode,
      };
      if (this.asapSlot) {
        DeliveryDetail['Schedule'] = this.DeliveryFrom
          ? this.chosenDeliverySlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : this.deliveryDayOptions[0].Title == 'Pick Date'
          ? this.chosenDeliverySlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : this.changeTime
          ? this.chosenDeliverySlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : 'asap';
      } else {
        DeliveryDetail['Schedule'] =
          this.chosenDeliverySlot +
          ' - ' +
          this.date.toString().substring(4, 15);
      }
      body['PaymentDetail'] = { ...body['PaymentDetail'], DeliveryDetail };
    }
    if (this.deliveryType == 'Store') {
      const PickupDetail = {};
      if (this.asapSlot) {
        PickupDetail['Schedule'] = this.CollectionFrom
          ? this.chosenCollectionSlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : this.collectionDayOptions[0].Title == 'Pick Date'
          ? this.chosenCollectionSlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : this.changeTime
          ? this.chosenCollectionSlot +
            ' - ' +
            this.date.toString().substring(4, 15)
          : 'asap';
      } else {
        PickupDetail['Schedule'] =
          this.chosenCollectionSlot +
          ' - ' +
          this.date.toString().substring(4, 15);
      }
      body['PaymentDetail'] = { ...body['PaymentDetail'], PickupDetail };
    }
    if (paymentType == 'Card') {
      const CardDetail = {
        CustomerEmail: this.guestCustomer
          ? this._local.getSessionItem('guestEmail')
          : this._local.get('username'),
        Token: this.cardID,
        Description: '',
        PaymentMethodId: this.PaymentMethodId,
        StatementDescriptor: this.brandFlavour,
      };
      body['PaymentDetail'] = { ...body['PaymentDetail'], CardDetail };
    }
    if (paymentType == 'Paypal') {
      const PaypalPurchaseDetails = {
        PaypalOrderId: this.paypalToken,
      };
      body['PaymentDetail'] = {
        ...body['PaymentDetail'],
        PaypalPurchaseDetails,
      };
    }

    return body;
  }
  getRevervationConfig(): Observable<any> {
    const brandFalvour = window.location.pathname.split('/')[1];
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    return this._requestService.getRequest(
      'api/Reservation/getConfig_v1/' + brandFalvour,
      httpHeaders
    );
  }
  resetData() {
    this.deliveryType = '';
    this.PromotionUsageId = 0;
    this.PromotionId = 0;
    this.notes = '';
    this.EmailMarketingOrder = null;
    this.SmsMarketingOrder = null;
    this.tipAmount = 0;
    this.vatCharges = 0;
    this.cartTotal = 0;
    this.deliveryCharges = 0;
    this.customerBalance = 0;
    this.address = '';
    this.postCode = '';
    this.asapSlot = false;
    this.DeliveryFrom = '';
    this.chosenDeliverySlot = '';
    this.date = null;
    this.deliveryDayOptions = [];
    this.collectionDayOptions = [];
    this.changeTime = null;
    this.CollectionFrom = '';
    this.chosenCollectionSlot = '';
    this.email = '';
    this.cardID = '';
    this.PaymentMethodId = '';
    this.orderId = null;
  }
}
