import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { favouritesService } from 'src/app/favourites/favourites.service';
import { popupData, popupService } from 'src/app/popup/popup.service';
import { GetterService } from 'src/app/service/getter-service.service';
import { RequestService } from 'src/app/service/request.service';
import { Product } from 'src/Model/interface/productInterfaces';

@Injectable({
  providedIn: 'root',
})
export class ProductCardService {
  constructor(
    private _requestService: RequestService,
    private spinner: NgxSpinnerService,
    private popupService: popupService,
    private _local: GetterService,
    private favService: favouritesService,
    private router: Router,
    private favouriteService: favouritesService
  ) {}
  openUrl(url: string) {
    this.router.navigateByUrl(this._requestService.setUrl(url));
  }
  getProductModal(product: Product) {
    let renderedExtraList: Array<any> = [];
    let renderExtralistChooseOnes = 0;
    this._requestService
      .getRequest('api/ProductExtra/getProductExtras/' + product.ProductId, [])
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res['Info']['Status'] == 200) {
            let data: any = res['Data'];
            Object.entries(data).forEach(([key, value]) => {
              if (typeof value !== 'string') {
                Object.entries(value).forEach(([k, v]) => {
                  v.Key = key;
                  v.Quantity = 0;
                });
              }
              let param = {
                Key: key,
                extras: value,
              };
              renderedExtraList.push(param);
            });

            if (renderedExtraList.length > 0) {
              renderedExtraList.forEach((extra) => {
                if (extra.Key.toLowerCase().includes('choose')) {
                  renderExtralistChooseOnes += 1;
                }
              });
            }
          }
          let popupdata: popupData = {
            MenuBuilder: res['Data']?.MenuBuilder,
            dineIn: false,
            filteredProduct: product,
            filteredRecipe: null,
            renderedExtraList: renderedExtraList,
            openForNonExtras: false,
            renderExtralistChooseOnes: renderExtralistChooseOnes,
          };
          this.popupService.popUpData.next(popupdata);
        },
        error: () => {
          this.spinner.hide();
          console.log('Some thing went wrong');
        },
      });
  }
  addRemoveFavs(product: Product, status: boolean) {
    product.IsFavourite = !product.IsFavourite;
    let body = {
      FK_CustomerId: this._local.get('CustomerId'),
      FK_RecipeId: null,
      FK_ProductId: product.ProductId,
      Status: status,
    };
    const httpToken: any = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this._requestService
      .postRequest(
        'api/CustomerFavourites/updateItem',
        JSON.stringify(body),
        httpToken
      )
      .subscribe({
        next: (res) => {
          if (res['Info'].Status == 200 && status) {
            this.favService.addFavouriteToList(product);
            return;
          }
          if (res['Info'].Status == 200 && !status) {
            this.favService.removeFavouriteFromList(product);
            return;
          }
          if (res['Info'].Status !== 200) {
            product.IsFavourite = !product.IsFavourite;
          }
        },
        error: () => {
          product.IsFavourite = !product.IsFavourite;
        },
      });
  }
  quantityControl(item: Product, flag: string) {
    let cart = JSON.parse(this._local.get('cartProductItems'));

    let updateCart = () => {
      this._local.set('cartProductItems', JSON.stringify(cart));
      this._requestService.storageChange.next();
    };

    if (flag == '-' && item.ProductQuantity == 1) {
      cart?.forEach((element) => {
        if (element.ProductId == item.ProductId) {
          cart.splice(cart.indexOf(element), 1);
          item.ProductQuantity = 0;
        }
      });
      updateCart();
      return;
    }
    if (flag == '+') {
      cart?.forEach((element) => {
        if (element.ProductId == item.ProductId) {
          element.ProductQuantity += 1;
          element.ProductTotal =
            element.ProductQuantity * element.ProductOldPrice;
        }
      });
      item.ProductQuantity += 1;
      updateCart();
      return;
    }
    if (flag == '-') {
      cart?.forEach((element) => {
        if (element.ProductId == item.ProductId) {
          element.ProductQuantity -= 1;
          element.ProductTotal =
            element.ProductQuantity * element.ProductOldPrice;
        }
      });
      item.ProductQuantity -= 1;
      updateCart();
      return;
    }
  }
  RemoveFavourite(product: Product) {
    this.spinner.show();
    this.favouriteService.deleteFavourite(product).subscribe((res) => {
      this.spinner.hide();
    });
  }
  kgListChanged(product: Product) {
    let cart = JSON.parse(this._local.get('cartProductItems'));
    cart?.forEach((element) => {
      if (element.ProductId == product.ProductId) {
        element.ProductQuantity = product.ProductQuantity;
        element.ProductTotal =
          element.ProductQuantity * product.ProductOldPrice;
      }
    });
    this._local.set('cartProductItems', JSON.stringify(cart));
    this._requestService.storageChange.next();
  }
}
