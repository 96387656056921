<div class="overlay" *ngIf="recommendations">
  <div class="popup">
    <div
      class="popup-header"
      [ngStyle]="{ 'background-color': SelectedButtonColor }"
    >
      <h2 class="modalHeading" [ngStyle]="{ color: SelectedButtonTextColor }">
        Would you like to add
      </h2>
      <button
        type="button"
        [ngStyle]="{ color: SelectedButtonTextColor }"
        class="close fs-4"
        (click)="recommendations = false"
      >
        &times;
      </button>
    </div>
    <div class="popup-body p-2">
      <app-recipe-card-list-style
        [recipes]="recomendedRecipies"
      ></app-recipe-card-list-style>

      <app-responsive-grid-style
        [products]="recomendedProducts"
      ></app-responsive-grid-style>
    </div>
    <div class="popup-footer">
      <button
        class="w-100 recipePopButton"
        type="button"
        (click)="openUrl('checkout')"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
      >
        <h2 class="m-0" [ngStyle]="{ color: SelectedButtonTextColor }">
          {{ recomendationsButtonText || "No Thanks , Continue to checkout" }}
        </h2>
      </button>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="!checkOutOff && ShowCheckOutOffMsg">
  <div class="popup">
    <div
      class="popup-header"
      [ngStyle]="{ 'background-color': SelectedButtonColor }"
    >
      <h2 class="modalHeading" [ngStyle]="{ color: SelectedButtonTextColor }">
        Sorry for the inconvenience
      </h2>
      <button
        type="button"
        [ngStyle]="{ color: SelectedButtonTextColor }"
        class="close fs-4"
        (click)="ShowCheckOutOffMsg = ''"
      >
        &times;
      </button>
    </div>
    <div class="popup-body p-2">
      <h2 class="text-center">{{ ShowCheckOutOffMsg }}</h2>
    </div>
    <div class="popup-footer">
      <button
        class="w-100 recipePopButton"
        type="button"
        (click)="ShowCheckOutOffMsg = ''"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
      >
        <h2 class="m-0" [ngStyle]="{ color: SelectedButtonTextColor }">OKAY</h2>
      </button>
    </div>
  </div>
</div>
