import { Component, OnInit } from '@angular/core';
import { RequestService } from "../service/request.service";
import { GetterService } from "../service/getter-service.service";
import { AppComponent } from './../app.component';

@Component({
	selector: 'app-postcode-iframe',
	templateUrl: './postcode-iframe.component.html',
	styleUrls: ['./postcode-iframe.component.scss']
})
export class PostcodeIframeComponent implements OnInit {

	//header//
	origin_Name: any;
	brand_Name: any;

	//popup//
	showpopup: boolean = true;
	//postcode validation
	validPostCode: boolean = false;
	InvalidPostCode: boolean = false;
	enteredPostCode: string;
	manualPostCode: string = '';
	InvalidMessage: string;
	postSpinner: boolean = false;
	typeNotify: boolean = false;

	cartProductItems: any[] = [];
	cartRecipeItems: any[] = [];

	/* Dynamic styles */
	SelectedButtonColor = "#B8860B";
	SelectedButtonTextColor: any = "#fff";

	/* check delivery */
	checkDeliveryTemplate: string = null;


	constructor(
		public _requestService: RequestService,
		
		private _local: GetterService,
		private app: AppComponent,
	) {
		app.setHeaderVisibility(false);
		app.setFooterVisibility(false);
	}

	ngOnInit(): void {
		this.origin_Name = window.location.origin;
		this.brand_Name = window.location.pathname.split('/')[1];
	}


	redirecturl() {
		window.open(this.origin_Name + "/" + this.brand_Name + "/login", '_self');
	}

	bookdelivery() {
		this.checkDeliveryTemplate = this._local.get("TemplateType");
		if (this.checkDeliveryTemplate == 'Nebula') {
			window.open(this.origin_Name + "/" + this.brand_Name + "/menu", '_self');
		}
		else if (this.checkDeliveryTemplate == 'Turbo') {
			window.open(this.origin_Name + "/" + this.brand_Name + "/online", '_self');
		}
		else if (this.checkDeliveryTemplate == 'Stellar') {
			window.open(this.origin_Name + "/" + this.brand_Name + "/shop", '_self');
		}
		else {
			window.open(this.origin_Name + "/" + this.brand_Name + "/recipe", '_self');
		}

		//window.open(this.origin_Name +"/" + this._requestService.changeTileFlag('deli'),'_self');

	}
	closeModel() {
		this.showpopup = false;
	}

	//post code validation//

	checkPostCodeValidity(val: string) {
		if (val == null) {
			this.InvalidPostCode = false;
			this.validPostCode = false;
			this.manualPostCode = '';
		}
		else {
			this.postSpinner = true;
			const cartProductItems = this._requestService.getProductCartItems()
			const cartRecipeItems = this._requestService.getCartRecipeItems();

			val = val.toUpperCase();
			const params = {
				BrandId: this._local.get("BrandId"), // Parent Brand Id.
				Postcode: val, // Customer's entered postcode.
				ProductOrderDetail: cartProductItems,
				RecipeOrderDetail: cartRecipeItems,
			};
			this._requestService
				.postRequest("api/CustomerAddress/checkPostcodeValidity", params, [])
				.subscribe((res) => {
					if (res["Info"]["Status"] == 200) {
						this._requestService.postCode = this.manualPostCode;
						this.validPostCode = true;
						this.InvalidPostCode = false;
						this.enteredPostCode = val;
						this.manualPostCode = null;
					} else {
						this.InvalidMessage = res["Info"]["Message"];
						this.InvalidPostCode = true;
						this.validPostCode = false;
						this.manualPostCode = null;
						this._requestService.postCode = '';
					}
					this.postSpinner = false;
				});
		}
	}

}
