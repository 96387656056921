<div>
	<div id="paypalCard"></div>
	<p class="m-0" *ngIf="loading">Paypal is Loading...</p>
</div>
<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
