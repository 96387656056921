<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-4 col-sm-6 col-12 p-1" *ngFor="let product of products">
      <div
        class="bg-white d-flex justify-content-between flex-column h-100 br-10px"
      >
        <div>
          <img
            *ngIf="product.ProductImageUrl"
            [src]="product.ProductImageUrl"
            [alt]="product.ProductTitle"
            class="w-100 br-10px"
          />
        </div>
        <div class="d-flex justify-content-between flex-column">
          <p class="text-muted p-2">{{ product.ProductTitle }}</p>
          <div class="d-flex justify-content-between align-items-center p-2">
            <span>
              <p class="fs-5 m-0" *ngIf="product.ProductOldPrice">
                RRP
                {{ product.ProductOldPrice | currency: "GBP":"symbol":"1.1-2" }}
              </p>
            </span>
            <span
              class="cartImg p-2 br-10px cursor-pointer"
              (click)="getProductModal(product)"
            >
              <img
                src="../../../../assets/img/cart icon.jpg"
                alt="cart"
                class="w-100 br-10px"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
