import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckoutService } from '../checkout/checkout.service';
import { PayService } from '../pay/pay.service';
import { NewReservationService } from '../reservation/reservation.service';
import { SendGiftcardService } from '../send-giftcard/send-giftcard.service';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentSenseService {
  token: string;
  statusCode: string;
  constructor(
    private checkoutService: CheckoutService,
    private reservationService: NewReservationService,
    private giftCardService: SendGiftcardService,
    private payService: PayService,
    private _local: GetterService,
    private _requestService: RequestService
  ) {}

  checkout(): Observable<any> {
    const body = this.checkoutService.getParams('Card');
    const PaymentsenseDetails = {
      payment_id: this.token,
      status: this.statusCode,
    };
    delete body['PaymentDetail'].CardDetail;
    body['PaymentDetail'] = { ...body['PaymentDetail'], PaymentsenseDetails };

    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      BrandFlavor: window.location.pathname.split('/')[1],
    });
    const component = window.location.pathname.split('/')[2];
    const guestCustomer = component == 'guest' ? true : false;
    if (guestCustomer) {
      return this._requestService.postRequest(
        'api/Checkout/Guest/v1',
        JSON.stringify(body),
        httpHeaders
      );
    } else {
      return this._requestService.postRequest(
        'api/Checkout/v1/Web',
        JSON.stringify(body),
        httpHeaders
      );
    }
  }
  bookTable(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    const brandId = this._local.get('BrandId');
    let body = this.reservationService.getParams('Card');
    const PaymentsenseDetails = {
      payment_id: this.token,
      status: this.statusCode,
    };
    delete body['CardDetail'];
    body['PaymentsenseDetails'] = PaymentsenseDetails;

    return this._requestService.postRequest(
      'api/Reservation/book/v1/' + brandId,
      body,
      httpHeaders
    );
  }

  sendGiftCard(): Observable<any> {
    let body = this.giftCardService.getParams('Card');
    const PaymentsenseDetails = {
      payment_id: this.token,
      status: this.statusCode,
    };
    delete body['PaymentDetail']['CardDetail'];
    body['PaymentDetail']['PaymentsenseDetails'] = PaymentsenseDetails;
    const brandId = this._local.get('BrandId');

    const token = this._local.get('access_token')
      ? this._local.get('access_token')
      : this._local.getSessionItem('access_token');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    return this._requestService.postRequest(
      'api/GiftCard/send/v1/' + brandId,
      body,
      httpHeaders
    );
  }

  pay(): Observable<any> {
    let body = this.payService.getParams('Card');
    const PaymentsenseDetails = {
      payment_id: this.token,
      status: this.statusCode,
    };
    delete body['CardDetail'];
    body['PaymentsenseDetails'] = PaymentsenseDetails;
    const brandId = this._local.get('BrandId');

    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    return this._requestService.postRequest(
      'api/Payment/OneTimePayment/v1',
      body,
      httpHeaders
    );
  }
}
