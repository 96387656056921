import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from 'src/Model/interface/productInterfaces';
import { ProductCardService } from '../product-card.service';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.scss'],
})
export class StoreProductComponent implements OnInit {
  @Input() products: Product[] = [];

  constructor(
    private productCardService: ProductCardService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  getProductModal(product: Product) {
    this.spinner.show();
    this.productCardService.getProductModal(product);
  }
}
