import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '../service/request.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GetterService } from '../service/getter-service.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { UserService } from '../service/user.service';
import { Observable, share, Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  message: string;
  IsLoginFromReg: boolean = false;
  openregisteruser: string;
  GuestForm: FormGroup;
  registerForm: FormGroup;
  submittedGuestFrom: boolean;
  tabName: string = 'Login';
  brandId: any;
  RedirectionFromLogin: any;
  MobileMenuTextColor: String;
  MobileMenuBorderColor: String;
  MobileMenuBackgroundColor: String;
  simpleCheckout: boolean;
  showMessage: boolean;

  loginForm: FormGroup;
  submitted = false;
  webCusSub: Subscription;
  showGuestLogin: Observable<any>;
  distributorLogin: boolean;
  recipesBrand: boolean;
  training: boolean;
  guestCheckoutEnabled: boolean = true;
  get loginFormControls() {
    return this.loginForm.controls;
  }
  get regForm() {
    return this.registerForm.controls;
  }
  get guestControls() {
    return this.GuestForm.controls;
  }
  constructor(
    private _requestService: RequestService,
    private _router: Router,
    private _local: GetterService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.showGuestLogin = this.route.queryParams.pipe(share());
    this.training = this.route.snapshot.queryParams['training'] == 'true';
  }
  ngOnInit() {
    if (window.location.pathname.split('/')[2] == 'registeruser') {
      this.changeTab('signup');
    }
    this.getWebCustomer();
    this.initializeForms();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.recipesBrand = res.TemplateData?.RecipesBrand;
      this.brandId = res.BrandData.BrandId;
      this.RedirectionFromLogin =
        res.TemplateData.RedirectionConfig.RedirectionFromCheckout;
      this.simpleCheckout = !res.TemplateData.CheckoutDetail?.simpleCheckout;
      this.distributorLogin = res.TemplateData.CheckoutDetail?.DistributorLogin;
      this.MobileMenuTextColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuTextColor;
      this.MobileMenuBorderColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBorderColor;
      this.MobileMenuBackgroundColor =
        res.TemplateData.DynamicStyle.PrimaryColors.MobileMenuBackgroundColor;
      
      if (
        res.TemplateData.RegistrationTemplate?.GuestCheckoutEnabled != undefined
      ) {
        this.guestCheckoutEnabled =
          res.TemplateData.RegistrationTemplate?.GuestCheckoutEnabled;
      }
    });
  }
  initializeForms() {
    this.loginForm = this.formBuilder.group({
      email: [null, Validators.required],
      passwordLogin: [null, [Validators.required, Validators.minLength(8)]],
    });
    this.registerForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      contactNo: [null, [Validators.required, Validators.pattern]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmpassword: [null, [Validators.required, Validators.minLength(8)]],
      terms: [null, Validators.required],
      EmailMarketing: [false],
      SmsMarketing: [false],
    });

    this.GuestForm = this.formBuilder.group({
      firstNameGuest: [null, Validators.required],
      lastNameGuest: [null, Validators.required],
      emailGuest: [null, [Validators.required, Validators.email]],
      contactNoGuest: [null, Validators.required],
    });
  }

  login() {
    this.submitted = true;

    let username;
    let password;
    if (this.IsLoginFromReg) {
      username = this.regForm['email'].value;
      password = this.regForm['password'].value;
    }
    if (!this.IsLoginFromReg) {
      username = this.loginFormControls['email'].value;
      password = this.loginFormControls['passwordLogin'].value;
    }

    if (this.loginForm.invalid && !this.IsLoginFromReg) {
      return;
    }

    this.spinner.show();
    let params = new HttpParams();

    let brandUserName = username + '#' + this.brandId;

    params = params.append('username', brandUserName);
    params = params.append('password', password);
    params = params.append('grant_type', 'password');
    this._requestService.postRequest('Token', params).subscribe({
      next: (res) => {
        this.userService.createSession(
          username,
          res['access_token'],
          res['refresh_token']
        );
        this.getUserData();
      },
      error: () => {
        this.message = 'The user name or password is incorrect.';
        this.showMessage = true;
        this.spinner.hide();
      },
    });
  }

  getUserData() {
    this.spinner.show();
    let username = this._local.get('username');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    this._requestService
      .getRequest(
        'api/Customer/getCustomer/' + username + '/' + this.brandId,
        httpHeaders
      )
      .subscribe({
        next: (res) => {
          if (res['Info']['Status'] == 200) {
            this._local.set('CustomerId', res['Data']['CustomerId']);
            this._local.set(
              btoa('CustomerEmail'),
              btoa(res['Data']['CustomerEmail'])
            );
            this._local.set(
              btoa('CustomerPassword'),
              btoa(res['Data']['CustomerPassword'])
            );
            this._local.set(
              'CustomerFirstName',
              res['Data']['CustomerFirstName']
            );
            this._local.set(
              'CustomerLastName',
              res['Data']['CustomerLastName']
            );
            this._local.set(
              btoa('CustomerMobile'),
              btoa(res['Data']['CustomerMobile'])
            );
            this._local.set(
              'CustomerImageUrl',
              res['Data']['CustomerImageUrl']
            );
            this._local.set(
              'FK_BrandId_Customer',
              res['Data']['FK_BrandId_Customer']
            );
            this.openUrl(this._requestService.redirectToComponent);
            this.userService.loggedIn();
            return;
          }
        },
        error: () => {
          this.message =
            'Something went wrong please refresh page and try again';
          this.showMessage = true;
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  openUrl(url) {
  
    this._router.navigateByUrl(this._requestService.setUrl(url));
  }

  focusInputs(event: Event) {
    let input = event.target as HTMLInputElement;
    if (input.value) {
      input.classList.add('in-focus');
    } else {
      input.classList.remove('in-focus');
    }
  }
  changeTab(Tab: string) {
    this.tabName = Tab;
  }

  registerUser() {
    this.spinner.show();
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.spinner.hide();
      return;
    }
    let params = new HttpParams();
    params = params.append(
      'CustomerFirstName',
      this.regForm['firstName'].value
    );
    params = params.append('CustomerLastName', this.regForm['lastName'].value);
    params = params.append('CustomerEmail', this.regForm['email'].value);
    params = params.append('CustomerPassword', this.regForm['password'].value);
    params = params.append('CustomerMobile', this.regForm['contactNo'].value);
    params = params.append('CustomerImageUrl', null);
    params = params.append('SmsMarketing', this.regForm['SmsMarketing'].value);
    params = params.append(
      'EmailMarketing',
      this.regForm['EmailMarketing'].value
    );
    params = params.append('FK_BrandId_Customer', this._local.get('BrandId'));

    this._requestService
      .postRequest('api/Account/v1/Register', params)
      .subscribe({
        next: (res) => {
          if (res.Info.Status === 201) {
            // this._snackBar.open(
            // 	"Successfully Registered",
            // 	"Close",
            // 	this.configSuccess
            // );
            this.IsLoginFromReg = true;
            this.login();
            return;
          }
          this.message = res?.Info?.Message;
          this.showMessage = true;
          this.spinner.hide();
        },
        error: () => {
          this.message = 'Network Error! Something went wrong please try again';
          this.showMessage = true;
        },
      });
  }

  registerGuest() {
    if (this.GuestForm.invalid) {
      this.submittedGuestFrom = true;
      return;
    }
    this.spinner.show();

    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const brandFlavour = window.location.pathname.split('/')[1];
    let form = this.guestControls;

    let params = new HttpParams();
    params = params.append('client_id', brandFlavour + '#client_id');
    params = params.append('client_secret', brandFlavour + '#client_secret');
    params = params.append('grant_type', 'client_credentials');

    const success = (res) => {
      this._local.setSessionItem(
        'guestFirstName',
        form['firstNameGuest'].value
      );
      this._local.setSessionItem('guestLastName', form['lastNameGuest'].value);
      this._local.setSessionItem('guestEmail', form['emailGuest'].value);
      this._local.setSessionItem('guestContact', form['contactNoGuest'].value);
      this._local.setSessionItem('access_token', res.access_token);

      this.openUrl(this._requestService.redirectToComponent);
    };
    const error = () => {
      this.spinner.hide();
      alert('Network Error! Something went wrong could not login');
    };
    const complete = () => {
      this.spinner.hide();
    };

    this._requestService.postRequest('Token', params, httpHeaders).subscribe({
      next: success,
      error: error,
      complete: complete,
    });
  }

  matchPass() {
    if (
      this.registerForm.controls['password'].value !=
      this.registerForm.controls['confirmpassword'].value
    ) {
      return this.registerForm.controls['confirmpassword'].setErrors({
        mustMatch: true,
      });
    } else {
      if (this.registerForm.controls['confirmpassword'].hasError('mustMatch')) {
        this.registerForm.controls['confirmpassword'].setErrors(null);
      }
    }
  }
}
