<div class="container-fluid">
  <div class="p-relative">
    <div
      class="inputPosition"
      [ngClass]="{
        'z-index-500':
          (recipeData.length || productData.length || notfound) &&
          focus &&
          searchInput.value
      }"
    >
      <input
        type="search"
        class="searchInput form-control"
        placeholder="Search {{ brandName }}"
        name="search"
        id="search"
        (input)="searchProducts($event)"
        ngModel
        #searchInput="ngModel"
        (focus)="onFocus()"
      />
      <div
        class="spinner-border spinner-border-sm spinner"
        *ngIf="isLoading"
      ></div>
    </div>
    <div
      class="resultsBox mt-1"
      *ngIf="
        (recipeData.length || productData.length || notfound) &&
        focus &&
        searchInput.value
      "
    >
      <p class="text-danger text-center" *ngIf="searchResult">
        {{ searchResult }}
      </p>
      <app-recipe-card-list-style
        [recipes]="recipeData"
      ></app-recipe-card-list-style>
      <app-responsive-grid-style
        [products]="productData"
      ></app-responsive-grid-style>
    </div>
    <div
      class="overlay"
      *ngIf="
        (recipeData.length || productData.length || notfound) &&
        focus &&
        searchInput.value
      "
      (click)="onBlur()"
    ></div>
  </div>
</div>
