import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicColorsService } from 'src/app/service/dynamic-colors.service';
import { RequestService } from 'src/app/service/request.service';
import { Product } from 'src/Model/interface/productInterfaces';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: Product;
  constructor(
    private router: Router,
    private _requestService: RequestService,
    public dynamicCss: DynamicColorsService
  ) {}

  ngOnInit(): void {}

  productHover(product: Product) {
    product.showSecondImage = !product.showSecondImage;
  }

  openDetails(product: Product) {
    this.router.navigateByUrl(
      this._requestService.setUrl(
        'products/product-details/' + product.ProductId
      )
    );
  }
}
