<div class="p-4 br-40px bg-light" (click)="stopPropagation($event)">
  <h2 class="text-center">{{ brandName }}</h2>
  <div id="demo-payment" class="p-0"></div>
  <div id="errors" class="text-danger"></div>
  <button
    id="testPay"
    class="btn br-40px w-100"
    [ngStyle]="{
      'background-color': dynamicCss.SelectedButtonColor,
      color: dynamicCss.SelectedButtonTextColor
    }"
    (click)="pay()"
    [disabled]="paying"
  >
    <span *ngIf="!paying"
      >Pay {{ total | currency: "GBP":"symbol":"1.1-2" }}</span
    >
    <span *ngIf="paying">Please wait... </span>
    <span class="spinner-border spinner-border-sm" *ngIf="paying"></span>
  </button>
</div>
<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
