<hr class="m-0" style="border: 2px solid maroon" />
<div
  class="footerContainer p-2"
  *ngIf="!defaultFooter"
  [ngStyle]="{ 'background-color': FooterColor }"
>
  <ul class="w-maxContent mx-auto p-0">
    <ng-container *ngIf="tags | async as Tags">
      <li *ngFor="let tag of Tags; let last = last" class="d-inline">
        <a href="{{ tag.Url }}" class="text-danger">{{ tag.Tag }}</a>
        <span *ngIf="!last"> | </span>
      </li>
    </ng-container>

    <li class="d-inline" *ngIf="UnsubscribeFooterLink">
      |
      <a
        href="javascript:void(0)"
        (click)="unsubscribeModel = true; unsubResult = ''"
        class="text-danger"
        >Unsubscribe</a
      >
    </li>
  </ul>
  <p>
    <!-- {{BrandName}}  -->
    <b> Copyright &copy; {{ currentDateStr }}. All Rights Reserved</b>
  </p>
</div>

<div
  *ngIf="defaultFooter"
  class="footerContainer p-2"
  [ngStyle]="{ 'background-color': FooterColor }"
>
  <p>
    <span *ngIf="TermsAndConditionsFooterLink">
      <a
        href="javascript:void(0)"
        (click)="openUrl('terms-and-conditions')"
        class="text-danger"
        >Terms & Conditions</a
      >
    </span>
    <span *ngIf="PrivacyPolicyFooterLink">
      |
      <a
        href="javascript:void(0)"
        (click)="openUrl('privacy-policy')"
        class="text-danger"
        >Privacy Policy</a
      >
    </span>
    <span *ngIf="UnsubscribeFooterLink">
      |
      <a
        href="javascript:void(0)"
        (click)="unsubscribeModel = true; unsubResult = ''"
        class="text-danger"
        >Unsubscribe</a
      >
    </span>
  </p>
  <p class="footer-copyRight m-0" *ngIf="PerkssFooter">
    <!-- {{BrandName}}  -->
    Copyright &copy; {{ currentDateStr }}. All Rights Reserved |
    <img
      src="https://perkss.blob.core.windows.net/images/customer/footer/takeaway_tree.png"
      alt="Footer Image"
      style="max-width: 34px; max-width: 34px"
    />
    Takeaway Tree | Powered by
    <a href="http://perkss.co.uk/" class="text-danger">Perkss.</a>
  </p>
  <p class="footer-copyRight m-0" *ngIf="!PerkssFooter">
    <!-- {{BrandName}}  -->
    Copyright &copy; {{ currentDateStr }}. All Rights Reserved | Powered by
    <a href="http://perkss.co.uk/">Perkss.</a>
  </p>
</div>

<div class="overlay unsubBox" *ngIf="unsubscribeModel">
  <div class="unsubContent">
    <div class="unsubHead">
      <p class="m-0">UNSUBSCRIPTION</p>
      <button type="button" class="close" (click)="unsubscribeModel = false">
        &times;
      </button>
    </div>
    <div class="unsubBody">
      <p>
        Please enter your details to unsubscribe from
        {{ BrandName }} promotional text
      </p>
      <input
        type="text"
        name="unsubField"
        id="unsubField"
        placeholder="Enter your email/mobile number"
        class="form-control br-40px"
        [(ngModel)]="UnsubField"
      />
      <p
        class="text-success"
        *ngIf="unsubResult == 'Unsubscribed Successfully'"
      >
        {{ unsubResult }}
      </p>
      <p
        class="text-danger"
        *ngIf="unsubResult !== 'Unsubscribed Successfully'"
      >
        {{ unsubResult }}
      </p>
    </div>
    <div class="unsubFooter">
      <button type="button" class="unsubBtn" (click)="unsubscribe()">
        UNSUBSCRIBE
      </button>
    </div>
  </div>
</div>

<!-- <button (click)="showForm()" class="btn-help" [ngStyle]="{'background-color':SelectedButtonColor}">Help</button> -->

<!-- help-form -->

<!-- <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <h4 class="modal-title" id="myModalLabel">Help Form</h4>
        <button type="button" class="btn btn-dark btn-close" data-dismiss="modal" (click)="hide()">x</button>
      </div>
      <div class="modal-body">


        <form [formGroup]="registerForm" (ngSubmit)=" onSubmit()">




          <div class="form-group">
           
            <input id='OrderID' name="OrderID" type="number" formControlName="OrderID" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.OrderID.errors } " (input)="onInput($event.target.value)" />
              <label for="OrderID">OrderID:</label>
          </div>



          <div class="form-group">
           
            <input id='form-firstName' type="text" class="form-control" formControlName="firstName"
              [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" (input)="onInput($event.target.value)" />
              <label>First Name:</label>
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name is required</div>

            </div>
          </div>


          <div class="form-group">
           
            <input id='form-lastName' type="text" formControlName="lastName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" (input)="onInput($event.target.value)" />
              <label>Last Name:</label>
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Last Name is required</div>

            </div>
          </div>


          <div class="form-group">
            
            <input id='form-MobileNo' type="text" formControlName="MobileNo" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }" (input)="onInput($event.target.value)" />
              <label>Mobile NO:</label>
            <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
              <div *ngIf="f.MobileNo.errors.required">Mobile No is required</div>

            </div>
          </div>


          <div class="form-group">
           
            <input id='form-email' name="email" type="text" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (input)="onInput($event.target.value)"
              (keyup)="focusEmail($event.target.value)" />
              <label for="email">Email:</label>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>


          <div class="form-group">
            
            <textarea type="text" id='form-IssueDetail' formControlName="IssueDetail" class="form-control IssueDetail"
              [ngClass]="{ 'is-invalid': submitted && f.IssueDetail.errors }"
              (keyup)="focusTextArea($event.target.value)">

                </textarea>
                <label>Issue Details:</label>
            <div *ngIf="submitted && f.IssueDetail.errors" class="invalid-feedback">
              <div *ngIf="f.IssueDetail.errors.required">Issues is required</div>

            </div>
          </div>


          <button type="submit" class="btn btn-primary btn-submit">Submit</button>
        </form>
      </div>

    </div>
  </div>
</div>
<script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/superagent/2.2.0/superagent.min.js"></script>
<script>
  function onSubmit() {

    superagent
      .post(
        'https://prod-00.centralus.logic.azure.com:443/workflows/542177149f70488387ff52a5f0411fdf/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=LLzYP5q1VKptxMH_u6o7buHkPmxJPg2GsttZzfwu-28'
      )
      .send({
        OrderID: document.getElementById('form-OrderID').value,
        firstName: document.getElementById('form-firstName').value,
        lastName: document.getElementById('form-lastName').value,
        MobileNo: document.getElementById('form-MobileNo').value,
        email: document.getElementById('form-email').value,
        IssueDetail: document.getElementById('form-IssueDetail').value,
      })
      .end(function (err, res) {
        if (err || !res.ok) {
          alert('Whoops. Something went wrong.');
        } else {
          alert('Thanks for contacting us.');
        }
      });
    return false;
  }
</script> -->
