import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import { RequestService } from 'src/app/service/request.service';
import {
  CategoryWithProducts,
  SubCategory,
} from 'src/Model/interface/productInterfaces';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss'],
})
export class SubCategoriesComponent implements OnInit, OnDestroy {
  category: CategoryWithProducts;
  catProductsSub: Subscription;
  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private router: Router,
    private _requestService: RequestService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    const catID = this.route.snapshot.params['catID'];
    this.catProductsSub = this.productsService.catAndProducts.subscribe(
      (res) => {
     
        res.forEach((category) => {
          if (category.CategoryId == catID) {
            this.category = category;
            this.spinner.hide();
          }
        });
      }
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.catProductsSub.unsubscribe();
  }
  openSubCategories(category: SubCategory) {
    this.router.navigate([
      this._requestService.setUrl(
        `products/${this.category.CategoryTitle}/${this.category.CategoryId}/sub-category/${category.SubcategoryTitle}/${category.SubcategoryId}`
      ),
    ]);
  }
  openProducts() {
    this.router.navigateByUrl(this._requestService.setUrl('products'));
  }
}
