import { GetterService } from '../service/getter-service.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RequestService } from '../service/request.service';

@Injectable({
  providedIn: 'root'
})
export class MyRewardGuard implements CanActivate {

  constructor(private _requestService: RequestService, private _router: Router, private _local: GetterService) {
  }

  canActivate() {
    if (this._requestService.isLoggedIn()) {
      return true;
    } else if (!this._requestService.isLoggedIn() && this._local.getSessionItem('access_token')) {
      return true;
    } else {
      this._router.navigate([this._requestService.setUrl('login')]);
      return false;
    }
  }
}
