import { SendGiftcardComponent } from './send-giftcard/send-giftcard.component';
import { MyRewardsComponent } from './my-rewards/my-rewards.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Guards/auth.guard';
import { PreviousOrderGuard } from './Guards/previous-order.guard';
import { PreviousOrderComponent } from './previous-order/previous-order.component';
import { RewardComponent } from './reward/reward.component';
import { MyRewardGuard } from './Guards/my-reward.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BranchesComponent } from './branches/branches.component';
import { ShopComponent } from './shop/shop.component';
import { UpdateAccountComponent } from './update-account/update-account.component';
import { DineInListViewComponent } from './dine-in-list-view/dine-in-list-view.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { ReferalComponent } from './referal/referal.component';
import { ReferalGuard } from './Guards/referal.guard';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PayComponent } from './pay/pay.component';
import { payGuard } from './Guards/pay.guard';
import { LikeComponent } from './like/like.component';
import { GuestGuard } from './Guards/guest.guard';
import { LoginGuard } from './Guards/login.guard';
import { CategoryProductsComponent } from './category-products/category-products.component';

const routes: Routes = [
  { path: '', redirectTo: ':brand_name/home', pathMatch: 'full' },
  {
    path: ':brand_name/products',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: ':brand_name/training',
    loadChildren: () =>
      import('./training/training.module').then((m) => m.TrainingModule),
  },
  {
    path: ':brand_name/registeruser',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ':brand_name/login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ':brand_name/menu',
    loadChildren: () =>
      import('./recipe/recipe.module').then((m) => m.RecipeModule),
  },
  {
    path: ':brand_name/app-menu',
    loadChildren: () =>
      import('./recipe/recipe.module').then((m) => m.RecipeModule),
  },
  {
    path: ':brand_name/dine-in',
    component: DineInListViewComponent,
  },
  { path: ':brand_name/join-reward', component: RewardComponent },
  {
    path: ':brand_name/postcode-iframe',
    loadChildren: () =>
      import('./postcode-iframe/postcode-iframe.module').then(
        (m) => m.PostcodeIframeModule
      ),
  },
  {
    path: ':brand_name/reservation',
    loadChildren: () =>
      import('./reservation/reservation.module').then(
        (m) => m.ReservationModule
      ),
  },
  {
    path: ':brand_name/reserve-iframe',
    loadChildren: () =>
      import('./reservation/reservation.module').then(
        (m) => m.ReservationModule
      ),
  },
  { path: ':brand_name/updateaccount', component: UpdateAccountComponent },
  { path: ':brand_name/forgot-password', component: ForgotPasswordComponent },
  {
    path: ':brand_name/my-rewards',
    component: MyRewardsComponent,
    canActivate: [MyRewardGuard],
  },
  {
    path: ':brand_name/previous-order',
    component: PreviousOrderComponent,
    canActivate: [PreviousOrderGuard],
  },
  {
    path: ':brand_name/checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then(
        (m) => m.CheckoutRefactorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':brand_name/order-checkout',
    loadChildren: () =>
      import('./order-checkout/order-checkout.module').then(
        (m) => m.OrderCheckoutModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':brand_name/terms-and-conditions',
    component: TermsComponent,
  },
  {
    path: ':brand_name/privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: ':brand_name/guest',
    loadChildren: () =>
      import('./checkout/checkout.module').then(
        (m) => m.CheckoutRefactorModule
      ),
    canActivate: [GuestGuard],
  },
  {
    path: ':brand_name/pay',
    component: PayComponent,
    canActivate: [payGuard],
  },
  {
    path: ':brand_name/order',
    loadChildren: () =>
      import('src/app/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: ':brand_name/giftcard',
    component: SendGiftcardComponent,
    canActivate: [MyRewardGuard],
  },
  {
    path: ':brand_name/branches',
    component: BranchesComponent,
  },
  {
    path: ':brand_name/online',
    loadChildren: () =>
      import('src/app/online/online.module').then((m) => m.OrderModule),
  },
  {
    path: ':brand_name/shop',
    component: ShopComponent,
  },
  {
    path: ':brand_name/store',
    loadChildren: () =>
      import('src/app/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: ':brand_name/distributors',
    loadChildren: () =>
      import('src/app/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: ':brand_name/category-products/:catID',
    component: CategoryProductsComponent,
  },
  {
    path: ':brand_name/refer',
    component: ReferalComponent,
    canActivate: [ReferalGuard],
  },
  {
    path: ':brand_name/favourites',
    component: FavouritesComponent,
    canActivate: [LoginGuard],
  },
  {
    path: ':brand_name/likeAndShare',
    component: LikeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
