<div
  class="container"
  *ngIf="windowWidth < 768"
  #target
  [ngStyle]="{
    background: 'url(' + WebsiteBackground + ')',
    'background-color': SiteBackgroundColor
  }"
>
  <div class="row">
    <div
      class="btnGroup br-10px d-flex d-md-none justify-content-around align-item-center flex-row position-sticky"
      [ngStyle]="{ 'background-color': SelectedButtonColor }"
    >
      <p
        class="text-center m-0 pt-3 pb-3 fs-6"
        [ngStyle]="{ color: SelectedButtonTextColor }"
        (click)="showHideCart()"
      >
        {{ requestService.cartCounter }} Items
      </p>
      <span class="bg-light text-dark pt-3 pb-3">
        <p class="m-0 fs-6 ps-1 pe-1">
          Total: {{ cartTotal | currency: "GBP":"symbol":"1.2-2" }}
        </p>
      </span>
      <p
        class="text-center m-0 pt-3 pb-3 fs-6"
        (click)="openCheckout()"
        [ngStyle]="{ color: SelectedButtonTextColor }"
      >
        Checkout
      </p>
    </div>
    <div class="col">
      <div
        class="offers cursor-pointer d-flex align-items-center justify-content-between p-3 mb-2 mt-2"
        *ngIf="offers"
        [ngStyle]="{
          color: offers?.TitleColor,
          'background-color': offers?.BackgroundColor
        }"
        (click)="brandOffers()"
      >
        <h2 class="mb-0 p-0" [ngStyle]="{ color: offers?.TitleColor }">
          {{ offers?.Title }}
        </h2>
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </div>
      <div *ngIf="showOffers">
        <div *ngFor="let offer of offers.OfferTypes">
          <div class="RecipeCard p-3 bg-light">
            <div>
              <h1>{{ offer.Title }}</h1>
              <p>{{ offer.Description }}</p>
            </div>
            <div class="recipeImage p-0" *ngIf="offer.Image">
              <img src="{{ offer.Image }}" alt="offer Image" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2" id="sideCart">
        <div class="alert alert-success" *ngIf="CheckoutNotice">
          <strong>{{ CheckoutNotice }} {{ DeliveryFrom }}</strong>
        </div>
        <div
          class="alert alert-danger"
          *ngIf="ClosingNotice"
          [ngStyle]="{ 'background-color': ClosingNoticeBackground }"
        >
          <strong [ngStyle]="{ color: ClosingNoticeTextColor }">{{
            ClosingNotice
          }}</strong>
        </div>

        <div
          class="mb-3 minimumTimesContainer bg-light p-1"
          *ngIf="ShowCollection && ShowDelivery && MinDeliveryTime != 0"
        >
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Home')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Home'"
              name="homeRadio"
            />
            <label class="form-check-label cursor-pointer" for="homeRadio">
              <p class="m-0 text-center">Home Delivery</p>
              <small
                class="text-center"
                *ngIf="!DeliveryFrom && MinDeliveryTime"
                ><strong>
                  Approx
                  {{ MinDeliveryTime != null ? MinDeliveryTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="DeliveryFrom"
                ><strong>{{ DeliveryFrom }}</strong></small
              >
            </label>
          </div>
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center border-start border-dark p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Store')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Store'"
              name="collectionRadio"
            />
            <label
              class="form-check-label cursor-pointer"
              for="collectionRadio"
            >
              <p class="m-0 text-center">Collection</p>
              <small
                class="text-center"
                *ngIf="!CollectionFrom && MinCollectionTime"
                ><strong>
                  Approx
                  {{ MinCollectionTime != null ? MinCollectionTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="CollectionFrom"
                ><strong>{{ CollectionFrom }}</strong></small
              >
            </label>
          </div>
        </div>
        <div
          *ngIf="
            ShowDelivery && ShowCollection
              ? deliveryType == 'Home' &&
                postcodeValidation?.ShowPostcodeValidityField
              : postcodeValidation?.ShowPostcodeValidityField
          "
          class="mb-3 postCodeValidation"
        >
          <h4 class="text-center">
            <b>{{ postcodeValidation?.PostcodeValidityField.Title }} </b>
          </h4>
          <div class="postCodeWarapper">
            <div class="p-relative w-100">
              <input
                name="manualCode"
                type="text"
                class="form-control post-code postCodeInputField br-40px h-100"
                placeholder="Enter Post Code"
                (keyup.enter)="checkPostCodeValidity(manualPostCode)"
                maxlength="10"
                [(ngModel)]="manualPostCode"
                ngModel
              />
              <span
                class="spinner-border text-success"
                *ngIf="postSpinner"
              ></span>
            </div>
            <button
              class="btn checkBtn"
              [disabled]="!manualPostCode?.length"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              type="button"
              (click)="checkPostCodeValidity(manualPostCode)"
            >
              Check Delivery
            </button>
          </div>
          <p class="text-success" *ngIf="validPostCode">
            We are delivering to <b>{{ enteredPostCode }}</b>
          </p>
          <p class="text-danger" *ngIf="InvalidPostCode">
            <b>{{ postcodeValidation?.PostcodeValidityField.ErrorMessage }}</b>
          </p>
        </div>
        <app-side-cart
          id="checkoutButton"
          (checkout)="openCheckout()"
          (updateCart)="getCartTotal()"
        >
        </app-side-cart>
      </div>
      <div class="recipeDesktopContent p-2 bg-light mb-2">
        <h1 class="mb-0 p-2">
          Menu<span class="text-danger" *ngIf="currentEnvironment"
            >-Development</span
          >
        </h1>
        <hr class="mt-0" />
        <div class="row m-0" *ngIf="!hotCategoryStyle">
          <div
            class="col-6 p-1"
            *ngFor="let category of categories; let i = index"
          >
            <ul class="h-100 p-0">
              <li
                class="categoryItem h-100 d-flex align-items-center"
                (click)="getRecipies(category)"
                [ngStyle]="
                  selectedCategory == category.CategoryId
                    ? {
                        color: SelectedButtonTextColor,
                        'background-color': SelectedButtonColor
                      }
                    : {
                        color: category?.CategoryTextColor,
                        'background-color': category?.CategoryBackgroundColor
                      }
                "
              >
                <a
                  href="javascript:void(0);"
                  class="text-decoration-none"
                  [ngStyle]="
                    selectedCategory == category.CategoryId
                      ? { color: SelectedButtonTextColor }
                      : { color: category?.CategoryTextColor }
                  "
                >
                  {{ category?.CategoryTitle || "" }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngFor="let category of recipies" [id]="category.CategoryId">
        <div
          class="RecipeCardHeader"
          *ngIf="category.CategoryImage || category.CategoryTitle"
          [ngStyle]="{
            color: category?.CategoryTextColor,
            'background-color': category?.CategoryBackgroundColor
          }"
          (click)="getRecipies(category)"
        >
          <div *ngIf="category.CategoryImage.length">
            <img src="{{ category.CategoryImage }}" alt="category image" />
          </div>
          <h2
            class="text-center"
            [ngStyle]="{
              color: category?.CategoryTextColor,
              'background-color': category?.CategoryBackgroundColor
            }"
          >
            {{ category.CategoryTitle }}
          </h2>
        </div>
        <app-recipe-card-list-style
          [recipes]="category.Recipes"
        ></app-recipe-card-list-style>
      </div>
    </div>
  </div>
  <div
    class="backToTop cursor-pointer"
    [ngStyle]="{ 'background-color': SelectedButtonColor }"
    (click)="scrollToTop()"
  >
    <i
      class="fa fa-arrow-up topbtn"
      aria-hidden="true"
      [ngStyle]="{ color: SelectedButtonTextColor }"
    ></i>
  </div>
</div>

<!--desktop -->

<div
  class="container mt-2"
  *ngIf="windowWidth >= 768"
  [ngStyle]="{
    background: 'url(' + WebsiteBackground + ')',
    'background-color': SiteBackgroundColor
  }"
>
  <div class="row">
    <div class="col-md-8">
      <div class="row">
        <div class="col-4">
          <div class="positionSticky">
            <div
              class="offers cursor-pointer"
              *ngIf="offers"
              [ngStyle]="{ 'background-color': offers?.BackgroundColor }"
              (click)="brandOffers()"
            >
              <h2 class="mb-1 p-2" [ngStyle]="{ color: offers?.TitleColor }">
                {{ offers?.Title }}
              </h2>
            </div>
            <div class="recipeDesktopContent p-2 bg-light">
              <h1 class="mb-0 p-2">
                Menu<span class="text-danger" *ngIf="currentEnvironment"
                  >-Development</span
                >
              </h1>
              <hr class="mt-0" />
              <div>
                <ul class="p-0">
                  <li
                    class="categoryItem"
                    *ngFor="let category of categories"
                    (click)="getRecipies(category)"
                    [ngStyle]="
                      selectedCategory == category.CategoryId
                        ? { 'background-color': SelectedButtonColor }
                        : {
                            'background-color':
                              category?.CategoryBackgroundColor
                          }
                    "
                  >
                    <a
                      href="javascript:void(0);"
                      class="text-decoration-none"
                      [ngStyle]="
                        selectedCategory == category.CategoryId
                          ? { color: SelectedButtonTextColor }
                          : { color: category?.CategoryTextColor }
                      "
                    >
                      {{ category?.CategoryTitle || "" }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8" id="test">
          <div *ngIf="showOffers">
            <div *ngFor="let offer of offers.OfferTypes">
              <div class="RecipeCard p-3 bg-light">
                <div>
                  <h1>{{ offer.Title }}</h1>
                  <p>{{ offer.Description }}</p>
                </div>
                <div class="recipeImage p-0" *ngIf="offer.Image">
                  <img src="{{ offer.Image }}" alt="offer Image" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!showOffers">
            <div *ngFor="let category of recipies" [id]="category.CategoryId">
              <div
                class="RecipeCardHeader"
                *ngIf="category.CategoryImage && category.CategoryTitle"
              >
                <div *ngIf="category.CategoryImage.length">
                  <img
                    src="{{ category.CategoryImage }}"
                    alt="category image"
                  />
                </div>
                <h2 class="text-center">{{ category.CategoryTitle }}</h2>
              </div>
              <div
                class="RecipeTitle"
                *ngIf="!category.CategoryImage && category.CategoryTitle"
              >
                <h2 class="text-center">{{ category.CategoryTitle }}</h2>
              </div>
              <app-recipe-card-list-style
                [recipes]="category.Recipes"
              ></app-recipe-card-list-style>
            </div>
          </div>
        </div>
        <!--recipe end-->
      </div>
    </div>
    <div class="col-md-4">
      <div class="positionSticky">
        <div class="alert alert-success" *ngIf="CheckoutNotice">
          <strong>{{ CheckoutNotice }} {{ DeliveryFrom }}</strong>
        </div>
        <div
          class="alert alert-danger"
          *ngIf="ClosingNotice"
          [ngStyle]="{ 'background-color': ClosingNoticeBackground }"
        >
          <strong [ngStyle]="{ color: ClosingNoticeTextColor }">{{
            ClosingNotice
          }}</strong>
        </div>

        <div
          class="mb-3 minimumTimesContainer bg-light p-1"
          *ngIf="ShowCollection && ShowDelivery && MinDeliveryTime != 0"
        >
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Home')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Home'"
              name="homeRadio"
            />
            <label class="form-check-label cursor-pointer" for="homeRadio">
              <p class="m-0 text-center">Home Delivery</p>
              <small
                class="text-center"
                *ngIf="!DeliveryFrom && MinDeliveryTime"
                ><strong>
                  Approx
                  {{ MinDeliveryTime != null ? MinDeliveryTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="DeliveryFrom"
                ><strong>{{ DeliveryFrom }}</strong></small
              >
            </label>
          </div>
          <div
            class="form-check d-flex flex-column justify-content-center align-items-center border-start border-dark p-0 w-100 cursor-pointer"
            (click)="changeDeliveryType('Store')"
          >
            <input
              class="form-check-input m-0"
              type="radio"
              [checked]="deliveryType == 'Store'"
              name="collectionRadio"
            />
            <label
              class="form-check-label cursor-pointer"
              for="collectionRadio"
            >
              <p class="m-0 text-center">Collection</p>
              <small
                class="text-center"
                *ngIf="!CollectionFrom && MinCollectionTime"
                ><strong>
                  Approx
                  {{ MinCollectionTime != null ? MinCollectionTime : "N/A" }}
                  minutes
                </strong></small
              >
              <small class="d-block text-center" *ngIf="CollectionFrom"
                ><strong>{{ CollectionFrom }}</strong></small
              >
            </label>
          </div>
        </div>
        <div
          *ngIf="
            ShowDelivery && ShowCollection
              ? deliveryType == 'Home' &&
                postcodeValidation?.ShowPostcodeValidityField
              : postcodeValidation?.ShowPostcodeValidityField
          "
          class="mb-3 postCodeValidation"
        >
          <h4 class="text-center">
            <b>{{ postcodeValidation?.PostcodeValidityField.Title }} </b>
          </h4>
          <div class="postCodeWarapper">
            <div class="p-relative w-100">
              <input
                name="manualCode"
                type="text"
                class="form-control post-code postCodeInputField br-40px h-100"
                placeholder="Enter Post Code"
                (keyup.enter)="checkPostCodeValidity(manualPostCode)"
                maxlength="10"
                [(ngModel)]="manualPostCode"
                ngModel
              />
              <span
                class="spinner-border text-success"
                *ngIf="postSpinner"
              ></span>
            </div>
            <button
              class="btn checkBtn"
              [disabled]="!manualPostCode?.length"
              [ngStyle]="{
                'background-color': SelectedButtonColor,
                color: SelectedButtonTextColor
              }"
              type="button"
              (click)="checkPostCodeValidity(manualPostCode)"
            >
              Check Delivery
            </button>
          </div>
          <p class="text-success" *ngIf="validPostCode">
            We are delivering to <b>{{ enteredPostCode }}</b>
          </p>
          <p class="text-danger" *ngIf="InvalidPostCode">
            <b>{{ postcodeValidation?.PostcodeValidityField.ErrorMessage }}</b>
          </p>
        </div>
        <app-side-cart
          (checkout)="openCheckout()"
          (updateCart)="getCartTotal()"
        ></app-side-cart>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
