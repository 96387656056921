import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Subscription } from 'rxjs';
import { PayService } from './pay.service';
import { CheckoutDetail } from 'src/Model/interface/WebCustomer';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit, OnDestroy {
  SelectedButtonColor = '#B8860B';
  SelectedButtonTextColor = '#FFFFFF';
  showPaymentSense: boolean;
  amount: number = 0;
  amountInputReset: string;
  description: string;
  loadingPaypalAccount: boolean;
  loadingPaypalCard: boolean;
  brandFlavour: string;
  paypalSpinner: boolean;
  showMessage: boolean;
  message: string;
  handler: any;
  stripeKeySub: Subscription;
  cardID: string;
  email: string;
  brandBranchName: string;
  showStripe: boolean;
  checkoutConfig: CheckoutDetail;
  paymentGateway: string;
  webCusSub: Subscription;
  disableInput: boolean;
  desRequired: boolean;
  constructor(
    private apiService: ApiService,
    private payService: PayService,
    private route: ActivatedRoute
  ) {
    this.payService.orderId = +this.route.snapshot.queryParams['orderId'];
    
    if (!+this.route.snapshot.queryParams['orderId']) {
      this.desRequired = true;
    }

    if (this.route.snapshot.queryParams['totalBill']) {
      this.amountInputReset = this.route.snapshot.queryParams['totalBill'];
      this.payService.amount = this.amount = +this.amountInputReset;
      this.disableInput = true;
    }
  }
  ngOnInit(): void {
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      if (res.TemplateData.CheckoutDetail.PayPalEnabled) {
        this.loadingPaypalAccount = true;
        this.loadingPaypalCard = true;
      }
      this.paymentGateway = res.TemplateData.CheckoutDetail.PaymentGateway;
      this.brandBranchName = res.BrandData.BrandName;
      this.brandFlavour = res.BrandData.BrandFlavor;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;

      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.checkoutConfig = res.TemplateData.CheckoutDetail;
    });
  }

  isNumberKey(val: NgModel) {
    if (val.valid) {
      this.amount = +val.value;
    } else {
      this.amount = 0;
    }
    this.payService.amount = this.amount;
  }

  checkPaymentGateway() {
    if (this.paymentGateway == 'Stripe') {
      this.showStripe = true;
      return;
    }
    this.showPaymentSense = true;
  }

  response(res) {
    if (res?.error) {
      this.message = 'Something went wrong during payment';
      this.showMessage = true;
      return;
    }
    if (res.Info.Status == 200) {
      this.disableInput = false;
      this.amountInputReset = null;
      this.description = '';
      this.payService.description = this.description;
      this.showStripe = false;
      this.showPaymentSense = false;
      this.amount = null;
    }

    this.message = res.Info.Message;
    this.showMessage = true;
  }

  discriptionUpdate() {
    this.payService.description = this.description;
  }
}
