import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../service/api.service';
import { RequestService } from '../service/request.service';

@Component({
  selector: 'app-recent-order',
  templateUrl: './recent-order.component.html',
  styleUrls: ['./recent-order.component.scss']
})
export class RecentOrderComponent implements OnInit, OnDestroy {
  brandFlavour;
  HeaderBackgroundColor = "#DDDDDD";
  HeaderTextColor = "#000";
  postCode;
  recentOrders = []
  showMessage: boolean;
  @Input() deliveryType = 'Home';
  webCusSub: Subscription;
  constructor(private _requestService: RequestService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.brandFlavour = window.location.pathname.split('/')[1];
    this.getWebCustomer();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  getWebCustomer() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe(
      res => {
        if (!res) {
          return;
        }
        this.HeaderTextColor =
          res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderTextColor;
        this.HeaderBackgroundColor =
          res.TemplateData?.DynamicStyle?.PrimaryColors?.HeaderBackgroundColor;
        this.getRecentOrders();
      })
  }
  getRecentOrders() {
    this._requestService.getRequest('api/Order/getPlacedOrder/' + this.brandFlavour + '/0/20', []).subscribe(res => {
      if (res["Info"]["Status"] == 200) {
        this.recentOrders = res['Data'];
        if (this.recentOrders.length) {
          this.setMessage();
        }
      }
    })
  }
  setMessage() {
    let index = 0;
    let timeout = () => {
      setTimeout(() => {
        this.showMessage = false;
        index++;
        if (index < this.recentOrders.length) {
          this.postCode = this.recentOrders[index].DeliveryInfo.Postcode;
        }
        if (index == this.recentOrders.length) {
          index = 0;
        }
        showMessageLoop();
      }, 6000)
    }
    let showMessageLoop = () => {
      setTimeout(() => {
        this.showMessage = true;
        timeout();
      }, 6000);
    }

    this.postCode = this.recentOrders[0].DeliveryInfo.Postcode;
    this.showMessage = true;
    timeout();
  }
}
