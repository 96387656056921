<div class="container">
	<div class="row justify-content-center mt-4">
		<div class="col-lg-4 col-md-6 col-sm-8">
			<div
				class="border border-dark border-2 p-1 mb-3 mt-2 br-10px fs-2 d-md-none"
				[ngStyle]="{
					border: '1px solid ' + MobileMenuBorderColor,
					'background-color': MobileMenuBackgroundColor
				}"
			>
				<h1
					class="text-center m-0"
					(click)="openUrl(RedirectionFromLogin)"
					[ngStyle]="{ color: MobileMenuTextColor }"
				>
					Menu
				</h1>
			</div>

			<h1 class="text-center fs-4 fw-bolder">Forgot Password</h1>

			<form (ngSubmit)="getCode(form)" #form="ngForm" *ngIf="isEmailSection">
				<div class="inputContainer">
					<input
						type="email"
						id="email"
						name="username"
						class="form-control"
						(keyup)="focusInputs($event)"
						(click)="focusInputs($event)"
						required
						email
						ngModel
						#email="ngModel"
					/>
					<label class="fs-14 cursor-pointer" for="email">Email</label>
					<span
						*ngIf="submitted && email?.errors"
						class="form-text text-danger"
					>
						<span *ngIf="email.errors?.['required']">Email is required</span>
						<span *ngIf="email.errors?.['email']">Email Is Invalid</span>
					</span>
				</div>
				<button
					type="submit"
					[ngClass]="{
						'btn-secondary': form.invalid,
						'btn-success': form.valid
					}"
					class="btn w-100 br-40px"
				>
					Send Activation Code
				</button>
			</form>
			<form *ngIf="isCodeSection">
				<h4 class="text-light-black fw-600">
					Enter the code you got in your email.
				</h4>
				<div class="inputContainer">
					<input
						type="text"
						id="code"
						name="code"
						[(ngModel)]="codeFromUser"
						(input)="checkCode()"
						class="form-control form-control-submit"
						required
						maxlength="10"
					/>
					<label class="fs-14 cursor-pointer" for="code">Activation Code</label>
				</div>
			</form>
			<form
				(ngSubmit)="resetPasword(resetForm)"
				*ngIf="isResetSection"
				#resetForm="ngForm"
			>
				<h4 class="text-light-black fw-600">Reset your password now.</h4>
				<div class="inputContainer">
					<input
						type="password"
						id="password-fieldSignUp"
						name="password"
						class="form-control"
						required
						toggle
						(input)="matchPass(password, confirmPassword)"
						ngModel
						#password="ngModel"
					/>
					<label for="password-fieldSignUp"
						>Password* (8 character minimum)</label
					>
					<span *ngIf="password.errors" class="form-text text-danger">
						<span *ngIf="password.errors?.['required']">Password is required</span>
						<span *ngIf="password.errors?.['minlength']"
							>Password must be at least 8 characters long.</span
						>
					</span>
				</div>
				<div class="inputContainer">
					<input
						type="password"
						name="confirmpassword"
						class="form-control"
						required
						toggle
						id="confirmpassword"
						(input)="matchPass(password, confirmPassword)"
						ngModel
						#confirmPassword="ngModel"
					/>
					<label for="confirmpassword">Confirm Password</label>
					<span
						*ngIf="confirmPassword.errors?.['mustMatch']"
						class="form-text text-danger"
					>
						Passwords Do Not Match
					</span>
				</div>
				<button
					type="submit"
					[ngClass]="{
						'btn-secondary': resetForm.invalid,
						'btn-success': resetForm.valid
					}"
					class="btn w-100 br-40px"
				>
					Reset Password
				</button>
			</form>
			<div class="text-center mt-3" *ngIf="simpleCheckout">
				<p class="text-center">OR</p>
				<a
					href="javascript:void(0);"
					class="text-danger"
					(click)="openUrl('registeruser?guest=false')"
					>Create Your Account</a
				>
			</div>
		</div>
	</div>
</div>

<div
	class="overlay d-flex justify-content-center align-items-center"
	*ngIf="showMessage"
>
	<div
		class="content overflow-hidden bg-light br-40px d-flex justify-content-between flex-column"
	>
		<p class="text-center fs-4">{{ message }}</p>
		<button class="btn btn-danger" (click)="showMessage = false">Okay</button>
	</div>
</div>

<ngx-spinner
	bdColor="rgba(51, 51, 51, 0.8)"
	size="large"
	color="#dc3545"
	type="ball-scale-multiple"
>
</ngx-spinner>
