<div class="containerm mt-4 d-none d-md-block">
  <p class="text-center display-5">{{ categoryName }}</p>
  <app-product-grid-style
    [products]="products"
    [redirectToDetails]="true"
  ></app-product-grid-style>
</div>
<div class="containerm mt-4 d-md-none d-block">
  <p class="text-center display-5">{{ categoryName }}</p>
  <app-responsive-grid-style
    [products]="products"
    [redirectToDetails]="true"
  ></app-responsive-grid-style>
</div>
