import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideCartComponent } from '../side-cart/side-cart.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PopupComponent } from '../popup/popup.component';
import { RecentOrderComponent } from '../recent-order/recent-order.component';
import { RecipeCardListStyleComponent } from '../cards/recipe-card-list-style/recipe-card-list-style.component';
import { SelectDeliveryTypeComponent } from '../cards/select-delivery-type/select-delivery-type.component';
import { ResponsiveGridStyleComponent } from '../cards/product/responsive-grid-style/responsive-grid-style.component';
import { ProductGridStyleComponent } from '../cards/product/product-grid-style/product-grid-style.component';
import { FormsModule } from '@angular/forms';
import { StripeComponent } from '../stripe/stripe.component';
import { ToggleDirective } from '../Directives/toggle.directive';
import { PaypalBtnComponent } from '../paypalButtons/paypal-btn/paypal-btn.component';
import { PaypalCardComponent } from '../paypalButtons/paypal-card/paypal-card.component';
import { PaymentSenseComponent } from '../payment-sense/payment-sense.component';
import { StoreProductComponent } from '../cards/product/store-product/store-product.component';
import { InquiryPopupComponent } from '../store/inquiry-popup/inquiry-popup.component';
import { SubcatDetailsComponent } from '../store/subcat-details/subcat-details.component';

@NgModule({
  declarations: [
    PaypalBtnComponent,
    PaypalCardComponent,
    SideCartComponent,
    PopupComponent,
    RecentOrderComponent,
    RecipeCardListStyleComponent,
    SelectDeliveryTypeComponent,
    ResponsiveGridStyleComponent,
    ProductGridStyleComponent,
    StripeComponent,
    ToggleDirective,
    PaymentSenseComponent,
    StoreProductComponent,
    InquiryPopupComponent,
    SubcatDetailsComponent,
  ],
  imports: [CommonModule, NgxSpinnerModule, FormsModule],
  exports: [
    SelectDeliveryTypeComponent,
    SideCartComponent,
    NgxSpinnerModule,
    PopupComponent,
    RecentOrderComponent,
    RecipeCardListStyleComponent,
    ResponsiveGridStyleComponent,
    ProductGridStyleComponent,
    StripeComponent,
    ToggleDirective,
    PaypalBtnComponent,
    PaypalCardComponent,
    PaymentSenseComponent,
    StoreProductComponent,
    InquiryPopupComponent,
    SubcatDetailsComponent,
  ],
})
export class SharedModule {}
