import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GetterService } from './getter-service.service';
import { CartProdut } from 'src/Model/interface/chckout-Interfaces';
import { Category, SubCategory } from 'src/Model/interface/productInterfaces';
interface SideMenuData {
  cat: Category;
  subCat: SubCategory;
}
@Injectable({
  providedIn: 'root',
})
export class RequestService {
  redirectToComponent: string;
  cartCounter: number = 0;
  postCode: string = ''; // to populate checkout postcode from menu
  headerCheckoutBtn = new Subject<null>();
  sideCatChanged = new Subject<SideMenuData>();
  /* Delivery Type */
  deliveryType: string;
  storageChange = new Subject<void>();
  /* For updating delivery type from header component to other components */
  deliveryType$: Subject<string> = new Subject<string>();
  /* TemplateType */

  constructor(private _http: HttpClient, private _local: GetterService) {}

  getRequest(url, header) {
    return this._http.get(environment.apiUrl + url, { headers: header });
  }

  deleteRequest(url, header) {
    return this._http.delete(environment.apiUrl + url, { headers: header });
  }

  postRequest(url, params, header = null): Observable<any> {
    return this._http.post<any>(environment.apiUrl + url, params, {
      headers: header,
    });
  }

  putRequest(url, params, header = null) {
    return this._http.put(environment.apiUrl + url, params, {
      headers: header,
    });
  }

  isLoggedIn() {
    return !!this._local.get('access_token');
  }

  saveProductCartItems(products: CartProdut[]) {
    this._local.set('cartProductItems', JSON.stringify(products));
    this.cartCounter = products.length;
    this.storageChange.next();
  }

  saveRecipeCartItems(recipes) {
    this._local.set('cartRecipeItems', JSON.stringify(recipes));
    this.cartCounter = recipes.length;
    this.storageChange.next();
  }

  setUrl(pageName) {
    let brandFlavour = window.location.pathname.split('/')[1];
    return brandFlavour + '/' + pageName;
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
  getRecipiesItemsForSideCart() {
    const recipes = this._local.get('cartRecipeItems')
      ? JSON.parse(this._local.get('cartRecipeItems'))
      : [];
    return recipes;
  }
  getProductItemsForSideCart() {
    const products = this._local.get('cartProductItems')
      ? JSON.parse(this._local.get('cartProductItems'))
      : [];
    return products;
  }
  getCartRecipeItems() {
    const recipes = [];
    let recipeCart = JSON.parse(this._local.get('cartRecipeItems'));
    recipeCart?.forEach((value) => {
      let extraArray: any[] = [];
      if (
        value.RecipeExtraObject != null &&
        value.RecipeExtraObject.length != 0
      ) {
        value.RecipeExtraObject.forEach((extra) => {
          extraArray.push({
            ExtraTitle: extra.Title,
            ExtraQuantity: extra.Quantity,
            ExtraPrice: extra.Price,
          });
        });
      }
      recipes.push({
        ItemId: value.RecipeId,
        Quantity: value.RecipeQuantity,
        TotalPrice: value.RecipePrice,
        IsDiscountedItem: value.IsDiscountedItem
          ? value.IsDiscountedItem
          : false,
        SocialDiscountItem: value?.socialDiscountItem
          ? value?.socialDiscountItem
          : false,
        ExtraDetail: JSON.stringify(extraArray),
      });
    });
    return recipes;
  }
  getProductCartItems() {
    const products = [];
    let productCart = JSON.parse(this._local.get('cartProductItems'));
    productCart?.forEach((value) => {
      let extraArray: any[] = [];
      if (
        value.ProductExtraObject != null &&
        value.ProductExtraObject.length != 0
      ) {
        value.ProductExtraObject.forEach((extra) => {
          extraArray.push({
            ExtraTitle: extra.Title,
            ExtraQuantity: extra.Quantity,
            ExtraPrice: extra.Price,
          });
        });
      }
      products.push({
        ItemId: value.ProductId,
        Quantity: value.ProductQuantity,
        TotalPrice: value.ProductOldPrice,
        IsDiscountedItem: value.IsDiscountedItem
          ? value.IsDiscountedItem
          : false,
        SocialDiscountItem: value?.socialDiscountItem
          ? value?.socialDiscountItem
          : false,
        ExtraDetail: JSON.stringify(extraArray),
      });
    });
    return products;
  }
}
