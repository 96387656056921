import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { StoreComponent } from './store.component';
import { SharedModule } from '../shared/shared.module';
import { SubCategoriesComponent } from './sub-categories/sub-categories.component';
import { ErrorHandlingService } from '../service/error-handling.service';

@NgModule({
  declarations: [StoreComponent, SubCategoriesComponent],
  imports: [CommonModule, StoreRoutingModule, SharedModule],
  providers: [{ provide: ErrorHandler, useClass: ErrorHandlingService }],
})
export class StoreModule {}
