import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderRoutingModule } from './online-routing.module';
import { OnlineComponent } from './online.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { ErrorHandlingService } from '../service/error-handling.service';
import { FormsModule } from '@angular/forms';
 
@NgModule({ 
  declarations: [OnlineComponent],
  imports: [
    SharedModule,
    CommonModule,
    HttpClientModule,
    OrderRoutingModule,
    FormsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlingService }
  ]
})
export class OrderModule {}
