<!-- Tabs -->
<div class="tab-button-div container p-0 mt-4" *ngIf="loggedIn">
  <button
    class="tab-button"
    type="button"
    (click)="toggle('new')"
    [ngStyle]="
      showTab == 'new'
        ? {
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }
        : {}
    "
  >
    New
  </button>
  <button
    class="tab-button"
    type="button"
    (click)="toggle('redeem')"
    [ngStyle]="
      showTab == 'redeem'
        ? {
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }
        : {}
    "
  >
    Redeem
  </button>
  <button
    class="tab-button"
    type="button"
    (click)="toggle('existing')"
    [ngStyle]="
      showTab == 'existing'
        ? {
            'background-color': SelectedButtonColor,
            color: SelectedButtonTextColor
          }
        : {}
    "
  >
    Existing
  </button>
</div>
<!-- end Tabs -->

<!-- send gift card -->

<div class="container mt-2" *ngIf="showTab == 'new'">
  <div class="row">
    <div class="col-md-4 d-flex align-items-center">
      <div class="card-form" *ngIf="this.giftDesign != null">
        <div *ngFor="let item of giftDesign">
          <img [src]="item" alt="Gift card Image" />
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <form #Form="ngForm" (ngSubmit)="checkPaymentGateway()">
        <!-- <h2 class="heading-gift">{{ Title }}</h2> -->
        <p class="gift-para">Enter an email address to send gift</p>
        <div class="col-md-12 inputContainer form group">
          <input
            [(ngModel)]="radioAccess"
            (input)="radioAccessChange($event)"
            type="email"
            class="form-control"
            rows="1"
            name="contact"
            id="contact"
            required
            maxlength="50"
            [ngClass]="{ 'is-invalid': submitted && !radioAccess }"
            #channel="ngModel"
            ngModel
            email
          />

          <label for="contact" class="contactway"> Email</label>
          <div *ngIf="submitted && !radioAccess" class="help-block text-danger">
            Email is required
          </div>
          <div
            *ngIf="channel.invalid && channel.touched"
            class="help-block text-danger"
          >
            Enter Valid Email
          </div>
        </div>
        <div class="Amounts">
          <p class="gift-para">Select Amount</p>
          <div class="select-amount">
            <button
              (click)="amountSelected(item)"
              *ngFor="let item of Dominations"
              type="button"
              class="amount-btn"
              [ngStyle]="
                amountAcceess == item
                  ? {
                      border: '1px solid ' + SelectedButtonColor,
                      'background-color': SelectedButtonColor,
                      color: SelectedButtonTextColor
                    }
                  : { border: '1px solid ' + SelectedButtonColor }
              "
            >
              {{ item | currency: "GBP":"symbol":"1.0-0" }}
            </button>
            <div class="p-relative">
              <span class="euroSign" *ngIf="otherAmount?.value">£</span>
              <input
                type="text"
                name="amount"
                id="amount"
                placeholder="Other"
                class="amount-btn-input"
                [ngStyle]="{ border: '1px solid ' + SelectedButtonColor }"
                ngModel
                #otherAmount="ngModel"
                pattern="[0-9]+(\.[0-9]{1,2})?"
                (input)="isNumberKey(otherAmount)"
              />
              <p
                *ngIf="otherAmount.invalid"
                class="m-0 text-center text-danger amountInvalidText"
              >
                Invalid
              </p>
              <p
                *ngIf="otherAmount.value > 300 && otherAmount.valid"
                class="m-0 text-center text-danger amountInvalidText"
              >
                Max {{ 300 | currency: "GBP":"symbol":"1.2-2" }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12 inputContainer">
          <textarea
            [(ngModel)]="messageAccess"
            id="note"
            class="form-control"
            name="note"
            rows="4"
            cols="50"
            maxlength="99"
            (keyup)="focusTextArea($event)"
            style="max-height: 250px; margin-top: 30px"
          ></textarea>
          <label for="note" class="contactway">Note (Optional)</label>
        </div>
        <div class="w-450px mx-auto mt-2 p-relative">
          <div
            class="overlay overlayPayment d-flex justify-content-center align-items-center"
            *ngIf="Form.invalid || !amountAcceess"
          ></div>
          <button
            class="paymentBtn br-40px dynamicButtonHeight mb-2 btn"
            type="submit"
            [disabled]="Form.invalid || !amountAcceess"
            [ngStyle]="
              Form.invalid || !amountAcceess
                ? {
                    'background-color': SelectedButtonColor,
                    color: SelectedButtonTextColor,
                    opacity: '0.7'
                  }
                : {
                    'background-color': SelectedButtonColor,
                    color: SelectedButtonTextColor
                  }
            "
          >
            Debit/Credit Card
          </button>
          <div class="mb-1" *ngIf="checkoutConfig?.PayPalEnabled">
            <app-paypal-btn
              [total]="amountAcceess"
              (response)="response($event)"
            ></app-paypal-btn>
          </div>
          <div class="mb-2" *ngIf="checkoutConfig?.PayPalEnabled">
            <app-paypal-card
              [total]="amountAcceess"
              (response)="response($event)"
            ></app-paypal-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- History show section -->
<div class="container" *ngIf="showTab == 'existing'">
  <div class="existingCardBox mt-4" *ngFor="let item of showHistory">
    <div class="card-img">
      <img [src]="item.QRImage" alt="QR Image" />
    </div>
    <div class="details">
      <p class="text-center m-0">
        Recipient : {{ item.Recipient.CustomerEmail }}
      </p>

      <p class="text-center m-0">
        Balance : {{ item.Balance | currency: "GBP":"symbol":"1.0-0" }}
      </p>
      <p class="gift-message" *ngIf="item.GiftCard.Message?.length">
        {{ item.GiftCard.Message }}
      </p>
    </div>
  </div>

  <!-- No History -->
  <div *ngIf="showHistory.length === 0">
    <div class="gift-img">
      <img
        src="https://perkss.blob.core.windows.net/images/customer/gift/gift_web.gif"
        alt=""
      />
    </div>
    <div class="history-not">
      <h1>Oops... You don't have any history</h1>
    </div>
    <div class="btn-historyNot">
      <button
        class="send-gift"
        (click)="toggle('new')"
        [ngStyle]="{
          'background-color': SelectedButtonColor,
          color: SelectedButtonTextColor
        }"
      >
        Send Gift Now
      </button>
    </div>
  </div>
  <!-- End No History -->
</div>
<div
  class="container mt-4 d-flex justify-content-center align-items-center"
  *ngIf="showTab == 'redeem'"
>
  <div class="redeemContentContainer">
    <div *ngFor="let item of giftDesign" class="redeemImgContainer">
      <img [src]="item" alt="" />
    </div>
    <div class="existingBalance">
      <p>
        Your Gift Card Balance:&nbsp;&nbsp;<span class="text-success">{{
          customerBalance | currency: "GBP":"symbol":"1.2-2"
        }}</span>
      </p>
    </div>
    <div>
      <p>Enter Your Gift Card Code</p>
      <input
        type="text"
        [(ngModel)]="promotionCode"
        name="redeemInput"
        id="redeemInput"
        class="redeemInput br-40px form-control"
        (keyup.enter)="redeemCard()"
      />
      <p
        class="text-success"
        *ngIf="redeemSuccess == 'Gift voucher successfully redeemed'"
      >
        {{ redeemSuccess }}
      </p>
      <p
        class="text-danger"
        *ngIf="
          redeemSuccess !== 'Gift voucher successfully redeemed' &&
          redeemSuccess.length
        "
      >
        {{ redeemSuccess }}
      </p>
      <button
        type="button"
        class="redeemBtn btn btn-warning br-40px"
        (click)="redeemCard()"
        [disabled]="redeemingCard"
      >
        Apply to your balance
        <span
          class="spinner-border spinner-border-sm"
          *ngIf="redeemingCard"
        ></span>
      </button>
    </div>
  </div>
</div>
<!-- History end section -->
<div
  [ngClass]="{ 'd-none': !showStripe }"
  class="overlay d-flex justify-content-center align-items-center flex-column"
  (click)="showStripe = false"
>
  <app-stripe
    (response)="response($event)"
    [total]="amountAcceess"
  ></app-stripe>
</div>
<div
  *ngIf="showPaymentSense"
  class="overlay d-flex justify-content-center align-items-center flex-column"
  (click)="showPaymentSense = false"
>
  <app-payment-sense
    [total]="amountAcceess"
    (response)="response($event)"
  ></app-payment-sense>
</div>

<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showMessage"
>
  <div
    class="content bg-light d-flex justify-content-between align-items-center flex-column overflow-hidden"
  >
    <p class="fs-5 text-center p-1">{{ message }}</p>
    <button
      (click)="showMessage = false"
      class="closeBtn w-100 text-dark text-center fs-6 p-2"
    >
      Close
    </button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="#dc3545"
  type="ball-scale-multiple"
>
</ngx-spinner>
