<div class="overlay" (click)="closePopup()" *ngIf="showPopup">
  <div
    class="content br-40px m-1 bg-white overflow-hidden"
    (click)="stopPropagation($event)"
  >
    <div class="bg-light position-relative">
      <h2 class="text-center m-0 p-2">{{ title }}</h2>
      <button
        class="btn position-absolute top-0 end-0 fs-2 text-danger"
        (click)="closePopup()"
      >
        &times;
      </button>
    </div>
    <div #body class="popup-body p-2"></div>
    <div class="footer">
      <button class="btn btn-danger w-100" (click)="closePopup()">Close</button>
    </div>
  </div>
</div>
