import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RequestService } from "../service/request.service";
import { GetterService } from "../service/getter-service.service";
import { HttpHeaders } from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ApiService } from "../service/api.service";
import * as bootstrap from "bootstrap";
import { Subscription } from "rxjs";

@Component({
  selector: "my-rewards",
  templateUrl: "./my-rewards.component.html",
  styleUrls: ["./my-rewards.component.scss"],
})
export class MyRewardsComponent implements OnInit, OnDestroy {
  regularRewards: any[] = [];
  activatedRewards: any[] = [];
  redeemedRewards: any[] = [];
  activatingReward: boolean;
  loyaltyCardObj: any;

  rewardPoints: number;
  customerId: number;

  currentRewardObj: any;
  currentRewardRemaningPoint: number;
  currentRewardTitle: string;
  currentRewardMaxPoint: number;
  currentRewardPointsInPercent: number;
  isCurrentRewardUnlocked: boolean = false;
  customerBalance: number = 0;
  brandId: number;
  isReward: boolean = true;
  rewardInfoData: any;
  loyaltyRewardId: any;
  rewardQrImage: any;
  customerRewardPoints: any;
  recentHistoryList: any = [];
  SelectedButtonColor: string = "#B8860B";
  SelectedButtonTextColor: any = "#fff";
  promotionCode: string = "GC-";

  /*Snack Bar */
  private configSuccess: MatSnackBarConfig<any> = {
    panelClass: ["greensnack"],
    verticalPosition: "bottom",
    duration: 2000,
  };
  private configUnSuccess: MatSnackBarConfig<any> = {
    panelClass: ["redsnack"],
    verticalPosition: "bottom",
    duration: 2000,
  };
  rewardModal: bootstrap.Modal;
  webCusSub: Subscription;

  constructor(
    private _router: Router,
    private _requestService: RequestService,
    private _snackBar: MatSnackBar,
    private _local: GetterService,
    private apiService: ApiService
  ) {}
  ngOnInit(): void {
    this.customerId = JSON.parse(this._local.get("CustomerId"));
    this.getCustomerRewards();
    this.getRewardInfo();
    this.scanQRCard();
    this.dynamicCss();
  }
  ngOnDestroy(): void {
    this.webCusSub.unsubscribe();
  }
  scanQRCard() {
    let customerId = this._local.get("CustomerId");
    let brandId = this._local.get("BrandId");
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });
    this._requestService
      .getRequest(
        "api/LoyaltyAccountHolder/getCards/" +
          customerId +
          "/" +
          brandId +
          "/0/1",
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this.rewardInfoData = res["Data"];
          this.rewardInfoData.forEach((element) => {
            this.loyaltyRewardId = element.Id;
            this.rewardQrImage = element.QrImageUrl;
            this.customerRewardPoints = element.Points;
          });
          this.getRecentHistory();
        }
      });
  }

  dynamicCss() {
    this.webCusSub = this.apiService.getWebcustomer$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.brandId = res.BrandData.BrandId;
      this.SelectedButtonColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonColor;
      this.SelectedButtonTextColor =
        res.TemplateData.DynamicStyle.SecondaryColors.SelectedButtonTextColor;
      this.getBalance();
    });
  }
  toggle(value: boolean) {
    this.isReward = value;
  }

  openUrl(link: string) {
    this._router.navigateByUrl(this._requestService.setUrl(link));
  }

  openActivateDialog(rewardObj: any) {
    this.currentRewardObj = rewardObj;
    this.rewardModal = new bootstrap.Modal(
      document.getElementById("rewardModal"),
      {}
    );
    this.rewardModal.show();
  }

  getCustomerRewards() {
    let brandId = this._local.get("BrandId");
    let customerId = this._local.get("CustomerId");
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });
    this._requestService
      .getRequest(
        "api/Reward/getCustomerRewards/" + customerId + "/" + brandId,
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this.regularRewards = res["Data"]["RegularRewards"];
          this.rewardPoints = res["Data"]["LoyaltyCard"]["Points"];
          this.redeemedRewards = res["Data"]["RedeemedRewards"];
          
          this.activatedRewards = res["Data"]["ActivatedRewards"];
          this.loyaltyCardObj = res["Data"]["LoyaltyCard"];
          this._local.set(
            "RewardQrImageUrl",
            res["Data"]["LoyaltyCard"]["QrImageUrl"]
          );
          this._local.set(
            "CustomerRewardPoints",
            res["Data"]["LoyaltyCard"]["Points"]
          );
          this.onRegularRewardClicked(this.regularRewards[0]);
        }
      });
  }

  onRegularRewardClicked(rewardObj: any) {
    let netPoint: number = rewardObj.Redemption - this.rewardPoints;
    if (netPoint > 0) {
      this.isCurrentRewardUnlocked = false;
      this.currentRewardRemaningPoint = netPoint;
      this.currentRewardTitle = rewardObj.Title;
      this.currentRewardMaxPoint = rewardObj.Redemption;
      this.currentRewardPointsInPercent =
        (this.rewardPoints / rewardObj.Redemption) * 100;
    } else {
      this.currentRewardTitle = rewardObj.Title;
      this.isCurrentRewardUnlocked = true;
      this.currentRewardPointsInPercent =
        (this.rewardPoints / rewardObj.Redemption) * 100;
    }
  }
  getBalance() {
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });

    this._requestService
      .getRequest(
        "api/Wallet/GetBalance/" + this.brandId + "/" + this.customerId,
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this.customerBalance = res["Amount"];
        } else {
        }
      });
  }
  getRewardInfo() {
    let customerId = this._local.get("CustomerId");
    let brandId = this._local.get("BrandId");
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });
    this._requestService
      .getRequest(
        "api/LoyaltyAccountHolder/getCards/" +
          customerId +
          "/" +
          brandId +
          "/0/1",
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this.rewardInfoData = res["Data"];
          this.rewardInfoData.forEach((element) => {
            this.loyaltyRewardId = element.Id;
            this.rewardQrImage = element.QrImageUrl;
            this.customerRewardPoints = element.Points;
          });
          this.getRecentHistory();
        }
      });
  }

  getRecentHistory() {
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });

    this._requestService
      .getRequest(
        "api/LoyaltyAccountHolderHistory/getCardRecentHistory/" +
          this.loyaltyRewardId +
          "/0/50",
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this.recentHistoryList = res["Data"];
          this.customerRewardPoints =
            this.recentHistoryList[0].CurrentLoadedPoints;
        }
      });
  }

  activateReward() {
    this.activatingReward = true;
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });

    let activateParam = {
      RewardHistory: {
        FK_CustomerId: this.customerId,
        Fk_RewardId: this.currentRewardObj.Id,
        Status: true,
      },
      LoyaltyAccountHolder: {
        Id: this.loyaltyCardObj.Id,
        Identity: this.loyaltyCardObj.Identity,
        Points: this.loyaltyCardObj.Points,
        FK_LoyaltyAccountId: this.loyaltyCardObj.FK_LoyaltyAccountId,
        FK_CustomerId: this.loyaltyCardObj.FK_CustomerId,
      },
    };

    this._requestService
      .postRequest("api/Reward/postRewardHistory", activateParam, httpHeaders)
      .subscribe({
        next: (res) => {
          this.activatingReward = false;
          this.rewardModal.hide();
          if (res != null) {
            this.getCustomerRewards();
            this.getBalance();
            alert("Reward has been activated successfully");
          } else {
            alert("Activation NOT Successful");
          }
        },
        error: () => {
          this.rewardModal.hide();
          alert("Activation NOT Successful.");
        },
      });
  }

  sliderRight(id: string) {
    let customSliderWrapper =
      id == "redeemSlider"
        ? document.getElementById("redeemSlider")
        : document.getElementById("UpcomingSlider");
    let sliderWidth = customSliderWrapper.clientWidth;
    if (customSliderWrapper.scrollLeft) {
      customSliderWrapper.scrollLeft =
        customSliderWrapper.scrollLeft + sliderWidth;
    } else {
      customSliderWrapper.scrollLeft = sliderWidth;
    }
  }
  sliderLeft(id: string) {
    let customSliderWrapper =
      id == "redeemSlider"
        ? document.getElementById("redeemSlider")
        : document.getElementById("UpcomingSlider");
    let sliderWidth = customSliderWrapper.clientWidth;
    customSliderWrapper.scrollLeft =
      customSliderWrapper.scrollLeft - sliderWidth;
  }
  redeemGiftCard() {
    const brandId = this._local.get("BrandId");
    const customerId = this._local.get("CustomerId");
    const param = {
      QrIdentity: null,
      Code: this.promotionCode,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: "bearer " + this._local.get("access_token"),
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    });

    this._requestService
      .postRequest(
        "api/Wallet/RedeemGiftCard/" + brandId + "/" + customerId,
        JSON.stringify(param),
        httpHeaders
      )
      .subscribe((res) => {
        if (res["Info"]["Status"] == 200) {
          this._snackBar.open(
            "Code has been applied successfully",
            "Close",
            this.configSuccess
          );
          this.getBalance();
        } else {
          //configUnSuccess
          this._snackBar.open(
            "Code has been not applied successfully",
            "Close",
            this.configUnSuccess
          );
        }
      });
  }
}
