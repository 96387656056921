import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetterService } from '../service/getter-service.service';
import { RequestService } from '../service/request.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss'],
})
export class UpdateAccountComponent implements OnInit {
  updateForm: FormGroup;
  /* Brand Info */
  brandId: any;
  /* Update Form */
  firstName: string;
  lastName: string;
  submitted: boolean = false;
  message: string;
  showMessage: boolean;

  get updateUserForm() {
    return this.updateForm.controls;
  }

  constructor(
    private _requestService: RequestService,
    private _local: GetterService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.updateForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      contactNo: [null, [Validators.required, Validators.pattern]],
    });
  }
  matchPass() {
    if (
      this.updateForm.controls['password'].value !=
      this.updateForm.controls['confirmpassword'].value
    ) {
      return this.updateForm.controls['confirmpassword'].setErrors({
        mustMatch: true,
      });
    } else {
      if (this.updateForm.controls['confirmpassword'].hasError('mustMatch')) {
        this.updateForm.controls['confirmpassword'].setErrors(null);
      }
    }
  }

  updateUser() {
    this.submitted = true;
    if (this.updateForm.invalid) return;
    this.spinner.show();
    let email = this._local.get('username');
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + this._local.get('access_token'),
    });

    let updateUserForm = this.updateForm.controls;
    if (this.updateForm.invalid) {
      this.spinner.hide();
      return;
    }

    let params = new HttpParams();
    params = params.append(
      'CustomerFirstName',
      updateUserForm['firstName'].value
    );
    params = params.append(
      'CustomerLastName',
      updateUserForm['lastName'].value
    );
    params = params.append('CustomerMobile', updateUserForm['contactNo'].value);
    params = params.append('CustomerImageUrl', null);
    params = params.append('CustomerEmail', email);
    params = params.append('FK_BrandId_Customer', this._local.get('BrandId'));

    this._requestService
      .putRequest('api/Customer/update', params, httpHeaders)
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          if (res.Info.Status === 200) {
            this._local.set(
              'CustomerFirstName',
              updateUserForm['firstName'].value
            );
            this._local.set(
              'CustomerLastName',
              updateUserForm['lastName'].value
            );
            window.location.reload();
            return;
          }
          this.message = 'Update unsuccessfull';
          this.showMessage = true;
        },
        error: () => {
          this.spinner.hide();
          this.message = 'Network Error! something went wrong please try again';
          this.showMessage = true;
        },
      });
  }

  focusphoneSignUp(event: Event) {
    const phone = event.target as HTMLInputElement;
    if (phone.value) {
      phone?.classList.add('in-focus');
    } else {
      phone?.classList.remove('in-focus');
    }
  }
}
